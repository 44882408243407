import { subscriberInformation } from '../../services/subscriber/accountService';

export const fetchSubscriberInformation = () => async (dispatch, getState) => {
  try {
    const { login: { companyId } } = getState();
    const response = await subscriberInformation(companyId);
    if (!response.success) throw Error;

    dispatch({
      type: 'FETCH_SUBSCRIBER_INFORMATION_SUCCESS',
      payload: response
    });
  } catch (e) {
    dispatch({ type: 'FETCH_SUBSCRIBER_INFORMATION_FAIL' });
  }
};
