import API from '../baseService';

export const biddingPositionsResults = companyId => {
  return API.get(`/subscribers/${companyId}/bidforplacement`);
};

export const createBidCart = ({ companyId, metro }) =>
  API.post(`/subscribers/${companyId}/bidforplacement`, {
    metros: [metro]
  }).then(response => response.data);

export const getBidCart = ({ companyId, guid }) =>
  API.get(
    `/subscribers/${companyId}/bidforplacement/${guid}/confirmation`
  ).then(response => response.data);

export const submitBidCart = ({ companyId, guid }) =>
  API.post(
    `/subscribers/${companyId}/bidforplacement/${guid}/confirmation`
  ).then(response => response.data);
