import * as React from "react";

import withTracker from "./withTracker";
import Confirmation from "../containers/Confirmation";

const confirmation: {
  path: string,
  component: React.ComponentType<{}>
} = {
  path: "/confirmation",
  component: withTracker(Confirmation)
};

export default confirmation;
