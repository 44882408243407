const INITIAL_STATE = {
  reportsLoaded: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SUBSCRIBER_REPORTS_REQUEST':
      return { ...state, reportsLoaded: false };
    case 'SUBSCRIBER_REPORTS_SUCCESS':
      return { ...state, ...action.payload, reportsLoaded: true };
    case 'SUBSCRIBER_REPORTS_FAILURE':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
