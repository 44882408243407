import withAuthentication, { ROUTE_TYPES } from '../withAuthentication';
import withTracker from '.././withTracker';
import Company from '../../containers/subscriber/Company/Company';

const subscriberCompany = {
  path: '/subscribers/company/:showNewAccountModal?',
  component: withTracker(withAuthentication(Company, ROUTE_TYPES.SUBSCRIBER))
};

export default subscriberCompany;
