import API from './baseService';
import axios from 'axios';

export const associations = () =>
  API.get('subscribers/registration/associations').then(
    response => response.data
  );

export const metroStates = () =>
  API.get('subscribers/registration/metro_states').then(
    response => response.data
  );

export const createSubscriberAccount = data => {
  const associations = data.certifications.concat(
    data.national,
    data.stateAndRegional
  );
  return API.post('/subscribers/registration/register', {
    company_name: data.companyName,
    company_phone: data.companyPhone,
    company_website: data.companyWebsite,
    city: data.city,
    zip_code: data.zipCode,
    address: data.address,
    address_line_2: data.addressLine2,
    state: data.state,
    contact_name: data.contactName,
    contact_phone: data.contactPhone,
    contact_phone_extension: data.contactPhoneExtension,
    contact_email: data.contactEmail,
    dot_number: data.dotNumber,
    associations: associations,
    login_email: data.loginEmail,
    login_password: data.password,
    login_password_confirm: data.confirmPassword,
    agree_to_terms: data.terms,
    login_name: data.loginName
  }).then(response => response.data);
};
export const checkDotNumber = data =>
   API.get('/check/dot/number', {
    dotNumber: data
  }).then(response => response.data)
;

export const checkRegisterInfo = data =>
    API.post('/check/register/info', {
      dot_number: data.dotNumber,
      contact_email: data.contactEmail,
      city: data.city,
      address: data.address,
      state: data.state
    }).then(response => response.data)
;

