// @flow

import { persistStore } from 'redux-persist';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import reducers from './';

export default function configureStore() {
  const store = createStore(
    reducers,
    {},
    composeWithDevTools(applyMiddleware(thunk))
  );

  let persistor = persistStore(store);
  return { persistor, store };
}
