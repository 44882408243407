import {
  addFleet,
  amenities,
  deleteFleet,
  listingFleet,
  listingResults,
  updateCompanyInformationResults,
  updateFleet,
  updateListingContactResults,
  updateListingEmailResults,
} from '../../services/subscriber/listingService';
import { formatListingEmailsRequest } from '../../utils/formatUtils';

export const fetchListing = companyMetroId => async dispatch => {
  dispatch({
    type: 'SUBSCRIBER_LISTING_REQUEST'
  });

  try {
    const listingResponse = await listingResults(companyMetroId);
    const fleetResponse = await listingFleet(companyMetroId);
    const amenitiesResponse = await amenities(companyMetroId);

    const {
      address1,
      city,
      state,
      zip,
      description,
      website,
      phone,
      email,
      alias,
      facebookUrl,
      twitterUrl,
      instagramUrl,
      youtubeUrl,
      associationsAndCertifications,
      licensingAndInsurance,
      averageRating,
      usDotLink,
      isUmaMember
    } = listingResponse.data.company;

    const {
      additionalEmails,
      busResults,
      metro,
      photos,
      reviews,
      paymentTypes,
      errorMessage,
      success
    } = listingResponse.data;

    if (success) {
      const { buses } = fleetResponse;

      dispatch({
        type: 'SUBSCRIBER_LISTING_REQUEST_SUCCESS',
        payload: {
          address1,
          amenitiesOptions: amenitiesResponse,
          buses,
          city,
          state,
          zip,
          description,
          website,
          phone,
          email,
          alias,
          facebookUrl,
          twitterUrl,
          instagramUrl,
          youtubeUrl,
          associationsAndCertifications,
          licensingAndInsurance,
          averageRating,
          usDotLink,
          isUmaMember,
          busResults,
          additionalEmails,
          photos,
          reviews,
          paymentTypes,
          metro
        }
      });
    } else {
      dispatch({
        type: 'SUBSCRIBER_LISTING_REQUEST_FAILURE',
        payload: {
          error: errorMessage
        }
      });
    }
  } catch (error) {
    dispatch({
      type: 'SUBSCRIBER_LISTING_REQUEST_FAILURE',
      payload: error
    });
  }
};

export const updateCompanyInformation = ({
  values,
  callback,
  companyMetroId
}) => async dispatch => {
  dispatch({
    type: 'SUBSCRIBER_LISTING_UPDATE_COMPANY_INFORMATION_REQUEST'
  });

  const { nameOfCompany } = values;

  try {
    const response = await updateCompanyInformationResults({
      company_metro_id: companyMetroId,
      name: nameOfCompany
    });

    if (response && response.data) {
      if (response.data.success) {
        dispatch({
          type: 'SUBSCRIBER_LISTING_UPDATE_COMPANY_INFORMATION_REQUEST_SUCCESS',
          payload: {
            success: true,
            alias: nameOfCompany
          }
        });
        callback();
      } else {
        dispatch({
          type: 'SUBSCRIBER_LISTING_UPDATE_COMPANY_INFORMATION_REQUEST_FAILURE',
          payload: {
            success: false,
            error: response.data.errorMessage
          }
        });
      }
    }
  } catch (error) {
    dispatch({
      type: 'SUBSCRIBER_LISTING_UPDATE_COMPANY_INFORMATION_REQUEST_FAILURE',
      payload: {
        success: false,
        error: error
      }
    });
  }
};

export const updateListingContactInformation = ({
  values,
  callback,
  companyMetroId
}) => async dispatch => {
  dispatch({
    type: 'SUBSCRIBER_LISTING_UPDATE_CONTACT_REQUEST'
  });

  const {
    email,
    phone,
    website,
    description,
    address1,
    city,
    state,
    zip
  } = values;

  try {
    const response = await updateListingContactResults({
      company_metro_id: companyMetroId,
      email,
      phone,
      website,
      description,
      address: address1,
      city,
      state,
      zip
    });

    if (response && response.data) {
      if (response.data.success) {
        dispatch({
          type: 'SUBSCRIBER_LISTING_UPDATE_CONTACT_REQUEST_SUCCESS',
          payload: {
            success: true,
            email,
            phone,
            website,
            description,
            address1,
            city,
            state,
            zip
          }
        });
        callback();
      } else {
        dispatch({
          type: 'SUBSCRIBER_LISTING_UPDATE_CONTACT_REQUEST_FAILURE',
          payload: {
            success: false,
            error: response.data.errorMessage
          }
        });
      }
    }
  } catch (error) {
    dispatch({
      type: 'SUBSCRIBER_LISTING_UPDATE_COMPANY_INFORMATION_REQUEST_FAILURE',
      payload: {
        success: false,
        error: error
      }
    });
  }
};

export const updateListingEmails = ({
  values,
  callback,
  companyMetroId
}) => async dispatch => {
  dispatch({
    type: 'SUBSCRIBER_LISTING_UPDATE_EMAILS_REQUEST'
  });

  const { additionalEmails } = values;

  try {
    const response = await updateListingEmailResults({
      company_metro_id: companyMetroId,
      additional_emails: formatListingEmailsRequest(additionalEmails)
    });

    if (response && response.data) {
      if (response.data.success) {
        dispatch({
          type: 'SUBSCRIBER_LISTING_UPDATE_EMAILS_REQUEST_SUCCESS',
          payload: {
            success: true,
            additionalEmails
          }
        });
        callback();
      } else {
        dispatch({
          type: 'SUBSCRIBER_LISTING_UPDATE_EMAILS_REQUEST_FAILURE',
          payload: {
            success: false,
            error: response.data.errorMessage
          }
        });
      }
    }
  } catch (error) {
    dispatch({
      type: 'SUBSCRIBER_LISTING_UPDATE_COMPANY_INFORMATION_REQUEST_FAILURE',
      payload: {
        success: false,
        error: error
      }
    });
  }
};

export const updateProperty = (key, value) => dispatch => {
  dispatch({
    type: 'SUBSCRIBER_LISTING_PROPERTY_UPDATED',
    payload: { key, value }
  });
};

export const updateFleetListing = ({
  values,
  callback,
  companyMetroId
}) => async dispatch => {
  dispatch({ type: 'UPDATE_FLEET_REQUEST' });
  try {
    let response;
    if (values.listingId) {
      response = await updateFleet({ values, companyMetroId });
    } else {
      response = await addFleet({ values, companyMetroId });
    }
    if (!response.success) throw Error;

    const fleetResponse = await listingFleet(companyMetroId);

    dispatch({ type: 'UPDATE_FLEET_SUCCESS', payload: fleetResponse });
    callback();
  } catch (e) {
    dispatch({
      type: 'UPDATE_FLEET_FAIL',
      payload:
        'There was a problem updating your fleet. Please try again later.'
    });
  }
};

export const deleteDeletingListing = ({
  callback,
  companyMetroId,
  listingId
}) => async dispatch => {
  dispatch({ type: 'DELETE_FLEET_REQUEST' });
  try {
    await deleteFleet({ companyMetroId, listingId });

    dispatch({ type: 'DELETE_FLEET_SUCCESS', payload: listingId });
    callback();
  } catch (e) {
    dispatch({
      type: 'DELETE_FLEET_FAIL',
      payload: 'There was a problem delete your fleet. Please try again later.'
    });
  }
};
