const INITIAL_STATE = {
  reviews: [],
  errorMessage: ''
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SUBSCRIBER_REVIEWS_SUCCESS':
      return { ...state, reviews: action.payload };

    case 'SUBSCRIBER_REVIEWS_FAIL':
      return { ...state };

    case 'SUBSCRIBER_REPLY_REVIEW_REQUEST':
      return { ...state, errorMessage: '' };

    case 'SUBSCRIBER_REPLY_REVIEW_SUCCESS':
      return {
        ...state,
        reviews: state.reviews.map(
          review =>
            review.companyRatingId === action.payload.companyRatingId
              ? { ...review, isResolved: true }
              : review
        )
      };

    case 'SUBSCRIBER_REPLY_REVIEW_FAIL':
      return { ...state, errorMessage: action.payload };

    default:
      return state;
  }
};
