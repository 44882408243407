import {
  addPhoto,
  editUserProfile,
  updatePhoto,
  userProfile,
  updateUserLogin
} from '../../services/user/profileService';

export const fetchUserProfile = () => async dispatch => {
  dispatch({ type: 'FETCH_USER_PROFILE_REQUEST' });
  try {
    const response = await userProfile();
    if (!response.success) throw Error;

    dispatch({ type: 'FETCH_USER_PROFILE_SUCCESS', payload: response });
  } catch (e) {
    dispatch({ type: 'FETCH_USER_PROFILE_FAIL' });
  }
};

export const updateUserProfile = ({ values, callback }) => async dispatch => {
  dispatch({ type: 'UPDATE_USER_PROFILE_REQUEST' });
  try {
    const response = await editUserProfile(values);
    if (!response.success) throw Error;

    dispatch({ type: 'UPDATE_USER_PROFILE_SUCCESS', payload: values });
    callback();
  } catch (e) {
    dispatch({
      type: 'UPDATE_USER_PROFILE_FAIL',
      payload:
        'There was a problem updating your profile. Please try again later.'
    });
  }
};

export const updateProfilePhoto = ({
  image,
  initialPhoto,
  initialPhotoFilename,
  callback
}) => async dispatch => {
  dispatch({
    type: 'UPDATE_USER_PROFILE_PHOTO_REQUEST'
  });

  try {
    let response;
    if (initialPhotoFilename) {
      response = await updatePhoto({ initialPhotoFilename, image });
    } else {
      response = await addPhoto(image);
    }

    if (!response.success) throw Error;

    const photoUrl = response.photoUrl || initialPhoto;
    const photoFilename = response.photoFilename || initialPhotoFilename;

    dispatch({
      type: 'UPDATE_USER_PROFILE_PHOTO_SUCCESS',
      payload: { photoFilename, photoUrl }
    });
    callback();
  } catch (e) {
    dispatch({
      type: 'UPDATE_USER_PROFILE_PHOTO_FAIL',
      payload:
        'There was a problem updating your profile picture. Please try again later.'
    });
  }
};

export const updateLoginInformation = ({
  values,
  callback
}) => async dispatch => {
  dispatch({ type: 'UPDATE_USER_LOGIN_INFO_REQUEST' });
  try {
    const response = await updateUserLogin(values);
    if (!response.success) throw response.errorMessage;

    dispatch({ type: 'UPDATE_USER_LOGIN_INFO_SUCCESS' });
    callback();
  } catch (e) {
    const message =
      typeof e === 'string'
        ? e
        : 'There was a problem updating your login information. Please try again later.';
    dispatch({
      type: 'UPDATE_USER_LOGIN_INFO_FAIL',
      payload: message
    });
  }
};
