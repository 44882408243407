import { fetchFormMetadata } from "../services/metadataService";

export const fetchMetadata = () => async dispatch => {
  dispatch({
    type: "METADATA_REQUEST"
  });

  try {
    const metadataResponse = await fetchFormMetadata();

    if (metadataResponse && metadataResponse.data) {
      const cities = [];
      const states = [];

      metadataResponse.data.metros.forEach((value) => {
        if (cities.indexOf(value.city) === -1) {
          cities.push(value.city)
        }
      });

      metadataResponse.data.metros.forEach((value) => {
        if (states.indexOf(value.state) === -1) {
          states.push(value.state)
        }
      });

      dispatch({
        type: "METADATA_REQUEST_SUCCESS",
        payload: {
          metros: metadataResponse.data.metros,
          tripTypes: metadataResponse.data.tripTypes,
          cities,
          states
        }
      });
    }
  } catch (error) {
    dispatch({
      type: "METADATA_REQUEST_FAILURE",
      payload: error
    });
  }
};