import {
  associations,
  createSubscriberAccount,
  metroStates,
  checkRegisterInfo
} from '../../services/subscribeService';
import { getSetup} from '../../services/loginService';

export const fetchAssociations = () => async dispatch => {
  try {
    const response = await associations();

    dispatch({ type: 'FETCH_ASSOCIATIONS_SUCCESS', payload: response });
  } catch (e) {
    dispatch({ type: 'FETCH_ASSOCIATIONS_FAIL' });
  }
};

export const fetchMetroStates = () => async dispatch => {
  try {
    const response = await metroStates();

    dispatch({ type: 'FETCH_METRO_STATES_SUCCESS', payload: response });
  } catch (e) {
    dispatch({ type: 'FETCH_METRO_STATES_FAIL' });
  }
};

export const submitSubscriberAccount = (values, history) => async dispatch => {
  dispatch({ type: 'CREATE_SUBSCRIBER_REQUEST' });
  try {
    const response = await createSubscriberAccount(values);
    if (!response.success) throw response.errorMessage;

    dispatch({ type: 'CREATE_SUBSCRIBER_REQUEST_SUCCESS', payload: response });

    let companyId = response.subscriber.companyId
    const result = await getSetup(companyId);

    let index = result.result;

    let url

    switch (index) {
      case 1:
        url ="/subscribers/init";
        break
      case 2:
        url ="/subscribers/init2";
        break
      case 3:
        url ="/subscribers/init3";
        break
      case 4:
        url ="/subscribers/init4"
        break
      case 5:
        url ="/subscribers/init5"
        break
      default:
        url = '/subscribers/company';
    }

    history.push(url);
  } catch (e) {
    const message =
      typeof e === 'string'
        ? e
        : 'There was a problem creating your account. Please try again later.';
    dispatch({ type: 'CREATE_SUBSCRIBER_REQUEST_FAILURE', payload: message });
  }
};

export const toRegisterPaymentPage = (values, history) => async dispatch => {
  dispatch({type: 'TO_REGISTER_PAYMENT_PAGE', payload: values});
  history.push('/subscribers/subscribePayment');
}

export const  toRegisterConfirmPage = (values, history) => async dispatch => {
  dispatch({type: 'TO_REGISTER_CONFIRM_PAGE', payload: values});
  try {
    const response = await checkRegisterInfo(values);
    if (!response.result.status) {
      throw response.result.msg;
    }else{
      history.push('/subscribers/subscribeConfirm');
    }
  }catch (e) {
    const message =
        typeof e === 'string'
            ? e
            : 'There was a problem creating your account. Please try again later.';
    dispatch({ type: 'CHECK_DOT_NUMBER_REQUEST_FAILURE', payload: message });
  }
}
