import React from 'react';
import glamorous from 'glamorous';
import { FormGroup, Input as ReactstrapInput, Label } from 'reactstrap';

const StyledFormGroup = glamorous(FormGroup)({ marginBottom: '1em' });

export const InputSelect = ({
  className,
  disabled,
  errors,
  handleChange,
  handleBlur,
  label,
  name,
  optionKey,
  optionLabel,
  options,
  optionValue,
  required,
  rows,
  touched,
  values
}) => {
  const labelText = required ? `* ${label}` : label;
  return (
    <StyledFormGroup className={className}>
      <Label for={name}>{labelText}</Label>
      <ReactstrapInput
        disabled={disabled}
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        rows={rows || null}
        type="select"
        value={values[name]}
      >
        {options.map(option => (
          <option key={option[optionKey]} value={option[optionValue]}>
            {option[optionLabel]}
          </option>
        ))};
      </ReactstrapInput>
      {touched[name] &&
        errors[name] && <div className="text-danger mt-1">{errors[name]}</div>}
    </StyledFormGroup>
  );
};

export default InputSelect;
