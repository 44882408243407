import orderBy from 'lodash/orderBy';

const INITIAL_STATE = {
  errorMessage: '',
  loading: false,
  messages: [],
  newMessageCompany: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'FETCH_USER_MESSAGES_REQUEST':
      return { ...state };

    case 'FETCH_USER_MESSAGES_SUCCESS':
      return {
        ...state,
        messages: orderBy(action.payload.messages, ['dateCreated'], ['desc'])
      };

    case 'FETCH_USER_MESSAGES_FAIL':
      return { ...state };

    case 'FETCH_COMPANY_REVIEW_INFO_SUCCESS':
      return { ...state, newMessageCompany: action.payload };

    case 'SEND_USER_MESSAGE_REQUEST':
      return { ...state, errorMessage: '' };

    case 'SEND_USER_MESSAGE_SUCCESS':
      return { ...state };

    case 'SEND_USER_MESSAGE_FAIL':
      return { ...state, errorMessage: action.payload };

    default:
      return state;
  }
};
