import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table
} from 'reactstrap';
import { fetchQuotes } from '../../actions/user/userQuotes';
import { Footer, Section } from '../../components';
import UserMenu from '../../components/user/UserMenu';

class QuoteHistory extends Component {
  state = {
    selectedQuote: {},
    showDetailsModal: false
  };

  componentWillMount = () => {
    this.props.fetchQuotes();
  };

  toggleDetailsModal = quote =>
    this.setState(prevState => ({
      selectedQuote: quote || {},
      showDetailsModal: !prevState.showDetailsModal
    }));

  formatDate = date => (date ? moment(date).format('MM/DD/YYYY') : '-');

  renderCompanies = companies => {
    if (!companies) return null;

    return (
      <Row>
        <strong className="col-6">Companies:</strong>
        <div className="col-6">
          {companies.map(company => (
            <p key={company.companyId}>
              <Link to={`/companies/${company.urlSlug}`}>
                {company.companyDisplayName}
              </Link>
            </p>
          ))}
        </div>
      </Row>
    );
  };

  renderDetailsModal = () => {
    const {
      companies,
      dateSubmitted,
      departureCity,
      departureDate,
      departureState,
      destinationCity,
      destinationState,
      email,
      groupName,
      itineraryId,
      name,
      numberOfPassengers,
      phone,
      returnDate
    } = this.state.selectedQuote;

    const DetailRow = ({ label, value }) => (
      <Row>
        <strong className="col-6">{label}:</strong>
        <p className="col-6">{value}</p>
      </Row>
    );

    return (
      <Modal
        isOpen={this.state.showDetailsModal}
        toggle={this.toggleDetailsModal}
      >
        <ModalHeader
          className="d-flex align-items-center pl-5 pr-5"
          toggle={this.toggleDetailsModal}
        >
          {groupName}
        </ModalHeader>
        <ModalBody className="pl-5 pr-5">
          <DetailRow
            label="Departure Location"
            value={`${departureCity}, ${departureState}`}
          />
          <DetailRow
            label="Departure Date"
            value={this.formatDate(departureDate)}
          />
          <DetailRow
            label="Destination Location"
            value={`${destinationCity}, ${destinationState}`}
          />
          <DetailRow label="Return Date" value={this.formatDate(returnDate)} />
          <DetailRow label="Pasengers" value={numberOfPassengers} />
          <DetailRow label="Contact Name" value={name} />
          <DetailRow label="Contact Email" value={email} />
          <DetailRow label="Contact Phone" value={phone} />
          <DetailRow
            label="Date Submitted"
            value={this.formatDate(dateSubmitted)}
          />
          <DetailRow label="Itenerary Number" value={itineraryId} />
          {this.renderCompanies(companies)}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            className="text-white"
            onClick={this.toggleDetailsModal}
          >
            Close
          </Button>{' '}
        </ModalFooter>
      </Modal>
    );
  };

  renderQuotes = quotes => {
    if (!this.props.loading && quotes && !quotes.length) {
      return (
        <tr>
          <td colSpan="7">No previous quotes</td>
        </tr>
      );
    }

    return quotes.map(quote => {
      const {
        dateSubmitted,
        departureCity,
        departureDate,
        departureState,
        destinationCity,
        destinationState,
        groupName,
        itineraryId,
        returnDate
      } = quote;
      return (
        <tr key={itineraryId}>
          <td>{groupName}</td>
          <td>
            <p>{`${departureCity}, ${departureState}`}</p>
            <p>{this.formatDate(departureDate)}</p>
          </td>
          <td>
            <p>{`${destinationCity}, ${destinationState}`}</p>
          </td>
          <td>{this.formatDate(returnDate)}</td>
          <td>{this.formatDate(dateSubmitted)}</td>
          <td>
            <Button
              color="primary"
              onClick={() => this.toggleDetailsModal(quote)}
            >
              Details
            </Button>
          </td>
        </tr>
      );
    });
  };

  render() {
    return (
      <div className="pt-5">
        <Section>
          <Row className="mb-3">
            <Col xs="12" className="text-center">
              <h1>Quote History</h1>
            </Col>
          </Row>
          <Row>
            <UserMenu history={this.props.history} />
            <Col xs="12" lg="9">
              <Table striped>
                <thead>
                  <tr>
                    <th>Group Name</th>
                    <th>Departure</th>
                    <th>Destination</th>
                    <th>Return Date</th>
                    <th>Date Submitted</th>
                    <th />
                  </tr>
                </thead>
                <tbody>{this.renderQuotes(this.props.quotes)}</tbody>
              </Table>
            </Col>
          </Row>
        </Section>
        <Footer />
        {this.renderDetailsModal()}
      </div>
    );
  }
}

const mapStateToProps = ({ userQuotes: { loading, quotes } }) => ({
  loading,
  quotes
});

export default connect(mapStateToProps, { fetchQuotes })(QuoteHistory);
