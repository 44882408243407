import React from 'react';
import {
  Alert,
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import { Formik } from 'formik';
import Yup from 'yup';
import { Input } from '../../../components';

const EditContactModal = ({
  errorMessage,
  modalRef,
  modalVisible,
  onButtonSubmit,
  onFormSubmit,
  toggleModal,
  values
}) => (
  <Modal isOpen={modalVisible} toggle={toggleModal}>
    <ModalHeader
      className="d-flex align-items-center pl-5 pr-5"
      toggle={toggleModal}
    >
      Edit Contact Information
    </ModalHeader>
    <ModalBody className="pl-5 pr-5">
      <Formik
        ref={modalRef}
        initialValues={{
          phone: values.phone,
          website: values.website,
          email: values.email,
          description: values.description,
          address1: values.address1,
          city: values.city,
          state: values.state,
          zip: values.zip
        }}
        validationSchema={validationSchema}
        onSubmit={onFormSubmit}
        render={formProps => (
          <Form onSubmit={formProps.handleSubmit}>
            {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
            <Input name="phone" label="Phone" {...formProps} />
            <Input name="website" label="Website" {...formProps} />
            <Input name="email" label="Email" {...formProps} />
            <Input name="description" label="Description" type="textarea" rows={5} {...formProps} />
            <Input name="address1" label="Street" {...formProps} />
            <Input name="city" label="City" {...formProps} />
            <Input name="state" label="State or Province" {...formProps} />
            <Input name="zip" label="Zip" {...formProps} />
          </Form>
        )}
      />
    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={toggleModal}>
        Cancel
      </Button>{' '}
      <Button color="secondary" className="text-white" onClick={onButtonSubmit}>
        Save
      </Button>{' '}
    </ModalFooter>
  </Modal>
);

const validationSchema = Yup.object().shape({
  website: Yup.string().trim().matches(/[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,'Website must be a valid url'),
  email: Yup.string().trim().email('Email is not valid')
});

export default EditContactModal;
