import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Alert, Button, Form, Row} from 'reactstrap';
import {Formik} from 'formik';
import Yup from 'yup';
import {
    Footer,
    Input,
    InputCheckboxGroup,
    InputSelect
} from '../../components';
import {
    fetchAssociations,
    fetchMetroStates,
    submitSubscriberAccount,
    toRegisterConfirmPage
} from '../../actions/subscriber/subscribe';
import {
    getWordpressPage
} from '../../services/wordpressService'

class Subscribe extends Component {
    constructor(props) {

        super(props);
        this.state = {
            canSubmit : true,
            loadingCssName : "",
            nextDisabled: false
        }
    }

    componentDidMount() {

        this.props.fetchAssociations();
        this.props.fetchMetroStates();
        getWordpressPage(process.env.REACT_APP_WP_SUBSCRIBER_TERMS_ID).then(response => {
            this.setState({
                terms: response.data.content.rendered
            })
        });
    }

  onFormSubmit = values => {
      if (this.state.canSubmit) {
          this.setState({canSubmit: false, loadingCssName: "buttonLoading", nextDisabled: true});
          this.props.toRegisterConfirmPage(values, this.props.history).then(() => {
              this.setState({canSubmit: true, loadingCssName: "", nextDisabled: false});
          });
      }
    };

    metroStateOptions = () => {

        let states = [];
        this.props.metroStates.map(state => {
            states.push({key: state, value: state});
        });
        return states;
    };

    renderErrors = errors => {
        if (!errors) return null;

        var values = Object.values(errors);

        return (
            <React.Fragment>
                {values.map(error => {
                    return (
                        <React.Fragment>
                            <br/>
                            {error}
                        </React.Fragment>
                    )
                })}
            </React.Fragment>
        )
    }

    renderErrorsText = errors => {

    }

    renderTermsAndConditions = () => {
        if (!this.state || !this.state.terms) return null;

        return (
            <div
                style={{
                    height: '25em',
                    backgroundColor: 'white',
                    overflowY: 'scroll',
                    border: '1px solid lightgrey',
                    padding: '.75em 1em'
                }}
                dangerouslySetInnerHTML={{__html: this.state.terms}}/>
        )
    }

    render() {
        const {errorMessage} = this.props;
        return (
            <React.Fragment>
                <div className="pt-5 mt-5 pl-3 pr-3 lg-container">
                    <h1>BusRates.com Registration</h1>
                    <p>Thank you for your interest in joining BusRates.com!</p>
                    <ul>
                        {/*<li>*/}
                        {/*    Upon request, we can fax you the form instead of entering your*/}
                        {/*    info online. Please email:{' '}*/}
                        {/*    <a href="mailto:sales@busrates.com">sales@busrates.com</a>*/}
                        {/*</li>*/}
                        <li>
                            For help, or to expedite your posting, please call 703-838-2955 M-F 9am to 5:30pm EST
                        </li>
                    </ul>
                </div>
                <div className="lg-container pt-4 pb-3 pl-3 pr-3">
                    <div style={{backgroundColor: '#e9ecef', padding: '2em'}}>
                        <h3
                            className="font-weight-bolder text-primary mb-0"
                            style={{padding: '1em 0em'}}
                        >
                            Bus Operator Registration
                        </h3>
                        
                        <Formik
                            initialValues={{
                                national: [],
                                certifications: [],
                                stateAndRegional: []
                            }}
                            validationSchema={validationSchema}
                            onSubmit={this.onFormSubmit}
                            render={formProps => (
                                <>
                                    <Form onSubmit={formProps.handleSubmit}>
                                    <h4 className="text-primary">Profile Information</h4>
                                    <hr/>
                                    <Row>
                                        <Input
                                            name="companyName"
                                            label="Company Name"
                                            className="col-12 col-md-6"
                                            required
                                            {...formProps}
                                        />
                                        <Input
                                            name="companyPhone"
                                            label="Company Phone (xxx-xxx-xxxx)"
                                            className="col-12 col-md-6"
                                            placeholder="xxx-xxx-xxxx"
                                            required
                                            {...formProps}
                                        />
                                    </Row>
                                    <Row>
                                        <Input
                                            name="address"
                                            label="Company Address"
                                            className="col-12 col-md-6"
                                            required
                                            {...formProps}
                                        />
                                        <Input
                                            name="addressLine2"
                                            label="Company Address Line 2"
                                            className="col-12 col-md-6"
                                            {...formProps}
                                        />
                                    </Row>
                                    <Row>
                                        <Input
                                            name="city"
                                            label="Company City"
                                            className="col-12 col-md-6"
                                            required
                                            {...formProps}
                                        />
                                        <InputSelect
                                            className="col-12 col-md-6"
                                            label="Company State/Province"
                                            name="state"
                                            optionKey="key"
                                            optionLabel="value"
                                            options={this.metroStateOptions()}
                                            optionValue="key"
                                            required
                                            {...formProps}
                                        />
                                    </Row>
                                    <Row>
                                        <Input
                                            name="zipCode"
                                            label="Company Zip or Postal Code"
                                            className="col-12 col-md-6"
                                            required
                                            {...formProps}
                                        />
                                        <Input
                                            name="dotNumber"
                                            label="DOT Number"
                                            className="col-12 col-md-6"
                                            required
                                            {...formProps}
                                        />
                                    </Row>
                                    <Row>
                                        <Input
                                            name="companyWebsite"
                                            label="Company Website"
                                            className="col-12 col-md-12"
                                            {...formProps}
                                        />
                                    </Row>
                                    <Row>
                                        <Input
                                            name="contactName"
                                            label="Contact Name"
                                            className="col-12 col-md-6"
                                            required
                                            {...formProps}
                                        />
                                        <Input
                                            name="contactEmail"
                                            label="Contact Email / Login Email"
                                            className="col-12 col-md-6"
                                            required
                                            {...formProps}
                                        />
                                    </Row>
                                    <Row>
                                        <Input
                                            name="contactPhone"
                                            label="Contact Phone (xxx-xxx-xxxx)"
                                            className="col-12 col-md-6"
                                            placeholder="xxx-xxx-xxxx"
                                            required
                                            {...formProps}
                                        />
                                        <Input
                                            name="contactPhoneExtension"
                                            label="Extension"
                                            className="col-12 col-md-6"
                                            {...formProps}
                                        />
                                    </Row>
                                    <h4 className="text-primary">Company Details</h4>
                                    <hr/>
                                    <h5>Association Memberships</h5>
                                    <Row>
                                        <InputCheckboxGroup
                                            className="col-md-12"
                                            label="National"
                                            name="national"
                                            optionKey="associationId"
                                            options={this.props.associations.national}
                                            {...formProps}
                                        />
                                    </Row>
                                    <Row>
                                        <InputCheckboxGroup
                                            className="col-md-12"
                                            label="State and Regional"
                                            name="stateAndRegional"
                                            optionKey="associationId"
                                            options={this.props.associations.stateAndRegional}
                                            {...formProps}
                                        />
                                    </Row>
                                    <Row>
                                        <InputCheckboxGroup
                                            className="col-md-12"
                                            label="Certifications"
                                            name="certifications"
                                            optionKey="associationId"
                                            options={this.props.associations.certifications}
                                            {...formProps}
                                        />
                                    </Row>
                                    <h4 className="text-primary">Terms and Conditions</h4>
                                    <hr/>
                                    <div className="pb-3">
                                        {this.renderTermsAndConditions()}
                                        <br/>
                                        <br/>
                                        <Row>
                                            <span className="pr-3">
                                                I have read and agree to the terms.
                                            </span>
                                            <Input
                                                name="terms"
                                                label=""
                                                type="checkbox"
                                                className="col-12 col-md-2"
                                                {...formProps}
                                            />
                                        </Row>
                                    </div>
                                    {!formProps.isValid && (
                                        <div className="text-danger text-center mt-3">
                                            {formProps.dirty &&!formProps.isValid && (
                                                <span>Some fields are invalid, please check all fields and try again</span>)
                                            }
                                            {this.renderErrors(formProps.errors)}
                                        </div>)
                                    }
                                    {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                                    
                                </Form>
                                <Button onClick={
                                    async () => {
                                        try {
                                            // 手动触发验证
                                            await formProps.validateForm();
                                            if (Object.keys(formProps.errors).length === 0) {
                                            // 如果没有错误，执行提交操作
                                                this.onFormSubmit(formProps.values);
                                            }
                                        } catch (error) {
                                            console.error(error);
                                        }
                                    }
                                } disabled={this.state.nextDisabled} style={{color: 'white'}} className={this.state.loadingCssName}
                                    id={"submitBtn"}>Next</Button>
                                </>
                                 
                            )}
                        />
                    </div>
                </div>
                <Footer/>
            </React.Fragment>
        );
    }
}

Yup.addMethod(Yup.mixed, 'sameAs', function (ref, message) {
    return this.test('sameAs', message, function (value) {
        const other = this.resolve(ref);
        return !other || !value || value === other;
    });
});

const validationSchema = Yup.object().shape({
    contactEmail: Yup.string()
          .email('Email must be a valid email')
          .required('Contact email is required.'),
    companyName: Yup.string().required('Company name is required.'),
    companyPhone: Yup.string().required('Company phone is required.'),
    companyWebsite: Yup.string().trim().matches(/[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/, 'Website must be a valid url'),
    address: Yup.string().required('Address is required.'),
    city: Yup.string().required('City is required.'),
    state: Yup.string().required('State is required.'),
    zipCode: Yup.string().required('Zip code is required.'),
    contactName: Yup.string().required('Contact name is required.'),
    contactPhone: Yup.string().required('Contact phone is required.'),
    dotNumber: Yup.string().trim().required('DOT Number is required.').matches(/^[0-9]*$/, 'DOT Number must be a valid number'),
    terms: Yup.string().required('Terms and conditions is required.')
});

const mapStateToProps = ({subscribe: {associations, metroStates, errorMessage}}) => ({
    associations,
    metroStates,
    errorMessage
});

export default connect(mapStateToProps, {
    fetchAssociations,
    fetchMetroStates,
    submitSubscriberAccount,
    toRegisterConfirmPage
})(Subscribe);
