import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Login from '../containers/subscriber/Login';
import Navigation from '../containers/Navigation';

class Wrapper extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  renderHeader() {
    return <Navigation color="primary" history={this.props.history} />;
  }

  renderLoginModal() {
    return <Login history={this.props.history} />;
  }

  render() {
    return (
      <div>
        {this.renderHeader()}
        {this.renderLoginModal()}
        {this.props.children}
      </div>
    );
  }
}

export default withRouter(Wrapper);
