export const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SUBSCRIBER_PAYMENT_REQUEST':
      return { ...state };
    case 'SUBSCRIBER_PAYMENT_REQUEST_SUCCESS':
      return { ...state, ...action.payload };
    case 'SUBSCRIBER_PAYMENT_REQUEST_FAILURE':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
