import React from 'react';
import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import { Formik, FieldArray } from 'formik';
import glamorous from 'glamorous';
import Close from 'react-icons/lib/fa/close';

const StyledRow = glamorous(Row)(
  {
    padding: '1em 0'
  },
  ({ last }) => ({
    borderBottom: last.last ? null : '1px solid #dee2e6'
  })
);

const StyledButton = glamorous(Button)({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  color: 'red'
});

const EditEmailsModal = ({
  errorMessage,
  modalRef,
  modalVisible,
  onButtonSubmit,
  onFormSubmit,
  toggleModal,
  values
}) => (
  <Modal isOpen={modalVisible} toggle={toggleModal}>
    <ModalHeader
      className="d-flex align-items-center pl-5 pr-5"
      toggle={toggleModal}
    >
      Edit Additional Emails
    </ModalHeader>
    <ModalBody className="pl-5 pr-5">
      <Formik
        ref={modalRef}
        initialValues={{
          additionalEmails: values.additionalEmails
        }}
        onSubmit={onFormSubmit}
        render={({ handleBlur, handleChange, handleSubmit, values }) => (
          <FieldArray
            name="additionalEmails"
            render={arrayHelpers => (
              <Form onSubmit={handleSubmit}>
                {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                {values.additionalEmails && values.additionalEmails.length > 0
                  ? values.additionalEmails.map((email, index, array) => (
                      <StyledRow
                        last={{ last: index + 1 >= array.length }}
                        key={index}
                      >
                        <Col xs={10} sm={5}>
                          <Label for={`additionalEmails.${index}.name`}>
                            Name
                          </Label>
                          <Input
                            name={`additionalEmails.${index}.name`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={email.name}
                          />
                        </Col>
                        <Col xs={10} sm={6}>
                          <Label for={`additionalEmails.${index}.emailAddress`}>
                            Email Address
                          </Label>
                          <Input
                            name={`additionalEmails.${index}.emailAddress`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={email.emailAddress}
                          />
                        </Col>
                        <Col xs={2} sm={1}>
                          <StyledButton
                            color="link"
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            <Close size={30} />
                          </StyledButton>
                        </Col>
                      </StyledRow>
                    ))
                  : null}
                <Row>
                  <Col>
                    <Button
                      color="primary"
                      onClick={() =>
                        arrayHelpers.push({ name: '', emailAddress: '' })
                      }
                    >
                      Add Email
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          />
        )}
      />
    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={toggleModal}>
        Cancel
      </Button>{' '}
      <Button color="secondary" className="text-white" onClick={onButtonSubmit}>
        Save
      </Button>{' '}
    </ModalFooter>
  </Modal>
);

export default EditEmailsModal;
