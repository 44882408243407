import API from "../baseService";

export const costBreakdownResults = (companyId) => (
    API.get("subscribers/{company_id}/payments/cost_breakdown", {company_id: companyId})
)
export const setUpSecondIndex = (companyId) =>{

    return API.post('/company/progress/update/setup/third/index?companyId='+companyId).then(
        response => response.data
    );
}