import React from "react";
import glamorous from "glamorous";
import { Progress } from "reactstrap";

const ProgressLabel = glamorous.div({
  fontSize: 12,
  fontWeight: 400
});

const ProgressTitle = glamorous.div({
  fontSize: 16,
  fontWeight: 600
})

const QuoteProgress = props => (
  <div className="row">
    <ProgressTitle className="col-sm-3 pl-0 text-muted">Quote Request Progress</ProgressTitle>
    <div className="col pr-0">
      <Progress color="secondary" value={props.progress} />
      <div className="d-flex justify-content-between mt-1">
        <ProgressLabel>Quote Search</ProgressLabel>
        <ProgressLabel>Review Information</ProgressLabel>
        <ProgressLabel className="text-right">
          Confirmation
        </ProgressLabel>
      </div>
    </div>
  </div>
);

export default QuoteProgress;
