import API from '../baseService';

export const reviews = companyId =>
  API.get(`/review/listing/${companyId}`).then(response => response.data);

export const reviewReply = (companyRatingId, response) =>
  API.post(`/review/${companyRatingId}/reply`, { response }).then(
    response => response.data
  );

export const companyReviewInfo = companyId =>
  API.get(`/review/write/${companyId}`).then(response => response.data);

export const submitReview = data =>
  API.post('/review/write', {
    company_id: data.companyId,
    company_metro_id: data.companyMetroId,
    rating: data.rating,
    title: data.title,
    comment: data.comment,
    name: data.name,
    trip_date: data.tripDate,
    email: data.email,
    notify_on_response: data.notifyOnResponse
  }).then(response => response.data);
