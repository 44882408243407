import React, { Component } from 'react';
import glamorous from 'glamorous';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Alert,
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Table
} from 'reactstrap';
import { fetchUserMessages } from '../../actions/user/userMessages';
import { shorten } from '../../utils/formatUtils';
import { Footer, Section } from '../../components';
import UserMenu from '../../components/user/UserMenu';
import UserSendMessageModal from './UserSendMessageModal';

const StyledTable = glamorous(Table)({
  fontWeight: 400
});

const StyledText = glamorous.p(
  {
    marginBottom: '5px'
  },
  ({ isNew, smallText }) => ({
    fontSize: smallText ? '12px' : '14px',
    fontWeight: isNew ? 'bold' : null
  })
);

const StyledSubtext = glamorous.p(
  {
    fontStyle: 'italic',
    marginBottom: '5px'
  },
  ({ isNew, smallText }) => ({
    fontSize: smallText ? '10px' : '12px',
    fontWeight: isNew ? 'bold' : null
  })
);

const StyledTD = glamorous.td({
  cursor: 'pointer'
});

const StyledModalMessageText = glamorous.p({
  marginTop: '25px',
  marginBottom: '5px'
});

const StyledModalMessageDateTime = glamorous.p({
  position: 'absolute',
  top: 10,
  right: 10,
  fontStyle: 'italic'
});

class UserMessages extends Component {
  state = {
    message: {},
    modalMessage: null,
    showMessageModal: false,
    showNewMessageModal: false
  };

  componentDidMount() {
    this.props.fetchUserMessages();
  }

  toggleMessageModal = message => {
    this.setState(prevState => ({
      showMessageModal: !prevState.showMessageModal,
      modalMessage: message
    }));
  };

  toggleNewMessageModal = () =>
    this.setState(prevState => ({
      showNewMessageModal: !prevState.showNewMessageModal
    }));

  renderMessages = () => {
    if (!this.props.messages.length) {
      return (
        <tr>
          <td colSpan="3">No messages</td>
        </tr>
      );
    }

    return this.props.messages.map(message => {
      const {
        body,
        dateCreated,
        messageId,
        metroCity,
        metroState,
        subject,
        toCompany
      } = message;
      const dateTime = moment(dateCreated).format('MMM D, YYYY h:mm A');

      return (
        <tr key={messageId}>
          <StyledTD onClick={() => this.toggleMessageModal(message)}>
            <StyledText>{toCompany}</StyledText>{' '}
            <StyledSubtext>
              {metroCity}, {metroState}
            </StyledSubtext>
          </StyledTD>
          <StyledTD onClick={() => this.toggleMessageModal(message)}>
            <StyledText>{subject}</StyledText>
          </StyledTD>
          <StyledTD onClick={() => this.toggleMessageModal(message)}>
            <StyledText smallText>{shorten(body, 50)}</StyledText>{' '}
            <StyledSubtext smallText>{dateTime}</StyledSubtext>
          </StyledTD>
        </tr>
      );
    });
  };

  renderMessageModal = () => {
    const { modalMessage, showMessageModal } = this.state;
    if (!modalMessage) return null;

    const {
      body,
      dateCreated,
      metroCity,
      metroState,
      subject,
      toCompany
    } = modalMessage;

    const dateTime = moment(dateCreated).format('MMM D, YYYY h:mm A');

    return (
      <Modal isOpen={showMessageModal} toggle={this.toggleMessageModal}>
        <ModalBody className="pl-5 pr-5">
          <Alert color="primary">
            <StyledText>{toCompany}</StyledText>
            <StyledSubtext>
              {metroCity}, {metroState}
            </StyledSubtext>
            <StyledModalMessageText>{subject}</StyledModalMessageText>
            <StyledText>{body}</StyledText>
            <StyledModalMessageDateTime>{dateTime}</StyledModalMessageDateTime>
          </Alert>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.toggleMessageModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  render() {
    return (
      <div className="pt-5">
        <Section>
          <Row>
            <Col xs="12" className="text-center">
              <h1>Messages</h1>
            </Col>
          </Row>
          <Row>
            <UserMenu history={this.props.history} />
            <Col xs="9">
              <Row className="pb-2">
                <Col xs="12">
                  <Button
                    className="text-white float-right"
                    onClick={this.toggleNewMessageModal}
                  >
                    New Message
                  </Button>
                </Col>
              </Row>
              <Row>
                <StyledTable striped>
                  <thead>
                    <tr>
                      <th>To</th>
                      <th>Subject</th>
                      <th>Message</th>
                    </tr>
                  </thead>
                  <tbody>{this.renderMessages()}</tbody>
                  <tfoot />
                </StyledTable>
              </Row>
            </Col>
          </Row>
        </Section>
        <Footer />
        {this.renderMessageModal()}
        <UserSendMessageModal
          errorMessage={this.props.errorMessage}
          modalVisible={this.state.showNewMessageModal}
          toggleModal={this.toggleNewMessageModal}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userMessages }) => ({
  errorMessage: userMessages.errorMessage,
  messages: userMessages.messages
});

export default connect(mapStateToProps, {
  fetchUserMessages
})(UserMessages);
