import {
  sendMessage as sendMessageService,
  userMessages
} from '../../services/subscriber/messagesService';

export const fetchUserMessages = () => async dispatch => {
  dispatch({ type: 'FETCH_USER_MESSAGES_REQUEST' });
  try {
    const response = await userMessages();
    dispatch({ type: 'FETCH_USER_MESSAGES_SUCCESS', payload: response });
  } catch (e) {
    dispatch({ type: 'FETCH_USER_MESSAGES_FAIL' });
  }
};

export const sendMessage = ({ values, callback }) => async (
  dispatch,
  getState
) => {
  dispatch({ type: 'SEND_USER_MESSAGE_REQUEST' });
  try {
    const { userProfile } = getState();

    const requestObject = {
      message: values.message,
      companyMetroId: values.metro,
      email: userProfile.email,
      name: `${userProfile.firstName} ${userProfile.lastName}`,
      subject: values.subject
    };

    const response = await sendMessageService(requestObject);
    if (!response.data.success) throw Error;

    dispatch({ type: 'SEND_USER_MESSAGE_SUCCESS' });
    dispatch(fetchUserMessages());
    callback();
  } catch (e) {
    dispatch({
      type: 'SEND_USER_MESSAGE_FAIL',
      payload:
        'There was a problem sending your message. Please try again later.'
    });
  }
};
