import React from 'react';
import {Button,Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import { Formik } from 'formik';

const ArrearsInformationModal = ({modalRef, modalVisible, title, toggleModal, onButtonSubmit}) => (
  <Modal isOpen={modalVisible} toggle={toggleModal}>
    <ModalHeader className="d-flex align-items-center pl-5 pr-5" toggle={toggleModal} >
        {title}
    </ModalHeader>
    <ModalBody className="pl-5 pr-5">
      <Formik ref={modalRef}
        render={formProps => (
            <p>
                Your account has been suspended due to unpaid balances. You can reinstate your account by updating your credit card and settling your account online by clicking the "Comfirm" Button or calling in at 703-838-2958.
            </p>
        )}
      />
    </ModalBody>
    <ModalFooter>
      {/*<Button color="primary" onClick={toggleModal}>  Cancel</Button>*/}
      <Button color="secondary" className="text-white" onClick={onButtonSubmit}> Go</Button>
    </ModalFooter>
  </Modal>
);
export default ArrearsInformationModal;
