import withAuthentication, { ROUTE_TYPES } from '../withAuthentication';
import withTracker from '.././withTracker';
import UserProfile from '../../containers/user/UserProfile';

const userProfile = {
  path: '/users/my-account',
  component: withTracker(withAuthentication(UserProfile, ROUTE_TYPES.USER))
};

export default userProfile;
