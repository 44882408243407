import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Alert,
  Button,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from 'reactstrap';
import axios from 'axios';
import glamorous from 'glamorous';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { Formik } from 'formik';
import Yup from 'yup';
import { Input, InputRadioGroup, InputSelect } from '../../components';
import { fetchCompanyReviewInfo } from '../../actions/writeReview';
import { fetchUserProfile } from '../../actions/user/userProfile';
import { sendMessage } from '../../actions/user/userMessages';

const StyledForm = glamorous(Form)({
  maxWidth: 890
});

const StyledModalBody = glamorous(ModalBody)({
  minHeight: 400
});

const StyledButton = glamorous(Button)({
  borderRadius: '0px 3px 3px 0px',
  fontSize: '14px'
});

const StyledButtonCenter = glamorous(Button)({
  borderRadius: '3px',
  fontSize: '20px'
});

const TypeaheadFormInput = glamorous(AsyncTypeahead)({
  backgroundClip: 'inherit',
  background: 'white',
  border: '1px solid #ced4da',
  borderRadius: '3px 0px 0px 3px',
  fontSize: 18,
  fontWeight: 300,
  height: 35,
  outline: 'none !important'
});

const StyledFormText = glamorous.p({
  marginTop: '20px',
  marginBottom: '20px'
});

class UserSendMessageModal extends Component {
  state = {
    isLoading: false,
    options: [],
    selectedCompany: null,
    canSubmit : true,
    loadingCssName : "",
    cancelDisabled: false
  };

  componentDidMount() {
    this.props.fetchUserProfile();
  }

  onFormSubmit = values => {
    if (this.state.canSubmit) {
      this.setState({
        canSubmit:false,
        loadingCssName:"buttonLoading",
        cancelDisabled: true},)
      this.props.sendMessage({
        values,
        callback: this.onFormSubmitSuccess
      });
    }
  };

  onFormSubmitSuccess = () => {
    this.setState({
      options: [],
      selectedCompany: null,
      canSubmit:true,
      loadingCssName:"",
      cancelDisabled: false
    });
    this.props.toggleModal();
  };

  onSearchSubmit = event => {
    if (event) event.preventDefault();
    if (this.state.selectedCompany && this.isNumber(this.state.selectedCompany.id)) {
      this.props.fetchCompanyReviewInfo(this.state.selectedCompany.id);
    }
  };

  isNumber(value) {
    return typeof value === 'number' && !isNaN(value);
  }

  onSelectCompany = company => {
    this.setState({ selectedCompany: company[0] }, () => this.onSearchSubmit());
  };

  onSearch = query => {
    this.setState({ isLoading: true });
    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/review/write/companies?company_name=${query}`
      )
      .then(res =>
        this.setState({
          isLoading: false,
          options: res.data.companies
        })
      );
  };

  renderMessageForm = () => {
    const { errorMessage, newMessageCompany } = this.props;
    if (!newMessageCompany) return null;

    return (
      <Formik
        onSubmit={this.onFormSubmit}
        initialValues={{
          metro: newMessageCompany.metros[0].companyMetroId
        }}
        validationSchema={validationSchema}
        render={formProps => (
          <Form onSubmit={formProps.handleSubmit}>
            <StyledFormText>
              This communication will be sent to the company listed. All fields
              are required. You can expect to hear back from the contact in
              24-48 hours.
            </StyledFormText>
            {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
            <InputSelect
              label="Company Location"
              name="metro"
              optionKey="companyMetroId"
              optionLabel="name"
              options={newMessageCompany.metros}
              optionValue="companyMetroId"
              required
              {...formProps}
            />
            <InputRadioGroup
              label="Subject"
              name="subject"
              options={[
                { name: 'Please contact me', key: 'Please contact me' },
                { name: 'I have a question', key: 'I have a question' },
                {
                  name: "I need a bus. Here's my trip info",
                  key: "I need a bus. Here's my trip info"
                }
              ]}
              required
              {...formProps}
            />
            <Input
              label="Message"
              name="message"
              type="textarea"
              rows="3"
              required
              {...formProps}
            />

            <div className="text-center">
              <StyledButtonCenter
                color="secondary"
                style={{color: 'white'}}
                className={this.state.loadingCssName}
                disabled={this.state.cancelDisabled}
              >
                Send Message
              </StyledButtonCenter>
            </div>
          </Form>
        )}
      />
    );
  };

  render() {
    const { modalVisible, toggleModal } = this.props;
    return (
      <Modal isOpen={modalVisible} toggle={toggleModal}>
        <ModalHeader
          className="d-flex align-items-center pl-5 pr-5"
          toggle={toggleModal}
        >
          Send Message
        </ModalHeader>
        <StyledModalBody className="pl-5 pr-5">
          <StyledForm onSubmit={this.onSearchSubmit}>
            <Row>
              <TypeaheadFormInput
                className="typeahead col-md-8 col-sm-12"
                placeholder="Search for Company"
                isLoading={this.state.isLoading}
                labelKey="name"
                onChange={this.onSelectCompany}
                onSearch={this.onSearch}
                options={this.state.options}
              />
              <StyledButton className="col-md-4 col-sm-12 text-white">
                Search
              </StyledButton>
            </Row>
          </StyledForm>
          {this.renderMessageForm()}
        </StyledModalBody>
      </Modal>
    );
  }
}

const validationSchema = Yup.object().shape({
  metro: Yup.string().required('Location is required'),
  subject: Yup.string().required('Subject is required'),
  message: Yup.string().required('Message is required')
});

const mapStateToProps = ({ userMessages }) => ({
  newMessageCompany: userMessages.newMessageCompany
});

export default connect(mapStateToProps, {
  fetchCompanyReviewInfo,
  fetchUserProfile,
  sendMessage
})(UserSendMessageModal);
