export const INITIAL_STATE = {
  apiToken: null,
  companyId: '',
  companyName: null,
  contactName: '',
  firstName: '',
  lastName: '',
  loggedIn: false,
  loginType: '',
  rememberMe: false,
  subscriberLoginModalVisible: false,
  userId: null,
  isFreeTrial: false,
  isUpgradePremium: false,
  payBillState: false,
  upgradePremium: {
    bastNewPriceTotal: "",
    baseCurrentPriceTotal: "",
    haveBankCardAdded: true,
    listingList: []
  },
  submitLoading: false,
  payBillStateMsg: ""
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'LOGIN_MODAL_OPENED':
      return { ...state, subscriberLoginModalVisible: true };

    case 'LOGIN_MODAL_CLOSED':
    case 'LOGIN_MODAL_CLOSED_CREATE_ACCOUNT_OPEN':
      return { ...state, subscriberLoginModalVisible: false };

    case 'LOGIN_REQUEST':
      return { ...state };

    case 'LOGIN_SUCCESS': {
      return {
        ...state,
        ...action.payload.user,
        ...action.payload.subscriber,
        loginType: action.payload.loginType,
        loggedIn: true
      };
    }

    case 'LOGIN_FAILURE': {
      delete action.payload.password;
      return { ...state, ...action.payload, loggedIn: false };
    }
    case 'CREATE_SUBSCRIBER_REQUEST_SUCCESS':
      return {
        ...state,
        ...action.payload.subscriber,
        loginType: 'subscriber',
        loggedIn: true
      };

    case 'USER_SIGNUP_SUCCESS':
      return {
        ...state,
        apiToken: action.payload.user.apiToken,
        loginType: 'user',
        loggedIn: true,
        userId: action.payload.user.userId
      };

    case 'LOGIN_PROPERTY_UPDATED':
      return { ...state, [action.payload.key]: action.payload.value };

    case 'LOGOUT':
      return INITIAL_STATE;

    case 'CHECK_FREE_TRYOUT':
      return {...state, isFreeTrial: action.payload.isFreeTrial, payBillStateMsg: ''};
    case 'CHECK_UPGRADE_PREMIUM':
      return {...state, isUpgradePremium: action.payload.isUpgradePremium, payBillStateMsg: ''};
    case 'UPGRADE_PREMIUM_LIST':
        return { ...state, upgradePremium: action.payload.upgradePremium };
    case 'PAY_BILL_STATE':
        return { ...state, payBillState: action.payload.payBillState };
    case 'PAY_BILL_STATE_MSG':
      return { ...state, payBillStateMsg: action.payload.payBillStateMsg };
    case 'SUBMIT_LOADING':
      return { ...state, submitLoading: action.payload.submitLoading };

    default:
      return state;
  }
};
