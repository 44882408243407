const INITIAL_STATE = {
  loading: false,
  companyHomePageName: '',
  companyHomePageResults: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'HOME_REQUEST':
      return { loading: true };

    case 'HOME_REQUEST_SUCCESS':
      return { loading: false, ...action.payload };

    case 'FETCH_COMPANY_HOME_PAGE_REQUEST':
      return { ...state };

    case 'FETCH_COMPANY_HOME_PAGE_SUCCESS':
      return {
        ...state,
        companyHomePageName: doubleDashToSpaceCapitalized(
          action.payload.companyName
        ),
        companyHomePageResults: action.payload.searchResults
      };

    case 'FETCH_COMPANY_HOME_PAGE_FAIL':
      return { ...state };

    default:
      return state;
  }
};

const doubleDashToSpaceCapitalized = text =>
  text.replace(/--/g, ' ').replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
