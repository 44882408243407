import React, { Component } from 'react';
import glamorous from 'glamorous';
import { sortBy, without } from 'lodash';
import { connect } from 'react-redux';
import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Label,
  Input
} from 'reactstrap';

import {
  changeState,
  closeAmenityDetailsModal,
  closeMoreDetailsModal,
  closeReviewsModal,
  fetchResults,
  filterByBusType,
  updateProperty,
  onAddToQuoteRequest,
  onRemoveFromQuoteRequest,
  openAmenityDetailsModal,
  openMoreDetailsModal,
  openReviewsModal,
  sendMessage
} from '../actions/search';
import { fetchMetadata } from '../actions/metadata';
import { trackAnalytics, TRACKING_TYPES } from '../services/analyticsUtils';

import Result from '../components/search/Result';
import SearchDetailModal from '../components/search/SearchDetailModal';
import SearchAmenitiesModal from '../components/search/SearchAmenitiesModal';
import SearchReviewsModal from '../components/search/SearchReviewsModal';
import SendMessageModal from '../components/search/SendMessageModal';
import { jumpToHash } from '../utils/generalUtils';
import {
  Footer,
  Container,
  QuoteProgress,
  ShoppingCartButton,
  Spinner
} from '../components';

const StyledBusFilterContainer = glamorous.div({});

const StyledBusFilterButton = glamorous(Button)({
  backgroundColor: '#ededed',
  borderRadius: 0,
  border: 'none',
  padding: 17
});

const StyledBusFilterButtonTitle = glamorous.span({
  fontWeight: 400,
  fontSize: 16
});

const StyledResultsAreaDiv = glamorous.div({
  fontWeight: 300
});

const StyledResultsAreaMetroName = glamorous.span({
  fontWeight: 600
});

class Search extends Component {
  state = {
    busTypeSelected: 'all',
    detailModalVisible: false,
    reviewsModalVisible: false,
    selectedResult: null,
    busTypesOpen: false,
    seatsOpen: false,
    destinationOpen: false,
    amenitiesOpen: false,
    replyErrorMessage: '',
    sendMessageModalVisible: false
  };

  componentWillMount() {
    const city = this.props.match.params.city || this.props.departureCity;
    const state = this.props.match.params.state || this.props.departureState;

    if (this.props.match.params.city || this.props.match.params.state) {
      this.props.updateProperty('filterZip', '');
      this.props.updateProperty('filterZipWithin', '50');
    }

    this.props.fetchMetadata().then(() => {
      this.props.fetchResults({
        tripType: this.props.tripType,
        city: city,
        state: state,
        freeFormLocation: this.props.freeFormLocation,
        history: this.props.history
      });
    });
  }

  componentDidMount() {
    jumpToHash();
  }

  componentDidUpdate() {
    jumpToHash();
  }

  toggleTypes = () => {
    this.setState({
      busTypesOpen: !this.state.busTypesOpen
    });
  };

  toggleAmenities = () => {
    this.setState({ amenitiesOpen: !this.state.amenitiesOpen });
  };

  toggleSeats = () => {
    this.setState({ seatsOpen: !this.state.seatsOpen });
  };

  toggleDestination = () => {
    this.setState({ destinationOpen: !this.state.destinationOpen });
  };

  toggleSendMessageModal = () => {
    this.setState(prevState => ({
      replyErrorMessage: '',
      sendMessageModalVisible: !prevState.sendMessageModalVisible
    }));
  };

  onContactUs = event => {
    event.preventDefault();

    trackAnalytics({
      companyMetroId: this.props.selectedResult.company.metroId,
      trackingType: TRACKING_TYPES.CONTACT_US
    });

    this.toggleSendMessageModal();
  };

  onReplyButton = () => {
    this.sendMessageForm.submitForm();
  };

  onReplyFormSubmit = values => {
    const callback = () =>
      this.setState({ replyErrorMessage: '', sendMessageModalVisible: false });
    const errorCallback = () =>
      this.setState({
        replyErrorMessage: 'There was a problem. Please try again later.'
      });

    this.props.sendMessage({
      values,
      callback,
      companyMetroId: this.props.selectedResult.company.metroId,
      errorCallback
    });
  };

  onBusTypeFilterButtonPressed = busTypeSelected => {
    if (this.state.busTypeSelected === busTypeSelected) {
      return;
    }

    this.setState({
      busTypeSelected: busTypeSelected
    });

    this.props.filterByBusType(busTypeSelected);
    this.toggleTypes();
  };

  onRefineSearchButtonPressed = () => {
    this.props.fetchResults({
      tripType: this.props.tripType,
      city: this.props.departureCity,
      state: this.props.departureState,
      history: this.props.history
    });

    this.setState({
      amenitiesOpen: false,
      busTypesOpen: false,
      seatsOpen: false,
      destinationOpen: false
    });
  };

  onClearAmenitiesButtonPressed = () => {
    this.props.updateProperty('filterAmenities', []);
  };

  onFilterAmenityChange = event => {
    const { checked, value: id } = event.target;

    const newAmenities = checked
      ? [...this.props.filterAmenities, parseInt(id, 10)]
      : without(this.props.filterAmenities, parseInt(id, 10));

    setTimeout(() => {
      this.props.updateProperty('filterAmenities', newAmenities);
    }, 10);
  };

  renderSearchResults = () => {
    const { results } = this.props;

    if (!results) return null;

    return results.map(result => (
      <Result
        key={result.company.metroId}
        onAddToQuoteRequest={(id, companyName) =>
          this.props.onAddToQuoteRequest(id, companyName)
        }
        onRemoveFromQuoteRequest={(id, companyName) =>
          this.props.onRemoveFromQuoteRequest(id, companyName)
        }
        openMoreDetailsModal={() => this.props.openMoreDetailsModal(result)}
        openReviewsModal={() => this.props.openReviewsModal(result)}
        quoteRequests={this.props.quoteRequests}
        result={result}
        selectedBusType={this.state.busTypeSelected}
      />
    ));
  };

  renderResultsLocationText = () => {
    if (this.props.filterZip) {
      return this.renderZipLocationText();
    } else {
      return this.renderMetroLocationText();
    }
  };

  renderZipLocationText = () => {
    const { results } = this.props;

    if (!results) return null;

    return (
      <div className="col pl-0">
        Results within{' '}
        <StyledResultsAreaMetroName className="text-primary">
          {this.props.filterZipWithin} miles
        </StyledResultsAreaMetroName>{' '}
        of zip code{' '}
        <StyledResultsAreaMetroName className="text-primary">
          {this.props.filterZip}
        </StyledResultsAreaMetroName>{' '}
      </div>
    );
  };

  renderMetroLocationText = () => {
    const { results } = this.props;

    if (!results) return null;

    return (
      <div className="col pl-0">
        Results in the{' '}
        <StyledResultsAreaMetroName className="text-primary">
          {this.props.departureCity}, {this.props.departureState}
        </StyledResultsAreaMetroName>{' '}
        bus charter service area.
      </div>
    );
  };

  renderNumberOfResults = () => {
    const { results } = this.props;

    if (!results) return null;

    return (
      <div className="col pr-0 text-right">
        Viewing {this.props.results.length} Result{this.props.results.length > 1
          ? 's'
          : ''}
      </div>
    );
  };

  renderAmenitiesFilter = () => {
    const { availableAmenities } = this.props;

    if (!availableAmenities) return null;

    return (
      <div className="row">
        {availableAmenities.map(amenity => (
          <FormGroup check className="col-sm-6 col-md-6" key={amenity.id}>
            <Label check>
              <input
                checked={this.props.filterAmenities.includes(
                  parseInt(amenity.id, 10)
                )}
                onChange={this.onFilterAmenityChange}
                type="checkbox"
                value={amenity.id}
              />{' '}
              {amenity.name}
            </Label>
          </FormGroup>
        ))}
      </div>
    );
  };

  renderBusTypes = () => {
    const { busTypes } = this.props;

    if (!busTypes) return null;

    const busTypesList = sortBy(
      busTypes.map(busType => {
        const name =
          busType.description === 'all' ? 'All Bus Types' : busType.description;

        return {
          name,
          value: busType.description
        };
      }),
      'name'
    );

    return (
      <StyledBusFilterContainer className="row d-flex align-items-center justify-content-between">
        <Input
          type="select"
          style={{ margin: 10 }}
          name="Bus Type"
          id="busType"
          className="d-block d-md-none"
          onChange={e => this.onBusTypeFilterButtonPressed(e.target.value)}
        >
          {busTypesList.map(busType => (
            <option key={busType.value} value={busType.value}>
              {busType.name}
            </option>
          ))};
        </Input>

        <div className="d-none d-md-block">
          <ButtonGroup
            className="col p-0"
            role="group"
            aria-label="Basic example"
          >
            {busTypesList.map(busType => (
              <StyledBusFilterButton
                type="button"
                className="btn btn-light col"
                onClick={() => this.onBusTypeFilterButtonPressed(busType.value)}
                active={this.state.busTypeSelected === busType.value}
                key={busType.value}
              >
                <StyledBusFilterButtonTitle>
                  {busType.name}
                </StyledBusFilterButtonTitle>
                <br />
              </StyledBusFilterButton>
            ))}
          </ButtonGroup>
        </div>
      </StyledBusFilterContainer>
    );
  };

  renderFilters() {
    return (
      <div>
        <Dropdown
          isOpen={this.state.busTypesOpen}
          toggle={this.toggleTypes}
          style={{ textAlign: 'center' }}
          className={
            this.state.busTypeSelected && this.state.busTypeSelected !== 'all'
              ? 'filterPill selected'
              : 'filterPill'
          }
        >
          {this.renderBusTypesFilterButton()}
          <DropdownMenu>
            <DropdownItem header>{this.renderBusTypes()}</DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <Dropdown
          isOpen={this.state.destinationOpen}
          toggle={this.toggleDestination}
          style={{ textAlign: 'center' }}
          className={
            this.props.filterZip ? 'filterPill selected' : 'filterPill'
          }
        >
          <DropdownToggle color="link">Depart From</DropdownToggle>
          <DropdownMenu style={{ minWidth: '15em' }}>
            <DropdownItem header>
              <FormGroup>
                <Label for="departureCity">City</Label>
                <div>{this.renderCityInput()}</div>
              </FormGroup>
              <FormGroup style={{ width: '15em' }}>
                <Label for="departureState">State</Label>
                <div>{this.renderStateInput()}</div>
              </FormGroup>
              <div className="pb-2">
                <h5 className="text-primary font-weight-bold text-center">
                  OR
                </h5>
              </div>
              <FormGroup>
                <Label for="zip">Zip</Label>
                <Input
                  type="number"
                  name="zip"
                  id="zip"
                  value={this.props.filterZip}
                  onChange={e =>
                    this.props.updateProperty('filterZip', e.target.value)
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label for="zipWithin">Within</Label>
                <Input
                  type="select"
                  name="zipWithin"
                  id="zipWithin"
                  value={this.props.filterZipWithin}
                  onChange={e =>
                    this.props.updateProperty('filterZipWithin', e.target.value)
                  }
                >
                  <option value="10">10 miles</option>
                  <option value="50">50 miles</option>
                  <option value="100">100 miles</option>
                  <option value="150">150 miles</option>
                </Input>
              </FormGroup>
              <Button
                color="primary"
                onClick={this.onDestinationRefineSearchPressed}
                className="refine"
              >
                Refine Search
              </Button>{' '}
              {this.renderClearDepartFromButton()}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>

        <Dropdown
          isOpen={this.state.amenitiesOpen}
          toggle={this.toggleAmenities}
          style={{ textAlign: 'center' }}
          className={
            this.props.filterAmenities && this.props.filterAmenities.length > 0
              ? 'filterPill selected'
              : 'filterPill'
          }
        >
          {this.renderAmenitiesFilterButton()}
          <DropdownMenu style={{ width: '30vw', minWidth: '20em' }}>
            <DropdownItem header>
              {' '}
              {this.renderAmenitiesFilter()}
              {this.renderClearAmenitiesButton()}
              <Button
                color="primary"
                onClick={this.onRefineSearchButtonPressed}
                className="refine"
              >
                Refine Search
              </Button>{' '}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }

  onDestinationRefineSearchPressed = () => {
    //Reset because each metro has it's own bus types
    this.setState({
      busTypeSelected: 'all'
    });

    this.onRefineSearchButtonPressed();
  };

  onClearDepartFromButtonPressed = () => {
    this.props.updateProperty('filterZip', '');
    this.props.updateProperty('filterZipWithin', '50');
  };

  renderClearDepartFromButton = () => {
    if (!this.props.filterZip) return null;

    return (
      <Button
        color="primary"
        onClick={this.onClearDepartFromButtonPressed}
        className="clear"
      >
        Clear
      </Button>
    );
  };

  renderClearAmenitiesButton = () => {
    if (!this.props.filterAmenities || this.props.filterAmenities.length === 0)
      return null;

    return (
      <Button
        color="primary"
        onClick={this.onClearAmenitiesButtonPressed}
        className="clear"
      >
        Clear
      </Button>
    );
  };

  renderBusTypesFilterButton = () => {
    if (this.state.busTypeSelected && this.state.busTypeSelected !== 'all') {
      return (
        <DropdownToggle color="link">
          {this.state.busTypeSelected}
        </DropdownToggle>
      );
    } else {
      return <DropdownToggle color="link">Bus Types</DropdownToggle>;
    }
  };

  renderAmenitiesFilterButton = () => {
    if (this.props.filterAmenities && this.props.filterAmenities.length > 0) {
      return (
        <DropdownToggle color="link">
          Amenities ({this.props.filterAmenities.length})
        </DropdownToggle>
      );
    } else {
      return <DropdownToggle color="link">Amenities</DropdownToggle>;
    }
  };

  onStateInputChange = state => {
    this.props.changeState(state);
  };

  renderStateInput = () => {
    let states = this.props.filterStates;

    if (!states) return null;

    return (
      <Input
        type="select"
        name="departureState"
        id="departureState"
        value={this.props.departureState}
        onChange={e => this.onStateInputChange(e.target.value)}
      >
        {states.map(state => (
          <option key={state} value={state}>
            {state}
          </option>
        ))};
      </Input>
    );
  };

  renderCityInput = () => {
    let cities = this.props.filterCities;

    if (!cities) return null;

    return (
      <Input
        type="select"
        name="departureCity"
        id="departureCity"
        value={this.props.city}
        onChange={e =>
          this.props.updateProperty('departureCity', e.target.value)
        }
      >
        {cities.map(city => (
          <option key={city} value={city}>
            {city}
          </option>
        ))};
      </Input>
    );
  };

  render() {
    if (this.props.loading) {
      return <Spinner text="Searching" />;
    }

    return (
      <div className="pt-5">
        <div className="container pt-5 pb-5">
          <QuoteProgress progress={33} />

          <Container>
            <div className="row font-weight-light">
              <div className="col-sm-12 col-md-5 pr-0 pl-0">
                <h1 className="font-weight-bold text-primary mb-0">
                  Bus Quote Search Results:
                </h1>
              </div>
            </div>
            <StyledResultsAreaDiv className="row mt-3">
              {this.renderResultsLocationText()}
              {this.renderNumberOfResults()}
            </StyledResultsAreaDiv>
            {this.renderFilters()}
            {this.renderSearchResults()}
          </Container>
        </div>
        <Footer />
        <ShoppingCartButton {...this.props} />
        <SearchDetailModal
          className={this.props.className}
          closeAmenityDetailsModal={this.props.closeAmenityDetailsModal}
          closeMoreDetailsModal={this.props.closeMoreDetailsModal}
          detailModalVisible={this.props.detailModalVisible}
          openAmenityDetailsModal={this.props.openAmenityDetailsModal}
          onAddToQuoteRequest={this.props.onAddToQuoteRequest}
          onContactUs={this.onContactUs}
          onRemoveFromQuoteRequest={this.props.onRemoveFromQuoteRequest}
          openReviewsModal={this.props.openReviewsModal}
          quoteRequests={this.props.quoteRequests}
          selectedResult={this.props.selectedResult}
        />
        <SearchAmenitiesModal
          className={this.props.className}
          closeAmenityDetailsModal={this.props.closeAmenityDetailsModal}
          detailAmenityModalVisible={this.props.detailAmenityModalVisible}
          selectedBusResult={this.props.selectedBusResult}
          selectedResult={this.props.selectedResult}
        />
        <SearchReviewsModal
          className={this.props.className}
          closeReviewsModal={this.props.closeReviewsModal}
          reviewsModalVisible={this.props.reviewsModalVisible}
          selectedResult={this.props.selectedResult}
        />
        <SendMessageModal
          values={this.props}
          errorMessage={this.state.replyErrorMessage}
          modalRef={node => (this.sendMessageForm = node)}
          modalVisible={this.state.sendMessageModalVisible}
          onButtonSubmit={this.onReplyButton}
          onFormSubmit={this.onReplyFormSubmit.bind(this)}
          toggleModal={this.toggleSendMessageModal}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ search, metadata }) => ({
  loading: search.loading || metadata.loading,
  results: search.results,
  busTypes: search.busTypes,
  availableAmenities: search.availableAmenities,
  numberOfPeople: search.numberOfPeople,
  longitude: search.longitude,
  latitude: search.latitude,
  departureCity: search.departureCity,
  departureState: search.departureState,
  freeFormLocation: search.freeFormLocation,
  tripType: search.tripType,
  filterNumberOfPeople: search.filterNumberOfPeople,
  filterAmenities: search.filterAmenities,
  filterZip: search.filterZip,
  filterZipWithin: search.filterZipWithin,
  filterCities: search.filterCities,
  filterStates: metadata.states,
  reviewsModalVisible: search.reviewsModalVisible,
  detailModalVisible: search.detailModalVisible,
  detailAmenityModalVisible: search.detailAmenityModalVisible,
  selectedResult: search.selectedResult,
  selectedBusResult: search.selectedBusResult,
  selectedBusDetailRows: search.selectedBusDetailRows,
  selectedCompany: search.selectedCompany,
  changeState: search.changeState,
  quoteRequests: search.quoteRequests,
  metros: metadata.metros,
  tripTypes: metadata.tripTypes
});

export default connect(mapStateToProps, {
  fetchResults,
  filterByBusType,
  updateProperty,
  fetchMetadata,
  changeState,
  closeMoreDetailsModal,
  closeAmenityDetailsModal,
  closeReviewsModal,
  onAddToQuoteRequest,
  openAmenityDetailsModal,
  onRemoveFromQuoteRequest,
  openMoreDetailsModal,
  openReviewsModal,
  sendMessage
})(Search);
