import React from 'react';
import glamorous from 'glamorous';
import { Alert, Form, FormGroup } from 'reactstrap';
import { connect } from 'react-redux';

import { openSubscriberLoginModal } from '../actions/subscriber/login';
import { subscribeToNewsletter, updateProperty } from '../actions/newsletter';

import { mediaQueries } from '../utils/styleUtils';
import { LargeButton, LargeInput, Section } from '../components';
import { Twentieth, BusRatesLogoMd, CityScene, UMALogo } from '../assets/img';
import Facebook from '../assets/img/social/facebook.png';
import Twitter from '../assets/img/social/twitter.png';
import Instagram from '../assets/img/social/instagram.png';
import GooglePlus from '../assets/img/social/google-plus.png';
import LinkedIn from '../assets/img/social/linkedin.png';

import { staticWebLink } from '../utils/formatUtils';

const StyledFooterElement = glamorous.div({
  [mediaQueries.phone]: {
    textAlign: 'center !important'
  }
});

class Footer extends React.Component {
  componentWillMount() {
    this.props.updateProperty('error', '');
    this.props.updateProperty('joinSuccess', false);
    this.props.updateProperty('email', '');
  }

  renderCityScene = () => {
    const { withCityScene } = this.props;

    if (!withCityScene) return null;

    return (
      <Section
        key="footerCityScene"
        background="white"
        style={{ backgroundColor: 'white' }}
        Title={
          <h2 className="text-primary font-weight-light">
            Your Perfect Bus is Waiting For You. Find the Right Company Today.
          </h2>
        }
        actionText="Get Your Best Quote"
        actionPath="/"
        containerClassName="text-center"
      >
        <img src={CityScene} alt="City Scene" className="mt-5 pb-5 img-fluid" />
      </Section>
    );
  };

  onFormSubmit = e => {
    e.preventDefault();
    this.props.subscribeToNewsletter();
  };

  renderJoinSuccessMessage = () => {
    if (!this.props.joinSuccess) return null;

    return (
      <Alert color="success" className="mt-1">
        Thank you for joining our newsletter
      </Alert>
    );
  };

  renderJoinErrorMessage = () => {
    if (!this.props.error) return null;

    return (
      <Alert color="danger" className="mt-1">
        {this.props.error}
      </Alert>
    );
  };

  render() {
    return [
      <footer
        className={`${this.props.noTopPadding ? '' : 'pt-5'} text-white`}
        key="primaryFooter"
        style={{ backgroundColor: '#154988' }}
      >
        {this.renderCityScene()}
        <div className="row pt-1 pb-5 container mx-auto">
          <div className="col-sm-12 col-md-6">
            <div className="d-flex flex-wrap justify-content-start mb-5 align-items-center">
              <img
                  src={Twentieth}
                  alt="BusRates"
                  style={{marginTop: '0.5em', marginBottom: '0.5em', marginRight: '1em',width: "140px", height: "111px"}}
              />
              <img
                src={BusRatesLogoMd}
                alt="BusRates"
                style={{marginTop: '0.5em', marginBottom: '0.5em', marginRight: '1em',width: "269px", height: "36px" }}
              />
              {/*<img*/}
              {/*  src={UMALogo}*/}
              {/*  alt="United Motorcoach Association"*/}
              {/*  style={{marginTop: '0.5em', marginBottom: '0.5em', width: "104px", height: "42px" }}*/}
              {/*/>*/}
            </div>
            <div className="text-white mb-3">
              <h5 className="font-weight-bold">Newsletter</h5>
              <p className="font-weight-light">
                Receive travel tips and discover destinations in our newsletter for group planners.
              </p>
            </div>
            <div>
              <Form inline onSubmit={this.onFormSubmit}>
                <FormGroup className="col-sm-12 col-md-9 p-0">
                  <LargeInput
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    value={this.props.email}
                    onChange={e =>
                      this.props.updateProperty('email', e.target.value)
                    }
                  />
                </FormGroup>
                <FormGroup className="col-sm-12 col-md-3 p-0">
                  <LargeButton text="Join" />
                </FormGroup>
                {this.renderJoinErrorMessage()}
                {this.renderJoinSuccessMessage()}
              </Form>
            </div>
          </div>
          <div className="col-sm-12 col-md-2 pl-4 row">
            <div className="col">
              <h5 className="text-secondary">BusRates</h5>
              <ul className="d-flex flex-column list-unstyled pt-4">
                <a href={staticWebLink('about')} className="text-white pb-2">About Us</a>
                <a href={staticWebLink('blog')} className="text-white pb-2">Blog</a>
                <a href={staticWebLink('write-review')} className="text-white pb-2">Write Review</a>
              </ul>
            </div>
          </div>
          <div className="col-sm-12 col-md-3 row">
            <div className="col">
              <h5 className="text-secondary">Discover</h5>
              <ul className="d-flex flex-column list-unstyled pt-4">
                <a href={staticWebLink('cities')} className="text-white pb-2">Cities</a>
                <a href={staticWebLink('bustypes')} className="text-white pb-2">Bus Types</a>
                <a href={staticWebLink('licensing-insurance')} className="text-white pb-2">Licensing & Insurance</a>
                <a href={staticWebLink('group-travel-planner')} className="text-white pb-2">Group Travel Planner</a>
                <a href={staticWebLink('operating-authority')} className="text-white pb-2">Operating Authority</a>
                <a href={staticWebLink('testimonials')} className="text-white pb-2">Testimonials</a>
                <a href={staticWebLink('customer-service')} className="text-white pb-2">Customer Service</a>
                <a href={staticWebLink('travel-tips')} className="text-white pb-2">Travel Tips</a>
              </ul>
            </div>
          </div>
        </div>
        <div
          className="container-fluid"
          style={{ backgroundColor: '#061D3A', fontSize: 14 }}
        >
          <div className="row container mx-auto text-white">
            <StyledFooterElement className="col-sm-12 col-md-4 pt-3 pb-3">
              &copy;&nbsp;{new Date().getFullYear()} BusRates.com. All rights
              reserved.
            </StyledFooterElement>
            <StyledFooterElement className="col-sm-12 col-md-4 text-center pt-3 pb-3">
              <a href={staticWebLink('terms')} className="text-white">
                Terms&nbsp;&nbsp;
              </a>|<a className="text-white" href={staticWebLink('policies')}>
                &nbsp;&nbsp;Privacy&nbsp;&nbsp;
              </a>|<a className="text-white" href={staticWebLink('sitemap')}>
                &nbsp;&nbsp;Site Map
              </a>
            </StyledFooterElement>
            <StyledFooterElement className="col-sm-12 col-md-4 text-right pt-3 pb-3">
              <a
                href="https://plus.google.com/+BusRates/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={GooglePlus} alt="Google Plus" />
              </a>
              <a href="https://facebook.com/busratescom">
                <img className="ml-4" src={Facebook} alt="Facebook" />
              </a>
              <a
                href="https://www.linkedin.com/company/busrates-com-inc-"
                rel="noopener noreferrer"
              >
                <img className="ml-4" src={LinkedIn} alt="LinkedIn" />
              </a>
              <a
                href="https://www.instagram.com/busratesdotcom/"
                rel="noopener noreferrer"
              >
                <img className="ml-4" src={Instagram} alt="Instagram" />
              </a>
            </StyledFooterElement>
          </div>
        </div>
      </footer>
    ];
  }
}

const mapStateToProps = ({ newsletter }) => ({
  error: newsletter.error,
  joinSuccess: newsletter.joinSuccess,
  email: newsletter.email
});

export default connect(mapStateToProps, {
  openSubscriberLoginModal,
  subscribeToNewsletter,
  updateProperty
})(Footer);
