import React, {Component} from 'react';
import glamorous from 'glamorous';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import FaCheck from 'react-icons/lib/fa/check';
import FaExclamationTriangle from 'react-icons/lib/fa/exclamation-triangle';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faTimes from '@fortawesome/fontawesome-free-solid/faTimes';

import {
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Table,
    Jumbotron
} from 'reactstrap';

import {Footer, Section} from '../../components';

import Menu from '../../components/subscriber/Menu';

import {
    addNewMetro,
    fetchListings,
    fetchMetrosInRadius,
    fetchNewMetros,
    removeMetro,
    updateProperty
} from '../../actions/subscriber/listings';
import {
    selfPayToAddMetroResults,
    addNewMetroResults,
    validPaymentInfo
} from "../../services/subscriber/listingsService";
import { checkFreeTryOut } from '../../actions/subscriber/login';


const StyledTable = glamorous(Table)({
    fontWeight: 400
});

const StyledAddNewMetroButton = glamorous(Button)({
    marginRight: 37
});

class Listings extends Component {
    state = {
        addNewListingModalVisible: false,
        typeaheadIsLoading: false,
        findMetrosButtonEnabled: false,
        payModalVisible: false,
        payInfo: {
            companyMetroId: 0,
            companyId: 0,
            metroName: '',
            cost: ''
        },
        loadingCssName: "",
        canSubmit: true,
        payDisabled: false,
        listDataLoadingCssName: '',
        dataDisplay: false
    };

    componentWillMount = () => {
        // this.props.fetchListings();
        this.getFetchListings();
    };

    getFetchListings = () => {
        this.setState({listDataLoadingCssName: 'dataLoading', dataDisplay: false})
        this.props.checkFreeTryOut()
        this.props.fetchListings().then(() => {
            this.setState({listDataLoadingCssName: '', dataDisplay: true})
        })
    };

    renderMetroTotals = () => {
        const {totals} = this.props;

        if (!totals) return null;

        return (
            <tr style={{"display": this.state.dataDisplay ? "table-row" : "none"}} className="align-middle text-center">
                <td>Metros Total</td>
                <td>{totals.metros}</td>
                <td>{totals.metroCosts}</td>
                <td/>
                <td/>
                <td/>
            </tr>
        );
    };

    renderListingTotals = () => {
        const {totals} = this.props;

        if (!totals) return null;

        return (
            <tr className="align-middle text-center">
                <td>Listing Fee</td>
                <td/>
                <td>{totals.listingFee}</td>
                <td/>
                <td/>
                <td/>
            </tr>
        );
    };

    renderTotals = () => {
        const {totals} = this.props;

        if (!totals) return null;

        return (
            <tr className="align-middle text-center">
                <td>Totals</td>
                <td/>
                <td>{totals.totalFees}</td>
                <td/>
                <td/>
                <td/>
            </tr>
        );
    };

    renderApproved = () => (
        <span title="Active/Approved">
      <FaCheck color="#008000"/>
    </span>
    );

    renderPendingRemoval = () => (
        <span title="Pending Removal">
      <FontAwesomeIcon color="#dc3545" icon={faTimes}/>
    </span>
    );

    renderPendingApproval = () => (
        <span title="Pending Approval">
      <FaExclamationTriangle color="#e69a2b"/>
    </span>
    );

    renderStatus = status => {
        if (status === 'Active/Approved') {
            return this.renderApproved();
        }

        if (status === 'Pending Approval') {
            return this.renderPendingApproval();
        }

        if (status === 'Pending Removal') {
            return this.renderPendingRemoval();
        }

        if (status === 'Inactive/Approved') {
            return "Inactive";
        }

        if (status === 'Not Approved') {
            return "Not Approved";
        }

        if (status === 'No Bus') {
            return 'No Bus';
        }
    };

    renderCost = cost => {
        if (cost === 'Pending Approval') {
            return this.renderPendingApproval();
        }

        if (cost === 'Pending Removal') {
            return this.renderPendingRemoval();
        }

        return cost;
    };

    renderPosition = (position, status) => {
        if (position === 'Pending Approval') {
            return this.renderPendingApproval();
        }

        if (position === 'Pending Removal') {
            return this.renderPendingRemoval();
        }

        if (status === 'No Bus') {
            return "--";
        }

        return position;
    };

    renderRemoveButton = (status, companyMetroId) => (
        <Button
            color="danger"
            block
            disabled={status === 'Pending Removal'}
            onClick={() => this.onRemoveButtonClick(companyMetroId)}
        >
            Remove
        </Button>
    );

    renderEditButton = (status, companyMetroId) => {
        return <Button
            color="primary"
            block
            // disabled={status !== 'Active/Approved'}
            onClick={() => this.onEditButtonClick(companyMetroId)}
        >
            Edit
        </Button>
    }

    renderPayButton = (status, companyMetroId, companyId, metroName, cost) => (
        <Button
            color="info"
            block
            disabled={!(status == 'Pending Approval')}
            hidden={!(status == 'Pending Approval')}
            onClick={() => this.onShowPayContentClick(companyMetroId, companyId, metroName, cost)}
        >
            Pay
        </Button>
    );

    renderListingResults = () => {
        const {listings} = this.props;

        if (!listings) return null;

        return listings.map(result => (
            <tr key={result.companyMetroId}>
                <td className="align-middle">
                    <Link
                        to={'/search/' + result.city + '/' + result.state}
                        target="_blank"
                    >
                        {result.metroName}
                    </Link>
                </td>
                <td className="align-middle">{this.renderPosition(result.position, result.status)}</td>
                <td className="align-middle">{this.renderCost(result.cost)}</td>
                <td className="align-middle">{this.renderStatus(result.status)}</td>
                <td>
                    {this.renderEditButton(result.status, result.companyMetroId)}
                    {/*{this.renderPayButton(result.status, result.companyMetroId, result.companyId, result.metroName, result.payTotal)}*/}
                    {this.renderRemoveButton(result.status, result.companyMetroId)}
                </td>
            </tr>
        ));
    };

    renderListings = () => (
        <StyledTable striped>
            <thead className="text-center">
            <tr>
                <th>Metro</th>
                <th>Position</th>
                <th>Cost</th>
                <th>Status</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody style={{"display": this.state.dataDisplay ? "table-row-group" : "none"}}
                   className="text-center">{this.renderListingResults()}</tbody>
            <tfoot>
            {this.renderMetroTotals()}
            {/*    {this.renderListingTotals()}*/}
            {/*{this.renderTotals()}*/}
            </tfoot>
        </StyledTable>
    );

    onAddNewListingClick = () => {
        this.setState({
            addNewListingModalVisible: true
        });
    };

    onHereClick = () => {
        this.props.history.push('/subscribers/payments')
    };

    onPayAllClick = () => {

        this.props.history.push('/subscribers/crash');

        // validPaymentInfo(this.props.companyId).then(response => {
        //     if (response.status === 200) {
        //         console.log(response)
        //         if (response.data) {
        //             this.props.history.push('/subscribers/crash');
        //         } else {
        //             if (window.confirm("Missing Payments, please perfect it.")) {
        //                 this.props.history.push('/subscribers/payments');
        //             }
        //         }
        //     } else {
        //         alert('NetWork Error')
        //     }
        // });
    };

    onShowPayContentClick = (companyMetroId, companyId, metroName, cost) => {
        let date = this.state.payInfo
        date.companyMetroId = companyMetroId
        date.companyId = companyId
        date.metroName = metroName
        date.cost = cost
        this.setState({
            payModalVisible: true,
            payInfo: date
        })
    };

    closeAddNewListingModal = () => {
        this.setState({
            addNewListingModalVisible: false
        });
        this.props.updateProperty('metrosInRadius', null);
    };

    closePayModel = () => {
        let date = this.state.payInfo
        date.companyMetroId = 0
        date.companyId = 0
        date.metroName = ''
        date.cost = ''
        this.setState({
            payModalVisible: false,
            payInfo: date
        })
    };

    onFindMetrosClick = () => {
        this.props.fetchMetrosInRadius();
    };

    onTypeaheadChange = e => {
        if (!e || e.length === 0) return;
        this.props.updateProperty('newMetroSearchTerm', e[0].id);
        this.setState({
            findMetrosButtonEnabled: true
        });
    };

    onTypeaheadSearch = query => {
        this.setState({typeaheadIsLoading: true});

        this.props.fetchNewMetros(query).then(() => {
            const locations = this.props.locations.map(location => ({
                id: location.id,
                label: location.name
            }));

            this.setState({
                typeaheadIsLoading: false,
                typeaheadOptions: locations
            });
        });
    };

    onAddMetroClick = (metroId, baseRate) => {
        if (!window.confirm('Are you sure you want to request to add this metro?'))
            return false;

        addNewMetroResults({
            metro_id: metroId,
            base_rate: baseRate,
            company_id: this.props.companyId,
            company_name: this.props.companyName
        }).then((response) => {
            if (response.data.success) {
                this.closeAddNewListingModal();
                this.props.history.push('/subscribers/listings/' + response.data.companyMetroId);
            } else {
                alert(response.data.errorMessage)
            }
        });
    };

    onRemoveButtonClick = companyMetroId => {
        if (
            !window.confirm(
                'Please Note: After removing a metro, please be aware that the displayed pricing may require adjustment in cases where the removed metro was part of a special discount or package deal. We will email you with confirmation of your new rate within two business days. Do you want to continue and Remove the metro?'
            )
        )
            return false;

        this.props.removeMetro(companyMetroId).then(() => {
            // this.props.fetchListings();
            this.getFetchListings();
        });
    };

    onEditButtonClick = companyMetroId => {
        this.props.history.push('/subscribers/listings/' + companyMetroId);
    };

    onPayClick = () => {
        if (this.state.canSubmit) {
            this.setState({
                canSubmit: false,
                loadingCssName: "buttonLoading",
                payDisabled: true
            });
            let companyMetroId = this.state.payInfo.companyMetroId
            let companyId = this.state.payInfo.companyId
            selfPayToAddMetroResults({
                company_metro_id: companyMetroId,
                company_id: companyId
            }).then(response => {
                this.setState({
                    canSubmit: true,
                    loadingCssName: "",
                    payDisabled: false
                });
                if (response.status === 200) {
                    const {success} = response.data
                    if (success) {
                        this.closePayModel()
                        this.props.history.push('/subscribers/listings/' + companyMetroId);
                    } else {
                        alert(response.data.error_message)
                    }
                } else {
                    alert('NetWork Error')
                }
            });
        }
    };

    renderMetrosInRadius = () => {
        const {metrosInRadius, isFreeTrial} = this.props;

        if (!metrosInRadius) return null;

        return (
            <Table>
                <thead>
                <tr>
                    <th>Metro</th>
                    <th>Base Rate</th>
                    <th/>
                </tr>
                </thead>
                <tbody>
                {metrosInRadius.map(metro => (
                    <tr key={metro.id}>
                        <td>
                            {metro.city}, {metro.state}
                        </td>
                        <td>${metro.baseRate}</td>
                        <td>
                            <Button
                                className="text-white"
                                onClick={() => this.onAddMetroClick(metro.id, metro.baseRate)}
                            >
                                {
                                    isFreeTrial ? 'Add This Metro As Free Trial' : 'Add This Metro'
                                }
                                
                            </Button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
        );
    };

    renderRadiusOption = miles => (
        <Label check className="pr-5">
            <Input
                type="radio"
                name="radius"
                value={miles}
                onChange={() =>
                    this.props.updateProperty('newMetroSearchRadius', miles)
                }
            />
            {miles} Miles
        </Label>
    );

    renderAddNewListingModal = () => (
        <Modal
            isOpen={this.state.addNewListingModalVisible}
            toggle={this.closeAddNewListingModal}
        >
            <ModalHeader
                className="d-flex align-items-center pl-5 pr-5"
                toggle={this.closeAddNewListingModal}
            >
                Add New Metro
            </ModalHeader>
            <ModalBody className="pl-5 pr-5">
                <Form className="customForm">
                    <FormGroup>
                        <Col sm={9} className="pl-0">
                            <AsyncTypeahead
                                minLength={2}
                                placeholder="Search for a city"
                                id="newMetroSearchTerm"
                                autoComplete="false"
                                type="text"
                                allowNew="false"
                                style={{width: '20em'}}
                                isLoading={this.state.typeaheadIsLoading}
                                options={this.state.typeaheadOptions}
                                onSearch={this.onTypeaheadSearch}
                                onChange={this.onTypeaheadChange}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup check>
                        {this.renderRadiusOption(50)}
                        {this.renderRadiusOption(100)}
                        {this.renderRadiusOption(200)}
                    </FormGroup>
                    <FormGroup className="mt-3 mb-0">
                        <Button
                            className="text-white"
                            onClick={this.onFindMetrosClick}
                            disabled={!this.state.findMetrosButtonEnabled}
                        >
                            Find Metros
                        </Button>
                    </FormGroup>
                </Form>
                {this.renderMetrosInRadius()}
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={this.closeAddNewListingModal}>
                    Cancel
                </Button>{' '}
            </ModalFooter>
        </Modal>
    );

    renderPayModal = () => (
        <Modal isOpen={this.state.payModalVisible} toggle={this.closePayModel}>
            <ModalHeader toggle={this.closePayModel} className="d-flex align-items-center pl-5 pr-5">Pay
                Content</ModalHeader>
            <ModalBody>
                <Jumbotron>
                    <p className="lead"><b>Metro:</b> {this.state.payInfo.metroName}</p>
                    <hr className="my-2"/>
                    <p><b>Total: &nbsp;</b>$ {this.state.payInfo.cost}</p>
                </Jumbotron>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" disabled={this.state.payDisabled} className={this.state.loadingCssName}
                        onClick={this.onPayClick}>Pay</Button>{' '}
                <Button color="secondary" onClick={this.closePayModel}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );

    renderAddNewMetroButton = () => {

        const {companyApproved} = this.props;
        const {paymentsAvailable} = this.props;
        if (paymentsAvailable == null || companyApproved == null) {
            return null;
        }
        if (!paymentsAvailable) {
            if (!companyApproved) {
                return (
                    <div>
                        <div style={{"margin": "10px 0"}}>
                            Your account is still pending approval. You cannot add additional
                            metros at this time.
                        </div>
                        <div style={{"margin": "10px 0"}}>
                            Your account is missing payment information. You can click <em
                            onClick={this.onHereClick} style={{"color": "#007bff", "cursor": "pointer"}}>here</em> to
                            complete it.
                        </div>
                    </div>
                );
            } else {

                return (
                    <div>
                        <div style={{"margin": "10px 0"}}>
                            Your account is missing payment information. You can click <em
                            onClick={this.onHereClick} style={{"color": "#007bff", "cursor": "pointer"}}>here</em> to
                            complete it.
                        </div>
                        <div>
                            <StyledAddNewMetroButton
                                className="text-white float-right"
                                onClick={this.onAddNewListingClick}
                            >
                                Add New Metro
                            </StyledAddNewMetroButton>
                        </div>
                    </div>
                );
            }
        } else {

            if (!companyApproved) {
                return (
                    <div style={{"margin": "10px 0"}}>
                        Your account is still pending approval. You cannot add additional
                        metros at this time.
                    </div>
                );
            }

            return (
                <StyledAddNewMetroButton
                    className="text-white float-right"
                    onClick={this.onAddNewListingClick}
                >
                    Add New Metro
                </StyledAddNewMetroButton>
            );
        }
    };

    renderPayButton = () => {
        const {isFreeTrial} = this.props;
        if(!isFreeTrial){
            return (
                <StyledAddNewMetroButton
                    className="text-white float-right"
                    onClick={this.onPayAllClick}
                >
                    Pay
                </StyledAddNewMetroButton>
            );
        }
        
    };

    render() {
        return (
            <div className="pt-5">
                <Section>
                    <Row>
                        <Col xs="12" className="text-center">
                            <h1>Listings</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Menu history={this.props.history}/>
                        <Col xs="9" className={this.state.listDataLoadingCssName}>
                            <Row className="pb-2">
                                <Col xs="12">{this.renderAddNewMetroButton()}</Col>
                            </Row>
                            <Row>{this.renderListings()}</Row>
                            <Row className="pb-2">
                                <Col xs="12">{this.renderPayButton()}</Col>
                            </Row>
                        </Col>
                    </Row>
                </Section>
                <Footer/>
                {this.renderAddNewListingModal()}
                {this.renderPayModal()}
            </div>
        );
    }
}

const mapStateToProps = ({subscriberListings, login}) => ({
    companyId: login.companyId,
    companyName: login.companyName,
    listings: subscriberListings.listings,
    locations: subscriberListings.locations,
    totals: subscriberListings.totals,
    metrosInRadius: subscriberListings.metrosInRadius,
    newMetroSearchTerm: subscriberListings.newMetroSearchTerm,
    companyApproved: subscriberListings.companyApproved,
    paymentsAvailable: subscriberListings.paymentsAvailable,
    isFreeTrial: login.isFreeTrial
});

export default connect(mapStateToProps, {
    addNewMetro,
    fetchListings,
    fetchMetrosInRadius,
    fetchNewMetros,
    removeMetro,
    updateProperty,
    checkFreeTryOut
})(Listings);
