import React, { Component } from 'react';

class Message extends Component {
  render() {
    const { message, type } = this.props;
    return (
      <div className={`message ${type}`}>
        {message}
      </div>
    );
  }
}
export default Message;
