import React from 'react';
import { compose, withProps } from 'recompose';
import {
  withGoogleMap,
  Circle,
  GoogleMap,
  DirectionsRenderer
} from 'react-google-maps';

class MetroMap extends React.Component {
  calculateMiles = meters => meters * 1609.34;

  componentWillMount() {
    this.setState({
      directions: null
    });
  }

  getOriginLatLong = () => {
    const { originLatitude, originLongitude } = this.props;
    return {
      originLatitude: parseFloat(originLatitude),
      originLongitude: parseFloat(originLongitude)
    };
  };

  renderDirections = () => {
    const {
      destinationCity,
      destinationState,
      departureCity,
      departureState
    } = this.props;
    const origin = departureCity + ', ' + departureState;
    const destination = destinationCity + ', ' + destinationState;

    if (!destinationCity || !destinationState) return;

    if (
      !this.state.directions ||
      this.state.directions.request.destination.query !== destination ||
      this.state.directions.request.origin.query !== origin
    ) {
      const google = window.google;
      const DirectionsService = new google.maps.DirectionsService();

      DirectionsService.route(
        {
          origin: origin,
          destination: destination,
          travelMode: google.maps.TravelMode.DRIVING
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            this.setState({
              directions: result
            });
          } else {
            console.error(`error fetching directions`, { result });
          }
        }
      );
    }

    return (
      this.state.directions && (
        <DirectionsRenderer directions={this.state.directions} />
      )
    );
  };

  renderCircle() {
    const { circle } = this.props;
    const { originLatitude, originLongitude } = this.getOriginLatLong();

    if (!circle) return;

    return (
      <Circle
        defaultCenter={{ lat: originLatitude, lng: originLongitude }}
        defaultRadius={this.calculateMiles(50)}
        options={{
          clickable: false,
          draggable: false,
          editable: false,
          fillColor: '#e69a2b',
          strokeOpacity: 0
        }}
      />
    );
  }

  render() {
    const google = window.google;
    const { originLatitude, originLongitude } = this.getOriginLatLong();

    return (
      <GoogleMap
        defaultZoom={8}
        defaultCenter={new google.maps.LatLng(originLatitude, originLongitude)}
      >
        {this.renderDirections()}
        {this.renderCircle()}
      </GoogleMap>
    );
  }
}

export default compose(
  withProps({
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withGoogleMap
)(MetroMap);
