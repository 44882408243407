import withAuthentication, {ROUTE_TYPES} from '../withAuthentication';
import withTracker from '.././withTracker';
import Init2 from '../../containers/subscriber/setup/init2';

const subscriberInit2 = {
    path: '/subscribers/init2',
    component: withTracker(withAuthentication(Init2, ROUTE_TYPES.SUBSCRIBER))
};

export default subscriberInit2;
