import axios from 'axios';
import camelcaseKeysDeep from 'camelcase-keys-deep';
import { exportedStored } from '../App';

class Api {
  constructor() {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  }

  getHeaders() {
    const { apiToken } = exportedStored.getState().login;

    return new Promise(res => {
      let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      };

      if (apiToken && apiToken.length) {
        headers = {
          ...headers,
          'X-User-Token': apiToken
        };
      }

      res(headers);
    });
  }

  client() {
    return this.getHeaders().then(headers => {
      const instance = axios.create({
        transformResponse: [
          data => {
            if (data) {
              return camelcaseKeysDeep(JSON.parse(data));
            }
          }
        ]
      });
      instance.defaults.headers.common = {};

      Object.keys(headers).forEach(header => {
        instance.defaults.headers.common[header] = headers[header];
      });
      
      return instance;
    });
  }

  get(endpoint, params, headers) {
    const { apiToken } = exportedStored.getState().login;
    headers = {
      ...headers,
      'X-User-Token': apiToken
    }
    return this.client().then(client =>
      client.get(endpoint, {
        headers: headers || null,
        params: params || null
      })
    );
  }

  delete(endpoint, data) {
    return this.client().then(client => client.delete(endpoint, { data }));
  }

  put(endpoint, data) {
    return this.client().then(client => client.put(endpoint, {}, { data }));
  }

  post(endpoint, data, headers) {
    const { apiToken } = exportedStored.getState().login;
    headers = {
      ...headers,
      'X-User-Token': apiToken
    }
    return this.client().then(client =>
      client.post(endpoint, {}, { data, headers: headers || null })
    );
  }

  csv(endpoint, data, headers, useLongRunning) {
    const { apiToken } = exportedStored.getState().login;

    if (useLongRunning) {
      axios.defaults.baseURL = process.env.REACT_APP_LONG_RUNNING_API_URL;
    }

    return new Promise(res => {
      let headers = {
        Accept: 'text/csv',
        'Content-Type': 'application/json'
      };

      if (apiToken && apiToken.length) {
        headers = {
          ...headers,
          'X-User-Token': apiToken
        };
      }

      res(headers);
    })
      .then(headers => {
        const instance = axios.create();
        instance.defaults.headers.common = {};

        Object.keys(headers).forEach(header => {
          instance.defaults.headers.common[header] = headers[header];
        });

        return instance;
      })
      .then(client =>
        client.post(endpoint, {}, { data, headers: headers || null })
      );
  }
}

export default new Api();
