import withAuthentication, {ROUTE_TYPES} from '../withAuthentication';
import withTracker from '.././withTracker';
import Init3 from '../../containers/subscriber/setup/init3';

const subscriberInit3 = {
    path: '/subscribers/init3',
    component: withTracker(withAuthentication(Init3, ROUTE_TYPES.SUBSCRIBER))
};

export default subscriberInit3;
