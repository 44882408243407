import React from 'react';
import {Button,Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import { Formik } from 'formik';

const PaymentInfoModal = ({
          modalRef,
          modalVisible,
          title,
          content,
          toggleModal,
          onButtonSubmit,
          noCancel
    }) => (
  <Modal isOpen={modalVisible} toggle={toggleModal}>
    <ModalHeader className="d-flex align-items-center pl-5 pr-5" toggle={toggleModal} >
        {title}
    </ModalHeader>
    <ModalBody className="pl-5 pr-5">
      <Formik ref={modalRef}
        render={formProps => (
            <p>
                {content}
                {onButtonSubmit}
            </p>
        )}/>
    </ModalBody>
    <ModalFooter>
        <Button id="pay-button" color="secondary" className="text-white btn btn-secondary" onClick={onButtonSubmit}> Ok</Button>  
        {noCancel !== 'noCancel' && (
          <Button color="primary" onClick={toggleModal}> Cancel</Button>
        )}
    </ModalFooter>
  </Modal>
);
export default PaymentInfoModal;
