import React, { Component } from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import uniqueId from 'lodash/uniqueId';
import Slider from 'react-slick';
import glamorous from 'glamorous';
import FaFacebook from 'react-icons/lib/fa/facebook-square';
import FaTwitter from 'react-icons/lib/fa/twitter-square';
import FaInstagram from 'react-icons/lib/fa/instagram';
import FaYoutube from 'react-icons/lib/fa/youtube-square';

import { formatPhone } from '../../utils/formatUtils';
import * as Icons from '../../assets/img/icons';
import QuoteRequestButton from './QuoteRequestButton';
import DetailBusTypes from './DetailBusTypes';
import { Icon } from '../';
import ReviewButton from './ReviewButton';
import { trackAnalytics, TRACKING_TYPES } from '../../services/analyticsUtils';
import { blue } from '../../utils/globalColors';

const StyledModalBody = glamorous(ModalBody)({
  fontWeight: 400
});

const StyledCloseDetailsButton = glamorous(Button)({
  fontWeight: 400
});

const renderPaymentTypes = paymentTypes => {
  if (paymentTypes == null) return null;

  const renderPaymentTypes = [];

  if (paymentTypes.visa) {
    renderPaymentTypes.push(
      <Icon
        src={Icons.VisaIcon}
        title="Visa"
        alt="Visa"
        key="visa"
        size="25"
        className="mr-1"
      />
    );
  }

  if (paymentTypes.mastercard) {
    renderPaymentTypes.push(
      <Icon
        src={Icons.MastercardIcon}
        title="Mastercard"
        alt="Mastercard"
        key="mastercard"
        size="25"
        className="mr-1"
      />
    );
  }

  if (paymentTypes.discover) {
    renderPaymentTypes.push(
      <Icon
        src={Icons.DiscoverIcon}
        title="Discover"
        alt="Discover"
        key="discover"
        size="25"
        className="mr-1"
      />
    );
  }

  if (paymentTypes.paypal) {
    renderPaymentTypes.push(
      <Icon
        src={Icons.PaypalIcon}
        title="Paypal"
        alt="Paypal"
        key="paypal"
        size="25"
        className="mr-1"
      />
    );
  }

  if (paymentTypes.check) {
    renderPaymentTypes.push(<span key="check">Check</span>);
  }

  return renderPaymentTypes;
};

class SearchDetailModal extends Component {
  state = {
    showPhone: false
  };

  getSliderSettings(photos) {
    if (photos && photos.length > 1) {
      return {
        infinite: true,
        speed: 500,
        dots: false,
        arrows: true,
        adaptiveHeight: true
      };
    }
    else {
      return {
        dots: false,
        arrows: false,
        adaptiveHeight: true
      };
    }
  }

  onPhoneClick = event => {
    event.preventDefault();

    if (!this.props.isSampleResult) {
      trackAnalytics({
        companyMetroId: this.props.selectedResult.company.metroId,
        trackingType: TRACKING_TYPES.PHONE
      });
    }

    this.setState({ showPhone: true });
  };

  onWebsiteClick = () => {
    if (!this.props.isSampleResult) {
      trackAnalytics({
        companyMetroId: this.props.selectedResult.company.metroId,
        trackingType: TRACKING_TYPES.WEBSITE
      });
    }
  };

  onSocialClick = trackingType => {
    if (!this.props.isSampleResult) {
      trackAnalytics({
        companyMetroId: this.props.selectedResult.company.metroId,
        trackingType
      });
    }
  };

  toggleModal = () => {
    this.setState({ showPhone: false });
    this.props.closeMoreDetailsModal();
  };

  renderSocialMedia = () => {
    const {
      facebookUrl,
      twitterUrl,
      instagramUrl,
      youtubeUrl
    } = this.props.selectedResult.company;

    const style = { margin: '5px' };
    const socialIconProps = {
      color: blue,
      size: 35,
      style
    };
    const Link = props => (
      <a href={props.url} target="_blank" onClick={props.onClick}>
        {props.children}
      </a>
    );

    return (
      <React.Fragment>
        {facebookUrl.length > 0 && (
          <Link
            url={facebookUrl}
            onClick={() => this.onSocialClick(TRACKING_TYPES.FACEBOOK)}
          >
            <FaFacebook alt="Facebook" {...socialIconProps} />
          </Link>
        )}
        {twitterUrl.length > 0 && (
          <Link
            url={twitterUrl}
            onClick={() => this.onSocialClick(TRACKING_TYPES.TWITTER)}
          >
            <FaTwitter alt="Twitter" {...socialIconProps} />
          </Link>
        )}
        {instagramUrl.length > 0 && (
          <Link
            url={instagramUrl}
            onClick={() => this.onSocialClick(TRACKING_TYPES.INSTAGRAM)}
          >
            <FaInstagram alt="Instagram" {...socialIconProps} />
          </Link>
        )}
        {youtubeUrl.length > 0 && (
          <Link
            url={youtubeUrl}
            onClick={() => this.onSocialClick(TRACKING_TYPES.YOUTUBE)}
          >
            <FaYoutube alt="YouTube" {...socialIconProps} />
          </Link>
        )}
      </React.Fragment>
    );
  };

  render() {
    const { hideContactUs } = this.props;
    const result = this.props.selectedResult;

    if (result == null) return null;

    const { company, photos, paymentTypes } = result;
    const settings = this.getSliderSettings(photos);

    const email =
      !hideContactUs && company.email && company.email.length ? (
        <div className="mb-2">
          <a href="" onClick={this.props.onContactUs} className="mb-0">
            Contact Us
          </a>
        </div>
      ) : null;

    const phone = this.state.showPhone ? (
      [
        <p className="mb-0 font-weight-bold" key={company.phone}>Phone</p>,
        formatPhone(company.phone)
      ]
    ) : (
      <a href="" onClick={this.onPhoneClick} className="mb-0 font-weight-bold">
        Show Phone Number
      </a>
    );

    return (
      <Modal
        isOpen={this.props.detailModalVisible}
        toggle={this.toggleModal}
        className={this.props.className}
        size="lg"
      >
        <div className="modal-header">
          <div className="d-flex flex-wrap justify-content-between align-items-center w-100">
            <h4 className="modal-title text-primary">{company.name}</h4>
            <ReviewButton
              inline
              result={result}
              openReviewsModal={() => this.props.openReviewsModal(result)}
            />
          </div>
          <button
            aria-label="Close"
            className="close"
            onClick={this.toggleModal}
            type="button"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <StyledModalBody>
          <Row className="mb-3">
            <Col xs="12" s="6" md="6" lg="6">
              <Col xs="12">
                {photos.length > 0 ? (
                  <Slider {...settings}>
                    {photos.map((image, index) => (
                      <img
                        alt="Search result"
                        src={image.url}
                        onClick={e =>
                          this.handleClickImage(e, image, index, photos)
                        }
                        key={uniqueId()}
                        style={{ height: 'auto' }}
                      />
                    ))}
                  </Slider>
                ) : (
                  <div className="text-center" style={{ marginTop: '4.75em' }}>
                    {' '}
                    No Photos Available{' '}
                  </div>
                )}
              </Col>
            </Col>
            <Col xs="12" s="6" md="6" lg="6">
              <Row>
                <Col xs="12">{company.about}</Col>
              </Row>
              <hr />
              <Row>
                <h5 className="text-primary col-12">Contact & Details</h5>
                <Col md="6" xs="12">
                  <div className="d-flex flex-column">
                    <div className="mb-2">{company.phone && phone}</div>
                    {email}
                    <div className="mb-2">
                      <p className="mb-0 font-weight-bold">Website</p>
                      <a
                        href={company.website}
                        onClick={this.onWebsiteClick}
                        style={{ wordWrap: 'break-word' }}
                        target="_blank"
                      >
                        {company.website}
                      </a>
                    </div>
                    <div className="mb-2">
                      <p className="mb-0 font-weight-bold">Address</p>
                      {company.address}
                      <br />
                      {company.city}, {company.state} {company.zip}
                    </div>
                  </div>
                </Col>
                <Col md="6" xs="12">
                  <div className="d-flex flex-column">
                    <div className="mb-2">
                      <a href={company.usDotLink} target="_blank">
                        {company.usDotNumber}
                      </a>
                    </div>
                    <div className="mb-2">{company.licensingAndInsurance}</div>
                    <div className="mb-2" style={{ wordWrap: 'break-word' }}>
                      {company.associationsAndCertifications}
                    </div>
                    <div className="mb-2" style={{ wordWrap: 'break-word' }}>
                      {renderPaymentTypes(paymentTypes)}
                    </div>
                    <div className="mb-2">{this.renderSocialMedia()}</div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <DetailBusTypes
            result={result}
            closeAmenityDetailsModal={this.toggleModal}
            openAmenityDetailsModal={this.props.openAmenityDetailsModal}
          />
        </StyledModalBody>
        <ModalFooter>
          <StyledCloseDetailsButton color="link" onClick={this.toggleModal}>
            Close Details
          </StyledCloseDetailsButton>
          {!this.props.hideAddButton && (
            <QuoteRequestButton
              id={company.metroId}
              companyName={company.name}
              onAddToQuoteRequest={this.props.onAddToQuoteRequest}
              onRemoveFromQuoteRequest={this.props.onRemoveFromQuoteRequest}
              quoteRequests={this.props.quoteRequests}
            />
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

export default SearchDetailModal;
