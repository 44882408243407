import {paymentInfoResult} from '../../services/subscriber/paymentsService';

export const getPaymentInfo = () => async (dispatch, getState) => {
    dispatch({
        type: 'SUBSCRIBER_PAYMENT_REQUEST'
    });
    try {
        const {companyId} = getState().login;
        const response = await paymentInfoResult(companyId);
        const {firstName, lastName, companyName, billingAddress, billingCity, billingCountry, billingState, billingZip, email, clientSecret, id, success, errorMessage} = response.data;

        if (success) {
            dispatch({
                type: 'SUBSCRIBER_PAYMENT_REQUEST_SUCCESS',
                payload: {
                    firstName,
                    lastName,
                    companyName,
                    billingAddress,
                    billingCity,
                    billingCountry,
                    billingState,
                    billingZip,
                    email,
                    clientSecret,
                    id
                }
            });
        } else {
            dispatch({
                type: 'SUBSCRIBER_PAYMENT_REQUEST_FAILURE',
                payload: {
                    error: errorMessage
                }
            });
        }
    } catch (error) {
        dispatch({
            type: 'SUBSCRIBER_PAYMENT_REQUEST_FAILURE',
            payload: error
        });
    }
};
