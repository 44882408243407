import withAuthentication, { ROUTE_TYPES } from '../withAuthentication';
import withTracker from '.././withTracker';
import bidListingsComponent from '../../containers/subscriber/BidListings';

const subscriberBidListings = {
  path: '/subscribers/bidListings',
  component: withTracker(
    withAuthentication(bidListingsComponent, ROUTE_TYPES.SUBSCRIBER)
  )
};

export default subscriberBidListings;
