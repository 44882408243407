import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Alert,
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import { Formik } from 'formik';
import Yup from 'yup';
import { Input, InputRadioGroup } from '../../components';
import { fetchUserProfile } from '../../actions/user/userProfile';

class SendMessageModal extends Component {
  componentDidMount() {
    if (this.props.loginType === 'user') {
      this.props.fetchUserProfile();
    }
  }

  render() {
    const {
      email,
      errorMessage,
      firstName,
      lastName,
      modalRef,
      modalVisible,
      onButtonSubmit,
      onFormSubmit,
      toggleModal
    } = this.props;
    return (
      <Modal isOpen={modalVisible} toggle={toggleModal}>
        <ModalHeader
          className="d-flex align-items-center pl-5 pr-5"
          toggle={toggleModal}
        >
          Send Message
        </ModalHeader>
        <ModalBody className="pl-5 pr-5">
          <Formik
            ref={modalRef}
            initialValues={{ email, name: `${firstName} ${lastName}` }}
            validationSchema={validationSchema}
            onSubmit={onFormSubmit}
            render={formProps => (
              <Form onSubmit={formProps.handleSubmit}>
                <p>
                  This communication will be sent to the company listed. All
                  fields are required. You can expect to hear back from the
                  contact in 24-48 hours.
                </p>
                {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                <InputRadioGroup
                  label="Subject"
                  name="subject"
                  options={[
                    { name: 'Please contact me', key: 'Please contact me' },
                    { name: 'I have a question', key: 'I have a question' },
                    {
                      name: "I need a bus. Here's my trip info",
                      key: "I need a bus. Here's my trip info"
                    }
                  ]}
                  required
                  {...formProps}
                />
                <Input label="Your Name" name="name" required {...formProps} />
                <Input
                  label="Email Address"
                  name="email"
                  required
                  {...formProps}
                />
                <Input label="Phone" name="phone" required {...formProps} />
                <Input
                  label="Message"
                  name="additionalInfo"
                  type="textarea"
                  rows="3"
                  required
                  {...formProps}
                />
              </Form>
            )}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal}>
            Cancel
          </Button>{' '}
          <Button
            color="secondary"
            className="text-white"
            onClick={onButtonSubmit}
          >
            Submit
          </Button>{' '}
        </ModalFooter>
      </Modal>
    );
  }
}

const validationSchema = Yup.object().shape({
  subject: Yup.string().required('Subject is required'),
  name: Yup.string().required('Name is required'),
  email: Yup.string()
    .email('Must use valid email')
    .required('Email is required'),
  phone: Yup.string().required('Phone is required'),
  additionalInfo: Yup.string().required('Additional info is required')
});

const mapStateToProps = ({
  login: { loginType },
  userProfile: { email, firstName, lastName }
}) => ({
  email,
  firstName,
  lastName,
  loginType
});

export default connect(mapStateToProps, { fetchUserProfile })(SendMessageModal);
