import {
  addPhoto,
  addAdditionalPhotoService,
  associations,
  authority,
  companyInformation,
  companyLogin,
  deleteAdditionalPhoto,
  formsOfPayment,
  profileManagement,
  updatePhoto,
  API_UPDATE_COMPANY_SERVICES,
  API_UPDATE_COMPANY_HIGHLIGHTS
} from '../../services/subscriber/companyService';

const DEFAULT_ERROR_MESSAGE =
  'There was a problem updating your company. Please try again later.';

export const fetchCompanyInformation = companyId => async dispatch => {
  dispatch({ type: 'FETCH_COMPANY_INFO_REQUEST' });
  try {
    const response = await companyInformation(companyId);
    if (response && response.errorMessage) throw response.errorMessage;

    dispatch({ type: 'FETCH_COMPANY_INFO_SUCCESS', payload: response });
  } catch (e) {
    dispatch({ type: 'FETCH_COMPANY_INFO_FAIL' });
  }
};

export const updateProfileManagement = (values, callback) => async (
  dispatch,
  getState
) => {
  dispatch({ type: 'UPDATE_PROFILE_MANAGEMENT_REQUEST' });
  try {
    const { login: { companyId } } = getState();

    const response = await profileManagement(values, companyId);
    if (!response.success) throw Error;

    dispatch({ type: 'UPDATE_PROFILE_MANAGEMENT_SUCCESS', payload: values });
    callback();
  } catch (e) {
    dispatch({
      type: 'UPDATE_PROFILE_MANAGEMENT_FAIL',
      payload: DEFAULT_ERROR_MESSAGE
    });
  }
};

export const updateCompanyDetail = (values, callback) => async (
  dispatch,
  getState
) => {
  dispatch({ type: 'UPDATE_COMPANY_DETAIL_REQUEST' });
  try {
    const { login: { companyId } } = getState();

    const response = await associations(values, companyId);
    if (!response.success) throw Error;

    dispatch({ type: 'UPDATE_COMPANY_DETAIL_SUCCESS', payload: values });
    callback();
  } catch (e) {
    dispatch({
      type: 'UPDATE_COMPANY_DETAIL_FAIL',
      payload: DEFAULT_ERROR_MESSAGE
    });
  }
};

/**
 * 更新包车服务
 * @param {*} values 
 * @param {*} callback 
 * @returns 
 */
export const updateCompanyServices = (values, callback) => async (
  dispatch,
  getState
) => {
  dispatch({ type: 'UPDATE_COMPANY_SERVICES_REQUEST' });
  try {
    const { login: { companyId } } = getState();
    const response = await API_UPDATE_COMPANY_SERVICES(values, companyId);
    if (!response.status.msg || response.status.code !== 0) throw Error;

    dispatch({ type: 'UPDATE_COMPANY_SERVICES_SUCCESS', payload: values });
    callback();
  } catch (e) {
    dispatch({
      type: 'UPDATE_COMPANY_SERVICES_FAIL',
      payload: DEFAULT_ERROR_MESSAGE
    });
    callback();
  }
};

/**
 * 更新特色服务
 * @param {*} values 
 * @param {*} callback 
 * @returns 
 */
export const updateCompanyHighlights = (values, callback) => async (
  dispatch,
  getState
) => {
  dispatch({ type: 'UPDATE_COMPANY_HIGHLIGHTS_REQUEST' });
  try {
    const { login: { companyId } } = getState();
    const response = await API_UPDATE_COMPANY_HIGHLIGHTS(values, companyId);
    if (!response.status.msg || response.status.code !== 0) throw Error;

    dispatch({ type: 'UPDATE_COMPANY_HIGHLIGHTS_SUCCESS', payload: values });
    callback();
  } catch (e) {
    dispatch({
      type: 'UPDATE_COMPANY_HIGHLIGHTS_FAIL',
      payload: DEFAULT_ERROR_MESSAGE
    });
    callback();
  }
};

export const updateProfilePhoto = ({
  image,
  initialPhoto,
  initialPhotoFilename,
  callback
}) => async (dispatch, getState) => {
  dispatch({
    type: 'UPDATE_COMPANY_PROFILE_PHOTO_REQUEST'
  });
  let response;
  try {
    const { login: { companyId } } = getState();

    if (initialPhotoFilename) {
      response = await updatePhoto({ companyId, initialPhotoFilename, image });
    } else {
      response = await addPhoto(companyId, image);
    }

    if (!response.success) throw Error;

    const photoUrl = response.photoUrl || initialPhoto;
    const photoFilename = response.photoFilename || initialPhotoFilename;

    dispatch({
      type: 'UPDATE_COMPANY_PROFILE_PHOTO_SUCCESS',
      payload: { photoFilename, photoUrl }
    });
    callback();
  } catch (e) {
    const message = response.errorMessage || DEFAULT_ERROR_MESSAGE
    dispatch({
      type: 'UPDATE_COMPANY_PROFILE_PHOTO_FAIL',
      payload: message
    });
  }
};

export const addAdditionalPhoto = ({
  image,
  initialPhoto,
  initialPhotoFilename,
  callback
}) => async (dispatch, getState) => {
  dispatch({ type: 'ADD_COMPANY_PHOTO_REQUEST' });
  try {
    const { login: { companyId } } = getState();

    let response;
    response = await addAdditionalPhotoService(companyId, image);
    if (!response.success) throw Error;

    // const photoUrl = response.photoUrl || initialPhoto;
    // const photoFilename = response.photoFilename || initialPhotoFilename;

    dispatch({
      type: 'ADD_COMPANY_PHOTO_REQUEST',
      payload: ''
    });
    callback();
  } catch (e) {
    dispatch({
      type: 'ADD_COMPANY_PHOTO_FAIL',
      payload: DEFAULT_ERROR_MESSAGE
    });
  }
};

export const deletePhoto = (image, callback) => async (dispatch, getState) => {
  dispatch({
    type: 'DELETE_COMPANY_PHOTO_REQUEST'
  });

  try {
    const { login: { companyId } } = getState();

    let response;
    response = await deleteAdditionalPhoto(image, companyId);

    if (!response.success) throw Error;

    dispatch({
      type: 'DELETE_COMPANY_PHOTO_SUCCESS'
    });
    callback();
  } catch (e) {
    dispatch({
      type: 'DELETE_COMPANY_PHOTO_FAIL',
      payload: DEFAULT_ERROR_MESSAGE
    });
  }
};

export const updateFormsOfPayment = (values, callback) => async (
  dispatch,
  getState
) => {
  dispatch({ type: 'UPDATE_FORMS_OF_PAYMENT_REQUEST' });
  try {
    const { login: { companyId } } = getState();

    const response = await formsOfPayment(values, companyId);
    if (!response.success) throw Error;

    dispatch({ type: 'UPDATE_FORMS_OF_PAYMENT_SUCCESS', payload: values });
    callback();
  } catch (e) {
    dispatch({
      type: 'UPDATE_FORMS_OF_PAYMENT_FAIL',
      payload: DEFAULT_ERROR_MESSAGE
    });
  }
};

export const updatePassengerCarrier = (values, callback) => async (
  dispatch,
  getState
) => {
  dispatch({ type: 'UPDATE_PASSENGER_CARRIER_REQUEST' });
  try {
    const { login: { companyId } } = getState();

    const response = await authority(values, companyId);
    if (!response.success) throw Error;

    dispatch({ type: 'UPDATE_PASSENGER_CARRIER_SUCCESS', payload: values });
    callback();
  } catch (e) {
    dispatch({
      type: 'UPDATE_PASSENGER_CARRIER_FAIL',
      payload: DEFAULT_ERROR_MESSAGE
    });
  }
};

export const updateLoginInformation = (values, callback) => async (
  dispatch,
  getState
) => {
  dispatch({ type: 'UPDATE_COMPANY_LOGIN_INFO_REQUEST' });
  try {
    const { login: { companyId } } = getState();

    const response = await companyLogin(values, companyId);
    if (!response.success) throw response.errorMessage;

    dispatch({ type: 'UPDATE_COMPANY_LOGIN_INFO_SUCCESS', payload: values });
    callback();
  } catch (e) {
    const message = typeof e === 'string' ? e : DEFAULT_ERROR_MESSAGE;
    dispatch({
      type: 'UPDATE_COMPANY_LOGIN_INFO_FAIL',
      payload: message
    });
  }
};
