import { resetPasswordResults } from '../../services/subscriber/forgotService';

export const updateProperty = (key, value) => dispatch => {
  dispatch({
    type: 'FORGOT_PASSWORD_PROPERTY_UPDATED',
    payload: { key, value }
  });
};

export const resetPassword = () => async (dispatch, getState) => {
  dispatch({
    type: 'FORGOT_PASSWORD_REQUEST'
  });

  const { subscriberForgot } = getState();

  const { email } = subscriberForgot;

  try {
    const forgotResponse = await resetPasswordResults({
      email: email
    });

    if (forgotResponse && forgotResponse.data) {
      if (forgotResponse.data.success) {
        dispatch({
          type: 'FORGOT_PASSWORD_REQUEST_SUCCESS',
          payload: {
            forgotSuccess: true,
            forgotError: null
          }
        });
      } else {
        dispatch({
          type: 'FORGOT_PASSWORD_REQUEST_FAILURE',
          payload: {
            forgotSuccess: false,
            forgotError: forgotResponse.data.errorMessage
          }
        });
      }
    }
  } catch (error) {
    dispatch({
      type: 'FORGOT_PASSWORD_REQUEST_FAILURE',
      payload: {
        forgotSuccess: false,
        forgotError: error
      }
    });
  }
};
