import withAuthentication, { ROUTE_TYPES } from '../withAuthentication';
import withTracker from '.././withTracker';
import UserMessages from '../../containers/user/UserMessages';

const userMessages = {
  path: '/users/messages',
  component: withTracker(withAuthentication(UserMessages, ROUTE_TYPES.USER))
};

export default userMessages;
