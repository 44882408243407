import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Alert, Button, Form, Row} from 'reactstrap';
import {Formik} from 'formik';
import Yup from 'yup';
import {
    Footer,
    Input
} from '../../components';
import {submitSubscriberAccount} from "../../actions/subscriber/subscribe";

class SubscribeConfirm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            canSubmit: true,
            loadingCssName: "",
            nextDisabled: false
        }
    }

    componentDidMount() {
        this.fetchData(this.props.history);
    }

    fetchData(history) {
        const type = history.action
        if(type === 'POP') {
            this.props.history.push('/subscribers/subscribe');
        }
    }

    onFormSubmit = values => {
        if (this.state.canSubmit) {
            this.setState({canSubmit: false, loadingCssName: "buttonLoading", nextDisabled: true});
            this.props.submitSubscriberAccount(values, this.props.history).then(() => {
                this.setState({canSubmit: true, loadingCssName: "", nextDisabled: false});
            });
        }
    };

    renderErrors = errors => {
        if (!errors) return null;
        let values = Object.values(errors);
        return (
            <React.Fragment>
                {values.map(error => {
                    return (
                        <React.Fragment>
                            <br/>
                            {error}
                        </React.Fragment>
                    )
                })}
            </React.Fragment>
        )
    }

    renderTermsAndConditions = () => {
        if (!this.state || !this.state.terms) return null;

        return (
            <div
                style={{
                    height: '25em',
                    backgroundColor: 'white',
                    overflowY: 'scroll',
                    border: '1px solid lightgrey',
                    padding: '.75em 1em'
                }}
                dangerouslySetInnerHTML={{__html: this.state.terms}}/>
        )
    }

    render() {
        const {errorMessage} = this.props;
        return (
            <React.Fragment>
                <div className="pt-5 mt-5 pl-3 pr-3 lg-container">
                    <h1>BusRates.com Registration</h1>
                    <p>Thank you for your interest in joining BusRates.com!</p>
                    <ul>
                        {/*<li>*/}
                        {/*    Upon request, we can fax you the form instead of entering your*/}
                        {/*    info online. Please email:{' '}*/}
                        {/*    <a href="mailto:sales@busrates.com">sales@busrates.com</a>*/}
                        {/*</li>*/}
                        <li>
                            For help, or to expedite your posting, please call 703-838-2955 M-F 9am to 5:30pm EST
                        </li>
                    </ul>
                </div>
                <div className="lg-container pt-4 pb-3 pl-3 pr-3">
                    <div style={{backgroundColor: '#e9ecef', padding: '2em'}}>
                        <h3
                            className="font-weight-bolder text-primary mb-0"
                            style={{padding: '1em 0em'}}
                        >
                            Bus Operator Registration
                        </h3>
                        <Formik
                            initialValues={{
                                national: this.props.registerParam.national,
                                address: this.props.registerParam.address,
                                addressLine2: this.props.registerParam.addressLine2,
                                certifications: this.props.registerParam.certifications,
                                companyName: this.props.registerParam.companyName,
                                companyPhone: this.props.registerParam.companyPhone,
                                companyWebsite: this.props.registerParam.companyWebsite,
                                contactName: this.props.registerParam.contactName,
                                contactPhone: this.props.registerParam.contactPhone,
                                contactPhoneExtension: this.props.registerParam.contactPhoneExtension,
                                dotNumber: this.props.registerParam.dotNumber,
                                stateAndRegional: this.props.registerParam.stateAndRegional,
                                terms: this.props.registerParam.terms,
                                zipCode: this.props.registerParam.zipCode,
                                city: this.props.registerParam.city,
                                state: this.props.registerParam.state,
                                contactEmail: this.props.registerParam.contactEmail
                            }}
                            validationSchema={validationSchema}
                            onSubmit={this.onFormSubmit}
                            render={formProps => (
                                <>
                                    <Form onSubmit={formProps.handleSubmit}>
                                        <h4 className="text-primary">Login Information</h4>
                                        <hr/>
                                        <Row>
                                            <Input
                                                name="dotNumber"
                                                label="DOT Number"
                                                className="col-12 col-md-8"
                                                values={this.props.registerParam}
                                                disabled={true}
                                                required
                                                {...formProps}
                                            />
                                        </Row>
                                        <Row>
                                            <Input
                                                name="contactEmail"
                                                label="Contact Email / Login Email"
                                                className="col-12 col-md-8"
                                                values={this.props.registerParam}
                                                required
                                                disabled={true}
                                                {...formProps}
                                            />
                                        </Row>
                                        <Row>
                                            <Input
                                                name="loginName"
                                                label="UserName"
                                                className="col-12 col-md-8"
                                                required
                                                {...formProps}
                                            />
                                        </Row>
                                        <Row>
                                            <Input
                                                name="password"
                                                label="Password"
                                                className="col-12 col-md-8"
                                                required
                                                type="password"
                                                {...formProps}
                                            />
                                        </Row>
                                        <Row>
                                            <Input
                                                name="confirmPassword"
                                                label="Confirm Password"
                                                className="col-12 col-md-8"
                                                required
                                                type="password"
                                                {...formProps}
                                            />
                                        </Row>
                                        <hr/>
                                        {formProps.dirty && !formProps.isValid && (
                                            <div className="text-danger text-center mt-3">
                                                Some fields are invalid, please check all fields and try again
                                                {this.renderErrors(formProps.errors)}
                                            </div>)
                                        }
                                        {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                                        
                                    </Form>
                                    <Button onClick={
                                    async () => {
                                        try {
                                            // 手动触发验证
                                            await formProps.validateForm();
                                            if (formProps.isValid) {
                                            // 如果没有错误，执行提交操作
                                                this.onFormSubmit(formProps.values);
                                            }
                                        } catch (error) {
                                            console.error(error);
                                        }
                                    }
                                } disabled={this.state.nextDisabled} style={{color: 'white'}} className={this.state.loadingCssName}
                                    id={"submitBtn"}>Submit</Button>
                                </>
                            )}
                        />
                    </div>
                </div>
                <Footer/>
            </React.Fragment>
        );
    }
}

Yup.addMethod(Yup.mixed, 'sameAs', function (ref, message) {
    return this.test('sameAs', message, function (value) {
        const other = this.resolve(ref);
        return !other || !value || value === other;
    });
});

const validationSchema = Yup.object().shape({
    contactEmail: Yup.string().required('Contact email is required.'),
    loginName: Yup.string().required('Login Name is required.'),
    password: Yup.string()
        .required('Password is required.')
        .min(8, 'Password must be at least 8 characters long.'),
    confirmPassword: Yup.string()
        .sameAs(Yup.ref('password'), 'Passwords must match.')
        .required('Confirmation new password is required.')
});

const mapStateToProps = ({subscribe: {errorMessage, registerParam}}) => ({
        errorMessage,
        registerParam
});

export default connect(mapStateToProps, {
    submitSubscriberAccount
})(SubscribeConfirm);
