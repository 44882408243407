import React from "react";
import glamorous from "glamorous";

const StyledContainer = glamorous.div({
  maxWidth: 1500,
  paddingTop: 100,
  paddingBottom: 50
});

const Container = (props) => (
  <StyledContainer className="mx-auto">
    {props.children}
  </StyledContainer>
);

export default Container;
