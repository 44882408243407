import React from 'react';
import {
  Alert,
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
const FreeTrialModal = ({
  modalVisible,
  toggleModal,
  upgradePremiumHandler
}) => (
  <Modal isOpen={modalVisible} toggle={toggleModal}>
    <ModalHeader
      className="d-flex align-items-center pl-5 pr-5"
      toggle={toggleModal}
    >
      BusRates
    </ModalHeader>
    <ModalBody className="pl-5 pr-5">
      You are in free trail, want to Upgrade to get better placement?
    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={toggleModal}>
        Continue as Free Trial
      </Button>{' '}
      <Button color="secondary" className="text-white" onClick={upgradePremiumHandler}>
        Upgrade to get better placement
      </Button>{' '}
    </ModalFooter>
  </Modal>
);
export default FreeTrialModal;