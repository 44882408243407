import * as React from "react";

import withTracker from "./withTracker";
import Home from "../containers/Home";

const home: {
  path: string,
  component: React.ComponentType<{}>
} = {
  path: "/",
  component: withTracker(Home)
};

export default home;
