import React, {Component} from 'react';
import {connect} from 'react-redux';
import glamorous from 'glamorous';
import {Link} from 'react-router-dom';
import {Card, CardBody, Col} from 'reactstrap';
import {fetchSubscriberInformation} from '../../actions/subscriber/account';
import {
    BidForPlacement,
    BusFront,
    Company,
    Messages,
    Payments,
    Reports,
    Reviews,
    Crash
} from '../../assets/img/icons/svg';

const StyledMenuItem = glamorous.li(
    {
        fontSize: 16,
        padding: 15,
        marginBottom: 10,
        '& a': {
            textDecoration: 'none'
        },
        '& img': {
            marginRight: 10
        },
        '&:hover': {
            backgroundColor: '#eee',
            '& a': {
                color: '#646464'
            }
        }
    },
    ({active}) => ({
        backgroundColor: active ? '#eee' : 'transparent',
        '& a': {
            color: active ? '#646464' : '#333'
        }
    })
);

const StyledNotification = glamorous.div({
    backgroundColor: '#dc3545',
    color: 'white',
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    lineHeight: '24px',
    textAlign: 'center',
    marginLeft: '5px'
});

class Menu extends Component {
    componentDidMount() {
        if (this.props.numberOfUnreadMessages === null) {
            this.props.fetchSubscriberInformation();
        }
    }

    renderMenuItem = (linkTo, imgSrc, text, notifications) => {
        const notificationsText =
            notifications && notifications < 100 ? notifications : null;

        const notification =
            notifications && notifications > 0 ? (
                <StyledNotification>{notificationsText}</StyledNotification>
            ) : null;

        return (
            <StyledMenuItem active={window.location.pathname === linkTo}>
                <Link to={linkTo} className="d-flex align-items-middle">
                    <img src={imgSrc} alt={text}/>
                    {text}
                    {notification}
                </Link>
            </StyledMenuItem>
        );
    };

    render() {
        return (
            <Col md="12" sm="12" lg="3">
                <Card color="light" style={{marginBottom: '5em'}}>
                    <CardBody>
                        <ul className="list-unstyled mt-4 ml-1 mr-1">
                            {this.renderMenuItem('/subscribers/company', Company, 'Company')}
                            {this.renderMenuItem('/subscribers/listings', BusFront, 'Listings')}
                            {this.renderMenuItem('/subscribers/bidListings', BidForPlacement, 'Bid for Placement')}
                            {this.renderMenuItem('/subscribers/reports', Reports, 'Reports')}
                            {this.renderMenuItem('/subscribers/reviews', Reviews, 'Reviews')}
                            {this.renderMenuItem('/subscribers/payments', Payments, 'Payments')}
                            {this.renderMenuItem('/subscribers/messages', Messages, 'Messages', this.props.numberOfUnreadMessages)}
                            {this.renderMenuItem('/subscribers/crash', Crash, 'Invoice')}
                        </ul>
                    </CardBody>
                </Card>
            </Col>
        );
    }
}

const mapStateToProps = ({subscriberMessages}) => ({
    numberOfUnreadMessages: subscriberMessages.numberOfUnreadMessages
});

export default connect(mapStateToProps, {fetchSubscriberInformation})(Menu);
