import React, { Component } from 'react';
import glamorous from 'glamorous';
import { Button, Table } from 'reactstrap';
import FaCheck from 'react-icons/lib/fa/check';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faTimes from '@fortawesome/fontawesome-free-solid/faTimes';

import { Icon } from '../';

import * as Icons from '../../assets/img/icons';

const StyledBusDetailTableHeader = glamorous.th({
  fontWeight: 'normal'
});

const StyledBusDetailTableRow = glamorous.tr({
  fontWeight: 400
});

class DetailBusTypes extends Component {
  renderYesNoIcon = isTrue => {
    if (isTrue) {
      return <FaCheck alt="Yes" title="Yes" color="#008000" />;
    }

    return (
      <FontAwesomeIcon alt="No" title="No" color="#dc3545" icon={faTimes} />
    );
  };

  renderDetailResultBusTypeRow = busResult => {
    if (!busResult) return null;

    const {
      busType,
      dayAmount,
      id,
      mileAmount,
      oneTenVolts,
      quantity,
      restroom,
      seats,
      wifi,
      years
    } = busResult;

    return (
      <StyledBusDetailTableRow key={id}>
        <td className="align-middle hide-on-phn hide-on-tab">{quantity}</td>
        <td className="align-middle">{busType}</td>
        <td className="align-middle text-center">{seats || '--'}</td>
        <td className="align-middle hide-on-phn hide-on-tab">
          {years || '--'}
        </td>
        <td className="align-middle text-center">
          {this.renderYesNoIcon(restroom)}
        </td>
        <td className="align-middle text-center">
          {this.renderYesNoIcon(wifi)}
        </td>
        <td className="align-middle hide-on-phn hide-on-tab text-center">
          {this.renderYesNoIcon(oneTenVolts)}
        </td>
        <td className="align-middle hide-on-phn hide-on-tab text-center">
          {dayAmount || '--'}
        </td>
        <td className="align-middle hide-on-phn hide-on-tab text-center">
          {mileAmount || '--'}
        </td>
        <td
          className="text-right hide-on-phn hide-on-tab"
          style={{ maxWidth: 240, minWidth: 250, textAlign: 'right' }}
        >
          <Button
            className="hide-on-phn"
            color="primary"
            onClick={() => this.props.openAmenityDetailsModal(busResult)}
          >
            View Amenities
          </Button>
        </td>
      </StyledBusDetailTableRow>
    );
  };

  renderDetailResultBusTypes = result => {
    const { busResults } = result;

    if (busResults == null) return null;

    return busResults
      .filter(busResult => busResult.busType !== 'all')
      .map(busResult => this.renderDetailResultBusTypeRow(busResult));
  };

  render() {
    const { result } = this.props;

    if (result == null) return null;

    return (
      <Table>
        <thead>
          <tr>
            <StyledBusDetailTableHeader className="hide-on-phn hide-on-tab">
              Qty
            </StyledBusDetailTableHeader>
            <StyledBusDetailTableHeader>Bus Type</StyledBusDetailTableHeader>
            <th className="text-center">
              <Icon
                title="Seats Available"
                src={Icons.AvailableSeatsIcon}
                alt="Seats Available"
              />
            </th>
            <StyledBusDetailTableHeader className="hide-on-phn hide-on-tab">
              Model Year
            </StyledBusDetailTableHeader>
            <th className="text-center">
              <Icon title="Restroom" src={Icons.RestroomIcon} alt="Restroom" />
            </th>
            <th className="text-center">
              <Icon title="Wifi" src={Icons.WifiIcon} alt="Wifi" />
            </th>
            <th className="hide-on-phn hide-on-tab text-center">
              <Icon
                title="110v Outlets"
                src={Icons.PowerIcon}
                alt="110v Outlets"
              />
            </th>
            <th className="hide-on-phn hide-on-tab text-center">
              <Icon title="Day" src={Icons.CalendarIcon} alt="Day" />
            </th>
            <th className="hide-on-phn hide-on-tab text-center">
              <Icon title="Mile" src={Icons.RoadIcon} alt="Mile" />
            </th>
            <th className="hide-on-phn hide-on-tab" />
          </tr>
        </thead>
        <tbody>{this.renderDetailResultBusTypes(result)}</tbody>
      </Table>
    );
  }
}

export default DetailBusTypes;
