import React from 'react';
import ReactDOM from 'react-dom';
import Message from './Message';

const createMessage = (type, text, duration = 3000) => {
  const div = document.createElement('div');
  document.body.appendChild(div);

  const messageComponent = <Message type={type} message={text} />;
  ReactDOM.render(messageComponent, div);

  setTimeout(() => {
    ReactDOM.unmountComponentAtNode(div);
    document.body.removeChild(div);
  }, duration);
};

export default {
  info(text, duration) {
    createMessage('info', text, duration);
  },
  success(text, duration) {
    createMessage('success', text, duration);
  },
  error(text, duration) {
    createMessage('error', text, duration);
  },
  warning(text, duration) {
    createMessage('warning', text, duration);
  }
  // ...其他方法如 success, error, warning
};
