const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'FORGOT_PASSWORD_REQUEST':
      return { ...state };
    case 'FORGOT_PASSWORD_REQUEST_SUCCESS':
      return { ...state, ...action.payload };
    case 'FORGOT_PASSWORD_REQUEST_FAILURE':
      return { ...state, ...action.payload };
    case 'FORGOT_PASSWORD_PROPERTY_UPDATED':
      return { ...state, [action.payload.key]: action.payload.value };
    default:
      return state;
  }
};
