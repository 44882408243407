import API from '../baseService';

export const reportsResults = (companyMetroId, dates) =>
  API.post('/subscribers/reports/', {
    company_id: companyMetroId,
    start_date: dates.startDate,
    end_date: dates.endDate
  }).then(response => response.data);

export const quotesAsCsv = (companyMetroId, dates) => {
  return API.csv('/subscribers/reports/export/quotes', {
    company_id: companyMetroId,
    start_date: dates.startDate,
    end_date: dates.endDate
  })
}

export const activityAsCsv = (companyMetroId, dates) => {
  return API.csv('/subscribers/reports/export/clicks', {
    company_id: companyMetroId,
    start_date: dates.startDate,
    end_date: dates.endDate
  })
}
