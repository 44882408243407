import {listBustypes,addBusType,updateBusType,amenities,deleteBusType,setUpSecondIndex,listMetroByCompanyId, addFleet} from "../../services/subscriber/init"

export const list = (companyId, callback) => async dispatch => {
    try {
        const response = await listBustypes(companyId);
        if (response.status.code != 0) throw response.status.msg;

        callback(response.result)

    } catch (e) {
        console.log("list bustype error..." + e)
        throw Error;
    }
};

export const addBustypes = (params, callback) => async dispatch => {
    try {
        let response ;

        if(params.fleetId){
            response = await updateBusType(params)
        }else{
            response = await addBusType(params);
        }

        callback(response.status)

    } catch (e) {
        console.log("add bustype error..." + e)
        throw Error;
    }
};

export const deleteBustypes = (fleetId, callback) => async dispatch => {
    try {
        const response = await deleteBusType(fleetId);
        if (response.status.code != 0) throw response.status.msg;

        callback(response.result)

    } catch (e) {
        console.log("delete error..." + e)
        throw Error;
    }
};

export const listAmenity = (companyId,callback) => async dispatch => {
    try {
        const response = await amenities(companyId);
        if (response.status.code != 0) throw response.status.msg;

        callback(response.result)

    } catch (e) {
        console.log("list amenity error..." + e)
        throw Error;
    }
};

export const setUp = (companyId,callback) =>async ()=>{

    try{
        const response = await setUpSecondIndex(companyId)
        callback(response.status.code == 0);
    }catch (e) {
        console.log(e)
        callback(false)
    }
};

export const listMetro = (companyId,bustype,callback) => async dispatch => {
    try {
        const response = await listMetroByCompanyId(companyId,bustype);
        if (response.status.code != 0) throw response.status.msg;

        callback(response.result)

    } catch (e) {
        console.log("list metro error..." + e)
        throw Error;
    }
};

export const addFleetOnCompanyMetro = (companyMetroIds,fleetId, callback) => async dispatch => {
    try {
        let response = await addFleet(companyMetroIds, fleetId);

        callback(response.status)

    } catch (e) {
        console.log("add fleet error..." + e)
        throw Error;
    }
};




