import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, Label, Form, FormGroup, Input, Row, Col, Container} from 'reactstrap';
import {chargeArrearsInit} from '../../actions/subscriber/chargeArrears';
import {Footer, Section, SectionTitle} from '../../components';
import {arrearsInfoResult, paymentInfoResult, saveCustomerResult, chargeArrearsResult} from "../../services/subscriber/paymentsService";
import InfoModal from './ArrearsInformationModal';

class ChargeArrears extends Component {

    constructor(props) {
        super(props);
        this.state = {

            id: "",
            companyId: "",
            cardNumber: "",
            cardMonth: "01",
            cardYear: "2019",
            cardCvv: "",

            firstName: "",
            lastName: "",
            companyName: "",
            billingAddress: "",
            billingCity: "",
            billingCountry: "",
            billingState: "",
            billingZip: "",
            email: "",

            invoice: [],
            totalAmount: 0,

            canSubmit: true,
            loadingCssName: "text-white btn btn-secondary font-weight-bold"
        };
        this.handleChange = this.handleChange.bind(this);
    }

    toggleModal = modal =>
        this.setState(prevState => ({
            [modal]: !prevState[modal]
        }));

    componentDidMount = () => {


        let self = this;
        let cardButton = document.getElementById('card-button');
        let companyId = this.props.companyId;

        cardButton.addEventListener('click', function (ev) {

            if (self.state.canSubmit) {

                self.setState({canSubmit: false, loadingCssName: "text-white btn btn-secondary font-weight-bold buttonLoading"});
                if (self.validateForm(self)) {
                    let data = {
                        cardNumber: self.state.cardNumber
                        , cardMonth: self.state.cardMonth
                        , cardYear: self.state.cardYear
                        , cardCvv: self.state.cardCvv
                        , firstName: self.state.firstName
                        , lastName: self.state.lastName
                        , billingAddress: self.state.billingAddress
                        , billingCity: self.state.billingCity
                        , billingCountry: self.state.billingCountry
                        , billingState: self.state.billingState
                        , billingZip: self.state.billingZip
                        , email: self.state.email
                        , companyId: companyId
                        , notification_company_id: self.props.match.params.secretKey
                    };

                    chargeArrearsResult(data).then(function (response) {
                        if (response.data.result == true) {

                            alert("Pay Success!")
                            self.props.history.push('/');
                        } else {

                            self.setState({canSubmit: true, loadingCssName: "text-white btn btn-secondary font-weight-bold"});
                            alert("Pay Fail! Please check the input items. try again.")
                        }
                    });
                } else {
                    self.setState({canSubmit: true, loadingCssName: "text-white btn btn-secondary font-weight-bold"});
                    alert("please check input item.")
                }
            }
        });
        arrearsInfoResult(self.props.match.params.secretKey).then(function (result) {

            if (result.data.success == false) {

                alert(result.data.errorMessage);
                self.props.history.push('/');
            } else {

                //TODO 需要加上判断是否需要弹出提示
                //self.toggleModal('loginInformationModalVisible')
                self.setState(result.data);
            }
        })
    }

    handleChange(event) {

        let attr = event.target.getAttribute("valueAttr");
        let state = {};
        state[attr] = event.target.value
        this.setState(state);
        if(event.target.value != "") {
            event.target.style.backgroundColor = "white";
        }
    }
    openAuth() {
        window.open('https://verify.authorize.net/anetseal/?pid=0107e6ee-5a2e-43bb-b1e8-9d7d1ae0e68e&amp;rurl=https%3A//www.busrates.com/subscribers/payments',
            'AuthorizeNetVerification',
            'width=600,height=430,dependent=yes,resizable=yes,scrollbars=yes,menubar=no,toolbar=no,status=no,directories=no,location=yes');
    }
    validateForm(self) {

        let flag = true;
        if(self.state.cardNumber == "") {

            document.getElementById("card-number").style.backgroundColor = "#fbced2"
            flag = false;
        }
        if(self.state.firstName == "") {
            document.getElementById("first-name").style.backgroundColor = "#fbced2"
            flag = false;
        }
        if(self.state.lastName == "") {
            document.getElementById("last-name").style.backgroundColor = "#fbced2"
            flag = false;
        }
        if(self.state.companyName == "") {
            document.getElementById("company-name").style.backgroundColor = "#fbced2"
            flag = false;
        }
        if(self.state.billingAddress == "") {
            document.getElementById("billing-address").style.backgroundColor = "#fbced2"
            flag = false;
        }
        if(self.state.billingCity == "") {
            document.getElementById("billing-city").style.backgroundColor = "#fbced2"
            flag = false;
        }
        if(self.state.billingCountry == "") {
            document.getElementById("billing-country").style.backgroundColor = "#fbced2"
            flag = false;
        }
        if(self.state.billingState == "billing-state") {
            document.getElementById("").style.backgroundColor = "#fbced2"
            flag = false;
        }
        if(self.state.billingZip == "") {
            document.getElementById("billing-zip").style.backgroundColor = "#fbced2"
            flag = false;
        }
        if(self.state.email == "") {
            document.getElementById("email").style.backgroundColor = "#fbced2"
            flag = false;
        }
        return flag;
    }

    render() {

        const listItems = this.state.invoice.map((item, index) =>
            <tr>
                <td width={"50%"}>&nbsp;&nbsp;{item.note}</td>
                <td width={"50%"} align={"right"}>&nbsp;&nbsp;$ {item.amount}</td>
            </tr>
        );
        return (
            <div className="pt-5">
                <Section>
                    <Row>
                        <Col xs="12" className="text-center">
                            <h1 className="pb-3">{this.state.companyName}</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="9" className="box_new">
                            <div className="checkout">
                                <div>
                                    <span style={{"width": "180px", "display": "inline-block"}}>Card Number <span style={{"color": "red"}}>*</span></span>
                                    <input id="card-number" valueAttr="cardNumber" value={this.state.cardNumber} onChange={this.handleChange}/>
                                    <span style={{"width": "165px", "display": "inline-block", "margin-left": "20px"}}>Expiration date  <span style={{"color": "red"}}>*</span></span>
                                    <select style={{"width": "80px", "margin-left": "20px"}} id="card-month" valueAttr="cardMonth" value={this.state.cardMonth} onChange={this.handleChange}>
                                        <option value="01" selected={'true'}>01</option>
                                        <option value="02">02</option>
                                        <option value="03">03</option>
                                        <option value="04">04</option>
                                        <option value="05">05</option>
                                        <option value="06">06</option>
                                        <option value="07">07</option>
                                        <option value="08">08</option>
                                        <option value="09">09</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                    </select>
                                    <select style={{"width": "80px", "margin-left": "20px"}} id="card-year" valueAttr="cardYear" value={this.state.cardYear} onChange={this.handleChange}>
                                        <option value="2021" selected={'true'}>21</option>
                                        <option value="2022">22</option>
                                        <option value="2023">23</option>
                                        <option value="2024">24</option>
                                        <option value="2025">25</option>
                                        <option value="2026">26</option>
                                        <option value="2027">27</option>
                                        <option value="2028">28</option>
                                        <option value="2029">29</option>
                                        <option value="2030">30</option>
                                        <option value="2031">31</option>
                                        <option value="2032">32</option>
                                    </select>
                                </div>
                                <div>
                                    <span style={{"width": "180px", "display": "inline-block"}}>Security Code(CVV) <span style={{"color": "red"}}>*</span></span>
                                    <input id="card-cvv" valueAttr="cardCvv" maxLength={'4'} value={this.state.cardCvv} onChange={this.handleChange}/>
                                </div>

                                <div>
                                    <span style={{"width": "180px", "display": "inline-block"}}>First Name <span style={{"color": "red"}}>*</span></span>
                                    <input id="first-name" valueAttr="firstName" value={this.state.firstName} onChange={this.handleChange}/>
                                    <span style={{"width": "165px", "display": "inline-block", "margin-left": "20px"}}>Last Name <span style={{"color": "red"}}>*</span></span>
                                    <input id="last-name" valueAttr="lastName" value={this.state.lastName} onChange={this.handleChange}/>
                                </div>

                                <div>
                                    <span style={{"width": "180px", "display": "inline-block"}}>Billing Address <span style={{"color": "red"}}>*</span></span>
                                    <input id="billing-address" valueAttr="billingAddress" value={this.state.billingAddress} onChange={this.handleChange}/>
                                    <span style={{"width": "165px", "display": "inline-block", "margin-left": "20px"}}>Billing City <span style={{"color": "red"}}>*</span></span>
                                    <input id="billing-city" valueAttr="billingCity" value={this.state.billingCity} onChange={this.handleChange}/>
                                </div>
                                <div>
                                    <span style={{"width": "180px", "display": "inline-block"}}>Billing Country <span style={{"color": "red"}}>*</span></span>
                                    <input id="billing-country" valueAttr="billingCountry" value={this.state.billingCountry} onChange={this.handleChange}/>
                                    <span style={{"width": "165px", "display": "inline-block", "margin-left": "20px"}}>Billing State</span>
                                    <input id="billing-state" valueAttr="billingState" value={this.state.billingState} onChange={this.handleChange}/>
                                </div>
                                <div>
                                    <span style={{"width": "180px", "display": "inline-block"}}>Billing Zip <span style={{"color": "red"}}>*</span></span>
                                    <input id="billing-zip" valueAttr="billingZip" value={this.state.billingZip} onChange={this.handleChange}/>
                                    <span style={{"width": "165px", "display": "inline-block", "margin-left": "20px"}}>Email Address for Payment Receipt <span style={{"color": "red"}}>*</span></span>
                                    <input id="email" valueAttr="email" value={this.state.email} onChange={this.handleChange}/>
                                </div>
                                <div>
                                    <b>Invoice</b>
                                </div>
                                <table border={1} bordercolor={"#dee1e4"} width={"100%"}>
                                    {listItems}
                                    <tr>
                                        <td colSpan={2} align={"right"}>Total: ${this.state.totalAmount}</td>
                                    </tr>
                                </table>
                                <Button id="card-button"  style={{}} onClick={this.submit} className={this.state.loadingCssName}>
                                    Pay ${this.state.totalAmount}
                                </Button>
                                <div className="AuthorizeNetSeal">
                                    <a href="#" style={{float:'right'}} onMouseOver="window.status='http://www.authorize.net/'; return true;"
                                       onMouseOut="window.status=''; return true;" onClick={this.openAuth}>
                                        <img src="https://verify.authorize.net/anetseal/images/secure90x72.gif" width="90"
                                             height="72" border="0" alt="Authorize.Net Merchant - Click to Verify"/>
                                    </a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Section>
                <Container className="pt-3 pb-4">
                    <InfoModal
                        title="Message"
                        values={this.props}
                        errorMessage={this.props.errorMessage}
                        modalRef={node => (this.loginInformationModal = node)}
                        modalVisible={this.state.loginInformationModalVisible}
                        onButtonSubmit={() => this.loginInformationModal.submitForm()}
                        onFormSubmit={this.onLoginInformationSubmit}
                        toggleModal={() => this.toggleModal('loginInformationModalVisible')}
                    />
                </Container>
                <Footer/>
            </div>
        );
    }
}

const mapStateToProps = ({login, stripePaymentInfo}) => ({

    companyId: login.companyId,
    id: stripePaymentInfo.id,
    firstName: stripePaymentInfo.firstName,
    lastName: stripePaymentInfo.lastName,
    companyName: stripePaymentInfo.companyName,
    billingAddress: stripePaymentInfo.billingAddress,
    billingCity: stripePaymentInfo.billingCity,
    billingCountry: stripePaymentInfo.billingCountry,
    billingState: stripePaymentInfo.billingState,
    billingZip: stripePaymentInfo.billingZip,
    email: stripePaymentInfo.email
});
export default connect(mapStateToProps, {chargeArrearsInit})(ChargeArrears);
