const INITIAL_STATE = { loading: true, metros: [], tripTypes: [] };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'METADATA_REQUEST':
      return { ...state, loading: true };
    case 'METADATA_REQUEST_SUCCESS':
      return { ...state, loading: false, ...action.payload };
    default:
      return state;
  }
};
