import React from 'react';
import glamorous from 'glamorous';
import { FieldArray } from 'formik';
import { FormGroup, Input as ReactstrapInput, Label, Row } from 'reactstrap';

const StyledFormGroup = glamorous(FormGroup)({ marginBottom: '1em' });

export const InputCheckboxGroup = ({
  className,
  errors,
  label,
  name,
  optionKey = 'id',
  optionValue = 'name',
  options,
  required,
  touched,
  values
}) => {
  const labelText = required ? `* ${label}` : label;
  return (
    <FieldArray
      name={name}
      render={arrayHelpers => (
        <StyledFormGroup className={className} tag="fieldset">
          <Label for={name}>{labelText}</Label>
          <br />
          <Row>
            {options.map(option => {
              const defaultChecked = values[name].includes(option[optionKey]);
              return (
                <FormGroup
                  key={option[optionKey]}
                  check
                  className="col-sm-3 col-6"
                >
                  <Label check>
                    <ReactstrapInput
                      type="checkbox"
                      name={name}
                      value={defaultChecked}
                      defaultChecked={defaultChecked}
                      onChange={() => {
                        if (values[name].includes(option[optionKey])) {
                          const index = values[name].indexOf(option[optionKey]);
                          arrayHelpers.remove(index);
                        } else {
                          arrayHelpers.push(option[optionKey]);
                        }
                      }}
                    />{' '}
                    {option[optionValue]}
                  </Label>
                </FormGroup>
              );
            })}
          </Row>
          {touched[name] &&
            errors[name] && (
              <div className="text-danger mt-1">{errors[name]}</div>
            )}
        </StyledFormGroup>
      )}
    />
  );
};

export default InputCheckboxGroup;
