import API from "../baseService";

/** 查询是否为免费用户 */
export const getFreeTryOutStatus = (companyId) => (
    API.get(`subscribers/${companyId}/freetrialaccount`)
)
export const getUpgradePremium = (companyId) =>{
    return API.get(`subscribers/listings/upgrade_to_premium?company_id=${companyId}`)
}

export const api_payBill = (companyId) => {
    return API.get(`subscribers/self_pay_upgrade_to_premium?company_id=${companyId}`, {
        company_id: companyId
      })
}