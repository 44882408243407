export const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SUBSCRIBER_LISTINGS_REQUEST':
      return { ...state, metrosInRadius: null };
    case 'SUBSCRIBER_LISTINGS_REQUEST_SUCCESS':
      return { ...state, ...action.payload, metrosInRadius: null };
    case 'SUBSCRIBER_LISTINGS_REQUEST_FAILURE':
      return { ...state, ...action.payload };
    case 'SUBSCRIBER_LISTINGS_SEARCH_FOR_NEW_METRO_REQUEST':
      return { ...state };
    case 'SUBSCRIBER_LISTINGS_SEARCH_FOR_NEW_METRO_REQUEST_SUCCESS':
      return { ...state, ...action.payload };
    case 'SUBSCRIBER_LISTINGS_SEARCH_FOR_NEW_METRO_REQUEST_FAILURE':
      return { ...state, ...action.payload };
    case 'SUBSCRIBER_LISTINGS_FIND_METROS_IN_RADIUS_REQUEST':
      return { ...state };
    case 'SUBSCRIBER_LISTINGS_FIND_METROS_IN_RADIUS_REQUEST_SUCCESS':
      return { ...state, ...action.payload };
    case 'SUBSCRIBER_LISTINGS_FIND_METROS_IN_RADIUS_REQUEST_FAILURE':
      return { ...state, ...action.payload };
    case 'SUBSCRIBER_LISTINGS_ADD_NEW_METRO_REQUEST':
      return { ...state };
    case 'SUBSCRIBER_LISTINGS_ADD_NEW_METRO_REQUEST_SUCCESS':
      return { ...state, ...action.payload };
    case 'SUBSCRIBER_LISTINGS_ADD_NEW_METRO_REQUEST_FAILURE':
      return { ...state, ...action.payload };
    case 'SUBSCRIBER_LISTINGS_REMOVE_METRO_REQUEST':
      return { ...state };
    case 'SUBSCRIBER_LISTINGS_REMOVE_METRO_REQUEST_SUCCESS':
      return { ...state, ...action.payload };
    case 'SUBSCRIBER_LISTINGS_REMOVE_METRO_REQUEST_FAILURE':
      return { ...state, ...action.payload };
    case 'SUBSCRIBER_LISTINGS_PROPERTY_UPDATED':
      return { ...state, [action.payload.key]: action.payload.value };
    case 'LOGOUT':
      return INITIAL_STATE;
    default:
      return state;
  }
};
