import API from '../baseService';

export const metroBreakdownAsCsv = () => {
  return API.csv('/portal/reports/metro_breakdown', null, null, true)
}

export const salesPushAsCsv = () => {
  return API.csv('/portal/reports/sales_push', null, null, true)
}

export const registeredUsersAsCsv = () => {
  return API.csv('/portal/reports/registered_users', null, null, true)
}

export const umaCompaniesAsCsv = () => {
  return API.csv('/portal/reports/uma_companies', null, null, true)
}

export const metroRankingStatsAsCsv = (dates) => {
  return API.csv('/portal/reports/metro_ranking_stats', {
    start_date: dates.startDate,
    end_date: dates.endDate
  }, null, true)
}