import React, { Component } from 'react';
import { connect } from 'react-redux';
import glamorous from 'glamorous';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';

import {
  closeSubscriberLoginModal,
  updateProperty,
  login,
  forgotPassword,
  createAccount,
  checkFreeTryOut
} from '../../actions/subscriber/login';
import InfoModal from './ArrearsInformationModal';
import {Container} from "../../components";

const StyledLinkButton = glamorous(Button)({
  fontSize: 16,
  fontWeight: 400,
  color: '#e69a2b'
});

const ErrorText = glamorous.p({
  fontSize: 16,
  fontWeight: 400,
  color: 'red'
});

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {

      arrearsId: ""
    };
  }

  onButtonGo = () => {

    // TODO push 方式需要手动一次登录层
    //this.props.history.push("/charge_arrears/" + this.state.arrearsId)
    window.location.href = "/charge_arrears/" + this.state.arrearsId;
  };

  onForgotPasswordButtonClicked = () => {
    this.props.forgotPassword(this.props.history);
  };

  onCreateAccountButtonClicked = () => {
    this.props.closeSubscriberLoginModal();
    this.props.history.push('/join');
  };

  toggleArrearsModal = (modal) => {

    this.setState(prevState => ({
      [modal]: !prevState[modal]
    }));
  }


  onLoginButtonClicked = () => {
    this.props.login(this.props.history,  this);
  };

  toggleModal = () => {
    this.props.closeSubscriberLoginModal();
  };

  render() {
    return (
      <Modal
        isOpen={this.props.subscriberLoginModalVisible}
        toggle={this.toggleModal}
      >
        <ModalHeader toggle={this.toggleModal}>Login</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup row>
              <Label for="email" sm={2}>
                UserName
              </Label>
              <Col sm={10}>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder={'Email / UserName'}
                  value={this.props.email}
                  onChange={e =>
                    this.props.updateProperty('email', e.target.value)
                  }
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="password" sm={2}>
                Password
              </Label>
              <Col sm={10}>
                <Input
                  type="password"
                  name="password"
                  id="password"
                  value={this.props.password}
                  onChange={e =>
                    this.props.updateProperty('password', e.target.value)
                  }
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={2} />
              <Col sm={10}>
                <Label check>
                  <Input
                    type="checkbox"
                    name="rememberMe"
                    checked={this.props.rememberMe}
                    onChange={e =>
                      this.props.updateProperty('rememberMe', e.target.checked)
                    }
                  />{' '}
                  Remember Me
                </Label>
              </Col>
            </FormGroup>
          </Form>
          <div className="text-center">
            <ErrorText>{this.props.loginError}</ErrorText>
          </div>
          <div className="text-center">
            <StyledLinkButton
              color="link"
              onClick={() => this.onForgotPasswordButtonClicked()}
            >
              Forgot your password?
            </StyledLinkButton>|
            <StyledLinkButton
              color="link"
              onClick={() => this.onCreateAccountButtonClicked()}
            >
              Don't have an account?
            </StyledLinkButton>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => this.onLoginButtonClicked()}>
            Login
          </Button>{' '}
        </ModalFooter>

        <Container className="pt-3 pb-4">
          <InfoModal
              title='Message'
              onButtonSubmit={() => this.onButtonGo()}
              values={this.props}
              errorMessage={this.props.errorMessage}
              modalRef={node => (this.loginInformationModal = node)}
              modalVisible={this.state.loginInformationModalVisible}
              toggleModal={() => this.toggleArrearsModal('loginInformationModalVisible')}/>
        </Container>
      </Modal>
    );
  }
}

const mapStateToProps = ({
  login: {
    companyId,
    companyName,
    contactName,
    loggedIn,
    loginError,
    rememberMe,
    subscriberLoginModalVisible
  }
}) => ({
  companyId,
  companyName,
  contactName,
  loggedIn,
  loginError,
  rememberMe,
  subscriberLoginModalVisible
});

export default connect(mapStateToProps, {
  closeSubscriberLoginModal,
  createAccount,
  forgotPassword,
  login,
  updateProperty
})(Login);
