import React, { Component } from 'react';
import { Footer } from '../components';
import { Link } from 'react-router-dom';

class Tips extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="pt-5 mt-5 pl-3 pr-3 lg-container">
          <h1 className="text-center pb-3">
            Page Not Found
          </h1>
          <hr className="mb-4" style={{ border: '1px solid #e6e6e6' }} />
          <div className="text-center pb-3">
            <p>
              The page you are looking for was not found.
            </p>
            <p>
              <Link to="/">Return to Home Page</Link>.
            </p>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Tips;
