import API from './baseService';

export const loginResults = params =>
  API.post('/login', { ...params }).then(response => response.data);


export const getSetup= (companyId) =>
    API.get(`/company/progress/get/company/setup/index`,{companyId:companyId}).then(
        response => response.data
    );

