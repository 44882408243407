import withAuthentication, { ROUTE_TYPES } from '../withAuthentication';
import withTracker from '.././withTracker';
import Bid from '../../containers/subscriber/Bid';

const subscriberBid = {
  path: '/subscribers/bid/:company_metro_id',
  component: withTracker(withAuthentication(Bid, ROUTE_TYPES.SUBSCRIBER))
};

export default subscriberBid;
