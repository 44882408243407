import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Col, Container, Row} from 'reactstrap';
import {Footer, Section} from '../../../components';
import CompanyAdditionalPhotoModal from '../Company/CompanyAdditionalPhotoModal';
import {
    addAdditionalPhoto,
    deletePhoto,
    fetchCompanyInformation, saveSetup1Info
} from '../../../actions/subscriber/init';
import $ from 'jquery';

class Init3 extends Component {

    constructor(props) {

        super(props);

        this.state = {
            i: 0,
            additionalPhotoModalVisible: false,
            additionalPhotos: this.props.additionalPhotos,
            mainPhoto: undefined,
            description: '',
            hoursOfOperationMon: '',
            hoursOfOperationSat: '',
            hoursOfOperationSum: '',
            facebookLink: '',
            twitterLink: '',
            youtubeLink: '',
            instagramLink: ''
        };
    }

    componentWillMount = () => {

        // this.props.fetchCompanyInformation(this.props.companyId,function (data) {
        //     let photos = data.additionalPhotos.map(p => {return {fileName:p.fileName,photo:p.url,isMainImage:p.isMainImage}});
        //     let mainPhoto = data.additionalPhotos.filter(p => {return p.isMainImage});
        //
        //     this.setState({mainPhoto})
        //     this.setState({additionalPhotos:photos})
        //     this.setState({description:data.information.description})
        //     this.setState({hoursOfOperation:data.information.hoursOfOperation})
        //     this.setState({facebookLink:data.information.facebookLink})
        //     this.setState({twitterLink:data.information.twitterLink})
        //     this.setState({youtubeLink:data.information.youtubeLink})
        //     this.setState({instagramLink:data.information.instagramLink})
        // }.bind(this));

    };

    componentDidMount = () => {
        if(!$("#my-account").hasClass("disabled"))
         $("#my-account").addClass("disabled")
    };

    onSelectPhoto = modal => {
        this.toggleModal(modal)
    }

    toggleModal = modal =>
        this.setState(prevState => ({
            [modal]: !prevState[modal]
        }));

    onAdditionalPhotoSubmit = (image, close) => {

        let name = 'img' + this.state.i++ + ".jpeg";

        console.log("fileName:" + name)

        let addImage = {fileName: name, extension: 'jpeg', photo: image.replace('data:image/jpeg;base64,', '')}

        this.state.additionalPhotos.push(addImage);

        if (!this.state.mainPhoto || this.state.mainPhoto == null || this.state.mainPhoto == '') {
            this.setState({mainPhoto: addImage})
        }

        close();
        this.setState({additionalPhotoModalVisible: false});
    }

    onDeletePhoto = (e, image) => {

        let self = this;
        let delI;
        for (let i in self.state.additionalPhotos) {

            if (self.state.additionalPhotos[i].fileName === image.fileName) delI = i;
        }

        if (delI) {
            let delImg = self.state.additionalPhotos[delI];
            self.state.additionalPhotos.splice(delI, 1)
            self.setState({additionalPhotos: self.state.additionalPhotos})

            if (self.state.mainPhoto.fileName === delImg.fileName) {
                self.setState({mainPhoto: self.state.additionalPhotos[0]})
            }
        }

        e.stopPropagation()

    }

    onSetMainImg = image => {
        this.setState({mainPhoto: image})
    }

    onSubmit = () => {

        if (!this.valid()) {
            return;
        }

        let params = {}
        params.companyId = this.props.companyId;
        params.additionalPhotos = this.state.additionalPhotos.filter(p => p.fileName !== this.state.mainPhoto.fileName);
        params.description = this.state.description;
        params.hoursOfOperationMon = this.state.hoursOfOperationMon;
        params.hoursOfOperationSat = this.state.hoursOfOperationSat;
        params.hoursOfOperationSum = this.state.hoursOfOperationSum;
        params.facebookLink = this.state.facebookLink;
        params.twitterLink = this.state.twitterLink;
        params.youtubeLink = this.state.youtubeLink;
        params.instagramLink = this.state.instagramLink;
        params.profilePhoto = this.state.mainPhoto;

        const {history} = this.props;

        if ($("#save-btn").hasClass("loading"))
            return;

        $("#save-btn").addClass("loading");
        this.props.saveSetup1Info(params, function (data) {
            if (data)
                history.push("/subscribers/init2");
            else
                $("#save-btn").removeClass("loading");
        }.bind(this))
    }

    valid = () => {

        if (!this.state.description || this.state.description.length <= 0) {
            alert('Description is required.');
            return false;
        }

        if (this.state.additionalPhotos.length <= 0) {
            alert('Photo is required.');
            return false;
        }

        if (!this.state.hoursOfOperationMon || !this.state.hoursOfOperationSat || !this.state.hoursOfOperationSum) {
            alert('Hours of operation is required.');
            return false;
        }

        return true;
    }


    render(formProps) {
        return (
            <div className="pt-5">
                <Section>
                    <section className=" cus_init">
                        <div className="wrap">
                            <h3 className="section_title">Setup Progress</h3>
                            <ul className="init_steps">
                                <li>
                                    <div className="init_step">
                                        <div className="num">1</div>
                                        <div className="text">Profile Information</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="init_step">
                                        <div className="num">2</div>
                                        <div className="text">Fleet Information</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="init_step">
                                        <div className="num">3</div>
                                        <div className="text">Add Metros</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="init_step">
                                        <div className="num">4</div>
                                        <div className="text">Bid for Placement</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="init_step">
                                        <div className="num">5</div>
                                        <div className="text">Payment</div>
                                    </div>
                                </li>
                            </ul>
                            <div className="content_grid">
                                <div className="content">
                                    <div className="nr_content">
                                        <div className="content_block">
                                            <h3 className="block_title">* Company Description</h3>
                                            <div className="form_box">
                                                <div className="input_box no_label">
                                                    <textarea name="" id="" cols="30" rows="10" minLength="500"
                                                              value={this.state.description}
                                                              placeholder="Enter the company description"
                                                              onChange={(e) => this.setState({description: e.target.value})}></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="content_block">
                                            <h3 className="block_title">* Photos <small>(All photos should be formatted
                                                as jpeg and should be no larger than 10mb.)</small></h3>
                                            <div className="cus_image_upload">

                                                {this.state.additionalPhotos.map(photo => (

                                                    <div id={photo.fileName} key={photo.fileName}
                                                         className={this.state.mainPhoto && (this.state.mainPhoto.fileName === photo.fileName) ? "cus_image_item main_image" : 'cus_image_item set_main_image'}
                                                         onClick={() => this.onSetMainImg(photo)}>
                                                        <img width="97" height="97" key={photo.fileName}
                                                             src={'data:image/jpeg;base64,' + photo.photo}/>
                                                        <div onClick={(e) => this.onDeletePhoto(e, photo)}
                                                             className="delete"/>
                                                    </div>
                                                ))}


                                                <div className="cus_image_item add">
                                                    <input type="button"
                                                           onClick={() => this.onSelectPhoto('additionalPhotoModalVisible')}
                                                           title="Upload an image"/>
                                                </div>
                                            </div>
                                        </div>

                                        <CompanyAdditionalPhotoModal
                                            errorMessage={this.props.errorMessage}
                                            initialPhoto={this.props.photoUrl}
                                            modalVisible={this.state.additionalPhotoModalVisible}
                                            onButtonSubmit={this.onAdditionalPhotoSubmit}
                                            toggleModal={() => this.toggleModal('additionalPhotoModalVisible')}
                                        />

                                        <div className="content_block">
                                            <h3 className="block_title">Operation</h3>
                                            <div className="form_box">
                                                <div className="input_box half">
                                                    <label htmlFor="">* Hours of Operation</label>
                                                    <input type="text" placeholder="MON-FRI：9:00 AM - 6:00PM"
                                                           value={this.state.hoursOfOperationMon}
                                                           onChange={(e) => this.setState({hoursOfOperationMon: e.target.value})}/>
                                                    <div className="tip">Neque porro quisquam est qui dolorem</div>
                                                </div>
                                                <div className="input_box half">
                                                    <input type="text" placeholder="SAT：9:00 AM - 12:00 AM"
                                                           value={this.state.hoursOfOperationSat}
                                                           onChange={(e) => this.setState({hoursOfOperationSat: e.target.value})}/>
                                                    <div className="tip">Neque porro quisquam est qui dolorem</div>
                                                </div>
                                                <div className="input_box half">
                                                    <input type="text" placeholder="SUN：CLOSE"
                                                           value={this.state.hoursOfOperationSum}
                                                           onChange={(e) => this.setState({hoursOfOperationSum: e.target.value})}/>
                                                    <div className="tip">Neque porro quisquam est qui dolorem</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="content_block">
                                            <h3 className="block_title">Social Media Links</h3>
                                            <div className="form_box">
                                                <div className="input_box half">
                                                    <label htmlFor="">Facebook Link</label>
                                                    <input type="text" value={this.state.facebookLink}
                                                           onChange={(e) => this.setState({facebookLink: e.target.value})}/>
                                                    <div className="tip">Neque porro quisquam est qui dolorem</div>
                                                </div>
                                                <div className="input_box half">
                                                    <label htmlFor="">Twitter Link</label>
                                                    <input type="text" value={this.state.twitterLink}
                                                           onChange={(e) => this.setState({twitterLink: e.target.value})}/>
                                                    <div className="tip">Neque porro quisquam est qui dolorem</div>
                                                </div>
                                                <div className="input_box half">
                                                    <label htmlFor="">Instagram Link</label>
                                                    <input type="text" value={this.state.instagramLink}
                                                           onChange={(e) => this.setState({instagramLink: e.target.value})}/>
                                                    <div className="tip">Neque porro quisquam est qui dolorem</div>
                                                </div>
                                                <div className="input_box half">
                                                    <label htmlFor="">YouTube Link</label>
                                                    <input type="text" value={this.state.youtubeLink}
                                                           onChange={(e) => this.setState({youtubeLink: e.target.value})}/>
                                                    <div className="tip">Neque porro quisquam est qui dolorem</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="buttons">
                                    <div id="save-btn" className="button large" onClick={this.onSubmit}><span>Save and Next</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </Section>
                <Footer/>
            </div>
        );
    }
}

const mapStateToProps = ({login, subscriberCompany}) => ({

    companyId: login.companyId,
    additionalPhotos: []//subscriberCompany.additionalPhotos ? subscriberCompany.additionalPhotos : []
});
export default connect(mapStateToProps, {
    addAdditionalPhoto,
    deletePhoto,
    fetchCompanyInformation,
    saveSetup1Info
})(Init3);
