import * as React from "react";

import withTracker from "./withTracker";
import Search from "../containers/Search";

const search: {
  path: string,
  component: React.ComponentType<{}>
} = {
  path: "/search/:city?/:state?",
  component: withTracker(Search)
};

export default search;
