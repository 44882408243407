export default class ServicesEnum {
  constructor() {
    this.list = [
      {
        name: 'Business Groups',
        type: '1',
        img: ''
      },
      {
        name: 'Conventions',
        type: '2',
        img: ''
      },
      {
        name: 'Employeee-shuttle',
        type: '3',
        img: ''
      },
      {
        name: 'Faith-trips',
        type: '4',
        img: ''
      },
      {
        name: 'Family-reunions',
        type: '5',
        img: ''
      },
      {
        name: 'Festivals-concerts',
        type: '6',
        img: ''
      },
      {
        name: 'Field Trips',
        type: '7',
        img: ''
      },
      {
        name: 'Nationwide-travel',
        type: '8',
        img: ''
      },
      {
        name: 'Political-rally',
        type: '9',
        img: ''
      },
      {
        name: 'Sightseeing',
        type: '10',
        img: ''
      },
      {
        name: 'Sporting-events',
        type: '11',
        img: ''
      },
      {
        name: 'Wedding-shuttles',
        type: '12',
        img: ''
      }
    ]
  }
  
}
