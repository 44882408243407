import { removeHash } from 'react-scrollable-anchor';

export const jumpToHash = (retryCount = 0, retryLimit = 300) => {
  const { hash } = window.location;
  if (hash !== '') {
    window.requestAnimationFrame(() => {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
        removeHash();
      } else if (retryCount < retryLimit)
        setTimeout(jumpToHash(retryCount + 1), 100);
    });
  }
};
