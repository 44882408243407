import {
  companyReviewInfo,
  submitReview
} from '../services/subscriber/reviewService';

export const fetchCompanyReviewInfo = companyId => async dispatch => {
  dispatch({ type: 'FETCH_COMPANY_REVIEW_INFO_REQUEST' });
  try {
    const response = await companyReviewInfo(companyId);

    dispatch({ type: 'FETCH_COMPANY_REVIEW_INFO_SUCCESS', payload: response });
  } catch (e) {
    dispatch({ type: 'FETCH_COMPANY_REVIEW_INFO_FAIL' });
  }
};

export const submitCompanyReview = ({
  callback,
  selectedCompany,
  selectedMetro,
  values
}) => async dispatch => {
  dispatch({ type: 'SUBMIT_REVIEW_REQUEST' });
  try {
    const requestData = {
      companyId: selectedCompany.id,
      companyMetroId: selectedMetro.companyMetroId,
      rating: values.rating,
      title: values.subject,
      comment: values.comment,
      name: values.name,
      tripDate: values.tripDate,
      email: values.email,
      notifyOnResponse: values.notifyOnResponse
    };
    const response = await submitReview(requestData);
    if (!response.success) throw Error;

    dispatch({ type: 'SUBMIT_REVIEW_SUCCESS' });

    callback();
  } catch (e) {
    dispatch({ type: 'SUBMIT_REVIEW_FAIL' });
  }
};
