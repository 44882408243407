import {
    addAdditionalPhotoService,
    deleteAdditionalPhoto, companyInformation
} from '../../services/subscriber/companyService';
import {submitSetup1Info} from "../../services/subscriber/init"

const DEFAULT_ERROR_MESSAGE =
    'There was a problem updating your company. Please try again later.';

export const addAdditionalPhoto = (image, callback) => async (dispatch, getState) => {
    try {
        const {login: {companyId}} = getState();

        let response;
        response = await addAdditionalPhotoService(companyId, image);

        if (!response.success) throw Error;

        let data = {url: response.photoUrl, name: response.photoFilename}

        callback(data)

    } catch (e) {
        console.log("add photo error:" + e)
        throw Error;
    }
};

export const deletePhoto = (image, callback) => async (dispatch, getState) => {

    try {
        const {login: {companyId}} = getState();

        let response;
        response = await deleteAdditionalPhoto(image, companyId);

        if (!response.success) throw Error;


        callback();
    } catch (e) {
        console.log("delete photo error..." + e)
        throw Error;
    }
};


export const fetchCompanyInformation = (companyId, callback) => async dispatch => {
    try {
        const response = await companyInformation(companyId);
        if (response && response.errorMessage) throw response.errorMessage;

        callback(response)

    } catch (e) {
        console.log("get information error..." + e)
        throw Error;
    }
};

export const saveSetup1Info = (params, callback) => async dispatch => {
    let response
    try {
        response = await submitSetup1Info(params);
    } catch (e) {
        console.log("save error..." + e)
        callback(false)
        // throw Error;
    }

    // if (response.status.code != 0) throw response.status.msg;
    if (response.status.code != 0) callback(false);

    callback(true)


};




