export const busTypes = [
  { key: 1, value: 'Full Size' },
  { key: 2, value: 'Minibus' },
  { key: 3, value: 'School Bus' },
  { key: 4, value: 'Entertainer' },
  { key: 5, value: 'Executive' },
  { key: 6, value: 'Trolley' },
  { key: 7, value: 'Double Decker' },
  { key: 8, value: 'Van' },
  { key: 9, value: 'Limobus' },
  { key: 10, value: 'Tour Operator' },
  { key: 11, value: 'Transit' },
  { key: 12, value: 'Travel Agent' },
  { key: 13, value: 'Party Bus' },
  { key: 14, value: 'Limo Stretch Town Car' },
  { key: 15, value: 'BR Limo' },
  { key: 16, value: 'Suv' },
  { key: 17, value: 'BlackCar' }
];


export const busTypes2 = [
  { key: 1, value: 'CharterBus' },
  { key: 2, value: 'Minibus' },
  { key: 3, value: 'School Bus' },
  { key: 4, value: 'Entertainer' },
  { key: 5, value: 'Executive' },
  { key: 6, value: 'Trolley' },
  { key: 7, value: 'Double Decker' },
  { key: 8, value: 'Van' },
  { key: 9, value: 'Limobus' },
  { key: 10, value: 'Tour Operator' },
  { key: 11, value: 'Transit' },
  { key: 12, value: 'Travel Agent' },
  { key: 13, value: 'Party Bus' },
  { key: 14, value: 'Limo Stretch Town Car' },
  { key: 15, value: 'BR Limo' },
  { key: 16, value: 'Suv' },
  { key: 17, value: 'BlackCar' }
];
