import API from '../baseService';

export const userProfile = () =>
  API.get('/users/profile').then(response => response.data);

export const editUserProfile = values =>
  API.post('/users/profile', {
    first_name: values.firstName,
    last_name: values.lastName,
    about: values.about,
    company: values.company
  }).then(response => response.data);

export const addPhoto = image =>
  API.post('/users/photo/add', {
    extension: 'jpeg',
    photo: image.replace('data:image/jpeg;base64,', '')
  }).then(response => response.data);

export const updatePhoto = ({ initialPhotoFilename, image }) =>
  API.post('/users/photo/replace', {
    extension: 'jpeg',
    file_name: initialPhotoFilename,
    photo: image.replace('data:image/jpeg;base64,', '')
  }).then(response => response.data);

export const updateUserLogin = values =>
  API.post('/users/change_password', {
    current_password: values.currentPassword,
    new_password: values.newPassword
  }).then(response => response.data);

export const userSignup = values =>
  API.post('users/register', {
    email: values.email,
    password: values.password,
    first_name: values.firstName,
    last_name: values.lastName,
    about: values.about
  }).then(response => response.data);

export const associateQuote = (quoteNumber, apiToken) => {
  const headers = apiToken ? { 'X-User-Token': apiToken } : null;
  API.post(`/users/quotes/${quoteNumber}/associate`, {}, headers).then(
    response => response.data
  );
};
