import API from "../baseService";

export const costBreakdownResults = (companyId) => (
    API.get("subscribers/{company_id}/payments/cost_breakdown", {company_id: companyId})
)

export const paymentInfoResult = (companyId) => (
    API.get("subscribers/{company_id}/payments/init", {company_id: companyId})
)


export const savePaymentInfo = (data) => (
    API.post("subscribers/{company_id}/paymentInfo", data)
)

export const arrearsInfoResult = (secretKey) => (
    API.get("subscribers/{company_id}/payments/arrears", {secret_key: secretKey})
)

export const chargeArrearsResult = (data) => (
    API.post("subscribers/{company_id}/payments/arrears", data)
)

export const payArrears = (data) => (
    API.post("subscribers/" + data.companyId + "/payments/arrears", data)
)