import withAuthentication, { ROUTE_TYPES } from '../withAuthentication';
import withTracker from '.././withTracker';
import Listing from '../../containers/subscriber/Listing/Listing';

const subscriberListing = {
  path: '/subscribers/listings/:company_metro_id',
  component: withTracker(withAuthentication(Listing, ROUTE_TYPES.SUBSCRIBER))
};

export default subscriberListing;
