import React, { Component } from 'react';
import glamorous from 'glamorous';
import { Button } from 'reactstrap';

import { RatingStars } from '../';

const StyledReviewDiv = glamorous.div({
  fontWeight: 300,
  '& button': {
    fontWeight: 300
  }
});

class ReviewButton extends Component {
  onReviewsClick = result => {
    this.props.openReviewsModal(result);
  };

  renderReviewsButton = result => {
    const { reviews } = this.props.result;

    return (
      <Button
        className="d-flex justify-content-between align-items-center pl-0 pr-0"
        color="link"
        onClick={() => this.onReviewsClick(result)}
      >
        <div className="mr-2 pt-1">{`${reviews.length} ${
          reviews.length === 1 ? 'Review' : 'Reviews'
        }`}</div>
        <RatingStars averageRating={result.company.averageRating} />
      </Button>
    );
  };

  render() {
    const { result } = this.props;

    return (
      <StyledReviewDiv>{this.renderReviewsButton(result)}</StyledReviewDiv>
    );
  }
}

export default ReviewButton;
