/* eslint import/prefer-default-export: 0 */

export const cropImage = ({ crop, file }) => {
  const image = new Image();
  image.src = file;

  const sX = image.naturalWidth * (crop.x / 100);
  const sY = image.naturalHeight * (crop.y / 100);
  const croppedImgWidth = image.naturalWidth * (crop.width / 100);
  const croppedImgHeight = image.naturalHeight * (crop.height / 100);

  const canvas = document.createElement('canvas');
  canvas.width = croppedImgWidth;
  canvas.height = croppedImgHeight;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    image,
    sX,
    sY,
    croppedImgWidth,
    croppedImgHeight,
    0,
    0,
    croppedImgWidth,
    croppedImgHeight
  );

  const base64Image = canvas.toDataURL('image/jpeg');
  return base64Image;
};
