import orderBy from 'lodash/orderBy';

const INITIAL_STATE = {
  loading: false,
  quotes: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GET_QUOTES_FAIL':
      return {
        ...state,
        loading: false
      };

    case 'GET_QUOTES_REQUEST':
      return {
        ...state,
        loading: true
      };

    case 'GET_QUOTES_SUCCESS':
      return {
        ...state,
        loading: false,
        quotes: orderBy(
          action.payload.response.data.quotes,
          ['dateSubmitted'],
          ['desc']
        )
      };

    default:
      return {
        ...state
      };
  }
};
