import React, { Component } from 'react';
import glamorous from 'glamorous';
import uniqueId from 'lodash/uniqueId';
import Slider from 'react-slick';
import Lightbox from 'react-image-lightbox';
import FaCheck from 'react-icons/lib/fa/check';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faTimes from '@fortawesome/fontawesome-free-solid/faTimes';
import { Button, Card, CardHeader, CardBody, CardTitle } from 'reactstrap';
import GoogleAnalytics from 'react-ga';
import 'react-image-gallery/styles/css/image-gallery.css';
import { trackAnalytics, TRACKING_TYPES } from '../../services/analyticsUtils';
import umaLogo from '../../assets/img/uma-logo-badge.png';

import ReviewButton from './ReviewButton';
import { Icon } from '../';
import * as Icons from '../../assets/img/icons';

const StyledSearchResultHeader = glamorous(CardHeader)({
  backgroundColor: '#ededed'
});

const StyledSearchResultBody = glamorous(CardBody)({
  fontWeight: 300
});

const StyledMoreDetailsButton = glamorous(Button)({
  fontWeight: 400
});

const StyledBusTypeInfoImageWrapper = glamorous.div({
  minHeight: 30
});

const StyledBusTypeInfoDiv = glamorous.div({
  display: 'inline-block',
  textAlign: 'center'
});

class Result extends Component {
  state = {
    photoIndex: 0,
    images: this.props.result.photos,
    current: ''
  };

  getSliderSettings(photos) {
    if (photos && photos.length > 1) {
      return {
        infinite: true,
        speed: 500,
        dots: false,
        arrows: true,
        adaptiveHeight: true
      };
    }
    else {
      return {
        dots: false,
        arrows: false,
        adaptiveHeight: true
      };
    }
  }

  handleClickImage = (e, image) => {
    e && e.preventDefault();

    this.setState({
      current: image.url
    });
  };

  handleCloseModal = e => {
    e && e.preventDefault();

    this.setState({
      current: ''
    });
  };

  getSelectedBusTypeResult() {
    const { busResults } = this.props.result;
    const selectedBusType = this.props.selectedBusType;
    const busType = busResults.filter(
      busResult => busResult.busType === selectedBusType
    );

    if (busType.length > 0) {
      return busType[0];
    } else {
      return null;
    }
  }

  renderSeatCount = () => {
    const selectedBusType = this.props.selectedBusType;

    if (!selectedBusType) return null;

    const currentBusType = this.getSelectedBusTypeResult();

    if (!currentBusType) return null;

    let seats = currentBusType.seats;

    if (seats.length <= 0) return null;

    return (
      <div>
        <Icon src={Icons.AvailableSeatsIcon} alt="Available Seats" />
        <span className="pl-3">{seats} Seats Available</span>
      </div>
    );
  };

  renderMoreDetailsButton = () => {
    const { result } = this.props;

    return (
      <StyledMoreDetailsButton
        color="link"
        style={{ paddingLeft: 0, textAlign: 'left' }}
        onClick={() => this.onMoreDetailsClick(result)}
      >
        More Details
      </StyledMoreDetailsButton>
    );
  };

  renderBusTypeInfo = () => {
    const { result } = this.props;
    const { company } = result;
    const selectedBusType = this.props.selectedBusType;

    if (!selectedBusType) return null;

    const currentBusType = this.getSelectedBusTypeResult();

    if (!currentBusType) return null;

    var infos = [];

    let restroom = currentBusType.restroom ? (
      <FaCheck alt="Yes" title="Yes" color="#008000" />
    ) : (
      <FontAwesomeIcon
        alt="No"
        title="No"
        color="#dc3545"
        icon={faTimes}
        style={{ color: 'transparent' }}
      />
    );
    let wifi = currentBusType.wifi ? (
      <FaCheck alt="Yes" title="Yes" color="#008000" />
    ) : (
      <FontAwesomeIcon
        alt="No"
        title="No"
        color="#dc3545"
        icon={faTimes}
        style={{ color: 'transparent' }}
      />
    );
    let oneTenVolts = currentBusType.oneTenVolts ? (
      <FaCheck alt="Yes" title="Yes" color="#008000" />
    ) : (
      <FontAwesomeIcon
        alt="No"
        title="No"
        color="#dc3545"
        icon={faTimes}
        style={{ color: 'transparent' }}
      />
    );
    let umaMember = company.isUmaMember ? (
      <img
        style={{
          height: '7.5em',
          paddingLeft: '.5em',
          opacity: '0.75'
        }}
        src={umaLogo}
        alt="UMA Member"
      />
    ) : (
      ''
    );

    infos.push(
      <StyledBusTypeInfoDiv
        className="mr-4"
        key={selectedBusType + 'restroom'}
        style={{ verticalAlign: 'middle' }}
      >
        <StyledBusTypeInfoImageWrapper>
          <Icon title="Restroom" src={Icons.RestroomIcon} alt="RestroomIcon" />
        </StyledBusTypeInfoImageWrapper>
        <div>{restroom}</div>
      </StyledBusTypeInfoDiv>
    );

    infos.push(
      <StyledBusTypeInfoDiv
        className="mr-4"
        key={selectedBusType + 'wifi'}
        style={{ verticalAlign: 'middle' }}
      >
        <StyledBusTypeInfoImageWrapper>
          <Icon title="Wifi" src={Icons.WifiIcon} alt="WifiIcon" />
        </StyledBusTypeInfoImageWrapper>
        <div>{wifi}</div>
      </StyledBusTypeInfoDiv>
    );

    infos.push(
      <StyledBusTypeInfoDiv
        className="mr-4"
        key={selectedBusType + 'oneTenVolts'}
        style={{ verticalAlign: 'middle' }}
      >
        <StyledBusTypeInfoImageWrapper>
          <Icon title="Power Outlets" src={Icons.PowerIcon} alt="PowerIcon" />
        </StyledBusTypeInfoImageWrapper>
        <div>{oneTenVolts}</div>
      </StyledBusTypeInfoDiv>
    );

    return (
      <div className="d-flex flex-wrap align-items-center justify-content-between">
        <div className="pt-2 pb-2">
          {infos}
          {umaMember}
        </div>
        <div className="pt-4 pb-2">
          {this.renderQuoteRequestButton(company.metroId, company.name)}
        </div>
      </div>
    );
  };

  renderQuoteRequestButton = (id, companyName) => {
    const { quoteRequests, hideAddButton } = this.props;

    if (hideAddButton) return null;

    const intId = parseInt(id, 10);

    if (quoteRequests.filter(r => r.id === intId).length > 0) {
      return (
        <Button
          color="danger"
          style={{ width: '183px', marginBottom: '2em' }}
          className="text-white align-self-end"
          onClick={() => this.props.onRemoveFromQuoteRequest(id, companyName)}
        >
          Remove Request
        </Button>
      );
    }

    return (
      <React.Fragment>
        <Button
          id="addToQuote"
          style={{ marginBottom: '2em' }}
          className="text-white align-self-end"
          onClick={() => this.props.onAddToQuoteRequest(id, companyName)}
        >
          Add To Quote Request
        </Button>
      </React.Fragment>
    );
  };

  onMoreDetailsClick = result => {
    if (!this.props.isSampleResult) {
      GoogleAnalytics.modalview('more-details');
      GoogleAnalytics.event({
        category: 'Conversions',
        action: 'More Details Clicked',
        label: result.company.name
      });

      trackAnalytics({
        companyMetroId: result.company.metroId,
        trackingType: TRACKING_TYPES.MORE_DETAILS
      });
    }

    this.props.openMoreDetailsModal(result);
  };

  render() {
    const { locationHeader } = this.props;
    const { company, photos } = this.props.result;
    const settings = this.getSliderSettings(photos);
    const { images, current, photoIndex } = this.state;

    const headerText = locationHeader || company.name;

    return (
      <Card className="mt-4 p-0 search-result" id={company.id.toString()}>
        <StyledSearchResultHeader className="d-flex flex-wrap align-items-center justify-content-between pt-3 pb-3">
          <CardTitle tag="h4" className="font-weight-bold text-primary m-0">
            <span>{headerText}</span>
          </CardTitle>
          <ReviewButton
            result={this.props.result}
            openReviewsModal={this.props.openReviewsModal}
          />
        </StyledSearchResultHeader>
        <StyledSearchResultBody
          className={
            company.isUmaMember
              ? 'member search-body row'
              : 'notMember search-body row'
          }
        >
          <div className="col-sm-12 col-md-4" href="#">
            <div>
              {this.state.images.length > 0 ? (
                <Slider {...settings}>
                  {photos.map((image, index) => (
                    <img
                      alt="Search result"
                      src={image.url}
                      onClick={e =>
                        this.handleClickImage(e, image, index, photos)
                      }
                      key={uniqueId()}
                      style={{ height: 'auto !important' }}
                    />
                  ))}
                </Slider>
              ) : (
                <div className="text-center" style={{ marginTop: '4.75em' }}>
                  {' '}
                  No Images Available{' '}
                </div>
              )}
              <br />
              {current && (
                <Lightbox
                  mainSrc={photos[photoIndex].url}
                  nextSrc={photos[(photoIndex + 1) % photos.length].url}
                  prevSrc={
                    photos[(photoIndex + images.length - 1) % photos.length].url
                  }
                  onCloseRequest={this.handleCloseModal}
                  onMovePrevRequest={() =>
                    this.setState({
                      photoIndex:
                        (photoIndex + photos.length - 1) % photos.length
                    })
                  }
                  onMoveNextRequest={() =>
                    this.setState({
                      photoIndex: (photoIndex + 1) % photos.length
                    })
                  }
                />
              )}
            </div>
          </div>
          <div className="col-sm-12 col-md-8 pl-3">
            <div>{company.about}</div>
            <br />
            {this.renderMoreDetailsButton()}
            <br />
            {this.renderBusTypeInfo(company)}
          </div>
        </StyledSearchResultBody>
      </Card>
    );
  }
}

export default Result;
