import React, { Component } from 'react';
import glamorous from 'glamorous';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import { staticWebLink } from '../../../utils/formatUtils'

import {
  Button,
  Col,
  Container, Input, Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import map from 'lodash/map';
import FaAmex from 'react-icons/lib/fa/cc-amex';
import FaDiscover from 'react-icons/lib/fa/cc-discover';
import FaMastercard from 'react-icons/lib/fa/cc-mastercard';
import FaVisa from 'react-icons/lib/fa/cc-visa';
import FaPaypal from 'react-icons/lib/fa/cc-paypal';
import FaFacebook from 'react-icons/lib/fa/facebook-square';
import FaTwitter from 'react-icons/lib/fa/twitter-square';
import FaInstagram from 'react-icons/lib/fa/instagram';
import FaYoutube from 'react-icons/lib/fa/youtube-square';
import 'react-image-crop/dist/ReactCrop.css';

import { Footer, Section } from '../../../components';
import Menu from '../../../components/subscriber/Menu';
import CompanyDetailsModal from './CompanyDetailsModal';
import CompanyAdditionalPhotoModal from './CompanyAdditionalPhotoModal';

import CompanyPhotoModal from './CompanyPhotoModal';
import FormsOfPaymentModal from './FormsOfPaymentModal';
import LoginInformationModal from './LoginInformationModal';
import ProfileManagementModal from './ProfileManagementModal';
import {
  addAdditionalPhoto,
  fetchCompanyInformation,
  deletePhoto,
  updateCompanyDetail,
  updateFormsOfPayment,
  updateLoginInformation,
  updatePassengerCarrier,
  updateProfileManagement,
  updateProfilePhoto,
  updateCompanyServices,
  updateCompanyHighlights
} from '../../../actions/subscriber/company';
import find from "lodash/find";
import {busTypes2} from "../../../utils/constantUtils";
import {
  list,addBustypes,listAmenity,deleteBustypes,listMetro, addFleetOnCompanyMetro
} from '../../../actions/subscriber/init2';
import EditFleetModal from "../Listing/EditFleetModal";
import HighlightsEnum from './data/highlights'
import ServicesEnum from './data/services'
import MessageManager from '../../../components/MessageManager'

const StyledSectionHeaderDiv = glamorous.div(props => ({
  backgroundColor: '#e9ecef',
  marginBottom: '2em',
  marginTop: props.first ? null : '2em'
}));

const StyledSectionHeaderTitle = glamorous.h3({ padding: '1em' });

const StyledContainer = glamorous.div({
  border: '1px solid #e9ecef'
});

const StyledContactLabel = glamorous.span({
  fontWeight: 'bold',
  display: 'inline-block',
  width: '2em'
});

class MyAccount extends Component {
  state = {
    addOnsModalVisible: false,
    additionalPhotoModalVisible: false,
    companyDetailsModalVisible: false,
    crop: { aspect: 11 / 7 },
    deletePhotoModalVisible: false,
    file: undefined,
    formsOfPaymentModalVisible: false,
    loginInformationModalVisible: false,
    passengerCarrierModalVisible: false,
    profileManagementModalVisible: false,
    profilePhotoModalVisible: false,
    selectedPhoto: undefined,
    showNewAccountModal: false,
    buses:[],
    editFleetModalVisible: false,
    editFleetFlag: true,
    saveCssName:"text-white",
    selectedBus:{},
    error: '',
    amenitiesOptions:[],
    addForMetroModalVisible:false,
    addMetros: [],
    addFleetId:undefined,
    highlightsEnum: new HighlightsEnum(),
    businessHighlights: [],
    servicesEnum: new ServicesEnum(),
    charterBusServices: []
  };

  componentDidMount() {
    const { showNewAccountModal } = this.props.match.params;

    if (showNewAccountModal) {
      this.setState({ showNewAccountModal: true });
    }

    this.props.fetchCompanyInformation(this.props.companyId).then(res => {
      this.setState({
        businessHighlights: this.props.businessHighlights,
        charterBusServices: this.props.charterBusServices
      })
    })

    this.props.listAmenity(this.props.companyId,function (data) {
      this.setState({amenitiesOptions:data})
    }.bind(this))

    this.list();
    
  }

  deleteAdditionalPhoto = () => {
    const callback = () => {
      this.props.fetchCompanyInformation(this.props.companyId);
      this.setState({ deletePhotoModalVisible: false });
    };
    if (this.state.selectedPhoto === undefined) {
      let current = this.props.additionalPhotos[0];
      this.props.deletePhoto(current, callback);
    }
    this.props.deletePhoto(this.state.selectedPhoto, callback);
  };

  deletePhotoModal = photo => {
    this.setState({ deletePhotoModalVisible: true });
  };

  renderAdditionalPhotos = () => {
    const settings = {
      infinite: true,
      speed: 500,
      arrows: true,
      afterChange: photoIndex => {
        this.setState({
          selectedPhoto: this.props.additionalPhotos[photoIndex]
        });
      }
    };

    return (
      <Slider {...settings}>
        {this.props.additionalPhotos.map(photo => (
          <img
            src={photo.url}
            alt="Company Photos"
            style={{ height: 'auto' }}
            key={photo.fileName}
          />
        ))}
      </Slider>
    );
  };

  toggleModal = modal =>
    this.setState(prevState => ({
      [modal]: !prevState[modal]
    }));

  onProfileManagementSubmit = values => {
    const callback = () => this.toggleModal('profileManagementModalVisible');
    this.props.updateProfileManagement(values, callback);
  };

  onCompanyDetailSubmit = values => {
    const callback = () => this.toggleModal('companyDetailsModalVisible');
    this.props.updateCompanyDetail(values, callback);
  };

  onProfilePhotoSubmit = (image, callback) => {
    const { photoFilename, photoUrl } = this.props;
    this.props.updateProfilePhoto({
      image,
      initialPhoto: photoUrl,
      initialPhotoFilename: photoFilename,
      callback
    });
  };

  onAdditionalPhotoSubmit = image => {
    const callback = () => {
      this.props.fetchCompanyInformation(this.props.companyId);
      this.setState({ additionalPhotoModalVisible: false });
    };
    this.props.addAdditionalPhoto({
      image,
      callback
    });
  };

  onFormsOfPaymentSubmit = values => {
    const callback = () => this.toggleModal('formsOfPaymentModalVisible');
    this.props.updateFormsOfPayment(values, callback);
  };

  onPassengerCarrierSubmit = values => {
    const callback = () => this.toggleModal('passengerCarrierModalVisible');
    this.props.updatePassengerCarrier(values, callback);
  };

  onLoginInformationSubmit = values => {
    const callback = () => this.toggleModal('loginInformationModalVisible');
    this.props.updateLoginInformation(values, callback);
  };

  onFileChange = file => {
    this.setState({ file: file.base64 });
  };

  onCrop = crop => {
    this.setState({ crop });
  };

  toggleEditFleetModal = bus => {
    this.setState(prevState => ({
      editFleetModalVisible: !prevState.editFleetModalVisible,
      selectedBus: bus || {}
    }));
    this.setState({error:''})
  };

  onDelete = bus =>{
    let fleetId = bus.listingId;

    let callback = this.list.bind(this)
    this.props.deleteBustypes(fleetId, callback );


  }

  onFleetSubmit = values => {

    if(!this.state.editFleetFlag){
      return;
    }


    this.setState({editFleetFlag:false,saveCssName:'buttonLoading'})

    let params = {};
    params.fleetId = values.listingId;
    params.quantity = values.quantity;
    params.companyId = this.props.companyId;
    params.numberSeats = values.seatCount;
    params.year = values.year;
    params.busTypeId = values.busTypeId;
    params.trnsfRate = values.transferRate;
    params.fiveHourRate = values.fiveHourRate;
    params.dayRate = values.dayRate;
    params.mileRate = values.mileRate;
    params.amenity = values.amenities;

    this.props.addBustypes(params,function (data) {

      this.setState({editFleetFlag:true,saveCssName:"text-white"})

      if(data.code == 0){
        this.toggleModal('editFleetModalVisible')
        this.list();
      }else{
        this.setState({error:data.msg})
      }

    }.bind(this))

  };

  list = () => {
    this.props.list(this.props.companyId, function (data) {

      data = data.map(b => {
        let bustype = {};
        bustype.listingId = b.fleetId;
        bustype.quantity = b.quantity;
        bustype.seatCount = b.numberSeats;
        bustype.year = b.year;
        bustype.busTypeId = b.busTypeId;
        bustype.transferRate = b.trnsfRate;
        bustype.fiveHourRate = b.fiveHourRate;
        bustype.dayRate = b.dayRate;
        bustype.mileRate = b.mileRate;
        bustype.amenities = b.amenity;
        bustype.displayAdd = b.displayAdd;

        return bustype
      })

      this.setState({buses: data})
    }.bind(this));
  }

  toggleAddForMetroModal = () =>{
    this.setState({addMetros:[]})
    this.setState({addFleetId:undefined})

    this.toggleModal('addForMetroModalVisible')
  }

  onAddMetro = values =>{

    this.setState({saveCssName:"buttonLoading"})
    let addMetros = this.state.addMetros.filter(metro => metro.checked).map(metro => metro.companyMetroId)

    this.props.addFleetOnCompanyMetro(addMetros,this.state.addFleetId,function (data) {
      if(data.code === 0){
        this.toggleAddForMetroModal();
        this.list();
      }
      this.setState({saveCssName:"text-white"})

    }.bind(this))

  }

  toggleAddForMetro = bus =>{

    this.props.listMetro(this.props.companyId, bus.busTypeId,function (data) {

      this.setState({addMetros:data.map(d =>{
          return {metroId:d.metroId,metroName:d.metroName,companyMetroId: d.companyMetroId,checked:false}
        })})
    }.bind(this));

    this.setState({addFleetId:bus.listingId})

    this.toggleModal('addForMetroModalVisible');
  }

  renderSocialMedia = () => {
    const {
      facebookLink,
      twitterLink,
      instagramLink,
      youtubeLink
    } = this.props.information;

    const style = { margin: '5px' };
    const socialIconProps = {
      color: '#212529',
      size: 40,
      style
    };
    const Link = props => (
      <a href={props.url} target="_blank">
        {props.children}
      </a>
    );

    return (
      <React.Fragment>
        {facebookLink.length >= 1 && (
          <Link url={facebookLink}>
            <FaFacebook alt="Facebook" {...socialIconProps} />
          </Link>
        )}
        {twitterLink.length >= 1 && (
          <Link url={twitterLink}>
            <FaTwitter alt="Twitter" {...socialIconProps} />
          </Link>
        )}
        {instagramLink.length >= 1 && (
          <Link url={instagramLink}>
            <FaInstagram alt="Instagram" {...socialIconProps} />
          </Link>
        )}
        {youtubeLink.length >= 1 && (
          <Link url={youtubeLink}>
            <FaYoutube alt="YouTube" {...socialIconProps} />
          </Link>
        )}
      </React.Fragment>
    );
  };

  renderAssociations = () =>
    this.props.associations.map(association => (
      <React.Fragment key={association.name}>
        <h6 className="pt-2">{association.name}</h6>
        <p>
          {association.list.length
            ? association.list.map((item, index, arr) => {
                const comma = index === arr.length - 1 ? '' : ', ';
                return `${item.name}${comma}`;
              })
            : 'None'}
        </p>
      </React.Fragment>
    ));

  renderPaymentOptions = () => {
    const { paymentOptions } = this.props;
    if (Object.keys(paymentOptions).every(key => !paymentOptions[key])) {
      return 'None';
    }

    return map(paymentOptions, (value, index) => {
      const style = { margin: '5px' };
      if (value && index === 'allowsAmex') {
        return (
          <FaAmex
            key={index}
            alt="American Express"
            color="#212529"
            size={40}
            style={style}
          />
        );
      } else if (value && index === 'allowsDiscover') {
        return (
          <FaDiscover
            key={index}
            alt="Discover"
            color="#212529"
            size={40}
            style={style}
          />
        );
      } else if (value && index === 'allowsMastercard') {
        return (
          <FaMastercard
            key={index}
            alt="Mastercard"
            color="#212529"
            size={40}
            style={style}
          />
        );
      } else if (value && index === 'allowsVisa') {
        return (
          <FaVisa
            key={index}
            alt="Visa"
            color="#212529"
            size={40}
            style={style}
          />
        );
      } else if (value && index === 'allowsPaypal') {
        return (
          <FaPaypal
            key={index}
            alt="Paypal"
            color="#212529"
            size={40}
            style={style}
          />
        );
      } else if (value && index === 'allowsCheck') {
        return <strong key={index}>Check</strong>;
      }
    });
  };

  renderNewAccountModal = () => (
    <Modal
      isOpen={this.state.showNewAccountModal}
      toggle={() => this.setState({ showNewAccountModal: false })}
    >
      <ModalHeader
        className="d-flex align-items-center pl-5 pr-5"
        toggle={() => {}}
      >
        BusRates Registration Confirmation
      </ModalHeader>
      <ModalBody className="pl-5 pr-5">
        <h5>Registration Successfully Submitted</h5>
        <p>
          Thank you for submitting your company to be added to the BusRates
          directory.
        </p>
        <p>
          Please allow one business day for your submission to be reviewed. Once
          approved, you will receive an email confirmation from our team with
          further steps on customizing your posting.
        </p>
        <p>
          To view and edit your company's information, please continue to the
          "My Account" area.
        </p>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          className="text-white"
          onClick={() => this.setState({ showNewAccountModal: false })}
        >
          Close
        </Button>{' '}
      </ModalFooter>
    </Modal>
  );

  renderContent = () => {
    const { dotNumber, loginEmail, information, photoUrl, userName } = this.props;
    const FormSectionHeader = ({ first, title }) => (
      <StyledSectionHeaderDiv first={first}>
        <StyledSectionHeaderTitle className="font-weight-border text-primary mb-0">
          {title}
        </StyledSectionHeaderTitle>
      </StyledSectionHeaderDiv>
    );

    const imageSource = this.props.photoFilename
      ? `${this.props.photoUrl}`
      : this.props.photoUrl;

    const companyUrl = staticWebLink(`companies/${information.urlSlug}`);

    return (
      <StyledContainer>
        <FormSectionHeader title="Profile Management" first />
        <Container className="pt-3 pb-4">
          <Row>
            <Col md="12" sm="12" lg="8">
              <h4>{information.name}</h4>
              <h5 className="pt-2">
                {information.street}
                <br />
                {information.city}, {information.state} {information.zip}
              </h5>
              <br />
              <p>
                <b>BusRates Company Page</b>
                <br />
                <a href={ companyUrl } target="_blank">
                  { companyUrl }
                </a>
              </p>
              <p>{information.description}</p>
              <p>{information.hoursOfOperation}</p>
            </Col>
            <Col md="12" sm="12" lg="4">
              <span>{information.contactName}</span>
              <br />

              <StyledContactLabel>P: </StyledContactLabel>
              <span>
                {information.contactPhone}{' '}
                {information.contactPhoneExtension &&
                  `+${information.contactPhoneExtension}`}
              </span>
              <br />
              <StyledContactLabel>W: </StyledContactLabel>
              <span>{information.website}</span>
              <br />
              <StyledContactLabel>E: </StyledContactLabel>
              <span>{information.contactEmail}</span>
              <br />
              {this.renderSocialMedia()}
              <br />

              <Button
                className="text-white"
                onClick={() =>
                  this.toggleModal('profileManagementModalVisible')
                }
              >
                Edit Profile
              </Button>
            </Col>
          </Row>
          <ProfileManagementModal
            values={this.props}
            errorMessage={this.props.errorMessage}
            modalRef={node => (this.profileManagementForm = node)}
            modalVisible={this.state.profileManagementModalVisible}
            onButtonSubmit={saveToAllListings => {
              this.profileManagementForm.setFieldValue('saveToAllListings', saveToAllListings);
              this.profileManagementForm.submitForm();
            }}
            onFormSubmit={this.onProfileManagementSubmit}
            toggleModal={() =>
              this.toggleModal('profileManagementModalVisible')
            }
          />
        </Container>

        <FormSectionHeader title="Company Details" />
        <Container className="pt-3 pb-4">
          <Row>
            <Col md="12" sm="12" lg="8">
              <h3>Association Memberships</h3>

              {this.renderAssociations()}
            </Col>
            <Col md="12" sm="12" lg="4">
              <Button
                className="text-white"
                onClick={() => this.toggleModal('companyDetailsModalVisible')}
              >
                Edit Company Details
              </Button>
            </Col>
          </Row>
          <CompanyDetailsModal
            values={this.props}
            errorMessage={this.props.errorMessage}
            modalRef={node => (this.companyDetailsForm = node)}
            modalVisible={this.state.companyDetailsModalVisible}
            onButtonSubmit={() => {
              this.companyDetailsForm.submitForm();
            }}
            onFormSubmit={this.onCompanyDetailSubmit}
            toggleModal={() => this.toggleModal('companyDetailsModalVisible')}
          />
        </Container>

        <FormSectionHeader title="Fleet Information" />
        <Container className="pt-3 pb-4">
          <section className=" cus_init">
            <div className="wrap">
              <div className="content_grid">
                <div className="content">
                  <div className="nr_content">
                    <div className="content_block">
                      <h3 className="block_title">Fleet Information</h3>
                      <div className="table">
                        <table>
                          <tr>
                            <th>Bus Type</th>
                            <th data-breakpoints="xs">Qty</th>
                            <th data-breakpoints="xs">Seat</th>
                            <th data-breakpoints="xs">Model Year</th>
                            <th>Action</th>
                          </tr>

                          {
                            this.state.buses.map(bus => {
                              const busTypeObject = find(busTypes2, {key: bus.busTypeId});
                              const busType = busTypeObject ? busTypeObject.value : '--';

                              let addForMetro = bus.displayAdd?
                                  <div className="button green" onClick={() => this.toggleAddForMetro(bus)}>Add for Metro
                                  </div>
                                  :null;
                              return <tr key={bus.listingId}>
                                <td>{busType}</td>
                                <td>{bus.quantity}</td>
                                <td>{bus.seatCount}</td>
                                <td>{bus.year}</td>
                                <td>
                                  <div className="button red idelete"
                                       onClick={() => this.onDelete(bus)}></div>
                                  <div className="button blue"
                                       onClick={() => this.toggleEditFleetModal(bus)}>Edit
                                  </div>
                                  {addForMetro}
                                </td>
                              </tr>
                            })
                          }
                        </table>
                      </div>
                    </div>
                    <div className="btns">
                      <div className="button small" onClick={() => this.toggleEditFleetModal()}>+ Add New Type</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Container>

        <Modal isOpen={this.state.addForMetroModalVisible} fade={false} toggle={this.toggleAddForMetroModal}>
          <ModalHeader toggle={this.toggleAddForMetroModal}></ModalHeader>
          <ModalBody>
            {this.state.addMetros.map(metro =>{
              return <Col sm={10}>
                <Label check>
                  <Input
                      type="checkbox"
                      name={metro.metroId}
                      onChange={e => {
                        metro.checked = e.target.checked
                      }
                      }
                  />{' '}
                  {metro.metroName}
                </Label>
              </Col>
            })}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggleAddForMetroModal}>
              Cancel
            </Button>{' '}
            <Button color="secondary" className={this.state.saveCssName} onClick={this.onAddMetro}>
              Save
            </Button>{' '}
          </ModalFooter>
        </Modal>

        <EditFleetModal
            amenitiesOptions={this.state.amenitiesOptions}
            busTypeOptions={busTypes2}
            values={this.state.selectedBus}
            errorMessage={this.state.error}
            modalRef={node => (this.fleetForm = node)}
            modalVisible={this.state.editFleetModalVisible}
            onButtonSubmit={() => this.fleetForm.submitForm()}
            onFormSubmit={this.onFleetSubmit}
            toggleModal={() => this.toggleEditFleetModal()}
            saveCssName={this.state.saveCssName}
        />

        <FormSectionHeader title="Profile Image" />
        <Container className="pt-3 pb-4">
          <Row>
            <Col md="12" sm="12" lg="8">
              {photoUrl &&
                photoUrl.length && (
                  <img src={imageSource} alt="Listing" className="w-100" />
                )}
            </Col>
            <Col md="12" sm="12" lg="4">
              <Button
                className="text-white"
                onClick={() => this.toggleModal('profilePhotoModalVisible')}
              >
                Edit Profile Image
              </Button>
            </Col>
          </Row>
          <CompanyPhotoModal
            errorMessage={this.props.errorMessage}
            initialPhoto={this.props.photoUrl}
            modalVisible={this.state.profilePhotoModalVisible}
            onButtonSubmit={this.onProfilePhotoSubmit}
            toggleModal={() => this.toggleModal('profilePhotoModalVisible')}
          />
        </Container>
        <FormSectionHeader title="Additional Photos" />
        <Container className="pt-3 pb-4">
          <div style={{ width: '80%', display: 'block', margin: '0 auto ' }}>
            <div style={{ textAlign: 'right', marginBottom: '1em' }}>
              {this.props.additionalPhotos.length > 0 && (
                <Button
                  className="text-white mr-2 btn-danger"
                  onClick={() => this.deletePhotoModal()}
                >
                  Delete Photo
                </Button>
              )}
              <Button
                className="text-white"
                onClick={() => this.toggleModal('additionalPhotoModalVisible')}
              >
                Add Photo
              </Button>
            </div>
            {this.props.additionalPhotos.length > 0 &&
              this.renderAdditionalPhotos()}
            {this.props.additionalPhotos.length === 0 && (
              <p>
                {' '}
                There are currently no additional photos. Click Add Photo to
                upload.
              </p>
            )}
          </div>
          <Modal
            isOpen={this.state.deletePhotoModalVisible}
            toggle={() => this.setState({ deletePhotoModalVisible: false })}
          >
            <ModalBody className="pl-5 pr-5">
              <h5>Are you sure you want to delete this photo?</h5>
            </ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                className="text-white"
                onClick={() =>
                  this.setState({ deletePhotoModalVisible: false })
                }
              >
                Cancel
              </Button>{' '}
              <Button
                color="primary"
                className="text-white"
                onClick={() => this.deleteAdditionalPhoto()}
              >
                Delete
              </Button>
            </ModalFooter>
          </Modal>
          <CompanyAdditionalPhotoModal
            errorMessage={this.props.errorMessage}
            initialPhoto={this.props.photoUrl}
            modalVisible={this.state.additionalPhotoModalVisible}
            onButtonSubmit={this.onAdditionalPhotoSubmit}
            toggleModal={() => this.toggleModal('additionalPhotoModalVisible')}
          />
        </Container>

        <FormSectionHeader title="Forms of Payment" />
        <Container className="pt-3 pb-4">
          <Row>
            <Col md="12" sm="12" lg="8">
              <h6 className="pt-2">Credit Cards Accepted</h6>
              <p>{this.renderPaymentOptions()}</p>
            </Col>
            <Col md="12" sm="12" lg="4">
              <Button
                className="text-white"
                onClick={() => this.toggleModal('formsOfPaymentModalVisible')}
              >
                Edit Forms of Payment
              </Button>
            </Col>
          </Row>
          <FormsOfPaymentModal
            values={this.props.paymentOptions}
            errorMessage={this.props.errorMessage}
            modalRef={node => (this.formsOfPaymentModal = node)}
            modalVisible={this.state.formsOfPaymentModalVisible}
            onButtonSubmit={() => this.formsOfPaymentModal.submitForm()}
            onFormSubmit={this.onFormsOfPaymentSubmit}
            toggleModal={() => this.toggleModal('formsOfPaymentModalVisible')}
          />
        </Container>

        <FormSectionHeader title="Passenger Carrier Authority" />
        <Container className="pt-3 pb-4">
          <Row>
            <Col md="12" sm="12" lg="8">
              <h6 className="pt-2">DOT Number</h6>
              <p>{dotNumber}</p>
            </Col>
          </Row>
        </Container>

        <FormSectionHeader title="Login Information" />
        <Container className="pt-3 pb-4">
          <Row>
            <Col md="12" sm="12" lg="8">
              <h6 className="pt-2">Login Email</h6>
              <p>{loginEmail}</p>
              <h6 className="pt-2">UserName</h6>
              <p>{userName}</p>
              <h6 className="pt-2">Password</h6>
              <p>********</p>
            </Col>
            <Col md="12" sm="12" lg="4">
              <Button
                className="text-white"
                onClick={() => this.toggleModal('loginInformationModalVisible')}
              >
                Edit Login
              </Button>
            </Col>
          </Row>
          <LoginInformationModal
            values={{ email: loginEmail }}
            errorMessage={this.props.errorMessage}
            modalRef={node => (this.loginInformationModal = node)}
            modalVisible={this.state.loginInformationModalVisible}
            onButtonSubmit={() => this.loginInformationModal.submitForm()}
            onFormSubmit={this.onLoginInformationSubmit}
            toggleModal={() => this.toggleModal('loginInformationModalVisible')}
          />
        </Container>

        <FormSectionHeader title="Highlights from the Business" />
        <Container className="" style={{ display: 'flex', flexWrap: 'wrap' }}>
          {
            this.state.highlightsEnum.list.map((highlightItem, index) => (
              <Col sm={10} style={{flex: '1 1 276px'}}>
                <Label check>
                  <Input
                    type="checkbox"
                    name={`highlight-${index}`}
                    checked={this.state.businessHighlights && this.state.businessHighlights.length > 0 ? !!this.state.businessHighlights.find(f => f === highlightItem.type) : false}
                    onChange={e =>
                      this.handelHighlightCheck(highlightItem.type)
                    }
                  />{' '}
                  {highlightItem.name}
                </Label>
              </Col>
            ))
          }
        </Container>
        <Button
          className="text-white mt-3 ml-3"
          onClick={() => this.handelHighlightSave()}
        >
          Save
        </Button>

        <FormSectionHeader title="Group Charter Bus Services Provided" />
        <Container className="pb-4">
          {
            this.state.servicesEnum.list.map((servicesItem, index) => (
              <Col sm={10}>
                <Label check>
                  <Input
                    type="checkbox"
                    name={`highlight-${index}`}
                    checked={this.state.charterBusServices && this.state.charterBusServices.length > 0 ? !!this.state.charterBusServices.find(f => f === servicesItem.type) : false}
                    onChange={e =>
                      this.handelServicesCheck(servicesItem.type)
                    }
                  />{' '}
                  {servicesItem.name}
                </Label>
              </Col>
            ))
          }
          <Button
            className="text-white mt-3"
            onClick={() => this.handelServicesSave()}
          >
            Save
          </Button>
        </Container>
      </StyledContainer>
    );
  };

  async handelHighlightCheck(type){
    await this.setState(prevState => {
      const businessHighlights = JSON.parse(JSON.stringify(prevState.businessHighlights));
      return {
        businessHighlights: businessHighlights.includes(type) ? businessHighlights.filter(f => f !== type) : [...businessHighlights, type]
      };
    });
  }

  handelHighlightSave(){
    this.props.updateCompanyHighlights(this.state.businessHighlights, () => this.handelSaveResponse())
  }

  handelSaveResponse(){
    if(this.props.responseState === 'ERROR'){
      MessageManager.error(this.props.errorMessage, 3000);
    }      
    if(this.props.responseState === 'SUCCESS'){
      MessageManager.success(this.props.successMessage, 3000);
    }
  }
  

  async handelServicesCheck(type){
    await this.setState(prevState => {
      const charterBusServices = JSON.parse(JSON.stringify(prevState.charterBusServices));
      return {
        charterBusServices: charterBusServices.includes(type) ? charterBusServices.filter(f => f !== type) : [...charterBusServices, type]
      };
    });
  }

  handelServicesSave(){
    this.props.updateCompanyServices(this.state.charterBusServices, () => this.handelSaveResponse())
  }

  render() {
    return (
      <div className="pt-5">
        <Section>
          <Row>
            <Col xs="12" className="text-center">
              <h1 className="pb-3">Company</h1>
            </Col>
          </Row>
          <Row>
            <Menu history={this.props.history} />
            <Col xs="9" className="mb-4">{this.renderContent()}</Col>
            {this.renderNewAccountModal()}
          </Row>
        </Section>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = ({ subscriberCompany, login }) => {
  const { companyId } = login;
  const {
    additionalPhotos,
    allAssociations,
    associations,
    dotNumber,
    loginEmail,
    responseState,
    successMessage,
    errorMessage,
    information,
    paymentOptions,
    photoFilename,
    photoUrl,
    selectedAssociations,
    userName,
    businessHighlights,
    charterBusServices
  } = subscriberCompany;

  return {
    additionalPhotos,
    allAssociations,
    associations,
    companyId,
    dotNumber,
    loginEmail,
    responseState,
    successMessage,
    errorMessage,
    information,
    paymentOptions,
    photoFilename,
    photoUrl,
    selectedAssociations,
    userName,
    businessHighlights,
    charterBusServices
  };
};

export default connect(mapStateToProps, {
  addAdditionalPhoto,
  deletePhoto,
  fetchCompanyInformation,
  updateCompanyDetail,
  updateFormsOfPayment,
  updateLoginInformation,
  updatePassengerCarrier,
  updateProfileManagement,
  updateProfilePhoto,
  list,addBustypes,listAmenity,deleteBustypes,listMetro, addFleetOnCompanyMetro,
  updateCompanyServices,
  updateCompanyHighlights
})(MyAccount);
