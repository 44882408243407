// @flow

import find from 'lodash/find';

export const createBidMetro = ({
  metros,
  companyMetroId,
  selectedPosition
}) => {
  const currentMetro = find(
    metros,
    metro => metro.companyMetroId == companyMetroId
  );

  const currentPosition = find(currentMetro.positions, 'isCurrentPosition');

  return {
    company_metro_id: parseInt(companyMetroId, 10),
    current_position: currentPosition.position,
    current_cost: currentPosition.newCost,
    new_position: selectedPosition.position,
    new_monthly_cost: selectedPosition.newCost
  };
};
