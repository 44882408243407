import withAuthentication, { ROUTE_TYPES } from '../withAuthentication';
import withTracker from '../withTracker';
import MyAccount from '../../containers/subscriber/MyAccount';

const subscriberMyAccount = {
  path: '/subscribers/my-account',
  component: withTracker(withAuthentication(MyAccount, ROUTE_TYPES.SUBSCRIBER))
};

export default subscriberMyAccount;
