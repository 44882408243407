import {
    listingsResults,
    listingsRecommendResults,
    searchForNewMetros,
    findMetrosInRadius,
    addNewMetroResults,
    removeMetroResults
} from '../../services/subscriber/listingsService';

import {setUpSecondIndex} from '../../services/subscriber/init3'

export const fetchListings = () => async (dispatch, getState) => {
    dispatch({
        type: 'SUBSCRIBER_LISTINGS_REQUEST'
    });

    try {
        const {login: {companyId}} = getState();

        const listingsResponse = await listingsResults(companyId);

        const {listings, totals, companyApproved, paymentsAvailable} = listingsResponse.data;
        dispatch({
            type: 'SUBSCRIBER_LISTINGS_REQUEST_SUCCESS',
            payload: {
                listings,
                totals,
                companyApproved,
                paymentsAvailable
            }
        });
    } catch (error) {
        dispatch({
            type: 'SUBSCRIBER_LISTINGS_REQUEST_FAILURE',
            payload: error
        });
    }
};

export const fetchListingsRecommend = () => async (dispatch, getState) => {
    dispatch({
        type: 'SUBSCRIBER_LISTINGS_RECOMMEND_REQUEST'
    });

    try {
        const {login: {companyId}} = getState();

        const listingsResponse = await listingsRecommendResults(companyId);

        const {metros} = listingsResponse.data;
        const listingsRecommed = metros;
        dispatch({
            type: 'SUBSCRIBER_LISTINGS_RECOMMEND_REQUEST_SUCCESS',
            payload: {
                listingsRecommed
            }
        });
    } catch (error) {
        dispatch({
            type: 'SUBSCRIBER_LISTINGS_RECOMMEND_REQUEST_FAILURE',
            payload: error
        });
    }
};

export const fetchNewMetros = query => async dispatch => {
    dispatch({
        type: 'SUBSCRIBER_LISTINGS_SEARCH_FOR_NEW_METRO_REQUEST'
    });

    try {
        const response = await searchForNewMetros({
            term: query
        });

        const {locations} = response.data;

        dispatch({
            type: 'SUBSCRIBER_LISTINGS_SEARCH_FOR_NEW_METRO_REQUEST_SUCCESS',
            payload: {
                locations
            }
        });
    } catch (error) {
        dispatch({
            type: 'SUBSCRIBER_LISTINGS_SEARCH_FOR_NEW_METRO_REQUEST_FAILURE',
            payload: error
        });
    }
};

export const fetchMetrosInRadius = (cityId, radius, companyId) => async (dispatch, getState) => {
    dispatch({
        type: 'SUBSCRIBER_LISTINGS_FIND_METROS_IN_RADIUS_REQUEST'
    });

    try {
        const response = await findMetrosInRadius({
            city_id: cityId,
            mile_radius: radius || 50,
            company_id:companyId
        });

        const {metros} = response.data;

        dispatch({
            type: 'SUBSCRIBER_LISTINGS_FIND_METROS_IN_RADIUS_REQUEST_SUCCESS',
            payload: {
                metrosInRadius: metros
            }
        });
    } catch (error) {
        dispatch({
            type: 'SUBSCRIBER_LISTINGS_FIND_METROS_IN_RADIUS_REQUEST_FAILURE',
            payload: error
        });
    }
};

export const addNewMetro = (metroId, baseRate) => async (
    dispatch,
    getState
) => {
    dispatch({
        type: 'SUBSCRIBER_LISTINGS_ADD_NEW_METRO_REQUEST'
    });

    try {
        const {companyId, companyName} = getState().login;

        const response = await addNewMetroResults({
            metro_id: metroId,
            base_rate: baseRate,
            company_id: companyId,
            company_name: companyName
        });

        const {success} = response.data;

        if (success) {
            dispatch({
                type: 'SUBSCRIBER_LISTINGS_ADD_NEW_METRO_REQUEST_SUCCESS',
                payload: {
                    success: success
                }
            });
        } else {
            dispatch({
                type: 'SUBSCRIBER_LISTINGS_ADD_NEW_METRO_REQUEST_FAILURE',
                payload: {
                    success: success,
                    error_message: response.data.error_message
                }
            });
        }
    } catch (error) {
        dispatch({
            type: 'SUBSCRIBER_LISTINGS_ADD_NEW_METRO_REQUEST_FAILURE',
            payload: error
        });
    }
};

export const removeMetro = companyMetroId => async (dispatch, getState) => {
    dispatch({
        type: 'SUBSCRIBER_LISTINGS_REMOVE_METRO_REQUEST'
    });

    try {
        const {companyId, companyName} = getState().login;

        const response = await removeMetroResults({
            company_metro_id: companyMetroId,
            company_id: companyId,
            company_name: companyName
        });

        const {success} = response.data;

        if (success) {
            dispatch({
                type: 'SUBSCRIBER_LISTINGS_REMOVE_METRO_REQUEST_SUCCESS',
                payload: {
                    success: success
                }
            });
        } else {
            dispatch({
                type: 'SUBSCRIBER_LISTINGS_REMOVE_METRO_REQUEST_FAILURE',
                payload: {
                    success: success,
                    error_message: response.data.error_message
                }
            });
        }
    } catch (error) {
        dispatch({
            type: 'SUBSCRIBER_LISTINGS_ADD_NEW_METRO_REQUEST_FAILURE',
            payload: error
        });
    }
};

export const setUp = (companyId,callback) =>async ()=>{

    try{
        const response = await setUpSecondIndex(companyId)
        callback(response.status.code == 0);
    }catch (e) {
        console.log(e)
        callback(false)
    }
}


export const updateProperty = (key, value) => dispatch => {
    dispatch({
        type: 'SUBSCRIBER_LISTINGS_PROPERTY_UPDATED',
        payload: {key, value}
    });
};
