import {paymentInfoResult} from '../../services/subscriber/paymentsService';
import {getMetroListing} from '../../services/subscriber/init5';
import {listingsResults} from "../../services/subscriber/listingsService";

export const getPaymentInfo = () => async (dispatch, getState) => {
    dispatch({
        type: 'SUBSCRIBER_PAYMENT_REQUEST'
    });
    try {
        const {companyId} = getState().login;
        const response = await paymentInfoResult(companyId);
        const {
            accountStatus,
            ccRecurring,
            paymentOption,
            nextRecurringDate,
            firstName,
            lastName,
            cardNumber,
            cardCvv,
            companyName,
            cardMonth,
            cardYear,
            billingAddress,
            billingZip,
            billingCity,
            billingState,
            billingCountry,
            email,
            success,
            errorMessage
        } = response.data;

        if (success) {
            dispatch({
                type: 'SUBSCRIBER_PAYMENT_REQUEST_SUCCESS',
                payload: {
                    accountStatus,
                    ccRecurring,
                    paymentOption,
                    nextRecurringDate,
                    firstName,
                    lastName,
                    cardNumber,
                    cardCvv,
                    companyName,
                    cardMonth,
                    cardYear,
                    billingAddress,
                    billingZip,
                    billingCity,
                    billingState,
                    billingCountry,
                    email
                }
            });
        } else {
            dispatch({
                type: 'SUBSCRIBER_PAYMENT_REQUEST_FAILURE',
                payload: {
                    error: errorMessage
                }
            });
        }
    } catch (error) {
        dispatch({
            type: 'SUBSCRIBER_PAYMENT_REQUEST_FAILURE',
            payload: error
        });
    }
};

export const fetchListings = (companyId, paymentOption) => async (dispatch, getState) => {
    dispatch({
        type: 'SUBSCRIBER_LISTINGS_REQUEST'
    });

    try {
        const listingsResponse = await getMetroListing(companyId, paymentOption);

        const {listings, totals, companyApproved, paymentsAvailable, today, nextRecurringDate, proratedBillingAmount, totalBillingAmount} = listingsResponse.data.result;
        dispatch({
            type: 'SUBSCRIBER_LISTINGS_REQUEST_SUCCESS',
            payload: {
                listings,
                totals,
                companyApproved,
                paymentsAvailable,
                proratedBillingAmount,
                totalBillingAmount,
                nextRecurringDate,
                today
            }
        });
    } catch (error) {
        dispatch({
            type: 'SUBSCRIBER_LISTINGS_REQUEST_FAILURE',
            payload: error
        });
    }
};
