import React from 'react';
import glamorous from 'glamorous';
import { FormGroup, Input as ReactstrapInput, Label } from 'reactstrap';

const StyledFormGroup = glamorous(FormGroup)({ marginBottom: '1em' });

export const InputRadioGroup = ({
  className,
  errors,
  handleChange,
  label,
  name,
  options,
  required,
  touched,
  values
}) => {
  const labelText = required ? `* ${label}` : label;
  return (
    <StyledFormGroup className={className} tag="fieldset">
      <Label for={name}>{labelText}</Label>
      <br />
      {options.map(option => (
        <FormGroup key={option.key} check className="form-check-inline">
          <Label check>
            <ReactstrapInput
              type="radio"
              name={name}
              value={option.key}
              defaultChecked={values[name] === option.key}
              onChange={handleChange}
            />{' '}
            {option.name}
          </Label>
        </FormGroup>
      ))}
      {touched[name] &&
        errors[name] && <div className="text-danger mt-1">{errors[name]}</div>}
    </StyledFormGroup>
  );
};

export default InputRadioGroup;
