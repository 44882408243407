export const fetchResults = () => async (dispatch, getState) => {
  dispatch({
    type: 'CONFIRMATION_REQUEST'
  });

  try {
    const { review } = getState();

    dispatch({
      type: 'CLEAR_QUOTE_REQUESTS',
      payload: {
        quoteRequests: []
      }
    })

    dispatch({
      type: 'CONFIRMATION_REQUEST_SUCCESS',
      payload: {
        quoteNumber: review.quoteNumber
      }
    });
  } catch (error) {
    dispatch({
      type: 'CONFIRMATION_REQUEST_FAILURE',
      payload: error
    });
  }
};