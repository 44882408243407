import API from "../baseService";

export const savePaymentInfo = (data) => (
    API.post("/company/progress/fifth/step/save", data)
);

export const savePaymentInfoAndPay = (data) => (
    API.post("/company/progress/fifth/step/pay", data)
);

export const getMetroListing = (companyId, paymentOption) => (
    API.get("/company/progress/fifth/step/listing", {companyId: companyId, paymentOption: paymentOption})
);