import { getQuotes } from '../../services/user/quoteService';

export const fetchQuotes = () => async dispatch => {
  dispatch({
    type: 'GET_QUOTES_REQUEST'
  });

  try {
    const response = await getQuotes();

    dispatch({
      type: 'GET_QUOTES_SUCCESS',
      payload: { response }
    });
  } catch (e) {
    dispatch({
      type: 'GET_QUOTES_FAIL',
      payload: e
    });
  }
};
