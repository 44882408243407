import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, Col, Container, Jumbotron, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from 'reactstrap';
import {Footer, Section} from '../../../components';
import {
    addNewMetro,
    fetchListings,
    fetchListingsRecommend,
    fetchMetrosInRadius,
    fetchNewMetros,
    removeMetro,
    updateProperty,
    setUp
} from '../../../actions/subscriber/init3';
import subscriberInit3 from "../../../routes/subscriber/init3";
import TableUtil from "../../../utils/tableUtils";
import {addNewMetroResults} from "../../../services/subscriber/listingsService";
import $ from 'jquery';
import {fetchCompanyInformation} from "../../../actions/subscriber/init";

class Init3 extends Component {

    constructor(props) {

        super(props);

        this.state = {
            addMetroModalVisible: false,
            metroInfo: {
                companyId: null,
                companyName: null,
                metroId: null,
                metroName: null,
                costValue: null,
                position: null,
                address: null,
                addressLine2: null,
                city: null,
                state: null,
                country: '',
                metroPhone: null
            },
            loadingCssName: "",
            saveDisabled: false,
            companyInfo:{}
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentWillMount = () => {
        this.props.fetchCompanyInformation(this.props.companyId,function (data) {
            this.setState({companyInfo: data.information});
        }.bind(this));
    };

    componentDidMount = () => {
        if(!$("#my-account").hasClass("disabled"))
            $("#my-account").addClass("disabled")

        const self = this;
        $(document).ready(function () {
            $("#searchInput").bind("input porpertychange", function () {
                if ($(this).val().length > 0) {
                    self.props.fetchNewMetros($(this).val()).then(() => {
                        const locations = self.props.locations;
                        if (locations) {
                            $("#searchResults").removeClass("dn");
                        } else {
                            $("#searchResults").addClass("dn");
                        }
                    });
                } else {
                    this.props.locations = null;
                    $("#searchResults").addClass("dn");
                }
            })

            TableUtil($, window);

            if ($('.table:not(.data) table').length > 0) {
                $('.table table').footable({
                    "toggleColumn": "first",
                    "breakpoints": {
                        "xs": 0,
                        "sm": 450,
                        "md": 720,
                        "lg": 1024,
                        "xlg": 1920,
                        "infinite": 10000
                    }
                });
            }
        });
        this.props.fetchListings().then(() => {

            this.props.fetchListingsRecommend();
        });
    };

    onSaveClick = () => {
        this.setState({
            loadingCssName: "buttonLoading",
            saveDisabled: true
        });

        addNewMetroResults({
            metro_id: this.state.metroInfo.metroId,
            base_rate: this.state.metroInfo.costValue,
            company_id: this.state.metroInfo.companyId,
            company_name: this.state.metroInfo.companyName,
            city: this.state.metroInfo.city,
            address: this.state.metroInfo.address,
            address_line: this.state.metroInfo.addressLine2,
            state_province: this.state.metroInfo.state,
            country: this.state.metroInfo.country,
            phone: this.state.metroInfo.metroPhone
        }).then((response) => {
            if (response.data.success) {
                this.props.fetchListings().then(() => {
                    this.closeAddMetroModel();
                });
            } else {
                alert(response.data.errorMessage)
            }
        });
    };

    showAddMetroModel = (companyId, companyName, metroId, metroName, costValue, position) => {

        const metroInfo = this.state.metroInfo;
        metroInfo.metroId = metroId;
        metroInfo.companyId = companyId;
        metroInfo.companyName = companyName;
        metroInfo.metroName = metroName;
        metroInfo.costValue = costValue;
        metroInfo.position = position;
        metroInfo.address = this.state.companyInfo.addressLine1;
        metroInfo.addressLine2 = this.state.companyInfo.addressLine2;
        metroInfo.metroPhone = this.state.companyInfo.contactPhone;
        metroInfo.city = this.state.companyInfo.city;
        metroInfo.state = this.state.companyInfo.state;

        this.setState({
            addMetroModalVisible: true,
            metroInfo: metroInfo
        })
    };

    closeAddMetroModel = () => {
        const metroInfo = this.state.metroInfo;
        metroInfo.metroId = null;
        metroInfo.companyId = null;
        metroInfo.companyName = null;
        metroInfo.metroName = null;
        metroInfo.costValue = null;
        metroInfo.position = null;
        metroInfo.address = null;
        metroInfo.addressLine2 = null;
        metroInfo.city = null;
        metroInfo.state = null;
        metroInfo.country = '';
        metroInfo.metroPhone = null;
        this.setState({
            addMetroModalVisible: false,
            metroInfo: metroInfo,
            loadingCssName: "",
            saveDisabled: false
        })
    };

    handleChange(event) {
        let attr = event.target.getAttribute("valueAttr");
        const metroInfo = this.state.metroInfo;
        metroInfo[attr] = event.target.value;
        this.setState({metroInfo: metroInfo});
        if (event.target.value !== "") {
            event.target.style.backgroundColor = "white";
        }
    }

    renderAddMetroModal = () => (
        <Modal isOpen={this.state.addMetroModalVisible} toggle={this.closeAddMetroModel}>
            <ModalHeader toggle={this.closePayModel} className="d-flex align-items-center pl-5 pr-5">Add Metro
                Location</ModalHeader>
            <ModalBody>
                <Jumbotron>
                    <p className="lead"><b>Metro:</b> {this.state.metroInfo.metroName}</p>
                    <p className="lead"><b>Base Rate:</b> {this.state.metroInfo.costValue}</p>
                    <p className="lead"><b>Position:</b> {this.state.metroInfo.position}</p>
                    <hr className="my-2"/>
                    <div>
                        <div style={{"display": "inline-block", "width": "50%"}}>
                            <span>Address</span>
                            <div>
                                <input type="text" style={{"width": "70%", "background": "white"}} valueAttr="address"
                                       value={this.state.metroInfo.address}
                                       onChange={this.handleChange}/>
                            </div>
                        </div>
                        <div style={{"display": "inline-block", "width": "50%"}}>
                            <span>Address Line2</span>
                            <div>
                                <input type="text" style={{"width": "70%", "background": "white"}} valueAttr="addressLine2"
                                       value={this.state.metroInfo.addressLine2}
                                       onChange={this.handleChange}/>
                            </div>
                        </div>
                    </div>
                    <div style={{"margin": "10px 0 0 0"}}>
                        <div style={{"display": "inline-block", "width": "50%"}}>
                            <span>City</span>
                            <div>
                                <input type="text" style={{"width": "70%", "background": "white"}} valueAttr="city"
                                       value={this.state.metroInfo.city}
                                       onChange={this.handleChange}/>
                            </div>
                        </div>
                        <div style={{"display": "inline-block", "width": "50%"}}>
                            <span>State</span>
                            <div>
                                <input type="text" style={{"width": "70%", "background": "white"}} valueAttr="state"
                                       value={this.state.metroInfo.state}
                                       onChange={this.handleChange}/>
                            </div>
                        </div>
                    </div>
                    <div style={{"margin": "10px 0 0 0"}}>
                        <div style={{"display": "inline-block", "width": "50%"}}>
                            <span>Country</span>
                            <div>
                                <input type="text" style={{"width": "70%", "background": "white"}} valueAttr="country"
                                       value={this.state.metroInfo.country}
                                       onChange={this.handleChange}/>
                            </div>
                        </div>
                        <div style={{"display": "inline-block", "width": "50%"}}>
                            <span>Phone</span>
                            <div>
                                <input type="text" style={{"width": "70%", "background": "white"}} valueAttr="metroPhone"
                                       value={this.state.metroInfo.metroPhone}
                                       onChange={this.handleChange}/>
                            </div>
                        </div>
                    </div>
                </Jumbotron>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={this.closeAddMetroModel}>Cancel</Button>
                <Button color="primary" disabled={this.state.saveDisabled} className={this.state.loadingCssName}
                        onClick={this.onSaveClick}>Save</Button>
            </ModalFooter>
        </Modal>
    );

    onAddRecommendMetroClick = (metro) => {
        this.showAddMetroModel(this.props.companyId, this.props.companyName, metro.id, metro.city + ', ' + metro.state, metro.baseRate, metro.position);
    };

    renderRecommend = () => {

        const listingsRecommed = this.props.listingsRecommed;
        if (!listingsRecommed) {
            return null;
        }

        const listings = this.props.listings;

        let finalResult = [];
        if (listings) {
            const result = JSON.parse(JSON.stringify(listingsRecommed));
            for (let i = 0; i < result.length; i++) {
                let hasAdd = false;
                for (let y = 0; y < listings.length; y++) {
                    if (listings[y].metroId === result[i].id) {
                        hasAdd = true;
                        break;
                    }
                }
                if (!hasAdd) {
                    finalResult.push(result[i]);
                }
            }
        }

        if (finalResult.length === 0) {
            finalResult = listingsRecommed
        }

        return finalResult.map(item => (
            <tr>
                <td className="metro_name">{item.city}, {item.state}</td>
                <td className="price"><em>${item.baseRate}/Month</em></td>
                <td>{item.position}</td>
                <td>
                    <div onClick={() => this.onAddRecommendMetroClick(item)} className="button blue">Add This Metro
                    </div>
                </td>
            </tr>
        ));
    };

    deleteMetro = (companyMetroId) => {
        this.props.removeMetro(companyMetroId).then(() => {
            this.props.fetchListings()
        })
    };

    renderMetroListing = () => {
        const listings = this.props.listings;
        if (!listings)
            return null;
        return listings.map(result => (
            <li>
                <span>{result.metroName}</span>&nbsp;<em>${result.costValue}/Month</em>
                <div onClick={() => this.deleteMetro(result.companyMetroId)} className="delete"></div>
            </li>
        ));
    };

    renderMetroListingTotal = () => {
        if (!this.props.totals)
            return null;
        return (
            <li>
                <span>Total Billing</span>&nbsp;<em>{this.props.totals.metroCosts}</em>
            </li>
        )
    };

    onAddMetroClick = (metro) => {

        this.showAddMetroModel(this.props.companyId, this.props.companyName, metro.id, metro.city + " " + metro.state, metro.baseRate, metro.position);
    };

    onLocationSelected = (id, name) => {
        $("#searchInput").val(name)
        $("#searchInput").attr("data", id)
        $("#searchResults").addClass("dn");
    };

    renderLocations = () => {
        const locations = this.props.locations
        if (!locations)
            return null;

        return locations.map(location => (
            <li onClick={() => this.onLocationSelected(location.id, location.name)}>
                {location.name}
            </li>
        ));
    };

    handleChangeSelect(event) {
        const parentDom = $(event.target.parentNode);
        event.target.style.backgroundColor = "white";
        parentDom.find("em").html(event.target.options[event.target.selectedIndex].text);
        parentDom.find("em").addClass("done");
    };

    onFindMetrosClick = () => {
        const id = $("#searchInput").attr("data");
        const radius = $("#searchRadius").val();
        if (id) {
            this.props.fetchMetrosInRadius(id, radius, this.props.companyId);
        }
    };

    renderMetrosInRadius = () => {
        const {metrosInRadius} = this.props;

        if (!metrosInRadius) return null;

        const listings = this.props.listings;

        let finalResult = [];
        if (listings) {
            const result = JSON.parse(JSON.stringify(metrosInRadius));
            for (let i = 0; i < result.length; i++) {
                let hasAdd = false;
                for (let y = 0; y < listings.length; y++) {
                    if (listings[y].metroId === result[i].id) {
                        hasAdd = true;
                        break;
                    }
                }
                if (!hasAdd) {
                    finalResult.push(result[i]);
                }
            }
        }

        if (finalResult.length === 0) {
            finalResult = metrosInRadius
        }

        return (
            <table>
                <thead>
                <tr>
                    <th>Metro</th>
                    <th data-breakpoints="xs" className="price">Base Rate</th>
                    <th data-breakpoints="xs">Position</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {finalResult.map(metro => (
                    <tr key={metro.id}>
                        <td className="metro_name">
                            {metro.city}, {metro.state}
                        </td>
                        <td className="price">${metro.baseRate}/Month</td>
                        <td>{metro.position}</td>
                        <td>
                            <div onClick={() => this.onAddMetroClick(metro)}
                                 className="button blue">Add This Metro
                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        );
    };

    onNextButtonClick = () => {

        if ($("#next-btn").hasClass("loading"))
            return;
        $("#next-btn").addClass("loading");
        this.props.setUp(this.props.companyId, function (data) {

            if (data) {
                this.props.history.push("/subscribers/init4")
            } else {
                $("#next-btn").removeClass("loading");
                console.log("setUp 3 error");
            }
        }.bind(this))
    };

    render() {
        return (
            <div className="pt-5">
                <Section>
                    <section className="cus_init">
                        <div className="wrap">
                            <h3 className="section_title">Setup Progress</h3>
                            <ul className="init_steps">
                                <li className="done">
                                    <div className="init_step">
                                        <div className="num">1</div>
                                        <div className="text">Profile Information</div>
                                    </div>
                                </li>
                                <li className="done">
                                    <div className="init_step">
                                        <div className="num">2</div>
                                        <div className="text">Fleet Information</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="init_step">
                                        <div className="num">3</div>
                                        <div className="text">Add Metros</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="init_step">
                                        <div className="num">4</div>
                                        <div className="text">Bid for Placement</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="init_step">
                                        <div className="num">5</div>
                                        <div className="text">Payment</div>
                                    </div>
                                </li>
                            </ul>
                            <div className="content_grid content_lr_73">
                                <div className="content">
                                    <div className="nr_content">
                                        <div className="content_block">
                                            <h3 className="block_title">Recommend</h3>
                                            <div className="table">
                                                <table>
                                                    <thead>
                                                    <tr>
                                                        <th>Metro</th>
                                                        <th data-breakpoints="xs" className="price">Base Rate</th>
                                                        <th data-breakpoints="xs">Position</th>
                                                        <th>Action</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {this.renderRecommend()}
                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>
                                        <div className="content_block">
                                            <h3 className="block_title">Search</h3>
                                            <div className="form_box">
                                                <div className="input_box half">
                                                    <label htmlFor="">City</label>
                                                    <input id="searchInput" type="text"
                                                           placeholder="Search for a city"/>
                                                    <div id="searchResults" className="auto_complete extend dn">
                                                        <ul className=" ac_list">
                                                            {this.renderLocations()}
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="input_box quarter">
                                                    <label htmlFor="">Radius</label>
                                                    <div className="select_content">
                                                        <em className="done">50 Miles</em>
                                                        <select name="" id="searchRadius" onChange={this.handleChangeSelect}>
                                                            <option value="50" selected="selected">50 Miles</option>
                                                            <option value="100">100 Miles</option>
                                                            <option value="200">200 Miles</option>
                                                        </select>
                                                        <div className="idrop"></div>
                                                    </div>
                                                </div>
                                                <div className="input_box quarter">
                                                    <div onClick={this.onFindMetrosClick} className="button">Find
                                                        Metros
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table">
                                                {this.renderMetrosInRadius()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="content">
                                    <div className="nr_content step_summary">
                                        <div className="content_block">
                                            <h3 className="block_title">Metro Listing</h3>
                                            <ul className="ss_list">
                                                {this.renderMetroListing()}
                                            </ul>
                                            <ul className="ss_total">
                                                {this.renderMetroListingTotal()}
                                            </ul>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="buttons">
                            <div id="next-btn" onClick={() => this.onNextButtonClick()} className="button large">
                                <span>Next</span>
                            </div>
                        </div>
                    </section>
                </Section>
                <Footer/>
                {this.renderAddMetroModal()}
            </div>
        );
    }
}

const mapStateToProps = ({login, init3}) => ({

    companyId: login.companyId,
    companyName: login.companyName,
    listings: init3.listings,
    listingsRecommed: init3.listingsRecommed,
    locations: init3.locations,
    totals: init3.totals,
    metrosInRadius: init3.metrosInRadius,
    newMetroSearchTerm: init3.newMetroSearchTerm,
    companyApproved: init3.companyApproved,
    paymentsAvailable: init3.paymentsAvailable,
});
export default connect(mapStateToProps, {
    addNewMetro,
    fetchListings,
    fetchListingsRecommend,
    fetchMetrosInRadius,
    fetchNewMetros,
    removeMetro,
    updateProperty,
    setUp,
    fetchCompanyInformation
})(Init3);
