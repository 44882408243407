import React, { Component } from 'react';
import glamorous from 'glamorous';
import { DateRangePicker } from 'react-dates';
import { Formik } from 'formik';
import Yup from 'yup';
import moment from 'moment';
import { connect } from 'react-redux';
import FileDownload from 'js-file-download';
import { quotesAsCsv, activityAsCsv } from '../../services/subscriber/reportsService';
import { formatPhone } from '../../utils/formatUtils';

import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  FormGroup,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Table
} from 'reactstrap';

import { fetchReports } from '../../actions/subscriber/reports';

import { Footer, Section } from '../../components';

import Menu from '../../components/subscriber/Menu';

const StyledListGroupItem = glamorous(ListGroupItem)({
  paddingTop: 10
});

const StyledListGroupItemText = glamorous(ListGroupItemText)({
  marginBottom: 0
})

class Reports extends Component {
  state = {
    focusedInput: null,
    detailsModal: false,
    selectedQuote: null,
    uniqueMetros: [],
    filteredQuotes: []
  };

  toggleDetailsModal = () => {
    this.setState({
      detailsModal: !this.state.detailsModal
    });
  }

  renderListGroupItem = (header, text) => {
    return (
      <StyledListGroupItem>
        <ListGroupItemHeading>{header}</ListGroupItemHeading>
        <StyledListGroupItemText>
          {text}
        </StyledListGroupItemText>
      </StyledListGroupItem>
    );
  }

  renderDetailsModal = () => {
    const { selectedQuote } = this.state;
    if (!selectedQuote) return null;

    return (
      <div>
        <Button color="danger" onClick={this.toggleDetailsModal}>{this.props.buttonLabel}</Button>
        <Modal isOpen={this.state.detailsModal} toggle={this.toggleDetailsModal} className={this.props.className}>
          <ModalHeader toggle={this.toggleDetailsModal}>Quote Details</ModalHeader>
          <ModalBody>
            <ListGroup>
              {this.renderListGroupItem('Quote Id', selectedQuote.quoteId)}
              {this.renderListGroupItem('Phone', formatPhone(selectedQuote.phone))}
              {this.renderListGroupItem('Email', <a href={'mailto:' + selectedQuote.email}>{selectedQuote.email}</a>)}
              {this.renderListGroupItem('Destination', `${selectedQuote.destinationCity}, ${selectedQuote.destinationState}`)}
              {this.renderListGroupItem('Departure Date', selectedQuote.departureDate ? moment(selectedQuote.departureDate).format('MMM DD, YYYY') : '')}
              {this.renderListGroupItem('Return Date', selectedQuote.returnDate ? moment(selectedQuote.returnDate).format('MMM DD, YYYY') : '')}
              {this.renderListGroupItem('Trip Type', selectedQuote.tripType)}
              {this.renderListGroupItem('Delivered Date', selectedQuote.delivered ? moment(selectedQuote.deliveredDate).format('MMM DD, YYYY') : 'Not Delivered')}
              {this.renderListGroupItem('Additional Information', selectedQuote.additionalInfo)}
            </ListGroup>
          </ModalBody>
        </Modal>
      </div>
    );
  }

  onDetailsClick = (metroQuote) => {
    this.setState({
      selectedQuote: metroQuote
    });

    this.toggleDetailsModal();
  }

  renderQuotesForMetro = (metro) => {
    const { reportsLoaded } = this.props;
    if (!reportsLoaded) return null;

    const { filteredQuotes } = this.state;
    if (!filteredQuotes) return null;

    return (
      <React.Fragment key={metro.companyMetroId}>
        <h4>{ metro.city + ', ' + metro.state }</h4>
        <Table striped>
          <thead>
            <tr>
              <th>Submitted</th>
              <th>Requester Name</th>
              <th>Group Name</th>
              <th># of People</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {filteredQuotes
              .filter(x => x.companyMetroId === metro.companyMetroId)
              .map(metroQuote => {
                return (
                  <tr key={metroQuote.quoteId}>
                    <td>{moment(metroQuote.submittedOn).format('MMM DD, YYYY')}</td>
                    <td><a href={'mailto:' + metroQuote.email}>{metroQuote.name}</a></td>
                    <td>{metroQuote.nameOfGroup}</td>
                    <td>{metroQuote.numberOfPeople}</td>
                    <td>
                      <Button
                        color="link"
                        style={{fontWeight: 'bold'}}
                        onClick={() => this.onDetailsClick(metroQuote)}
                      >
                        Details
                      </Button>
                    </td>
                  </tr>
                )
            })}
          </tbody>
        </Table>
      </React.Fragment>
    )
  }

  getFilteredUniqueMetros = (quotes) => {
    let metros = [];

    quotes.forEach(metroQuote => {
      if (metros.filter(m => m.companyMetroId === metroQuote.companyMetroId).length === 0) {
        let metro = {
            companyMetroId: metroQuote.companyMetroId,
            city: metroQuote.city,
            state: metroQuote.state
        }
        metros.push(metro);
      }
    });

    return metros;
  }

  setUniqueMetros = () => {
    const { metroQuotes, reportsLoaded } = this.props;
    if (!reportsLoaded) return null;

    this.setState({
      uniqueMetros: this.getFilteredUniqueMetros(metroQuotes)
    })
  }

  renderQuotesTables = () => {
    const { reportsLoaded } = this.props;
    if (!reportsLoaded) return null;

    const { filteredQuotes } = this.state;
    const uniqueMetros = this.getFilteredUniqueMetros(filteredQuotes);

    if (!uniqueMetros || uniqueMetros.length <= 0) {
      return (
        <p>There are no quote requests for this period</p>
      )
    }
    else {
      return (
        uniqueMetros.map(metro => {
          return this.renderQuotesForMetro(metro);
        })
      );
    }
  };

  renderReportsTables = () => {
    const { metroReports, reportsLoaded } = this.props;
    if (!reportsLoaded || !metroReports) return null;

    return (
      <Table striped>
        <thead>
          <tr>
            <th>Metro</th>
            <th>RFQ's</th>
            <th>Messages</th>
            <th>More Details</th>
            <th>Phone</th>
            <th>Contact Us</th>
            <th>Website</th>
            <th>Social</th>
            <th>Total Activity</th>
          </tr>
        </thead>
        <tbody>
          {metroReports.map(metro => {
            return(
              <tr>
                <td>{metro.city}, {metro.state}</td>
                <td>{metro.numberOfQuotes}</td>
                <td>{metro.messages}</td>
                <td>{metro.moreDetails}</td>
                <td>{metro.phoneClicks}</td>
                <td>{metro.contactUs}</td>
                <td>{metro.websiteVisits}</td>
                <td style={{'white-space': 'nowrap'}}>
                  <p>Facebook: {metro.facebookClicks}</p>
                  <p>Twitter: {metro.twitterClicks}</p>
                  <p>Instagram: {metro.instagramClicks}</p>
                  <p>Youtube: {metro.youtubeClicks}</p>
                </td>
                <td>{metro.totalActivity}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    )
  };

  fetchSubscriberReports = values => {
    const { companyId } = this.props;
    const dates = {
      startDate: moment(values.startDate).format('YYYY-MM-DD'),
      endDate: moment(values.endDate).format('YYYY-MM-DD')
    };
    this.props.fetchReports(companyId, dates)
      .then(() => {
        this.setUniqueMetros();
        this.setState({ filteredQuotes: this.props.metroQuotes })
      })
  };

  exportCsvQuotes = e => {
    const { companyId } = this.props;
    const { startDate, endDate } = this.form.state.values;

    let formattedStartDate = moment(startDate).format('YYYY-MM-DD');
    let formattedEndDate = moment(endDate).format('YYYY-MM-DD');

    const dates = {
      startDate: formattedStartDate,
      endDate: formattedEndDate
    };

    quotesAsCsv(companyId, dates).then(response => {
      FileDownload(response.data, 'quotes_' + formattedStartDate + '_' + formattedEndDate + '.csv');
    })
  }

  exportCsvActivity = e => {
    const { companyId } = this.props;
    const { startDate, endDate } = this.form.state.values;

    let formattedStartDate = moment(startDate).format('YYYY-MM-DD');
    let formattedEndDate = moment(endDate).format('YYYY-MM-DD');

    const dates = {
      startDate: formattedStartDate,
      endDate: formattedEndDate
    };

    activityAsCsv(companyId, dates).then(response => {
      FileDownload(response.data, 'activity_' + formattedStartDate + '_' + formattedEndDate + '.csv');
    })
  }

  renderFormik = () => (
    <Formik
      ref={node => (this.form = node)}
      initialValues={{
        startDate: moment().add(-1, 'weeks'),
        endDate: moment()
      }}
      validationSchema={Yup.object().shape({
        startDate: Yup.date()
          .typeError('Start Date is required')
          .required('Start Date is required'),
        endDate: Yup.date()
          .typeError('End Date is required')
          .required('End Date is required')
      })}
      onSubmit={this.fetchSubscriberReports}
      render={({ values, setValues }) => (
        <Form
          id="reports-form"
          style={{ border: '1px solid #e9ecef', padding: '1em' }}
          className="mb-4"
        >
          <FormGroup className="date-range-picker">
            <DateRangePicker
              startDate={values.startDate}
              startDateId="reports-start-date"
              endDate={values.endDate}
              endDateId="reports-end-date"
              onDatesChange={({ startDate, endDate }) =>
                setValues({ ...values, startDate, endDate })
              }
              focusedInput={this.state.focusedInput}
              onFocusChange={focusedInput => this.setState({ focusedInput })}
              isOutsideRange={() => false}
              minimumNights={0}
              hideKeyboardShortcutsPanel={true}
            />
          </FormGroup>
          <Button
            className="text-white"
            onClick={() => {
              this.form.submitForm();
            }}
          >
            Fetch Reports
          </Button>
          {this.renderExportButtons()}
        </Form>
      )}
    />
  );

  renderExportButtons = () => {
    const { reportsLoaded } = this.props;

    if (!reportsLoaded) return null;

    return (
      <div className="float-right">
        <Button
          className="text-white mr-2"
          color="primary"
          onClick={this.exportCsvQuotes}
        >
          Export Quotes
        </Button>
        <Button
          className="text-white"
          color="primary"
          onClick={this.exportCsvActivity}
        >
          Export Activity
        </Button>
      </div>
    );
  }

  filterQuoteRequests = (metro) => {
    const { metroQuotes } = this.props;
    let { filteredQuotes } = this.state;
    if (!filteredQuotes) return null;

    if (!metro) { //Assumes they want all
      filteredQuotes = metroQuotes
    }
    else {
      filteredQuotes = metroQuotes.filter(quote => quote.companyMetroId === metro.companyMetroId);
    }

    this.setState({filteredQuotes: filteredQuotes })
  }

  renderFilters = () => {
    return (
      <Container className="pt-3 pb-4">
        <Row>
          <Col md="12" sm="12" lg="12">
            {this.renderFormik()}
          </Col>
        </Row>
      </Container>
    );
  }

  renderQuoteRequestsFilter = () => {
    const { reportsLoaded } = this.props;
    const { uniqueMetros } = this.state;
    if (!reportsLoaded || !uniqueMetros || uniqueMetros.length <= 1) return null;

    return (
      <ButtonGroup>
        <Button
          key="All"
          className="text-white"
          color="primary"
          onClick={() => this.filterQuoteRequests()}>
          All
        </Button>
        {uniqueMetros.map(metro => {
          return (
            <Button
              key={metro.companyMetroId}
              className="text-white"
              color="primary"
              onClick={() => this.filterQuoteRequests(metro)}>
              {metro.city}, {metro.state}
            </Button>
          )
        })}
      </ButtonGroup>
    );
  }

  render() {
    return (
      <div className="pt-5">
        <Section>
          <Row>
            <Col xs="12" className="text-center">
              <h1>Reports</h1>
            </Col>
          </Row>
          <Row>
            <Menu history={this.props.history} />
            <Col xs="12" s="12" md="9">
              {this.renderFilters()}
              <div className={this.props.reportsLoaded ? 'block' : 'd-none'}>
                <h2 style={{ color: '#154988', fontWeight: 'bold' }}>
                  Activity
                </h2>
                <div>
                  {this.renderReportsTables()}
                </div>
                <br />
                <hr />
                <h2 style={{ color: '#154988', fontWeight: 'bold' }}>
                  Quote Requests
                </h2>
                <div>
                  {this.renderQuoteRequestsFilter()}
                </div>
              </div>
              <hr />
              {this.renderQuotesTables()}
            </Col>
          </Row>
        </Section>
        <Footer />
        {this.renderDetailsModal()}
      </div>
    );
  }
}

const mapStateToProps = ({ login, subscriberReports }) => ({
  companyId: login.companyId,
  reportsLoaded: subscriberReports.reportsLoaded,
  reports: subscriberReports,
  metroQuotes: subscriberReports.metroQuotes,
  metroReports: subscriberReports.metroReports,
  tablesRendered: false,
});

export default connect(mapStateToProps, {
  fetchReports,
})(Reports);
