import React, { Component } from 'react';
import {
  Alert,
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import { connect } from 'react-redux';
import { getUpgradePremiumList, payBillHandel } from '../../../actions/subscriber/login'
import PayBillSuccessModal from '../../subscriber/Company/PayBillSuccessModal'

const paymentOptionToString = function (type) {
  let str = ''
  switch (type) {
    case 1:
      str = "Month";
      break
    case 2:
      str = "Quarter";
      break
    case 3:
      str = "Biannual";
      break
    case 4:
      str = "Year";
      break
  }
  return str
}
class UpgradePremiumModal extends Component {

  state = {
    payBillSuccessModalVisible: false
  };

  toggleModal = modal =>
    this.setState(prevState => ({
      [modal]: !prevState[modal]
    }));


  componentDidUpdate = (prevProps) => {
    if(this.props.modalVisible !== prevProps.modalVisible){
      this.getUpgradePremium()

    }
  }

  getUpgradePremium = () => {
    if(this.props.modalVisible){
      this.props.getUpgradePremiumList()
    }
  };

  /**跳转至信用卡配置页面 */
  addCreditCardHabdler = () => {
    this.props.toggleModal()
    this.props.history.push('/subscribers/payments')
  };

  payBillSuccessModalHandler = () => {
    this.setState({
      payBillSuccessModalVisible: false
    });
    window.location.href="/subscribers/listings";
  }

  /** 升级 */
  onButtonPayBillHandel = () => {
    this.props.payBillHandel().then((result) => {
      if(this.props.payBillState){
        this.props.toggleModal()
        this.setState({
          upgradePremiumModalVisible: false,
          payBillSuccessModalVisible: true,
        })
      }
    }).catch((err) => {
      
    });
  };

  render() {
    const {
      modalVisible,
      toggleModal,
      upgradePremium,
      payBillStateStateObj,
      submitLoading
    } = this.props
    const loadingCssName = submitLoading ? "buttonLoading" : "text-white"

    return (
      <>
        <Modal isOpen={modalVisible} toggle={toggleModal}>
          <ModalHeader
            className="d-flex align-items-center pl-5 pr-5"
            toggle={toggleModal}
          >
            Upgrade to get better placement
          </ModalHeader>
          <ModalBody className="pl-5 pr-5">
            {
              !upgradePremium && (
                <div>{payBillStateStateObj.payBillStateMsg}</div>
              )
            }
            {
              upgradePremium !== null && (
                <div className='listing_list'>
                  <div className='header'>
                    <div className='col'>Metro:</div>
                    <div className='col align-right'>Total billing</div>
                    <div className='col align-right'>Prorated Billing</div>
                  </div>
                  <div className='list'>

                    {
                      upgradePremium?.listingList && (
                        upgradePremium.listingList.map(item => {
                          return (
                            <div className='item' key={`${item.companyMetroId}-listingList`}>
                              <div className='col'>{item.metroName}</div>
                              <div className='col price'>${item.baseNewPrice}</div>
                              <div className='col price'>${item.baseCurrentPrice}</div>
                            </div>
                          )
                        })
                      )
                    }
                  </div>
                  <div className='list statistics'>
                    <div className='item'>
                      <div className='col'></div>
                      <div className='col price'>${upgradePremium.bastNewPriceTotal ? upgradePremium.bastNewPriceTotal : '0.00'}</div>
                      <div className='col price'>${upgradePremium.baseCurrentPriceTotal ? upgradePremium.baseCurrentPriceTotal : '0.00'}</div>
                    </div>
                  </div>
                  <div className='list total'>
                    <div className='item'>
                      <div className='col'></div>
                      <div className='col price'></div>
                      <div className='col price'>Total:<span className='mgr40'></span>${upgradePremium.baseCurrentPriceTotal ? upgradePremium.baseCurrentPriceTotal : '0.00'}</div>
                    </div>
                  </div>
                </div>
              )
            }


          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggleModal}>
              Cancel
            </Button>{' '}
            {
              upgradePremium !== null && (
                <>
                  {
                    !upgradePremium.haveBankCardAdded && (
                      <Button color="secondary" disabled={submitLoading} className={loadingCssName} onClick={this.addCreditCardHabdler}>
                        Add Credit Card
                      </Button>
                    )
                  }
                  {
                    upgradePremium.haveBankCardAdded && (
                      <Button color="secondary" disabled={submitLoading} className={loadingCssName} onClick={this.onButtonPayBillHandel}>
                        Pay the Bill
                      </Button>
                    )
                  }
                </>
              )
            }

          </ModalFooter>
          {/* <Alert isOpen={payBillStateStateObj.payBillStateMsg !== '' && modalVisible} color={payBillStateStateObj.payBillState ? 'info' : 'danger'} className="mt-2">
            {payBillStateStateObj.payBillStateMsg}
          </Alert> */}
        </Modal>
        <PayBillSuccessModal modalVisible={this.state.payBillSuccessModalVisible} toggleModal={() => this.toggleModal('payBillSuccessModalVisible')} okClickHandel={()=>this.payBillSuccessModalHandler()} />
      </>
    )

  }
}

const mapStateToProps = ({ search, login: { upgradePremium, submitLoading, payBillState } }) => ({
  upgradePremium,
  submitLoading,
  payBillState
});


export default connect(mapStateToProps, {
  getUpgradePremiumList,
  payBillHandel,
})(UpgradePremiumModal);