import React, { Component } from 'react';
import glamorous from 'glamorous';
import { connect } from 'react-redux';
import { Button, Label, Form, FormGroup, Input } from 'reactstrap';

import { resetPassword, updateProperty } from '../../actions/subscriber/forgot';
import { Footer, Section, SectionTitle } from '../../components';

const ErrorText = glamorous.p({
  fontWeight: 400,
  color: 'red'
});

const SuccessText = glamorous.p({
  fontWeight: 400
});

class ForgotPassword extends Component {
  onRequestPasswordButtonClicked = () => {
    this.props.resetPassword();
  };

  renderSuccessMessage = () => {
    if (!this.props.forgotSuccess) return null;

    return (
      <SuccessText className="text-primary">
        An email has been sent to {this.props.email} with your password. Please
        check your email and login.
      </SuccessText>
    );
  };

  render() {
    return (
      <div className="pt-5">
        <Section containerStyles={{ maxWidth: 500 }}>
          <div className="row">
            <SectionTitle>Forgot Your Password</SectionTitle>
            <p>
              Please type in the login email address provided when you created
              your account and your password will be automatically emailed to
              you.
            </p>
          </div>
          <Form className="pb-5">
            <FormGroup row>
              <Label for="email">Email</Label>
              <Input
                type="email"
                name="email"
                id="email"
                value={this.props.email}
                onChange={e =>
                  this.props.updateProperty('email', e.target.value)
                }
              />
            </FormGroup>
            <div className="row">
              <ErrorText>{this.props.forgotError}</ErrorText>
              {this.renderSuccessMessage()}
            </div>
            <FormGroup row>
              <Button
                color="secondary"
                className="text-white"
                onClick={() => this.onRequestPasswordButtonClicked()}
              >
                Request Password
              </Button>
            </FormGroup>
          </Form>
        </Section>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = ({
  subscriberForgot: { forgotError, forgotSuccess }
}) => ({
  forgotError,
  forgotSuccess
});

export default connect(mapStateToProps, {
  resetPassword,
  updateProperty
})(ForgotPassword);
