import React from 'react';
import glamorous from 'glamorous';

const StyledIcon = glamorous.img({}, ({ small }) => ({
  height: small ? 11 : 23
}));

const Icon = props => <StyledIcon {...props} />;

export default Icon;
