import { subscribeToNewsletterResults } from '../services/newsletterService';

export const subscribeToNewsletter = () => async (
  dispatch,
  getState
) => {
  dispatch({
    type: 'SUBSCRIBE_REQUEST'
  });

  try {
    const { email } = getState().newsletter;

    const resultsResponse = await subscribeToNewsletterResults({
      email: email
    });

    if (resultsResponse && resultsResponse.data ) {
      if (resultsResponse.data.success === true)
      {
        dispatch({
          type: 'SUBSCRIBE_REQUEST_SUCCESS',
          payload: {
            joinSuccess: true,
            error: null,
            email: ''
          }
        });
      }
      else {
        dispatch({
          type: 'SUBSCRIBE_REQUEST_FAILURE',
          payload: {
            joinSuccess: false,
            error: resultsResponse.data.errorMessage,
            email: email
          }
        });
      }
    }
  } catch (error) {
    dispatch({
      type: 'SUBSCRIBE_REQUEST_FAILURE',
      payload: {
        joinSuccess: false,
        error: error,
      }
    });
  }
};

export const updateProperty = (key, value) => dispatch => {
  dispatch({
    type: 'SUBSCRIBE_PROPERTY_UPDATED',
    payload: { key, value }
  });
};