import React from 'react';
import {
  Alert,
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import { Formik } from 'formik';
import Yup from 'yup';
import { Input } from '../../../components';

const LoginInformationModal = ({
  errorMessage,
  modalRef,
  modalVisible,
  onButtonSubmit,
  onFormSubmit,
  title,
  toggleModal,
  values
}) => (
  <Modal isOpen={modalVisible} toggle={toggleModal}>
    <ModalHeader
      className="d-flex align-items-center pl-5 pr-5"
      toggle={toggleModal}
    >
      {title || 'Edit Listing Information'}
    </ModalHeader>
    <ModalBody className="pl-5 pr-5">
      <Formik
        ref={modalRef}
        initialValues={{
          email: values.email
        }}
        validationSchema={validationSchema}
        onSubmit={onFormSubmit}
        render={formProps => (
          <Form onSubmit={formProps.handleSubmit}>
            {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
            <Row>
              <Input
                className="col-md-6"
                label="Login Email"
                name="email"
                disabled
                {...formProps}
              />
              <Input
                className="col-md-6"
                label="Enter Your Current Password"
                name="currentPassword"
                type="password"
                {...formProps}
              />
            </Row>
            <Row>
              <Input
                className="col-md-6"
                label="Choose a New Password (8 char minimum)"
                name="newPassword"
                type="password"
                {...formProps}
              />
              <Input
                className="col-md-6"
                label="Confirm New Password"
                name="confirmNewPassword"
                type="password"
                {...formProps}
              />
            </Row>
          </Form>
        )}
      />
    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={toggleModal}>
        Cancel
      </Button>{' '}
      <Button color="secondary" className="text-white" onClick={onButtonSubmit}>
        Save
      </Button>{' '}
    </ModalFooter>
  </Modal>
);

Yup.addMethod(Yup.mixed, 'sameAs', function(ref, message) {
  return this.test('sameAs', message, function(value) {
    const other = this.resolve(ref);
    return !other || !value || value === other;
  });
});

const validationSchema = Yup.object().shape({

  currentPassword: Yup.string().required('Current password is required'),
  newPassword: Yup.string().min(8, 'Password must be at least 8 characters long').required('Confirmation new password is required'),
  confirmNewPassword: Yup.string().sameAs(Yup.ref('newPassword'), 'Passwords must match').required('Confirmation new password is required')
});

export default LoginInformationModal;
