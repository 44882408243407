import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Footer, Section} from '../../../components';
import {
    list, addBustypes, listAmenity, deleteBustypes, setUp
} from '../../../actions/subscriber/init2';
import $ from 'jquery';
import {busTypes2} from "../../../utils/constantUtils";
import EditFleetModal from "../Listing/EditFleetModal";
import find from "lodash/find";
import {Button} from "reactstrap";

class Init2 extends Component {

    constructor(props) {

        super(props);

        this.state = {
            editFleetModalVisible: false,
            editFleetFlag: true,
            saveCssName:"text-white",
            buses: [],
            selectedBus: {},
            amenitiesOptions: [],
            error: ''
        };
    }

    componentWillMount = () => {

        this.props.listAmenity(this.props.companyId, function (data) {
            this.setState({amenitiesOptions: data})
        }.bind(this))

        this.list();

    };

    componentDidMount = () => {
        if(!$("#my-account").hasClass("disabled"))
            $("#my-account").addClass("disabled")    };

    list = () => {
        this.props.list(this.props.companyId, function (data) {

            data = data.map(b => {
                let bustype = {};
                bustype.listingId = b.fleetId;
                bustype.quantity = b.quantity;
                bustype.seatCount = b.numberSeats;
                bustype.year = b.year;
                bustype.busTypeId = b.busTypeId;
                bustype.transferRate = b.trnsfRate;
                bustype.fiveHourRate = b.fiveHourRate;
                bustype.dayRate = b.dayRate;
                bustype.mileRate = b.mileRate;
                bustype.amenities = b.amenity;
                return bustype
            })

            this.setState({buses: data})
        }.bind(this));
    }

    toggleModal = modal =>
        this.setState(prevState => ({
            [modal]: !prevState[modal]
        }));

    toggleEditFleetModal = bus => {
        this.setState(prevState => ({
            editFleetModalVisible: !prevState.editFleetModalVisible,
            selectedBus: bus || {}
        }));
        this.setState({error: ''})
    };

    onDelete = bus => {
        let fleetId = bus.listingId;

        let callback = this.list.bind(this)
        this.props.deleteBustypes(fleetId, callback);


    }

    onFleetSubmit = values => {

        if(!this.state.editFleetFlag){
            return;
        }


        this.setState({editFleetFlag:false,saveCssName:'buttonLoading'})

        let params = {};
        params.fleetId = values.listingId;
        params.quantity = values.quantity;
        params.companyId = this.props.companyId;
        params.numberSeats = values.seatCount;
        params.year = values.year;
        params.busTypeId = values.busTypeId;
        params.trnsfRate = values.transferRate;
        params.fiveHourRate = values.fiveHourRate;
        params.dayRate = values.dayRate;
        params.mileRate = values.mileRate;
        params.amenity = values.amenities;

        this.props.addBustypes(params, function (data) {
            this.setState({editFleetFlag:true,saveCssName:"text-white"})

            if (data.code == 0) {
                this.toggleModal('editFleetModalVisible')
                this.list();
            } else {
                this.setState({error: data.msg})
            }

        }.bind(this))

    };

    onNext = () => {

        if ($("#next-btn").hasClass("loading"))
            return;
        $("#next-btn").addClass("loading");
        this.props.setUp(this.props.companyId, function (data) {

            if (data) {
                this.props.history.push("/subscribers/init3")
            } else {
                $("#next-btn").removeClass("loading");
            }
        }.bind(this))

    }


    render(formProps) {
        return (
            <div className="pt-5">
                <Section>
                    <section className=" cus_init">
                        <div className="wrap">
                            <h3 className="section_title">Setup Progress</h3>
                            <ul className="init_steps">
                                <li className="done">
                                    <div className="init_step">
                                        <div className="num">1</div>
                                        <div className="text">Profile Information</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="init_step">
                                        <div className="num">2</div>
                                        <div className="text">Fleet Information</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="init_step">
                                        <div className="num">3</div>
                                        <div className="text">Add Metros</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="init_step">
                                        <div className="num">4</div>
                                        <div className="text">Bid for Placement</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="init_step">
                                        <div className="num">5</div>
                                        <div className="text">Payment</div>
                                    </div>
                                </li>
                            </ul>
                            <div className="content_grid">
                                <div className="content">
                                    <div className="nr_content">
                                        <div className="content_block">
                                            <h3 className="block_title">Fleet Information</h3>
                                            <div className="table">
                                                <table>
                                                    <tr>
                                                        <th>Bus Type</th>
                                                        <th data-breakpoints="xs">Qty</th>
                                                        <th data-breakpoints="xs">Seat</th>
                                                        <th data-breakpoints="xs">Model Year</th>
                                                        <th>Action</th>
                                                    </tr>

                                                    {
                                                        this.state.buses.map(bus => {
                                                            const busTypeObject = find(busTypes2, {key: bus.busTypeId});
                                                            const busType = busTypeObject ? busTypeObject.value : '--';

                                                            return <tr key={bus.listingId}>
                                                                <td>{busType}</td>
                                                                <td>{bus.quantity}</td>
                                                                <td>{bus.seatCount}</td>
                                                                <td>{bus.year}</td>
                                                                <td>
                                                                    <div className="button red idelete"
                                                                         onClick={() => this.onDelete(bus)}></div>
                                                                    <div className="button blue"
                                                                         onClick={() => this.toggleEditFleetModal(bus)}>Edit
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        })
                                                    }
                                                </table>
                                            </div>
                                        </div>
                                        <div className="btns">
                                            <div className="button small" onClick={() => this.toggleEditFleetModal()}>+
                                                Add New Type
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="buttons">
                            <div id="next-btn" className="button large" onClick={() => this.onNext()}><span>Next</span>
                            </div>
                        </div>
                    </section>

                    <EditFleetModal
                        amenitiesOptions={this.state.amenitiesOptions}
                        busTypeOptions={busTypes2}
                        values={this.state.selectedBus}
                        errorMessage={this.state.error}
                        modalRef={node => (this.fleetForm = node)}
                        modalVisible={this.state.editFleetModalVisible}
                        onButtonSubmit={() => this.fleetForm.submitForm()}
                        onFormSubmit={this.onFleetSubmit}
                        toggleModal={() => this.toggleEditFleetModal()}
                        saveCssName={this.state.saveCssName}
                    />
                </Section>
                <Footer/>
            </div>

        );
    }
}

const mapStateToProps = ({login, subscriberListing}) => ({
    companyId: login.companyId,
    amenitiesOptions: subscriberListing.amenitiesOptions,
    error: ''
});
export default connect(mapStateToProps, {
    list, addBustypes, listAmenity, deleteBustypes, setUp

})(Init2);
