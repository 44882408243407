import React, {Component} from 'react';
import glamorous from 'glamorous';
import {Link} from 'react-router-dom';

const SubTitle = glamorous.p({
    fontSize: 22,
    fontWeight: 200,
    maxWidth: 600
});

const StyledActionButton = glamorous(Link)({
    whiteSpace: 'normal !important',
    wordWrap: 'break-word'
});

class Section extends Component {
    renderActionLink = () => {
        const {actionText, actionPath} = this.props;

        if (!actionPath) return null;

        return (
            <StyledActionButton
                to={actionPath}
                className="btn btn-lg btn-secondary text-white mt-3 pr-5 pl-5"
            >
                {actionText}
            </StyledActionButton>
        );
    };

    renderTitle = () => {
        const {Title, title, textColor} = this.props;

        if (!Title && !title) return null;

        if (Title) {
            return Title;
        } else {
            return (
                <h2 className={`text-${textColor || 'primary'} font-weight-bold`}>
                    {title}
                </h2>
            );
        }
    };

    renderSubTitle = () => {
        const {subTitle, textColor} = this.props;

        if (!subTitle) return null;

        return (
            <SubTitle className={`text-${textColor} mx-auto mt-2`}>
                {subTitle}
            </SubTitle>
        );
    };

    render() {
        const {
            actionPath,
            background,
            className,
            containerClassName,
            containerStyles,
            style
        } = this.props;

        return (
            <div
                className={`container-fluid pt-5 bg-${background} ${className}`}
                style={containerStyles}
            >
                <div className={`${containerClassName || 'container'}`} style={style}>
                    <section className={`text-center ${actionPath ? 'pb-5' : ''}`}>
                        {this.renderTitle()}
                        {this.renderSubTitle()}
                        {this.renderActionLink()}
                    </section>

                    {this.props.children}
                </div>
            </div>

        );
    }
}

export default Section;
