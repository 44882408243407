import * as React from 'react';
import glamorous from 'glamorous';
import uniqueId from 'lodash/uniqueId';
import { Card, CardBody, CardDeck, CardText } from 'reactstrap';

import { RatingStars, Section } from '../components';

const StyledReview = glamorous(Card)({
  borderColor: 'rgba(255, 255, 255, 0.30)'
});

const StyledAuthor = glamorous(CardText)({
  fontSize: 14
});

class Reviews extends React.Component {
  renderReviews = () => {
    const { reviews } = this.props;

    return reviews.map(review => (
      <StyledReview color="primary text-white p-2" key={uniqueId()}>
        <CardBody className="d-flex justify-content-center flex-column align-items-center">
          <CardText className="text-center">“{review.comment}”</CardText>
          <StyledAuthor className="font-weight-light mb-1">
            {review.name}, {review.title}
          </StyledAuthor>
          <RatingStars averageRating={review.rating} />
        </CardBody>
      </StyledReview>
    ));
  };

  render() {
    return (
      <Section
        background="primary"
        title={this.props.title}
        textColor="white"
        subTitle="Hear what people have to say about using BusRates"
      >
        <CardDeck className="pb-5 pt-3">{this.renderReviews()}</CardDeck>
      </Section>
    );
  }
}

export default Reviews;
