import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Button, Col, Form, Row } from 'reactstrap';
import { Formik } from 'formik';
import Yup from 'yup';
import glamorous from 'glamorous';
import { fetchResults } from '../actions/confirmation';
import { signup } from '../actions/user/userSignup';
import { Input, Footer, QuoteProgress } from '../components';

const StyledButtonContainer = glamorous.div({
  margin: '10px 0',
  textAlign: 'center'
});

const StyledButton = glamorous(Button)({
  fontSize: 20
});

const StyledForm = glamorous(Form)({
  textAlign: 'left'
});

class Confirmation extends Component {
  state = {};

  componentWillMount() {
    this.props.fetchResults();
  }

  onFormSubmit = values => {
    const { history, quoteNumber } = this.props;
    this.props.signup({ values, history, quoteNumber });
  };

  renderSignUpForm = () => {
    const { errorMessage, loggedIn } = this.props;
    if (loggedIn) return null;

    return (
      <div>
        <h5>Create an account with BusRates</h5>
        <br />
        <p>View history of your past quote requests</p>
        <p>Have easy access to communicate with bus companies</p>
        <p>Create your own personalized profile</p>
        <Formik
          ref={node => (this.signupForm = node)}
          validationSchema={validationSchema}
          onSubmit={this.onFormSubmit}
          render={formProps => (
            <StyledForm onSubmit={formProps.handleSubmit}>
              {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
              <Row>
                <Col xs={12} md={{ size: 8, offset: 2 }}>
                  <Input
                    name="firstName"
                    label="First Name"
                    required
                    {...formProps}
                  />
                  <Input
                    name="lastName"
                    label="Last Name"
                    required
                    {...formProps}
                  />
                  <Input
                    name="about"
                    label="Tell Us About Yourself"
                    type="textarea"
                    row="3"
                    {...formProps}
                  />
                  <Input name="email" label="Email" required {...formProps} />
                  <Input
                    name="password"
                    label="Password (8 character minimum)"
                    required
                    type="password"
                    {...formProps}
                  />
                  <Input
                    name="confirmPassword"
                    label="Password Confirmation"
                    required
                    type="password"
                    {...formProps}
                  />
                </Col>
              </Row>
            </StyledForm>
          )}
        />
        <StyledButtonContainer>
          <StyledButton
            className="text-white"
            onClick={() => this.signupForm.submitForm()}
          >
            Create My Account
          </StyledButton>
        </StyledButtonContainer>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <div
          dangerouslySetInnerHTML={{
            __html: `<!-- Google Code for Confirmation Page Conversion Page -->
        <script type="text/javascript">
        /* <![CDATA[ */
        var google_conversion_id = 1070544806;
        var google_conversion_label = "2sVxCPKks30Qpu-8_gM";
        var google_remarketing_only = false;
        /* ]]> */
        </script>
        <script type="text/javascript" src="//www.googleadservices.com/pagead/conversion.js">
        </script>
        <noscript>
        <div style="display:inline;">
        <img height="1" width="1" style="border-style:none;" alt="" src="//www.googleadservices.com/pagead/conversion/1070544806/?label=2sVxCPKks30Qpu-8_gM&amp;guid=ON&amp;script=0"/>
        </div>
        </noscript>`
          }}
        />
        <div className="pt-5">
          <div className="container text-center pt-5 mb-5">
            <QuoteProgress progress={100} />
            <div className="row">
              <div className="md-container">
                <h1 className="mb-4 mt-4" id="thank-you">
                  Thank you
                </h1>
                <p>
                  Your quote number is <strong>{this.props.quoteNumber}</strong>
                  <br />
                  For your convenience, a copy of this quote has been emailed to
                  you and a contact from each bus company will be in touch with
                  you soon.
                </p>
                <Alert color="danger">
                  <p>
                    {' '}
                    <strong>
                      Please Note: Typical response time for each company is 2
                      business days.
                    </strong>{' '}
                    <br />
                    If you do not hear from them within that time frame, please
                    contact them directly, or send us an email at{' '}
                    <a href="mailto:accounts@busrates.com">
                      accounts@busrates.com
                    </a>
                  </p>
                </Alert>
                {this.renderSignUpForm()}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

Yup.addMethod(Yup.mixed, 'sameAs', function(ref, message) {
  return this.test('sameAs', message, function(value) {
    const other = this.resolve(ref);
    return !other || !value || value === other;
  });
});

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string()
    .email('Must use valid email')
    .required('Email is required'),
  password: Yup.string().min(8, 'Password must be at least 8 characters long'),
  confirmPassword: Yup.string()
    .sameAs(Yup.ref('password'), 'Passwords must match')
    .required('Confirmationpassword is required')
});

const mapStateToProps = ({
  confirmation: { quoteNumber },
  login: { loggedIn },
  signup: { errorMessage }
}) => ({
  errorMessage,
  loggedIn,
  quoteNumber
});

export default connect(mapStateToProps, {
  fetchResults,
  signup
})(Confirmation);
