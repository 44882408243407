import withAuthentication, { ROUTE_TYPES } from '../withAuthentication';
import withTracker from '.././withTracker';
import Reports from '../../containers/subscriber/Reports';

const subscriberReports = {
  path: '/subscribers/reports',
  component: withTracker(withAuthentication(Reports, ROUTE_TYPES.SUBSCRIBER))
};

export default subscriberReports;
