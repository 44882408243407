import withAuthentication, { ROUTE_TYPES } from '../withAuthentication';
import withTracker from '.././withTracker';
import QuoteHistory from '../../containers/user/QuoteHistory';

const userQuoteHistory = {
  path: '/users/quote-history',
  component: withTracker(withAuthentication(QuoteHistory, ROUTE_TYPES.USER))
};

export default userQuoteHistory;
