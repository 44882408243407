const INITIAL_STATE = {
  associations: {
    national: [],
    stateAndRegional: [],
    certifications: []
  },
  metroStates: [],
  errorMessage: '',
  registerParam:{
    address: '',
    addressLine2: '',
    certifications: [],
    companyName: '',
    companyPhone: '',
    companyWebsite: '',
    contactName: '',
    contactPhone: '',
    contactPhoneExtension: '',
    dotNumber: '',
    national: [],
    stateAndRegional: [],
    terms: false,
    zipCode: '',
    city: '',
    state: ''
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CREATE_SUBSCRIBER_REQUEST':
      return { ...state, errorMessage: '' };

    case 'CREATE_SUBSCRIBER_REQUEST_FAILURE':
      return { ...state, errorMessage: action.payload };

    case 'CHECK_DOT_NUMBER_REQUEST_FAILURE':
      return { ...state, errorMessage: action.payload };

    case 'TO_REGISTER_CONFIRM_PAGE':
      return {
        ...state,
        registerParam: action.payload,
        errorMessage: ''
      };

    case 'TO_REGISTER_PAYMENT_PAGE':
      return {
        ...state,
        registerParam: action.payload,
        errorMessage: ''
      };

    case 'FETCH_ASSOCIATIONS_SUCCESS':
      return {
        ...state,
        associations: buildSubscribeAssociations(action.payload)
      };

    case 'FETCH_METRO_STATES_SUCCESS':
      return {
        ...state,
        metroStates: action.payload
      };

    case 'FETCH_ASSOCIATIONS_FAIL':
      return state;

    default:
      return state;
  }
};

const buildSubscribeAssociations = associations => {
  const national = [];
  const stateAndRegional = [];
  const certifications = [];

  associations.forEach(association => {
    if (association.type === 'National') {
      national.push(association);
    }
    if (association.type === 'State and Regional') {
      stateAndRegional.push(association);
    }
    if (association.type === 'Certifications') {
      certifications.push(association);
    }
  });

  return { national, stateAndRegional, certifications };
};
