import React, {Component} from 'react';
import glamorous from 'glamorous';
import {connect} from 'react-redux';
import FaCheck from 'react-icons/lib/fa/check';
import FaExclamationTriangle from 'react-icons/lib/fa/exclamation-triangle';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faTimes from '@fortawesome/fontawesome-free-solid/faTimes';

import {Button, Col, Row, Table} from 'reactstrap';

import {Footer, Section} from '../../components';

import Menu from '../../components/subscriber/Menu';

import {fetchBidPositions} from '../../actions/subscriber/bid';
import UpgradePremiumModal from './Company/UpgradePremiumModal'


const StyledTable = glamorous(Table)({
    fontWeight: 400
});

class BidListings extends Component {
    state = {
        typeaheadIsLoading: false,
        findMetrosButtonEnabled: false,
        listDataLoadingCssName: '',
        dataDisplay: false,
        upgradePremiumModalVisible: false
    };

    componentWillMount = () => {
        const {companyId} = this.props;

        if (!companyId) return null;
       
        // this.props.fetchBidPositions(companyId);
        this.getFetchBidPositions(companyId);
        
    };

    toggleModal = modal =>
    this.setState(prevState => ({
      [modal]: !prevState[modal]
    }));

    getFetchBidPositions = companyId => {
        this.setState({listDataLoadingCssName: 'dataLoading', dataDisplay: false})
        this.props.fetchBidPositions(companyId).then(() => {
            this.setState({listDataLoadingCssName: '', dataDisplay: true})
            if(this.props.isFreeTrial && this.props.isUpgradePremium){
                this.setState({upgradePremiumModalVisible: true})
            }
        });
        
    };

    renderTotals = () => {
        const {totals} = this.props;

        if (!totals) return null;

        return (
            <tr className="align-middle text-center">
                <td>Total</td>
                <td>{totals.metros}</td>
                <td>{totals.costs}</td>
                <td/>
                <td/>
                <td/>
            </tr>
        );
    };

    renderApproved = () => (
        <span title="Active/Approved">
      <FaCheck color="#008000"/>
    </span>
    );

    renderPendingRemoval = () => (
        <span title="Pending Removal">
      <FontAwesomeIcon color="#dc3545" icon={faTimes}/>
    </span>
    );

    renderPendingApproval = () => (
        <span title="Pending Approval">
      <FaExclamationTriangle color="#e69a2b"/>
    </span>
    );

    renderStatus = status => {

        if (status === 'Inactive/Approved') {
            return "Inactive";
        }

        if (status === 'No Bus') {
            return "No Bus";
        }

        if (status === 'Active/Approved') {
            return this.renderApproved();
        }

        if (status === 'Pending Approval') {
            return this.renderPendingApproval();
        }

        if (status === 'Pending Removal') {
            return this.renderPendingRemoval();
        }
    };

    renderCost = cost => {
        if (cost === 'Pending Approval') {
            return this.renderPendingApproval();
        }

        if (cost === 'Pending Removal') {
            return this.renderPendingRemoval();
        }

        return cost;
    };

    renderPosition = (position, status) => {
        if (position === 'Pending Approval') {
            return this.renderPendingApproval();
        }

        if (position === 'Pending Removal') {
            return this.renderPendingRemoval();
        }

        if (status === 'No Bus') {
            return "--";
        }

        return position;
    };

    handleEditButtonClick = companyMetroId => {
        this.props.history.push('/subscribers/bid/' + companyMetroId);
    };

    renderEditButton = (status, companyMetroId) => (
        <Button
            color="primary"
            block
            disabled={status !== 'Active/Approved' || this.props.isFreeTrial}
            onClick={() => this.handleEditButtonClick(companyMetroId)}
        >
            Edit
        </Button>
    );

    renderListingResults = () => {
        const {bidPositionResults} = this.props;

        if (!bidPositionResults) return null;

        return bidPositionResults.map(result => (
            <tr key={result.companyMetroId}>
                <td className="align-middle">{result.metroName}</td>
                <td className="align-middle">
                    {this.renderPosition(result.currentPosition, result.status)}
                </td>
                <td className="align-middle">
                    {this.renderCost(result.currentCostDisplay)}
                </td>
                <td className="align-middle">
                    {this.renderStatus(result.status)}
                </td>
                <td>{this.renderEditButton(result.status, result.companyMetroId)}</td>
            </tr>
        ));
    };

    renderListings = () => (
        <StyledTable striped>
            <thead className="text-center">
            <tr>
                <th>Metro</th>
                <th>Position</th>
                <th>Cost</th>
                <th>Status</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody style={{"display": this.state.dataDisplay ? "table-row-group" : "none"}}
                   className="text-center">{this.renderListingResults()}</tbody>
            {/* <tfoot>{this.renderTotals()}</tfoot> */}
        </StyledTable>
    );

    onEditButtonClick = companyMetroId => {
        this.props.history.push(`/subscribers/bid/${companyMetroId}`);
    };

    render() {
        return (
            <div className="pt-5">
                <Section>
                    <Row>
                        <Col xs="12" className="text-center">
                            <h1>Listings</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Menu history={this.props.history}/>
                        <Col xs="9" className={this.state.listDataLoadingCssName}>
                            <Row>{this.renderListings()}</Row>
                        </Col>
                    </Row>
                    <UpgradePremiumModal modalVisible={this.state.upgradePremiumModalVisible} toggleModal={() => this.toggleModal('upgradePremiumModalVisible')} history={this.props.history}/>
                </Section>
                <Footer/>
            </div>
        );
    }
}

const mapStateToProps = ({subscriberBidPositions, login}) => ({
    companyId: login.companyId,
    bidPositionResults: subscriberBidPositions.results,
    isFreeTrial: login.isFreeTrial,
    isUpgradePremium: login.isUpgradePremium
});

export default connect(mapStateToProps, {
    fetchBidPositions
})(BidListings);
