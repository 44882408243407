import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
   Button,
   Card,
   CardTitle,
   CardText,
}  from 'reactstrap';

class Join extends Component {
  onSubscriberSignupButton = event => {
    event.preventDefault();
    this.props.history.push('/subscribers/subscribe');
  };

  onUserSignupButton = event => {
    event.preventDefault();
    this.props.history.push('/users/subscribe');
  };

  render() {
    return (
      <div className="pt-5 mt-5 pl-3 pr-3 lg-container">
        <h1>Sign Up with BusRates.com</h1>
        <div className="pt-3">
          <p className="text-success">
            To all Young Life and Boy Scout users, please join using the register as a User button (first button).
            You will be able to setup your account and save your RFQs and companies you want to work with.
          </p>
        </div>
        <div className="pt-3">
          <Card body>
            <CardTitle>User Registration</CardTitle>
              <CardText>User Registration, click here to register as a user of BusRates, you will be able to keep track of your quotes, receive info from bus companies, and do research on companies.</CardText>
              <Button
                className="text-white"
                color="primary"
                size="lg"
                onClick={this.onUserSignupButton}
              >
                Click here to register as a user!
              </Button>
          </Card>
        </div>
        <div className="pt-3">
          <Card body>
            <CardTitle>Bus Owner Operator Registration</CardTitle>
              <CardText>Bus Owner Operator, click here to register your bus company with Busrates, make sure you have all of your contact information, Your DOT # and your insurance info to be able to register your company with BusRates.</CardText>
              <Button
                className="text-white"
                color="primary"
                size="lg"
                onClick={this.onSubscriberSignupButton}
              >
                Click here to become a subscriber!
              </Button>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({
});

export default connect(mapStateToProps, {
})(Join);
