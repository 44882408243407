import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {
    Alert,
    Button,
    Col,
    Container, Input,
    Jumbotron,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Table
} from 'reactstrap';
import {Footer, LargeButton, Section} from '../../../components';
import {
    clearBidConfirmation,
    fetchBidConfirmation,
    fetchBidPositions,
    fetchListings,
    submitBidConfirmation
} from '../../../actions/subscriber/init4';
import TableUtil from "../../../utils/tableUtils";
import {submitBidMetros, skipBidMetros} from '../../../services/subscriber/init4';
import {createBidMetro} from '../../../actions/subscriber/helpers';
import $ from 'jquery';
import glamorous from "glamorous";

const StyledTable = glamorous(Table)({
    fontWeight: 400
});

const StyledItemsContainer = glamorous.div({
    marginTop: '20px'
});

const StyledBoldItem = glamorous.p({
    fontWeight: 'bold'
});

class Init4 extends Component {

    constructor(props) {

        super(props);

        this.state = {
            bidModalVisible: false,
            bidModelInfo: {
                companyMetroId: null,
                selectedPosition: null
            },
            bidMetros: []
        };
    }

    componentWillMount = () => {

    };

    componentDidMount = () => {
        if(!$("#my-account").hasClass("disabled"))
            $("#my-account").addClass("disabled")

        $(document).ready(function () {
            TableUtil($, window);
            if ($('.table:not(.data) table').length > 0) {
                $('.table table').footable({
                    "toggleColumn": "first",
                    "breakpoints": {
                        "xs": 0,
                        "sm": 450,
                        "md": 720,
                        "lg": 1024,
                        "xlg": 1920,
                        "infinite": 10000
                    }
                });
            }
        });
        this.props.fetchBidPositions(this.props.companyId);
        this.props.fetchListings();
    };

    onConfirmation = () => {
        const metro = createBidMetro({
            metros: this.props.bidPositionResults,
            companyMetroId: this.state.bidModelInfo.companyMetroId,
            selectedPosition: this.state.bidModelInfo.selectedPosition
        });
        const bidMetros = this.state.bidMetros;
        if (bidMetros.length > 0) {
            for (let i = 0; i < bidMetros.length; i++) {
                const item = bidMetros[i];
                if (item.company_metro_id === metro.company_metro_id) {
                    bidMetros.splice(i, 1);
                    break;
                }
            }
        }
        bidMetros.push(metro);
        this.setState({bidMetros: bidMetros});
        this.closeBidModel();
    };

    getMetroInformation = () => {
        if (!this.props.bidPositionResults) return null;

        const filteredMetroPositions = this.props.bidPositionResults.filter(
            metro => metro.companyMetroId === this.state.bidModelInfo.companyMetroId
        );

        if (filteredMetroPositions.length !== 1) return null;

        return filteredMetroPositions[0];
    };

    onPositionSelect = position => {
        const bidModelInfo = this.state.bidModelInfo;
        bidModelInfo.selectedPosition = position;
        this.setState({bidModelInfo: bidModelInfo});
    };

    renderBidRadio = position => {
        if (position.isCurrentPosition) {
            return 'Current';
        }

        if (!position.canUpgrade) return null;

        return (
            <Fragment>
                <Input
                    className="origin_radio"
                    type="radio"
                    name="openBid"
                    onClick={() => this.onPositionSelect(position)}
                />{' '}
                {'Bid Now'}
            </Fragment>
        );
    };

    renderMetroResultRow = (position) => {
        if (position.isCurrentPosition) {
            return (
                <tr key={position.company + position.position}>
                    <th className="align-middle">{position.position}</th>
                    <th className="align-middle">{position.company}</th>
                    <th className="align-middle">{position.newCostDisplay}</th>
                    <th>{this.renderBidRadio(position)}</th>
                </tr>
            )
        } else {
            return (
                <tr key={position.company + position.position}>
                    <td className="align-middle">{position.position}</td>
                    <td className="align-middle">{position.company}</td>
                    <td className="align-middle">{position.newCostDisplay}</td>
                    <td>{this.renderBidRadio(position)}</td>
                </tr>
            )
        }
    };

    renderMetroResults = () => {
        let metroPosition = this.getMetroInformation();
        if (!metroPosition) return null;

        return metroPosition.positions.map(position => (
            this.renderMetroResultRow(position)
        ));
    };

    renderListings = () => (
        <StyledTable striped>
            <thead className="text-center">
            <tr>
                <th>Position</th>
                <th>Company</th>
                <th>Cost</th>
                <th/>
            </tr>
            </thead>
            <tbody className="text-center">{this.renderMetroResults()}</tbody>
        </StyledTable>
    );

    renderConfirmButton = () => {
        let metroPosition = this.getMetroInformation();
        return (<LargeButton
            disabled={this.state.bidModelInfo.selectedPosition === null || (metroPosition && metroPosition.hasPendingPositionChange)}
            text="Submit Bid for Placement"
            margin="true"
            onClick={() => this.onConfirmation()}
        />)
    };

    showBidModel = (companyMetroId) => {
        const bidModelInfo = this.state.bidModelInfo;
        bidModelInfo.companyMetroId = companyMetroId;
        this.setState({bidModalVisible: true, bidModelInfo: bidModelInfo});
    };

    closeBidModel = () => {
        const bidModelInfo = this.state.bidModelInfo;
        bidModelInfo.companyMetroId = null;
        bidModelInfo.selectedPosition = null;
        this.setState({bidModalVisible: false, bidModelInfo: bidModelInfo});
    };

    renderBidModal = () => (
        <Modal isOpen={this.state.bidModalVisible} toggle={this.closeBidModel}>
            <ModalHeader toggle={this.closePayModel} className="d-flex align-items-center pl-5 pr-5">Bid for
                Placement</ModalHeader>
            <ModalBody>
                <Jumbotron>
                    <h4>Adjust Your Company's Rank</h4>
                    <p>
                        Your listing's placement among other operators is determined via
                        longevity by tier (except for zip code searches which are by
                        proximity). If you would like to adjust your placement, use the
                        bid tool below.
                    </p>
                    <ul className="origin_olul">
                        <li>Once submitted, your new rank will display immediately</li>
                        <li>
                            Pay nothing now, we will adjust your invoice or account
                            manually (prorated to the nearest day)
                        </li>
                        <li>
                            Bidding is an open process and rank is not locked in (other
                            companies can outbid you)
                        </li>
                        <li>
                            Use the Higher Tier option to bid past the highest tier and
                            create separation from the other companies posted in the
                            selected metro
                        </li>
                    </ul>
                    <Row>{this.renderListings()}</Row>
                </Jumbotron>
            </ModalBody>
            <ModalFooter>
                {this.renderConfirmButton()}
            </ModalFooter>
        </Modal>
    );

    renderBidPositions = () => {
        const {bidPositionResults} = this.props
        if (!bidPositionResults) {
            return null
        }

        const result = JSON.parse(JSON.stringify(bidPositionResults));

        const bidMetros = this.state.bidMetros;
        if (bidMetros.length > 0) {
            for (let i = 0; i < bidMetros.length; i++) {
                for (let y = 0; y < result.length; y++) {
                    if (result[y].companyMetroId === bidMetros[i].company_metro_id) {
                        result[y].currentPosition = bidMetros[i].new_position;
                        result[y].currentCost = bidMetros[i].new_monthly_cost;
                        break;
                    }
                }
            }
        }

        return result.map(item => {
            const position = item.currentPosition + "/" + item.position.split("/")[1];
            return (
                <tr>
                    <td className="metro_name">{item.metroName}</td>
                    <td>{position}</td>
                    <td className="price"><em>${item.currentCost}/Month</em></td>
                    <td>
                        <div onClick={() => this.showBidModel(item.companyMetroId)} className="button blue">Bid
                            Position
                        </div>
                    </td>
                </tr>
            )
        });
    };

    renderMetroListings = () => {
        const {listings} = this.props;
        if (!listings) {
            return null;
        }

        const result = JSON.parse(JSON.stringify(listings));

        const bidMetros = this.state.bidMetros;
        if (bidMetros.length > 0) {
            for (let i = 0; i < bidMetros.length; i++) {
                for (let y = 0; y < result.length; y++) {
                    if (result[y].companyMetroId === bidMetros[i].company_metro_id) {
                        // result[y].position = bidMetros[i].new_position + "/" + result[y].position.split("/")[1];
                        result[y].costValue = Number(bidMetros[i].new_monthly_cost).toFixed(2);
                        break;
                    }
                }
            }
        }

        return result.map(item => (
            <li>
                <span>{item.metroName}</span>&nbsp;<em>${item.costValue}/Month</em>
            </li>
        ));
    };

    renderMetroListingTotal = () => {
        const {listings} = this.props;
        if (!listings) {
            return null;
        }

        const result = JSON.parse(JSON.stringify(listings));

        const bidMetros = this.state.bidMetros;
        if (bidMetros.length > 0) {
            for (let i = 0; i < bidMetros.length; i++) {
                for (let y = 0; y < result.length; y++) {
                    if (result[y].companyMetroId === bidMetros[i].company_metro_id) {
                        // result[y].position = bidMetros[i].new_position + "/" + result[y].position.split("/")[1];
                        result[y].costValue = Number(bidMetros[i].new_monthly_cost).toFixed(2);
                        break;
                    }
                }
            }
        }
        let cost = 0;
        for (let i = 0; i < result.length; i++) {
            cost = this.accAdd(cost, Number(result[i].costValue));
        }
        return (
            <li>
                <span>Total Billing</span>&nbsp;<em>${cost}/Month</em>
            </li>
        )
    };

    accAdd = (arg1, arg2) => {
        var t1 = 0, t2 = 0, m;
        try {
            t1 = arg1.toString().split(".")[1].length;
        } catch (e) {
            t1 = 0;
        }
        try {
            t2 = arg2.toString().split(".")[1].length;
        } catch (e) {
            t2 = 0;
        }
        m = Math.pow(10, Math.max(t1, t2));
        return (arg1 * m + arg2 * m) / m;
    };

    onSkipButtonClick = () => {
        if ($("#skip-btn").hasClass("loading"))
            return
        $("#skip-btn").addClass("loading");
        skipBidMetros({companyId: this.props.companyId, metros: this.state.bidMetros}).then(response => {
            console.log(response)
            if (response.data.status.code === 0) {
                this.props.history.push("/subscribers/init5");
            } else {
                $("#skip-btn").removeClass("loading");
                alert(response.data.status.msg);
            }
        })
    };

    onSaveNextButtonClick = () => {
        if ($("#save-btn").hasClass("loading"))
            return
        $("#save-btn").addClass("loading");
        submitBidMetros({companyId: this.props.companyId, metros: this.state.bidMetros}).then(response => {
            console.log(response)
            if (response.data.status.code === 0) {
                this.props.history.push("/subscribers/init5");
            } else {
                $("#save-btn").removeClass("loading");
                alert(response.data.status.msg);
            }
        })
    };

    render() {
        return (
            <div className="pt-5">
                <Section>
                    <section className="cus_init">
                        <div className="wrap">
                            <h3 className="section_title">Setup Progress</h3>
                            <ul className="init_steps">
                                <li className="done">
                                    <div className="init_step">
                                        <div className="num">1</div>
                                        <div className="text">Profile Information</div>
                                    </div>
                                </li>
                                <li className="done">
                                    <div className="init_step">
                                        <div className="num">2</div>
                                        <div className="text">Fleet Information</div>
                                    </div>
                                </li>
                                <li className="done">
                                    <div className="init_step">
                                        <div className="num">3</div>
                                        <div className="text">Add Metros</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="init_step">
                                        <div className="num">4</div>
                                        <div className="text">Bid for Placement</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="init_step">
                                        <div className="num">5</div>
                                        <div className="text">Payment</div>
                                    </div>
                                </li>
                            </ul>
                            <div className="content_grid content_lr_73">
                                <div className="content">
                                    <div className="nr_content">
                                        <div className="content_block">
                                            <h3 className="block_title">Bid for Placement</h3>
                                            <div className="table">
                                                <table>
                                                    <thead>
                                                    <tr>
                                                        <th>Metro</th>
                                                        <th>Position</th>
                                                        <th className="price">Cost</th>
                                                        <th>Action</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {this.renderBidPositions()}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="content">
                                    <div className="nr_content step_summary">
                                        <div className="content_block">
                                            <h3 className="block_title">Metro Listing</h3>
                                            <ul className="ss_list">
                                                {this.renderMetroListings()}
                                            </ul>
                                            <ul className="ss_total">
                                                {this.renderMetroListingTotal()}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="buttons">
                            <div id="skip-btn" onClick={() => this.onSkipButtonClick()} className="button line large">
                                <span>Skip</span></div>
                            <div id="save-btn" onClick={() => this.onSaveNextButtonClick()} className="button large">
                                <span>Save and Next</span>
                            </div>
                        </div>
                    </section>
                </Section>
                <Footer/>
                {this.renderBidModal()}
            </div>
        );
    }
}

const mapStateToProps = ({login, init4}) => ({
    companyId: login.companyId,
    bidPositionResults: init4.results,
    listings: init4.listings,
    totals: init4.totals,
    bidConfirmation: init4.bidConfirmation
});
export default connect(mapStateToProps, {
    clearBidConfirmation,
    fetchBidConfirmation,
    fetchBidPositions,
    fetchListings,
    submitBidConfirmation
})(Init4);
