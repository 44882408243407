import withAuthentication, {ROUTE_TYPES} from '../withAuthentication';
import withTracker from '.././withTracker';
import Init5 from '../../containers/subscriber/setup/init5';

const subscriberInit5 = {
    path: '/subscribers/init5',
    component: withTracker(withAuthentication(Init5, ROUTE_TYPES.SUBSCRIBER))
};

export default subscriberInit5;
