export const formatPhone = value => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');

  if (onlyNums.length === 10) {
    return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(
      6,
      10
    )}`;
  } else if (onlyNums.length === 11) {
    return `${onlyNums.slice(0, 1)} (${onlyNums.slice(1, 4)}) ${onlyNums.slice(
      4,
      7
    )}-${onlyNums.slice(7, 11)}`;
  } else {
    return onlyNums;
  }
};

export const getFileNameFromURL = url =>
  url.substr(url.lastIndexOf('/') + 1, url.length);

export const camelToUnderscore = key =>
  key.replace(/([A-Z])/g, '_$1').toLowerCase();

export const formatListingEmailsRequest = emails => {
  const formattedEmails = emails.reduce((acc, email, index) => {
    const formattedEmail = {};

    for (var key in email) {
      formattedEmail[camelToUnderscore(key)] = email[key];
    }

    acc[index] = formattedEmail;
    return acc;
  }, []);

  return formattedEmails;
};

export const shorten = (str, len, ellipsis = '…') => {
  if (str.length <= len) return str;

  var result = str.substr(0, len - 1);
  result = result.substr(0, Math.min(result.length, result.lastIndexOf(' ')));

  return result + ellipsis;
};

export const staticWebLink = (page) => {
  return `${process.env.REACT_APP_BUSRATES_STATIC_URL}/${page}`;
}
