import { reportsResults } from '../../services/subscriber/reportsService';

export const fetchReports = (companyId, dates) => async (
  dispatch,
  getState
) => {
  dispatch({ type: 'SUBSCRIBER_REPORTS_REQUEST' });
  try {
    const { login: { companyId } } = getState();
    const response = await reportsResults(companyId, dates);
    dispatch({ type: 'SUBSCRIBER_REPORTS_SUCCESS', payload: response });
  } catch (e) {
    dispatch({ type: 'SUBSCRIBER_REPORTS_FAIL' });
  }
};