import API from '../baseService';

export const companyInformation = companyId =>
  API.get(`/subscribers/${companyId}/companymanagement`).then(
    response => response.data
  );

export const profileManagement = (values, companyId) =>
  API.post('/subscribers/companymanagement/edit/profile', {
    company_id: companyId,
    address_street: values.street,
    address_city: values.city,
    address_state: values.state,
    address_zip: values.zip,
    contact_name: values.contactName,
    contact_phone: values.contactPhone,
    contact_extension: values.contactPhoneExtension,
    contact_email: values.contactEmail,
    website: values.website,
    description: values.description,
    facebook_link: values.facebookLink,
    instagram_link: values.instagramLink,
    is_tour_operator: values.isTourOperator,
    has_receptive_services: values.hasReceptiveServices,
    twitter_link: values.twitterLink,
    youtube_link: values.youtubeLink,
    save_to_all_listings: values.saveToAllListings,
    hours_of_operation_mon: values.hoursOfOperationMon,
    hours_of_operation_sat: values.hoursOfOperationSat,
    hours_of_operation_sum: values.hoursOfOperationSum
  }).then(response => response.data);

export const associations = (values, companyId) =>
  API.post('/subscribers/companymanagement/edit/associations', {
    company_id: companyId,
    selected_associations: values.selectedAssociations
  }).then(response => response.data);

/**
 * 更新包车服务
 * @param {*} values 
 * @param {*} companyId 
 * @returns 
 */
export const API_UPDATE_COMPANY_SERVICES = (values, companyId) =>
  API.post('/subscribers/companymanagement/edit/charter-bus-services', {
    companyId: companyId,
    charterBusServices: values
  }).then(response => response.data);

/**
 * 更新特色服务
 * @param {*} values 
 * @param {*} companyId 
 * @returns 
 */
export const API_UPDATE_COMPANY_HIGHLIGHTS = (values, companyId) =>
  API.post('/subscribers/companymanagement/edit/business-highlights', {
    companyId: companyId,
    businessHighlights: values
  }).then(response => response.data);

export const addPhoto = (companyId, image) =>
  API.post('/subscribers/companymanagement/edit/addphoto', {
    company_id: companyId,
    extension: 'jpeg',
    photo: image.replace('data:image/jpeg;base64,', '')
  }).then(response => response.data);

export const addAdditionalPhotoService = (companyId, image) =>
  API.post('/subscribers/companymanagement/edit/addphoto', {
    company_id: companyId,
    extension: 'jpeg',
    photo: image.replace('data:image/jpeg;base64,', ''),
    is_additional: true
  }).then(response => response.data);

export const deleteAdditionalPhoto = (image, companyId) =>
  API.post('/subscribers/companymanagement/edit/removephoto', {
    company_id: companyId,
    file_name: image.fileName,
    is_additional: true
  }).then(response => response.data);

export const updatePhoto = ({ companyId, initialPhotoFilename, image }) =>
  API.post('/subscribers/companymanagement/edit/replacephoto', {
    company_id: companyId,
    extension: 'jpeg',
    file_name: initialPhotoFilename,
    photo: image.replace('data:image/jpeg;base64,', '')
  }).then(response => response.data);

export const formsOfPayment = (values, companyId) =>
  API.post('/subscribers/companymanagement/edit/payments', {
    company_id: companyId,
    allows_amex: values.allowsAmex,
    allows_discover: values.allowsDiscover,
    allows_mastercard: values.allowsMastercard,
    allows_visa: values.allowsVisa,
    allows_paypal: values.allowsPaypal,
    allows_check: values.allowsCheck
  }).then(response => response.data);

export const authority = (values, companyId) =>
  API.post('/subscribers/companymanagement/edit/authority', {
    company_id: companyId,
    dot_number: values.dotNumber
  }).then(response => response.data);

export const companyLogin = (values, companyId) => {
  const body =
    values.newPassword && values.newPassword.length
      ? {
          company_id: companyId,
          email: values.email,
          current_password: values.currentPassword,
          new_password: values.newPassword
        }
      : {
          company_id: companyId,
          email: values.email
        };

  return API.post('/subscribers/companymanagement/edit/login', body).then(
    response => response.data
  );
};
