import {
  biddingPositionsResults,
  createBidCart,
  getBidCart,
  submitBidCart
} from '../../services/subscriber/bidService';
import { createBidMetro } from './helpers';
import { BID_TYPES } from '../../reducers/subscriber/bid';

export const fetchBidPositions = companyId => async dispatch => {
  dispatch({
    type: BID_TYPES.SUBSCRIBER_BIDDING_POSITIONS_REQUEST
  });

  try {
    const response = await biddingPositionsResults(companyId);
    const { success, errorMessage } = response.data;
    if (!success) {
      dispatch({
        type: BID_TYPES.SUBSCRIBER_BIDDING_POSITIONS_FAILURE,
        payload: {
          error: errorMessage
        }
      });

      return;
    }

    const { results } = response.data;

    dispatch({
      type: BID_TYPES.SUBSCRIBER_BIDDING_POSITIONS_SUCCESS,
      payload: {
        results
      }
    });
  } catch (error) {
    dispatch({
      type: BID_TYPES.SUBSCRIBER_BIDDING_POSITIONS_FAILURE,
      payload: error
    });
  }
};

export const fetchBidConfirmation = ({
  companyMetroId,
  metros,
  selectedPosition
}) => async (dispatch, getState) => {
  dispatch({ type: BID_TYPES.BID_CONFIRMATION_REQUEST });
  try {
    const { login } = getState();
    const { companyId } = login;

    const metro = createBidMetro({ metros, companyMetroId, selectedPosition });

    const bidCartGuid = await createBidCart({
      companyId,
      metro
    });
    if (!bidCartGuid || !bidCartGuid.success) throw Error;

    const bidCart = await getBidCart({ companyId, guid: bidCartGuid.cartGuid });
    if (!bidCart || !bidCart.success) throw Error;

    dispatch({
      type: BID_TYPES.BID_CONFIRMATION_SUCCESS,
      payload: { ...bidCart, guid: bidCartGuid.cartGuid }
    });
  } catch (e) {
    dispatch({ type: BID_TYPES.BID_CONFIRMATION_FAILURE });
  }
};

export const submitBidConfirmation = callback => async (dispatch, getState) => {
  dispatch({ type: BID_TYPES.SUBMIT_BID_CONFIRMATION_REQUEST });
  let errorMessage = "";
  try {
    const { subscriberBidPositions, login } = getState();
    const { companyId } = login;
    const { guid } = subscriberBidPositions.bidConfirmation;

    const response = await submitBidCart({ companyId, guid });
    if (!response || !response.success) {
      errorMessage = response.errorMessage;
      throw Error;
    }

    dispatch({ type: BID_TYPES.SUBMIT_BID_CONFIRMATION_SUCCESS });
    callback();
  } catch (e) {
    if(errorMessage == "") {
      errorMessage = 'There was a problem saving your bid.';
    }
    dispatch({
      type: BID_TYPES.SUBMIT_BID_CONFIRMATION_FAILURE,
      payload: errorMessage
    });
  }
};

export const clearBidConfirmation = () => ({
  type: BID_TYPES.CLEAR_BID_CONFIRMATION
});
