import React from 'react';
import glamorous from 'glamorous';
import { Button } from 'reactstrap';

const StyledButton = glamorous(Button)(
  {
    borderRadius: 0,
    fontSize: 18,
    height: 58
  },
  ({ margin }) => ({
    margin: margin === 'true' ? '20px' : null
  })
);

const LargeButton = props => (
  <StyledButton
    id={props.id || null}
    className="text-white w-100 font-weight-bold pr-4 pl-4"
    disabled={props.disabled}
    onClick={props.onClick || null}
    margin={props.margin}
  >
    {props.text}
  </StyledButton>
);

export default LargeButton;
