import withAuthentication, { ROUTE_TYPES } from '../withAuthentication';
import withTracker from '.././withTracker';
import Listings from '../../containers/subscriber/Listings';

const subscriberListings = {
  path: '/subscribers/listings',
  component: withTracker(withAuthentication(Listings, ROUTE_TYPES.SUBSCRIBER))
};

export default subscriberListings;
