import React, { Component } from 'react';
import { connect } from 'react-redux';
import glamorous from 'glamorous';

import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';

import {
  Container,
  Footer
} from '../components'

import {
  closeSubscriberLoginModal,
  updateProperty,
  login,
  forgotPassword,
  createAccount
} from '../actions/subscriber/login';
import InfoModal from './subscriber/ArrearsInformationModal'

const StyledLinkButton = glamorous(Button)({
  fontSize: 16,
  fontWeight: 400,
  color: '#e69a2b'
});

const ErrorText = glamorous.p({
  fontSize: 16,
  fontWeight: 400,
  color: 'red'
});

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {

      arrearsId: ""
    };
  }

  onButtonGo = () => {
    this.props.history.push("/charge_arrears/" + this.state.arrearsId)
  };

  toggleArrearsModal = modal =>
      this.setState(prevState => ({
        [modal]: !prevState[modal]
      }));

  onForgotPasswordButtonClicked = () => {
    this.props.forgotPassword(this.props.history);
  };

  onCreateAccountButtonClicked = () => {
    this.props.history.push('/join');
  };

  onLoginButtonClicked = () => {
    this.props.login(this.props.history, this);
  };

  render() {
    return (
      <React.Fragment>
        <Container>
          <Row>
            <Col xs="12" md={{ size: 6, offset: 3}}>
              <Row>
                <Col className="mb-3 text-center">
                  <h2>Login to BusRates Admin Portal</h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form>
                    <FormGroup row>
                      <Label for="email" sm={2}>
                        UserName
                      </Label>
                      <Col sm={10}>
                        <Input
                          type="email"
                          name="email"
                          id="email"
                          placeholder={'Email / UserName'}
                          value={this.props.email}
                          onChange={e =>
                            this.props.updateProperty('email', e.target.value)
                          }
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="password" sm={2}>
                        Password
                      </Label>
                      <Col sm={10}>
                        <Input
                          type="password"
                          name="password"
                          id="password"
                          value={this.props.password}
                          onChange={e =>
                            this.props.updateProperty('password', e.target.value)
                          }
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm={2} />
                      <Col sm={10}>
                        <Label check>
                          <Input
                            type="checkbox"
                            name="rememberMe"
                            checked={this.props.rememberMe}
                            onChange={e =>
                              this.props.updateProperty('rememberMe', e.target.checked)
                            }
                          />{' '}
                          Remember Me
                        </Label>
                      </Col>
                    </FormGroup>
                  </Form>
                  <div className="text-center">
                    <ErrorText>{this.props.loginError}</ErrorText>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button color="primary" className="float-right" onClick={() => this.onLoginButtonClicked()}>
                    Login
                  </Button>{' '}
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-center">
                    <StyledLinkButton
                      color="link"
                      onClick={() => this.onForgotPasswordButtonClicked()}
                    >
                      Forgot your password?
                    </StyledLinkButton>|
                    <StyledLinkButton
                      color="link"
                      onClick={() => this.onCreateAccountButtonClicked()}
                    >
                      Don't have an account?
                    </StyledLinkButton>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Container className="pt-3 pb-4">
          <InfoModal
              title='Message'
              content={this.state.content}
              onButtonSubmit={() => this.onButtonGo()}
              values={this.props}
              errorMessage={this.props.errorMessage}
              modalRef={node => (this.loginInformationModal = node)}
              modalVisible={this.state.loginInformationModalVisible}
              toggleModal={() => this.toggleArrearsModal('loginInformationModalVisible')}/>
        </Container>
        <Footer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  login: {
    companyId,
    companyName,
    contactName,
    loggedIn,
    loginError,
    rememberMe,
    subscriberLoginModalVisible
  }
}) => ({
  companyId,
  companyName,
  contactName,
  loggedIn,
  loginError,
  rememberMe,
  subscriberLoginModalVisible
});

export default connect(mapStateToProps, {
  closeSubscriberLoginModal,
  createAccount,
  forgotPassword,
  login,
  updateProperty
})(Home);
