export const INITIAL_STATE = {
  additionalEmails: [],
  amenitiesOptions: [],
  buses: [],
  photos: [],
  error: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SUBSCRIBER_LISTING_PROPERTY_UPDATED':
      return { ...state, [action.payload.key]: action.payload.value };
    case 'SUBSCRIBER_LISTING_REQUEST':
      return { ...state };
    case 'SUBSCRIBER_LISTING_REQUEST_SUCCESS':
      return { ...state, ...action.payload };
    case 'SUBSCRIBER_LISTING_REQUEST_FAILURE':
      return { ...state, ...action.payload };
    case 'SUBSCRIBER_LISTING_UPDATE_COMPANY_INFORMATION_REQUEST':
      return { ...state, error: null };
    case 'SUBSCRIBER_LISTING_UPDATE_COMPANY_INFORMATION_REQUEST_SUCCESS':
      return { ...state, ...action.payload };
    case 'SUBSCRIBER_LISTING_UPDATE_COMPANY_INFORMATION_REQUEST_FAILURE':
      return {
        ...state,
        error: action.payload.error
      };
    case 'SUBSCRIBER_LISTING_UPDATE_CONTACT_REQUEST':
      return { ...state, error: null };
    case 'SUBSCRIBER_LISTING_UPDATE_CONTACT_REQUEST_SUCCESS':
      return { ...state, ...action.payload };
    case 'SUBSCRIBER_LISTING_UPDATE_CONTACT_REQUEST_FAILURE':
      return {
        ...state,
        error: action.payload.error
      };
    case 'SUBSCRIBER_LISTING_UPDATE_EMAILS_REQUEST':
      return { ...state };
    case 'SUBSCRIBER_LISTING_UPDATE_EMAILS_REQUEST_SUCCESS':
      return { ...state, additionalEmails: action.payload.additionalEmails };
    case 'SUBSCRIBER_LISTING_UPDATE_EMAILS_REQUEST_FAILURE':
      return {
        ...state,
        error: action.payload.error
      };
    case 'UPDATE_FLEET_REQUEST':
      return { ...state, error: null };
    case 'UPDATE_FLEET_SUCCESS':
      return { ...state, buses: action.payload.buses };
    case 'UPDATE_FLEET_FAIL':
      return { ...state, error: action.payload };
    case 'DELETE_FLEET_REQUEST':
      return { ...state, error: null };
    case 'DELETE_FLEET_SUCCESS':
      return {
        ...state,
        buses: state.buses.filter(bus => bus.listingId !== action.payload)
      };
    case 'DELETE_FLEET_FAIL':
      return { ...state, error: action.payload };
    default:
      return state;
  }
};
