import React from 'react';
import {
  Alert,
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import { Formik } from 'formik';
import Yup from 'yup';
import { Input, InputCheckboxGroup, InputSelect } from '../../../components';

const EditFleetModal = ({
  amenitiesOptions,
  busTypeOptions,
  errorMessage,
  modalRef,
  modalVisible,
  onButtonSubmit,
  onFormSubmit,
  toggleModal,
  values,
    saveCssName
}) => (
  <Modal isOpen={modalVisible} toggle={toggleModal}>
    <ModalHeader
      className="d-flex align-items-center pl-5 pr-5"
      toggle={toggleModal}
    >
      Edit Fleet Information
    </ModalHeader>
    <ModalBody className="pl-5 pr-5">
      <Formik
        ref={modalRef}
        initialValues={{
          quantity: values.quantity || '',
          seatCount: values.seatCount || '',
          busTypeId: values.busTypeId || busTypeOptions[0]['key'],
          year: values.year || '',
          amenities: values.amenities || [],
          listingId: values.listingId || null,
          transferRate: values.transferRate || '',
          fiveHourRate: values.fiveHourRate || '',
          dayRate: values.dayRate || '',
          mileRate: values.mileRate || ''
        }}
        validationSchema={validationSchema}
        onSubmit={onFormSubmit}
        render={formProps => (
          <Form onSubmit={formProps.handleSubmit}>
            {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
            <Row>
              <Input
                className="col-12 col-md-6"
                name="quantity"
                label="Quantity"
                type="number"
                required
                {...formProps}
              />
              <Input
                className="col-12 col-md-6"
                name="seatCount"
                label="Seat Count"
                type="number"
                required
                {...formProps}
              />
            </Row>
            <Row>
              <InputSelect
                className="col-12 col-md-6"
                label="Bus Type"
                name="busTypeId"
                optionKey="key"
                optionLabel="value"
                options={busTypeOptions}
                optionValue="key"
                required
                {...formProps}
              />
              <Input
                className="col-12 col-md-6"
                name="year"
                label="Year"
                required
                {...formProps}
              />
            </Row>
            <Row>
              <InputCheckboxGroup
                className="col-md-12"
                label="Amenities"
                name="amenities"
                optionKey="amenityId"
                options={amenitiesOptions}
                {...formProps}
              />
            </Row>
            <Row>
              <Input
                className="col-12 col-md-6"
                mask="99999"
                name="transferRate"
                label="Transfer Rate"
                {...formProps}
              />
              <Input
                className="col-12 col-md-6"
                mask="99999"
                name="fiveHourRate"
                label="Five Hour Rate"
                {...formProps}
              />
            </Row>
            <Row>
              <Input
                className="col-12 col-md-6"
                mask="9999"
                name="dayRate"
                label="Day Rate"
                {...formProps}
              />
              <Input
                className="col-12 col-md-6"
                mask="******"
                name="mileRate"
                label="Mile Rate"
                {...formProps}
              />
            </Row>
          </Form>
        )}
      />
    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={toggleModal}>
        Cancel
      </Button>{' '}
      <Button color="secondary" className={saveCssName?saveCssName:"text-white"} onClick={onButtonSubmit}>
        Save
      </Button>{' '}
    </ModalFooter>
  </Modal>
);

const validationSchema = Yup.object().shape({
  quantity: Yup.number().required('Quantity is required.')
      .min(1,'Quantity must be greater than 0')
      .max(999,'Quantity must be integer from 1 to 999.'),
  seatCount: Yup.number().required('Seat count is required.')
      .min(1,'Seat Count must be greater than 0')
      .max(200,'Seat Count must be integer from 1 to 200.'),
  busTypeId: Yup.string().required('Bus type is required.'),
  year: Yup.string().required('Year is required.')
});

export default EditFleetModal;
