import React, { Component } from 'react';
import { connect } from 'react-redux';
import glamorous from 'glamorous';
import Yup from 'yup';
import { Formik } from 'formik';
import {
  Alert,
  Button,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import { Footer, Input, RatingStars, Section } from '../../components';
import Menu from '../../components/subscriber/Menu';
import {
  fetchReviews,
  sendReviewReply
} from '../../actions/subscriber/reviews';

const StyledText = glamorous.p(
  {
    marginBottom: '5px'
  },
  ({ isNew, smallText }) => ({
    fontSize: smallText ? '12px' : '14px',
    fontWeight: isNew ? 'bold' : null
  })
);

const StyledSubtext = glamorous.p(
  {
    fontStyle: 'italic',
    marginBottom: '5px'
  },
  ({ isNew, smallText }) => ({
    fontSize: smallText ? '10px' : '12px',
    fontWeight: isNew ? 'bold' : null
  })
);

const StyledModalMessageText = glamorous.p({
  marginTop: '5px',
  marginBottom: '5px'
});

const StyledModalMessageDateTime = glamorous.p({
  position: 'absolute',
  top: 10,
  right: 10,
  fontStyle: 'italic'
});

class SubscriberReviews extends Component {
  state = {
    review: {},
    showReplyModal: false
  };

  componentDidMount() {
    this.props.fetchReviews();
  }

  onSubmitButton = () => {
    this.replyForm.submitForm();
  };

  submitReplyForm = values => {
    const callback = this.toggleReplyModal;
    const { review } = this.state;
    this.props.sendReviewReply({ callback, review, values });
  };

  toggleReplyModal = () =>
    this.setState(prevState => ({ showReplyModal: !prevState.showReplyModal }));

  renderReviews() {
    return this.props.reviews.map((review, index, arr) => {
      const bottomLine = index !== arr.length - 1 ? <hr /> : null;
      const button = !review.isResolved ? (
        <Button
          className="text-white mt-2"
          onClick={() => this.setState({ showReplyModal: true, review })}
        >
          Reply
        </Button>
      ) : null;

      return (
        <div className="mb-3" key={review.companyRatingId}>
          <div>
            <h5 className="text-primary mb-0">{review.name}</h5>
          </div>
          <div className="mb-2">
            <RatingStars averageRating={review.rating} />
          </div>
          <div className="mb-2">{review.title}</div>
          <div>{review.comment}</div>
          <div className="pt-2">
            <StyledSubtext>{review.trip}</StyledSubtext>
          </div>
          {button}
          {bottomLine}
        </div>
      );
    });
  }

  renderReplyModal() {
    const { review, showReplyModal } = this.state;
    const { errorMessage } = this.props;
    return (
      <Modal isOpen={showReplyModal} toggle={this.toggleReplyModal}>
        <ModalHeader
          className="d-flex align-items-center pl-5 pr-5"
          toggle={this.toggleReplyModal}
        >
          Reply to Review
        </ModalHeader>
        <ModalBody className="pl-5 pr-5">
          <Alert color="primary">
            <StyledText>{review.name}</StyledText>
            <StyledSubtext>{review.trip}</StyledSubtext>
            <RatingStars averageRating={review.rating} />
            <StyledModalMessageText>{review.trip}</StyledModalMessageText>
            <StyledText>{review.comment}</StyledText>
            <StyledModalMessageDateTime>
              {review.date}
            </StyledModalMessageDateTime>
          </Alert>
          <Formik
            ref={node => (this.replyForm = node)}
            validationSchema={validationSchema}
            onSubmit={this.submitReplyForm}
            render={formProps => (
              <Form onSubmit={formProps.handleSubmit}>
                {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                <Input
                  name="response"
                  label="Response"
                  rows={3}
                  type="textarea"
                  {...formProps}
                />
              </Form>
            )}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.toggleReplyModal}>
            Cancel
          </Button>{' '}
          <Button
            color="secondary"
            className="text-white"
            onClick={this.onSubmitButton}
          >
            Save
          </Button>{' '}
        </ModalFooter>
      </Modal>
    );
  }

  render() {
    return (
      <div className="pt-5">
        <Section>
          <Row>
            <Col xs="12" className="text-center">
              <h1>Reviews</h1>
            </Col>
          </Row>
          <Row>
            <Menu history={this.props.history} />
            <Col xs="9">{this.renderReviews()}</Col>
          </Row>
        </Section>
        <Footer />
        {this.renderReplyModal()}
      </div>
    );
  }
}

const validationSchema = Yup.object().shape({
  response: Yup.string().required('Response is required')
});

const mapStateToProps = ({ subscriberReviews }) => ({
  errorMessage: subscriberReviews.errorMessage,
  reviews: subscriberReviews.reviews
});

export default connect(mapStateToProps, { fetchReviews, sendReviewReply })(
  SubscriberReviews
);
