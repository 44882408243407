import { loginResults ,getSetup} from '../../services/loginService';
import { getFreeTryOutStatus, getUpgradePremium, api_payBill } from '../../services/subscriber/checkFreeTryOut'

export const openSubscriberLoginModal = () => dispatch => {
  dispatch({
    type: 'LOGIN_MODAL_OPENED'
  });
};

export const closeSubscriberLoginModal = () => dispatch => {
  dispatch({
    type: 'LOGIN_MODAL_CLOSED'
  });
};

export const updateProperty = (key, value) => dispatch => {
  dispatch({
    type: 'LOGIN_PROPERTY_UPDATED',
    payload: { key, value }
  });
};

export const login = (history, loginThis) => async (dispatch, getState) => {
  dispatch({
    type: 'LOGIN_REQUEST'
  });

  const { login } = getState();
  const { email, password } = login;

  try {

    if (email === 'info@busrates.com' && password === 'BusRates123!!') {
      dispatch({
        type: 'LOGIN_MODAL_CLOSED'
      });

      let payload = {
        loginType: 'admin',
        loggedIn: true
      }

      dispatch({
        type: 'LOGIN_SUCCESS',
        payload: payload
      });

      history.push('/admin/reports');
      return;
    }

    const response = await loginResults({
      email: email,
      password: password
    });

    if (!response.success) throw response.errorMessage;

    dispatch({
      type: 'LOGIN_SUCCESS',
      payload: response
    });

/*    dispatch({
      type: 'LOGIN_MODAL_CLOSED'
    });*/

    if (response.loginType === 'subscriber') {

      if(response.arrearsState) {

        loginThis.setState({arrearsId: response.arrearsId});
        loginThis.toggleArrearsModal('loginInformationModalVisible', dispatch)

      } else {

        dispatch({
          type: 'LOGIN_MODAL_CLOSED'
        });

        let companyId = response.subscriber.companyId
        const result = await getSetup(companyId);

        let index = result.result;

        let url

        switch (index) {
          case 1:
            url ="/subscribers/init";
            break
          case 2:
            url ="/subscribers/init2";
            break
          case 3:
            url ="/subscribers/init3";
            break
          case 4:
            url ="/subscribers/init4"
                break
          case 5:
            url ="/subscribers/init5"
            break
          default:
              url = '/subscribers/listings';
        }

        history.push(url);
      }

    } else {
      dispatch({
        type: 'LOGIN_MODAL_CLOSED'
      });
      history.push('/users/quote-history');
    }
  } catch (e) {
    const loginError =
      typeof e === 'string' ? e : 'Email or Password is incorrect';
    dispatch({
      type: 'LOGIN_FAILURE',
      payload: {
        loginError
      }
    });
  }
};

export const logout = history => dispatch => {
  dispatch({
    type: 'LOGOUT'
  });

  history.push('/');
};

export const forgotPassword = history => dispatch => {
  dispatch({
    type: 'LOGIN_MODAL_CLOSED'
  });

  history.push('/subscribers/forgot-password');
};

export const createAccount = () => ({
  type: 'LOGIN_MODAL_CLOSED_CREATE_ACCOUNT_OPEN'
});

export const fetchSubscriber = () => dispatch => {
  dispatch({
    type: 'SUBSCRIBER_FETCH'
  });
};

export const checkFreeTryOut = () => async (dispatch, getState) => {
  try {
      const {login: {companyId}} = getState();

      const response = await getFreeTryOutStatus(companyId);

      // if (!response.success) throw response.errorMessage;
      dispatch({
        type: 'CHECK_FREE_TRYOUT',
        payload: {isFreeTrial: response.data.result.freeTrial}
      });
      dispatch({
        type: 'CHECK_UPGRADE_PREMIUM',
        payload: {isUpgradePremium: response.data.result.upgradePremium}
      });
  } catch (error) {
      dispatch({
          type: 'CHECK_FREE_TRYOUT',
          payload: error
      });
  }
};

export const getUpgradePremiumList = () => async (dispatch, getState) => {
  dispatch({
    type: 'SUBMIT_LOADING',
    payload: {submitLoading: true}
  });
  dispatch({
    type: 'UPGRADE_PREMIUM_LIST',
    payload: {upgradePremium: {
      bastNewPriceTotal: "",
      baseCurrentPriceTotal: "",
      haveBankCardAdded: true,
      listingList: []
    }}
  });
  try {
      const {login: {companyId}} = getState();
      const response = await getUpgradePremium(companyId);
      const payBillStateMsg = response.data.status.msg
      dispatch({
        type: 'UPGRADE_PREMIUM_LIST',
        payload: {upgradePremium: response.data.result}
      });
      dispatch({
        type: 'SUBMIT_LOADING',
        payload: {submitLoading: false}
      });
      if(response.data.status.code !== 0){
        alert(payBillStateMsg)
        dispatch({
          type: 'PAY_BILL_STATE_MSG',
          payload: {payBillStateMsg: payBillStateMsg}
        });
      }
  } catch (error) {
      dispatch({
          type: 'UPGRADE_PREMIUM_LIST',
          payload: error
      });
      dispatch({
        type: 'SUBMIT_LOADING',
        payload: {submitLoading: false}
      });
  }
};

export const payBillHandel = () => async (dispatch, getState) => {
  dispatch({
    type: 'SUBMIT_LOADING',
    payload: {submitLoading: true}
  });
  try {
      const {login: {companyId}} = getState();
      const response = await api_payBill(companyId);
     
      
      dispatch({
        type: 'SUBMIT_LOADING',
        payload: {submitLoading: false}
      });
      const payBillState = response.data.status.code !== 0
      const payBillStateMsg = response.data.status.msg
      dispatch({
        type: 'PAY_BILL_STATE',
        payload: {payBillState: !payBillState}
      });
      dispatch({
        type: 'PAY_BILL_STATE_MSG',
        payload: {payBillStateMsg: payBillStateMsg}
      });
      if(response.data.status.code !== 0){
        alert(payBillStateMsg)
      }
  } catch (error) {
      dispatch({
        type: 'SUBMIT_LOADING',
        payload: {submitLoading: false}
      });
  }
};

