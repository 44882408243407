import withAuthentication, { ROUTE_TYPES } from '../withAuthentication';
import withTracker from '.././withTracker';
import SubscriberReviews from '../../containers/subscriber/SubscriberReviews';

const subscriberReviews = {
  path: '/subscribers/reviews',
  component: withTracker(
    withAuthentication(SubscriberReviews, ROUTE_TYPES.SUBSCRIBER)
  )
};

export default subscriberReviews;
