import React, { Component } from 'react';
import glamorous from 'glamorous';
import { connect } from 'react-redux';
import { Alert, Col, Row } from 'reactstrap';

import { fetchSubscriber } from '../../actions/subscriber/login';
import { Footer, Section } from '../../components';
import Menu from '../../components/subscriber/Menu';

const CapitalizedText = glamorous.span({
  textTransform: 'capitalize'
});

class MyAccount extends Component {
  componentWillMount = () => {
    this.props.fetchSubscriber();
  };

  render() {
    return (
      <div className="pt-5">
        <Section>
          <Row>
            <Col xs="12" className="text-center">
              <h1>Account Management</h1>
              <p>
                Welcome,{' '}
                <CapitalizedText>{this.props.contactName}!</CapitalizedText>
              </p>
            </Col>
          </Row>
          <Row>
            <Menu history={this.props.history} />
            <Col xs="9">
              <Alert color="info">
                <p>
                  Not all functionality is currently complete. You can still
                  access the old site at{' '}
                  <a href="http://old.busrates.com/">http://old.busrates.com</a>{' '}
                  if you need to access information that is not yet available in
                  the new portal.
                </p>
              </Alert>
            </Col>
          </Row>
        </Section>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = ({ login: { contactName } }) => ({
  contactName
});

export default connect(mapStateToProps, {
  fetchSubscriber
})(MyAccount);
