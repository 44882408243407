import withAuthentication, { ROUTE_TYPES } from '../withAuthentication';
import withTracker from '.././withTracker';
import Crash from "../../containers/subscriber/Crash";

const subscriberCrashListings = {
  path: '/subscribers/crash',
  component: withTracker(withAuthentication(Crash, ROUTE_TYPES.SUBSCRIBER))
};

export default subscriberCrashListings;
