import React, { Component, Fragment } from 'react';
import glamorous, { P } from 'glamorous';
import { uniqueId, omit } from 'lodash';
import { connect } from 'react-redux';
import {
  Button,
  Collapse,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem
} from 'reactstrap';
import { staticWebLink } from '../utils/formatUtils';
import { logout, openSubscriberLoginModal, checkFreeTryOut, payBillHandel } from '../actions/subscriber/login';
import { BusRatesLogo } from '../assets/img';
import FreeTrialModal from './subscriber/Company/FreeTrialModal'
import UpgradePremiumModal from './subscriber/Company/UpgradePremiumModal'
import PayBillSuccessModal from './subscriber/Company/PayBillSuccessModal'

const StyledNavItem = glamorous(NavItem)({
  cursor: 'pointer',
  paddingLeft: '1.3rem !important'
});

const StyledOutlineButton = glamorous(Button)({
  borderColor: 'rgba(255, 255, 255, 0.3)',
  color: '#fff',
  ':hover': {
    backgroundColor: '#fff',
    borderColor: 'rgba(255, 255, 255, 0.3)',
    color: '#154988'
  }
});

class Navigation extends Component {
  state = {
    collapseOpen: false,
    dropdownOpen: false,
    isFreeTrial: true,
    freeTrialModalVisible: false,
    upgradePremiumModalVisible: false,
    payBillSuccessModalVisible: false
  };


  componentWillMount = () => {
    // this.props.fetchListings();
    this.checkFreeTrialStatus();
    // console.log(this.props, '--props---')
  };

  toggleModal = modal =>
    this.setState(prevState => ({
      [modal]: !prevState[modal]
    }));

  payBillSuccessModalHandler = () => {
    this.setState({
      payBillSuccessModalVisible: false
    });
    window.location.href="/subscribers/listings";
  };

  freeTrialModalHandler = () => {
    this.setState({
      freeTrialModalVisible: !this.state.freeTrialModalVisible
    });
  };

  upgradePremiumlModalHandler = () => {
    this.setState({
      upgradePremiumModalVisible: !this.state.upgradePremiumModalVisible
    });
    this.freeTrialModalHandler()
  };

  /**跳转至信用卡配置页面 */
  addCreditCardHabdler = () => {
    this.setState({
      freeTrialModalVisible: false,
      upgradePremiumModalVisible: false
    });
    this.props.history.push('/subscribers/payments')
  };

  /** 升级 */
  onButtonPayBillHandel = () => {
    this.props.payBillHandel().then((result) => {
      if(this.props.payBillState){
        this.setState({
          payBillSuccessModalVisible: true,
          upgradePremiumModalVisible: false
        })
      }
    }).catch((err) => {
      
    });
  };

  /** 查询是否为免费用户 */
  checkFreeTrialStatus = () => {
    this.props.checkFreeTryOut()
  };

  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };

  toggleDiscover = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  };

  onJoinClick = event => {
    this.props.history.push('/join');
  };

  onMyAccountClick = () => {
    if (this.props.loginType === 'subscriber') {
      this.props.history.push('/subscribers/listings');
    }
    else if (this.props.loginType === 'admin') {
      this.props.history.push('/admin/reports');
    }
    else {
      this.props.history.push('/users/quote-history');
    }
  };

  renderLoggedInMenu = () => {
    const { loggedIn } = this.props;
    if (!loggedIn) return null;

    return (
      <Fragment>
        <StyledNavItem
          key={uniqueId()}
          style={{ textAlign: 'center' }}
        >
          <Button
            color="link"
            id="my-account"
            className="text-white pointer"
            onClick={this.onMyAccountClick}
            style={{ lineHeight: '1.75em', marginTop: '.05em' }}
          >
            My Account
          </Button>
          <hr
            className="menu-divider hide-above-tab hide-on-tab"
            style={{ marginBottom: '1em' }}
          />
        </StyledNavItem>
        <StyledNavItem
          key={uniqueId()}
          style={{ textAlign: 'center' }}
        >
          <StyledOutlineButton
            className="pl-4 pr-4 pointer"
            outline
            onClick={() => this.props.logout(this.props.history)}
            style={{ marginTop: '.25em' }}
          >
            Logout
          </StyledOutlineButton>
        </StyledNavItem>
      </Fragment>
    );
  };

  renderLoggedOutMenu = () => {
    if (this.props.loggedIn) return null;

    return [
      <StyledNavItem key={uniqueId()} className="center-on-mobile">
        <Button
          color="link"
          className="menu-link text-white pointer center-on-mobile"
          onClick={this.onJoinClick}
          style={{ marginTop: 3 }}
        >
          Join
        </Button>

        <hr className="menu-divider hide-above-tab hide-on-tab" />
      </StyledNavItem>,
      <StyledNavItem key={uniqueId()} className="center-on-mobile">
        <Button
          color="link"
          className="menu-link text-white pointer center-on-mobile"
          onClick={() => this.props.openSubscriberLoginModal()}
          style={{ marginTop: 3 }}
        >
          Login
        </Button>
        <hr className="menu-divider hide-above-tab hide-on-tab" />
      </StyledNavItem>,
    ];
  };

  render() {
    const { ...newProps } = this.props;
    const safeProps = omit(
      newProps,
      'loggedIn',
      'loginType',
      'isFreeTrial',
      'isUpgradePremium',
      'upgradePremium',
      'openSubscriberLoginModal',
      'logout',
      'checkFreeTryOut',
      'payBillHandel',
      'payBillState',
      'payBillStateMsg',
      'submitLoading'
    );
    if (!this.props.isFreeTrial) {
      this.checkFreeTrialStatus();
    }

    return (
      <>
        <Navbar dark expand="md" {...safeProps} style={{ zIndex: '999' }}>
          <NavbarBrand href={process.env.REACT_APP_BUSRATES_STATIC_URL}>
            <img src={BusRatesLogo} alt="BusRates.com" />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggleCollapse} />
          <Collapse isOpen={this.state.collapseOpen} navbar>
            <Nav className="ml-auto" navbar>
              <StyledNavItem>
                <Dropdown
                  isOpen={this.state.dropdownOpen}
                  toggle={this.toggleDiscover}
                  style={{ textAlign: 'center' }}
                >
                  <DropdownToggle
                    className="text-white"
                    color="link"
                    caret
                    style={{ marginTop: 3 }}
                  >
                    Discover
                  </DropdownToggle>
                  <hr className="menu-divider hide-above-tab hide-on-tab" />
                  <DropdownMenu>
                    <DropdownItem
                      className="dropdown-item"
                      onClick={() => !this.state.dropdownOpen}
                    >
                      <a href={staticWebLink('about')}>About Us</a>
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown-item"
                      onClick={() => !this.state.dropdownOpen}
                    >
                      <a href={staticWebLink('cities')}>Cities</a>
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown-item"
                      onClick={() => !this.state.dropdownOpen}
                    >
                      <a href={staticWebLink('bustypes')}>Bus Types</a>
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown-item"
                      onClick={() => !this.state.dropdownOpen}
                    >
                      <a href={staticWebLink('blog')}>Blog</a>
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown-item"
                      onClick={() => !this.state.dropdownOpen}
                    >
                      <a href={staticWebLink('licensing-insurance')}>Licensing & Insurance</a>
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown-item"
                      onClick={() => !this.state.dropdownOpen}
                    >
                      <a href={staticWebLink('group-travel-planner')}>Group Travel Planner</a>
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown-item"
                      onClick={() => !this.state.dropdownOpen}
                    >
                      <a href={staticWebLink('operating-authority')}>Operating Authority</a>
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown-item"
                      onClick={() => !this.state.dropdownOpen}
                    >
                      <a href={staticWebLink('testimonials')}>Testimonials</a>
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown-item"
                      onClick={() => !this.state.dropdownOpen}
                    >
                      <a href={staticWebLink('customer-service')}>Customer Service</a>
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown-item"
                      onClick={() => !this.state.dropdownOpen}
                    >
                      <a href={staticWebLink('travel-tips')}>Travel Tips</a>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </StyledNavItem>

              {this.renderLoggedOutMenu()}
              {this.renderLoggedInMenu()}
            </Nav>
          </Collapse>
        </Navbar>
        {
          this.props.isUpgradePremium && this.props.loggedIn && (
            <div className="free-trial">
              You are in free trail, want to <span onClick={this.freeTrialModalHandler}>Upgrade to get better placement?</span>
            </div>
          )
        }
        <FreeTrialModal modalVisible={this.state.freeTrialModalVisible} toggleModal={() => this.toggleModal('freeTrialModalVisible')} upgradePremiumHandler={() => this.upgradePremiumlModalHandler()} />
        <UpgradePremiumModal modalVisible={this.state.upgradePremiumModalVisible} toggleModal={() => this.toggleModal('upgradePremiumModalVisible')} history={this.props.history}/>
      </>
    );
  }
}

const mapStateToProps = ({ search, login: { loggedIn, loginType, isFreeTrial, isUpgradePremium, payBillState, payBillStateMsg } }) => ({
  loggedIn,
  loginType,
  isFreeTrial,
  isUpgradePremium,
  payBillState,
  payBillStateMsg
});

export default connect(mapStateToProps, {
  logout,
  openSubscriberLoginModal,
  checkFreeTryOut,
  payBillHandel
})(Navigation);
