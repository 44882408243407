import React from 'react';
import {
  Alert,
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
const PayBillSuccessModal = ({
  modalVisible,
  toggleModal,
  okClickHandel
}) => (
  <Modal isOpen={modalVisible} toggle={toggleModal}>
    <ModalHeader
      className="d-flex align-items-center pl-5 pr-5"
      toggle={toggleModal}
    >
      BusRates
    </ModalHeader>
    <ModalBody className="pl-5 pr-5">
      Congratulations, you have been upgrade successfully!
    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={okClickHandel}>
        OK
      </Button>{' '}
    </ModalFooter>
  </Modal>
);
export default PayBillSuccessModal;