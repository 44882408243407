import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Alert,
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table
} from 'reactstrap';
import find from 'lodash/find';
import glamorous from 'glamorous';
import { formatPhone } from '../../../utils/formatUtils';

import {
  Container as CustomContainer,
  Footer,
  Section
} from '../../../components';
import Result from '../../../components/search/Result';
import SearchDetailModal from '../../../components/search/SearchDetailModal';
import SearchAmenitiesModal from '../../../components/search/SearchAmenitiesModal';
import SearchReviewsModal from '../../../components/search/SearchReviewsModal';
import Menu from '../../../components/subscriber/Menu';
import EditContactModal from './EditContactModal';
import EditEmailsModal from './EditEmailsModal';
import EditFleetModal from './EditFleetModal';
import { busTypes } from '../../../utils/constantUtils';

import {
  deleteDeletingListing,
  fetchListing,
  updateCompanyInformation,
  updateFleetListing,
  updateListingContactInformation,
  updateListingEmails,
  updateProperty
} from '../../../actions/subscriber/listing';

import {
  closeAmenityDetailsModal,
  closeMoreDetailsModal,
  closeReviewsModal,
  openAmenityDetailsModal,
  openMoreDetailsModal,
  openReviewsModal,
} from '../../../actions/search';

const StyledFleetHeader = glamorous.h5({
  padding: '17px 0 8px 32px'
});

class Listing extends Component {
  state = {
    busTypeSelected: 'all',
    detailModalVisible: false,
    reviewsModalVisible: false,
    deleteFleetModalVisible: false,
    editFleetModalVisible: false,
    editListingInformationModalVisible: false,
    editListingContactModalVisible: false,
    editListingAdditionalEmailsModalVisible: false,
    selectedBus: {}
  };

  componentWillMount = () => {
    const companyMetroId = this.props.match.params.company_metro_id;
    this.props.fetchListing(companyMetroId);
  };

  toggleEditListingInformationModal = () => {
    this.setState(prevState => ({
      editListingInformationModalVisible: !prevState.editListingInformationModalVisible
    }));
    this.props.updateProperty('error', null);
  };

  toggleEditContactModal = () => {
    this.setState(prevState => ({
      editListingContactModalVisible: !prevState.editListingContactModalVisible
    }));
    this.props.updateProperty('error', null);
  };

  toggleEditListingAdditionalEmailsModal = () => {
    this.setState(prevState => ({
      editListingAdditionalEmailsModalVisible: !prevState.editListingAdditionalEmailsModalVisible
    }));
    this.props.updateProperty('error', null);
  };

  toggleDeleteFleetModal = bus => {
    this.setState(prevState => ({
      deleteFleetModalVisible: !prevState.deleteFleetModalVisible,
      selectedBus: bus || {}
    }));
    this.props.updateProperty('error', null);
  };

  toggleEditFleetModal = bus => {
    this.setState(prevState => ({
      editFleetModalVisible: !prevState.editFleetModalVisible,
      selectedBus: bus || {}
    }));
    this.props.updateProperty('error', null);
  };

  saveListingInformation = () => {
    this.informationForm.submitForm();
  };

  saveContact = () => {
    this.contactForm.submitForm();
  };

  saveEmails = () => {
    this.emailForm.submitForm();
  };

  onFleetSubmit = values => {
    const companyMetroId = this.props.match.params.company_metro_id;
    const callback = this.toggleEditFleetModal;

    this.props.updateFleetListing({ values, callback, companyMetroId });
  };

  onFleetDelete = () => {
    const companyMetroId = this.props.match.params.company_metro_id;
    const callback = this.toggleDeleteFleetModal;
    const { listingId } = this.state.selectedBus;

    this.props.deleteDeletingListing({ listingId, callback, companyMetroId });
  };

  onListingInformationSubmit = values => {
    const companyMetroId = this.props.match.params.company_metro_id;
    const callback = this.toggleEditListingInformationModal;

    this.props.updateCompanyInformation({ values, callback, companyMetroId });
  };

  onContactSubmit = values => {
    const companyMetroId = this.props.match.params.company_metro_id;
    const callback = this.toggleEditContactModal;

    this.props.updateListingContactInformation({
      values,
      companyMetroId,
      callback
    });
  };

  onEmailsSubmit = values => {
    const companyMetroId = this.props.match.params.company_metro_id;
    const callback = this.toggleEditListingAdditionalEmailsModal;

    this.props.updateListingEmails({ values, callback, companyMetroId });
  };

  renderAdditionalEmails = () => {
    const { additionalEmails } = this.props;

    if (!additionalEmails) return null;

    return (
      <div>
        {additionalEmails.map(email => (
          <div key={email.emailAddress} className="pb-1">
            <span style={{ fontSize: '1.15em', fontWeight: 'bold' }}>
              {email.name}:{' '}
            </span>
            <span style={{ fontSize: '1.15em' }}>{email.emailAddress}</span>
          </div>
        ))}
      </div>
    );
  };

  renderDeleteFleetModal = () => {
    const { error } = this.props;
    const deleteErrorMessage =
      error && error.length ? <Alert color="danger">{error}</Alert> : null;

    return (
      <Modal
        isOpen={this.state.deleteFleetModalVisible}
        toggle={() => this.toggleDeleteFleetModal()}
      >
        <ModalHeader
          className="d-flex align-items-center pl-5 pr-5"
          toggle={() => this.toggleDeleteFleetModal()}
        >
          Delete Bus Type
        </ModalHeader>
        <ModalBody className="pl-5 pr-5">
          <h5>Are you sure you want to delete this bus type?</h5>
          {deleteErrorMessage}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => this.toggleDeleteFleetModal()}>
            Cancel
          </Button>{' '}
          <Button
            color="danger"
            className="text-white"
            onClick={this.onFleetDelete}
          >
            Yes, Delete
          </Button>{' '}
        </ModalFooter>
      </Modal>
    );
  };

  renderBuses = () => (
    <div>
      <StyledFleetHeader>Fleet Information</StyledFleetHeader>
      <Table>
        <thead>
          <tr>
            <th>Qty</th>
            <th>Seats</th>
            <th>Bus Type</th>
            <th>Model Year</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {this.props.buses.map(bus => {
            const busTypeObject = find(busTypes, { key: bus.busTypeId });
            const busType = busTypeObject ? busTypeObject.value : '--';

            return (
              <tr key={bus.listingId}>
                <td>{bus.quantity}</td>
                <td>{bus.seatCount}</td>
                <td>{busType}</td>
                <td>{bus.year}</td>
                <td className="text-right">
                  <Button
                    color="primary"
                    className="text-white mr-3"
                    onClick={() => this.toggleEditFleetModal(bus)}
                  >
                    Edit
                  </Button>
                  <Button
                    color="danger"
                    className="text-white"
                    onClick={() => this.toggleDeleteFleetModal(bus)}
                  >
                    Remove
                  </Button>
                </td>
              </tr>
            );
          })}
          <tr>
            <td colSpan="4" />
            <td className="text-right">
              <Button
                className="text-white"
                onClick={() => this.toggleEditFleetModal()}
              >
                Add New Bus
              </Button>
            </td>
          </tr>
          <tr>
            <td colSpan="4" />
            <td className="text-right">
              <Button
                  className="text-white"
                  onClick={() => this.onSaveButtonClick()}
              >
                Save
              </Button>
            </td>
          </tr>
        </tbody>
      </Table>
      <EditFleetModal
        amenitiesOptions={this.props.amenitiesOptions}
        busTypeOptions={busTypes}
        values={this.state.selectedBus}
        errorMessage={this.props.error}
        modalRef={node => (this.fleetForm = node)}
        modalVisible={this.state.editFleetModalVisible}
        onButtonSubmit={() => this.fleetForm.submitForm()}
        onFormSubmit={this.onFleetSubmit}
        toggleModal={() => this.toggleEditFleetModal()}
      />
      {this.renderDeleteFleetModal()}
    </div>
  );

  renderSaveButton = () => (
      <Button
          color="info"
          block
          onClick={() => this.onSaveButtonClick()}
      >
        Save
      </Button>
  );

  onSaveButtonClick = () => {
    this.props.history.push('/subscribers/listings');
  };

  previewData = () => {
    if (!this.props.alias) return null;

    const data = {
      company: {
        id: 1,
        name: this.props.alias,
        phone: this.props.phone,
        email: this.props.email,
        website: this.props.website,
        usDotNumber: this.props.usDotNumber,
        usDotLink: this.props.usDotLink,
        about: this.props.description,
        city: this.props.city,
        state: this.props.state,
        zip: this.props.zip,
        associationsAndCertifications: this.props.associationsAndCertifications,
        licensingAndInsurance: this.props.licensingAndInsurance,
        averageRating: this.props.averageRating,
        isUmaMember: this.props.isUmaMember,
        facebookUrl: this.props.facebookUrl,
        twitterUrl: this.props.twitterUrl,
        instagramUrl: this.props.instagramUrl,
        youtubeUrl: this.props.youtubeUrl,
      },
      busResults: this.props.busResults,
      selectedBusType: this.props.selectedBusType,
      paymentTypes: this.props.paymentTypes,
      photos: this.props.photos,
      reviews: this.props.reviews
    };

    return data;
  }

  renderSampleListing = () => {
    if (!this.props.alias) return null;

    const data = this.previewData();

    return (
      <CustomContainer>
        <h4>Preview of your Listing:</h4>

        <Result
          isSampleResult
          onAddToQuoteRequest={() => {}}
          onRemoveFromQuoteRequest={() => {}}
          openMoreDetailsModal={() => this.props.openMoreDetailsModal(data)}
          openReviewsModal={() => this.props.openReviewsModal(data)}
          quoteRequests={[]}
          result={data}
          selectedBusType="all"
        />
      </CustomContainer>
    );
  };

  renderMoreDetailsModal = () => {
    if (!this.props.alias) return null;

    const data = this.previewData();

    return (
      <SearchDetailModal
        hideContactUs
        isSampleResult
        className={this.props.className}
        closeMoreDetailsModal={this.props.closeMoreDetailsModal}
        detailModalVisible={this.props.detailModalVisible}
        openAmenityDetailsModal={this.props.openAmenityDetailsModal}
        openReviewsModal={this.props.openReviewsModal}
        selectedResult={data}
      />
    )
  }

  renderAmenitiesModal = () => {
    if (!this.props.alias) return null;

    const data = this.previewData();

    return (
      <SearchAmenitiesModal
        isSampleResult
        className={this.props.className}
        closeAmenityDetailsModal={this.props.closeAmenityDetailsModal}
        detailAmenityModalVisible={this.props.detailAmenityModalVisible}
        selectedBusResult={this.props.selectedBusResult}
        selectedResult={data}
      />
    )
  }

  renderReviewsModal = () => {
    if (!this.props.alias) return null;

    const data = this.previewData();

    return (
      <SearchReviewsModal
        className={this.props.className}
        closeReviewsModal={this.props.closeReviewsModal}
        reviewsModalVisible={this.props.reviewsModalVisible}
        selectedResult={data}
      />
    )
  }

  render() {
    return (
      <div className="pt-5">
        <Section className="pb-5">
          <h1>&nbsp;</h1>
          <Row>
            <Menu history={this.props.history} />
            <Col md="12" sm="12" lg="9">
              <h1
                className="mb-3 pb-1 text-center"
                style={{ paddingTop: '0 !important', lineHeight: '1em' }}
              >
                {this.props.metro}
              </h1>
              <p className="lead">
                Edits on this page will only apply to this listing, they will
                not change Company level information or other listings
              </p>
              <Container
                className="pt-3 pb-4"
                style={{ borderTop: '1px solid lightgrey' }}
              >
                <Row>
                  <Col md="12" sm="12" lg="8">
                    <br />
                    <h4>{this.props.alias}</h4>
                    <h5 className="pt-2">
                      {this.props.address1}
                      <br />
                      {`${this.props.city}, ${this.props.state} ${this.props.zip}`}
                    </h5>{' '}
                    <br />
                  </Col>
                  <Col md="12" sm="12" lg="4">
                    <br />
                    <span
                      style={{
                        fontWeight: 'bold',
                        display: 'inline-block',
                        width: '2em'
                      }}
                    >
                      P:{' '}
                    </span>
                    <span>{formatPhone(this.props.phone)}</span>

                    <br />
                    <span
                      style={{
                        fontWeight: 'bold',
                        display: 'inline-block',
                        width: '2em'
                      }}
                    >
                      W:{' '}
                    </span>
                    <span>{this.props.website}</span>
                    <br />
                    <span
                      style={{
                        fontWeight: 'bold',
                        display: 'inline-block',
                        width: '2em'
                      }}
                    >
                      E:{' '}
                    </span>
                    <span>{this.props.email}</span>
                    <br />
                    <br />
                    <Button
                      className="text-white"
                      block
                      onClick={this.toggleEditContactModal}
                    >
                      Edit Contact Details
                    </Button>
                  </Col>
                  <EditContactModal
                    values={this.props}
                    errorMessage={this.props.error}
                    modalRef={node => (this.contactForm = node)}
                    modalVisible={this.state.editListingContactModalVisible}
                    onButtonSubmit={this.saveContact}
                    onFormSubmit={this.onContactSubmit}
                    toggleModal={this.toggleEditContactModal}
                  />
                </Row>
              </Container>
              <Container
                className="mt-3 pt-3 pb-3"
                style={{
                  borderTop: '1px solid lightgrey',
                  backgroundColor: '#eff1f4'
                }}
              >
                <Row>
                  <Col>
                    {this.renderAdditionalEmails()}
                    <br />
                    <Button
                      className="text-white"
                      onClick={this.toggleEditListingAdditionalEmailsModal}
                    >
                      Edit Additional Emails
                    </Button>
                    <EditEmailsModal
                      values={this.props}
                      errorMessage={this.props.error}
                      modalRef={node => (this.emailForm = node)}
                      modalVisible={
                        this.state.editListingAdditionalEmailsModalVisible
                      }
                      onButtonSubmit={this.saveEmails}
                      onFormSubmit={this.onEmailsSubmit}
                      toggleModal={this.toggleEditListingAdditionalEmailsModal}
                    />
                  </Col>
                </Row>
              </Container>
              {this.renderBuses()}
            </Col>
          </Row>
          {this.renderSampleListing()}
        </Section>
        <Footer />
        {this.renderMoreDetailsModal()}
        {this.renderAmenitiesModal()}
        {this.renderReviewsModal()}
      </div>
    );
  }
}

const mapStateToProps = ({ search, subscriberListing }) => ({
  additionalEmails: subscriberListing.additionalEmails,
  address1: subscriberListing.address1,
  alias: subscriberListing.alias,
  amenitiesOptions: subscriberListing.amenitiesOptions,
  associationsAndCertifications: subscriberListing.associationsAndCertifications,
  averageRating: subscriberListing.averageRating,
  busResults: subscriberListing.busResults,
  buses: subscriberListing.buses,
  city: subscriberListing.city,
  description: subscriberListing.description,
  detailAmenityModalVisible: search.detailAmenityModalVisible,
  detailModalVisible: search.detailModalVisible,
  email: subscriberListing.email,
  error: subscriberListing.error,
  facebookUrl: subscriberListing.facebookUrl,
  instagramUrl: subscriberListing.instagramUrl,
  isUmaMember: subscriberListing.isUmaMember,
  licensingAndInsurance: subscriberListing.licensingAndInsurance,
  metro: subscriberListing.metro,
  paymentTypes: subscriberListing.paymentTypes,
  phone: subscriberListing.phone,
  photos: subscriberListing.photos,
  reviews: subscriberListing.reviews,
  reviewsModalVisible: search.reviewsModalVisible,
  selectedBusResult: search.selectedBusResult,
  state: subscriberListing.state,
  twitterUrl: subscriberListing.twitterUrl,
  usDotLink: subscriberListing.usDotLink,
  website: subscriberListing.website,
  youtubeUrl: subscriberListing.youtubeUrl,
  zip: subscriberListing.zip,
});

export default connect(mapStateToProps, {
  deleteDeletingListing,
  fetchListing,
  openAmenityDetailsModal,
  openReviewsModal,
  openMoreDetailsModal,
  closeMoreDetailsModal,
  closeAmenityDetailsModal,
  closeReviewsModal,
  updateCompanyInformation,
  updateFleetListing,
  updateListingContactInformation,
  updateListingEmails,
  updateProperty
})(Listing);
