import React, { Component } from 'react';
import glamorous from 'glamorous';
import { connect } from 'react-redux';
import { Button, Col, Container, Row } from 'reactstrap';
import 'react-image-crop/dist/ReactCrop.css';
import {
  fetchUserProfile,
  updateLoginInformation,
  updateProfilePhoto,
  updateUserProfile
} from '../../actions/user/userProfile';
import { Footer, Section } from '../../components';
import UserMenu from '../../components/user/UserMenu';
import EditUserProfileModal from './components/EditUserProfileModal';
import EditUserLoginModal from '../subscriber/Company/LoginInformationModal';
import PhotoModal from '../subscriber/Company/CompanyPhotoModal';

const StyledSectionHeaderDiv = glamorous.div(props => ({
  backgroundColor: '#e9ecef',
  marginBottom: '2em',
  marginTop: props.first ? null : '2em'
}));

const StyledSectionHeaderTitle = glamorous.h3({ padding: '1em' });

const StyledContainer = glamorous.div({
  border: '1px solid #e9ecef'
});

class UserProfile extends Component {
  state = {
    addOnsModalVisible: false,
    companyDetailsModalVisible: false,
    crop: { aspect: 11 / 7 },
    editUserProfileModalVisible: false,
    file: undefined,
    loginInformationModalVisible: false,
    profilePhotoModalVisible: false
  };

  componentDidMount() {
    this.props.fetchUserProfile();
  }

  toggleModal = modal =>
    this.setState(prevState => ({
      [modal]: !prevState[modal]
    }));

  onUserProfileFormSubmit = values => {
    const callback = () => this.toggleModal('editUserProfileModalVisible');
    this.props.updateUserProfile({ values, callback });
  };

  onLoginInformationSubmit = values => {
    const callback = () => this.toggleModal('loginInformationModalVisible');
    this.props.updateLoginInformation({ values, callback });
  };

  onProfilePhotoSubmit = (image, callback) => {
    const { photoFilename, photoUrl } = this.props;
    this.props.updateProfilePhoto({
      image,
      initialPhoto: photoUrl,
      initialPhotoFilename: photoFilename,
      callback
    });
  };

  onFileChange = file => {
    this.setState({ file: file.base64 });
  };

  onCrop = crop => {
    this.setState({ crop });
  };

  renderContent = () => {
    const {
      about,
      company,
      email,
      errorMessage,
      firstName,
      lastName,
      photoFilename,
      photoUrl
    } = this.props;

    const FormSectionHeader = ({ first, title }) => (
      <StyledSectionHeaderDiv first={first}>
        <StyledSectionHeaderTitle className="font-weight-border text-primary mb-0">
          {title}
        </StyledSectionHeaderTitle>
      </StyledSectionHeaderDiv>
    );

    const imageSource = photoFilename
      ? `${photoUrl}`
      : photoUrl;

    return (
      <StyledContainer>
        <FormSectionHeader title="Profile Management" first />
        <Container className="pt-3 pb-4">
          <Row>
            <Col md="12" sm="12" lg="4">
              {photoUrl &&
                photoUrl.length && (
                  <img src={imageSource} alt="My Profile" className="w-100" />
                )}
              <br />
              <br />
              <Button
                className="text-white"
                onClick={() => this.toggleModal('profilePhotoModalVisible')}
              >
                Edit Profile Photo
              </Button>
              <PhotoModal
                errorMessage={errorMessage}
                initialPhoto={photoUrl}
                modalVisible={this.state.profilePhotoModalVisible}
                onButtonSubmit={this.onProfilePhotoSubmit}
                toggleModal={() => this.toggleModal('profilePhotoModalVisible')}
              />
            </Col>
            <Col md="12" sm="12" lg="8">
              <h4>
                {firstName} {lastName}
              </h4>
              <h5>{company}</h5>
              <p className="pt-2">{about}</p>
              <Button
                className="text-white"
                onClick={() => this.toggleModal('editUserProfileModalVisible')}
              >
                Edit My Information
              </Button>
              <EditUserProfileModal
                values={this.props}
                errorMessage={errorMessage}
                modalRef={node => (this.userProfileForm = node)}
                modalVisible={this.state.editUserProfileModalVisible}
                onButtonSubmit={() => this.userProfileForm.submitForm()}
                onFormSubmit={this.onUserProfileFormSubmit}
                toggleModal={() =>
                  this.toggleModal('editUserProfileModalVisible')
                }
              />
            </Col>
          </Row>
        </Container>

        <FormSectionHeader title="Login Information" />
        <Container className="pt-3 pb-4">
          <Row>
            <Col md="12" sm="12" lg="8">
              <h6 className="pt-2">Login Email</h6>
              <p>{email}</p>
              <h6 className="pt-2">Password</h6>
              <p>********</p>
              <Button
                className="text-white"
                onClick={() => this.toggleModal('loginInformationModalVisible')}
              >
                Edit Login
              </Button>
            </Col>
          </Row>
          <EditUserLoginModal
            title="Edit Login"
            values={this.props}
            errorMessage={this.props.errorMessage}
            modalRef={node => (this.loginInformationModal = node)}
            modalVisible={this.state.loginInformationModalVisible}
            onButtonSubmit={() => this.loginInformationModal.submitForm()}
            onFormSubmit={this.onLoginInformationSubmit}
            toggleModal={() => this.toggleModal('loginInformationModalVisible')}
          />
        </Container>
      </StyledContainer>
    );
  };

  render() {
    return (
      <div className="pt-5">
        <Section>
          <Row>
            <Col xs="12" className="text-center">
              <h1 className="pb-3">My Account</h1>
            </Col>
          </Row>
          <Row>
            <UserMenu history={this.props.history} />
            <Col xs="9">{this.renderContent()}</Col>
          </Row>
        </Section>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = ({ userProfile }) => ({
  about: userProfile.about,
  company: userProfile.company,
  email: userProfile.email,
  errorMessage: userProfile.errorMessage,
  firstName: userProfile.firstName,
  lastName: userProfile.lastName,
  photoFilename: userProfile.photoFilename,
  photoUrl: userProfile.photoUrl
});

export default connect(mapStateToProps, {
  fetchUserProfile,
  updateLoginInformation,
  updateProfilePhoto,
  updateUserProfile
})(UserProfile);
