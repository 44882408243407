const INITIAL_STATE = {
  cities: [],
  loading: true,
  numberOfPeople: 0, // 默认值 由1 改为 0 适应website 网站过滤机制
  quoteRequests: [],
  states: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLEAR_QUOTE_REQUESTS':
      return { ...state, ...action.payload };
    case 'SEARCH_REQUEST':
      return { ...state, loading: true };
    case 'SEARCH_REQUEST_SUCCESS':
      return { loading: false, ...action.payload };
    case 'SEARCH_REQUEST_FAILURE':
      return { loading: false, ...action.payload };
    case 'SEARCH_RESULTS_FILTERED':
      return { ...state, results: action.payload };
    case 'FILTER_PROPERTY_UPDATED': {
      return { ...state, [action.payload.key]: action.payload.value };
    }
    case 'MORE_DETAILS_MODAL_OPENED':
      return {
        ...state,
        detailModalVisible: true,
        selectedResult: action.payload.result,
        selectedBusDetailRows: []
      };
    case 'MORE_DETAILS_MODAL_CLOSED':
      return {
        ...state,
        detailModalVisible: false,
        selectedResult: null,
        selectedBusDetailRows: []
      };
    case 'REVIEWS_MODAL_OPENED':
      return {
        ...state,
        reviewsModalVisible: true,
        selectedResult: action.payload.result
      };
    case 'REVIEWS_MODAL_CLOSED':
      if (state.detailModalVisible) {
        return { ...state, reviewsModalVisible: false };
      }
      return {
        ...state,
        reviewsModalVisible: false,
        selectedResult: null
      };
    case 'STATE_CHANGED':
      return {
        ...state,
        filterCities: action.payload.filteredCities,
        departureState: action.payload.newState,
        departureCity: action.payload.newCity
      };
    case 'AMENITY_DETAILS_MODAL_OPENED':
      return {
        ...state,
        detailAmenityModalVisible: true,
        selectedBusResult: action.payload.busResult
      };
    case 'AMENITY_DETAILS_MODAL_CLOSED':
      return {
        ...state,
        detailAmenityModalVisible: false,
        selectedBusResult: null
      };
    case 'BUS_DETAIL_ROW_CHECKED':
      return {
        ...state,
        selectedBusDetailRows: [
          ...state.selectedBusDetailRows,
          action.payload.row
        ]
      };
    case 'BUS_DETAIL_ROW_UNCHECKED':
      return {
        ...state,
        selectedBusDetailRows: state.selectedBusDetailRows.filter(
          item => item.id !== action.payload.row.id
        )
      };
    case 'ADD_TO_QUOTE_REQUEST_FROM_MODAL':
      return {
        ...state,
        quoteRequests: [
          ...state.quoteRequests,
          ...action.payload.newQuoteRequests
        ]
      };
    case 'ADD_TO_QUOTE_REQUEST':
      return {
        ...state,
        quoteRequests: [...state.quoteRequests, action.payload.quoteRequest]
      };
    case 'REVIEW_QUOTE_REQUEST_CLICKED':
      return { ...state };
    case 'REMOVE_FROM_QUOTE_REQUEST':
      return {
        ...state,
        quoteRequests: state.quoteRequests.filter(
          item => item.id !== action.payload.quoteRequest.id
        )
      };
    default:
      return state;
  }
};
