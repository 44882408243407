import { associateQuote, userSignup } from '../../services/user/profileService';

export const signup = ({ values, history, quoteNumber }) => async dispatch => {
  dispatch({ type: 'USER_SIGNUP_REQUEST' });
  try {
    const response = await userSignup(values);
    if (!response.success) throw response.errorMessage;

    if (quoteNumber && response.user.apiToken) {
      await associateQuote(quoteNumber, response.user.apiToken);
    }

    dispatch({ type: 'USER_SIGNUP_SUCCESS', payload: response });
    history.push('/users/my-account');
  } catch (e) {
    const message =
      typeof e === 'string'
        ? e
        : 'There was a problem creating your account. Please try again later.';
    dispatch({ type: 'USER_SIGNUP_FAIL', payload: message });
  }
};
