import React from 'react';
import glamorous from 'glamorous';
import { Button, FormGroup, Label } from 'reactstrap';
import ReactFileReader from 'react-file-reader';

const StyledFormGroup = glamorous(FormGroup)({ marginBottom: '1em' });

export const InputFileUpload = ({
  className,
  errors,
  label,
  name,
  onChange,
  required,
  touched,
  values
}) => {
  const labelText = required ? `* ${label}` : label;
  return (
    <StyledFormGroup className={className}>
      <Label for={name}>{labelText}</Label>
      <ReactFileReader id={name} base64={true} handleFiles={onChange}>
        <Button type="submit" className="btn" color="primary">
          Upload
        </Button>
      </ReactFileReader>
    </StyledFormGroup>
  );
};

export default InputFileUpload;
