import API from '../baseService';

export const submitSetup1Info = (values) => {

  return API.post('/company/progress/first/step', values).then(
    response => response.data
  );
};


export const listBustypes = (companyId) => (
    API.get("/company/default/fleet/list", {companyId: companyId}).then(
        response => response.data
    )
)

export const addBusType = (values) => {

    return API.post('/company/default/fleet/save', values).then(
        response => response.data
    );
};

export const updateBusType = (values) => {

    return API.post('/company/default/fleet/update', values).then(
        response => response.data
    );
};


export const deleteBusType = id =>{
    return API.post('/company/default/fleet/delete/'+id).then(
        response => response.data
    );
}

export const amenities = (companyId) =>
    API.get(`/company/default/fleet/amenity/list`,{companyId:companyId}).then(
        response => response.data
    );

export const setUpSecondIndex = (companyId) =>{

    return API.post('/company/progress/update/setup/second/index?companyId='+companyId).then(
        response => response.data
    );
}

export const listMetroByCompanyId = (companyId,bustype) =>
    API.get(`/subscribers/fleet/${companyId}/${bustype}/metros`).then(
        response => response.data
    );

export const addFleet = (companyMetroIds,fleetId) =>{
    return API.post('/company/default/fleet/addOnCompanyMetro',{companyMetroIds:companyMetroIds,fleetId:fleetId}).then(
        response => response.data
    );
}