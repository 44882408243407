import React, { Component, Fragment } from 'react';
import glamorous from 'glamorous';
import { connect } from 'react-redux';
import {
  Alert,
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table
} from 'reactstrap';
import {
  clearBidConfirmation,
  fetchBidConfirmation,
  fetchBidPositions,
  submitBidConfirmation
} from '../../actions/subscriber/bid';

import { Footer, LargeButton, Section } from '../../components';

import Menu from '../../components/subscriber/Menu';

const StyledTable = glamorous(Table)({
  fontWeight: 400
});

const StyledItemsContainer = glamorous.div({
  marginTop: '20px'
});

const StyledBoldItem = glamorous.p({
  fontWeight: 'bold'
});

class Bid extends Component {
  state = {
    selectedPosition: null,
    showConfirmationModal: false,
    showConfirmationComplete: false,
    loadingCssName: "text-white",
    canBid: true
  };

  componentWillMount = () => {
    const { companyId } = this.props;

    if (!companyId) return null;

    this.props.fetchBidPositions(companyId);
  };

  toggleConfirmationModal = () => {
    this.setState(prevState => ({
      showConfirmationModal: !prevState.showConfirmationModal
    }));
  };

  onConfirmationClose = () => {
    this.setState({
      showConfirmationModal: false,
      showConfirmationComplete: false
    });
    this.props.clearBidConfirmation();
    this.props.history.push('/subscribers/bidListings');
  };

  onConfirmation = () => {
    this.toggleConfirmationModal();

    const companyMetroId = this.props.match.params.company_metro_id;
    const metros = this.props.bidPositionResults;
    const selectedPosition = this.state.selectedPosition;

    this.props.fetchBidConfirmation({
      companyMetroId,
      metros,
      selectedPosition
    });
  };

  onConfirmationSubmit = () => {

    if (!this.state.canBid) {
      return;
    }

    const callback = () =>
      this.setState({
        showConfirmationComplete: true,
        canBid:true,
        loadingCssName: "text-white"
      });

    this.setState({
      canBid:false,
      loadingCssName: "buttonLoading"
    });

    this.props.submitBidConfirmation(callback);
  };

  onPositionSelect = position => {
    this.setState({ selectedPosition: position });
  };

  renderBidRadio = position => {
    if (position.isCurrentPosition) {
      return 'Current';
    }

    if (!position.canUpgrade) return null;

    return (
      <Fragment>
        <Input
          type="radio"
          name="openBid"
          onClick={() => this.onPositionSelect(position)}
          style={{"-webkit-appearance":"radio"}}
        />{' '}
        {'Bid Now'}
      </Fragment>
    );
  };

  getMetroInformation = () => {
    if (!this.props.bidPositionResults) return null;

    const filteredMetroPositions = this.props.bidPositionResults.filter(
      metro => metro.companyMetroId.toString() === this.props.match.params.company_metro_id
    );

    if (filteredMetroPositions.length !== 1) return null;

    return filteredMetroPositions[0];
  }

  renderPendingPositionChangeMessage = () => {
    let metroPosition = this.getMetroInformation();

    if (!metroPosition) return null;

    if (metroPosition.hasPendingPositionChange) {
      return (
        <Alert color="warning">{metroPosition.pendingPositionChangeMessage}</Alert>
      );
    }

    return null;
  }

  renderMetroResults = () => {
    let metroPosition = this.getMetroInformation();

    if (!metroPosition) return null;

    return metroPosition.positions.map(position => (
      this.renderMetroResultRow(position)
    ));
  };

  renderMetroResultRow = (position) => {
    if (position.isCurrentPosition) {
      return(
        <tr key={position.company + position.position}>
          <th className="align-middle">{position.position}</th>
          <th className="align-middle">{position.company}</th>
          <th className="align-middle">{position.newCostDisplay}</th>
          <th>{this.renderBidRadio(position)}</th>
        </tr>
      )
    }
    else {
      return(
        <tr key={position.company + position.position}>
          <td className="align-middle">{position.position}</td>
          <td className="align-middle">{position.company}</td>
          <td className="align-middle">{position.newCostDisplay}</td>
          <td>{this.renderBidRadio(position)}</td>
        </tr>
      )
    }
  }

  renderListings = () => (
    <StyledTable striped>
      <thead className="text-center">
        <tr>
          <th>Position</th>
          <th>Company</th>
          <th>Cost</th>
          <th />
        </tr>
      </thead>
      <tbody className="text-center">{this.renderMetroResults()}</tbody>
    </StyledTable>
  );

  renderConfirmationModalBody = () => {
    const { bidConfirmation } = this.props;
    const { showConfirmationComplete } = this.state;
    const { errorMessage } = bidConfirmation;

    const ItemRow = ({ bold, title, value }) => (
      <Row>
        <Col xs={6}>
          <p>{title}:</p>
        </Col>
        <Col xs={6}>
          {bold ? <StyledBoldItem>{value}</StyledBoldItem> : <p>{value}</p>}
        </Col>
      </Row>
    );

    const ConfirmationAlert = () => (
      <Alert color="primary">
        <h5>We have received your request to change your listing position!</h5>
        <p>
          We are reviewing your request to change positions. Once approved, to
          ensure you keep your placement, check back regularly to ensure you
          have not been outbid. Additionally, we will send you an email if you
          are outbid so you can ensure you remain aware of placement at all
          times.
        </p>
      </Alert>
    );

    const confirmationHeader = showConfirmationComplete ? (
      <ConfirmationAlert />
    ) : (
      <h5>This amount will be billed to your account.</h5>
    );

    const errorAlert =
      errorMessage && errorMessage.length ? (
        <Alert color="danger">
          <p>{errorMessage}</p>
          <p>Please click <a href={'/subscribers/payments'}>here</a> to update your billing information.</p>
        </Alert>
      ) : null;

    return (
      <Fragment>
        <p>
          Please note: We are currently updating our database. The pricing
          information below may not be accurate at this time. We will be
          following up with an email shortly with the current information. Thank
          you for your patience.
        </p>
        {errorAlert}
        {confirmationHeader}
        <StyledItemsContainer>
          <ItemRow title="Metro" value={bidConfirmation.metroName} />
          <ItemRow
            title="Current Position"
            value={bidConfirmation.currentPosition}
          />
          <ItemRow
            title="New Position"
            value={bidConfirmation.newPosition}
            bold
          />
          <ItemRow
            title="Current Price/Month"
            value={bidConfirmation.currentPrice}
          />
          <ItemRow
            title="New Price/Month"
            value={bidConfirmation.newPrice}
            bold
          />
          <ItemRow title="Renewal Date" value={bidConfirmation.renewalDate} />
          <ItemRow
            title="Prorated Amt"
            value={bidConfirmation.proratedAmount}
          />
          <ItemRow title="Discount Rate" value={bidConfirmation.discountRate} />
        </StyledItemsContainer>
        <hr />
        <ItemRow title="Total" value={bidConfirmation.total} bold />
      </Fragment>
    );
  };

  renderConfirmationModalButtons = () => {
    const { bidConfirmation } = this.props;
    const { showConfirmationComplete } = this.state;

    if (showConfirmationComplete) {
      return (
        <Button color="primary" onClick={this.onConfirmationClose}>
          Close
        </Button>
      );
    }
    return (
      <Fragment>
        <Button color="primary" onClick={this.toggleConfirmationModal}>
          Cancel
        </Button>
        <Button
          disabled={!bidConfirmation.metroName || this.state.showConfirmationComplete}
          onClick={this.onConfirmationSubmit}
          className={this.state.loadingCssName}
        >
          Submit Changes
        </Button>
      </Fragment>
    );
  };

  renderConfirmationModal = () => {
    const { showConfirmationModal } = this.state;

    return (
      <Modal
        isOpen={showConfirmationModal}
        toggle={this.toggleConfirmationModal}
      >
        <ModalHeader
          className="d-flex align-items-center pl-5 pr-5"
          toggle={this.toggleConfirmationModal}
        >
          Bid Increase Confirmation
        </ModalHeader>
        <ModalBody className="pl-5 pr-5">
          {this.renderConfirmationModalBody()}
        </ModalBody>
        <ModalFooter>{this.renderConfirmationModalButtons()}</ModalFooter>
      </Modal>
    );
  };

  render() {
    let metroPosition = this.getMetroInformation();

    if (!metroPosition) return null;

    return (
      <div className="pt-5">
        <Section>
          <Row>
            <Col xs="12" className="text-center">
              <h1>Bid For Placement</h1>
            </Col>
          </Row>
          <Row>
            <Menu history={this.props.history} />
            <Col xs="9">
              <h4>Adjust Your Company's Rank</h4>
              <p>
                Your listing's placement among other operators is determined via
                longevity by tier (except for zip code searches which are by
                proximity). If you would like to adjust your placement, use the
                bid tool below.
              </p>
              <ul>
                <li>Once submitted, your new rank will display immediately</li>
                <li>
                  Pay nothing now, we will adjust your invoice or account
                  manually (prorated to the nearest day)
                </li>
                <li>
                  Bidding is an open process and rank is not locked in (other
                  companies can outbid you)
                </li>
                <li>
                  Use the Higher Tier option to bid past the highest tier and
                  create separation from the other companies posted in the
                  selected metro
                </li>
              </ul>
              <Row>{this.renderPendingPositionChangeMessage()}</Row>
              <Row>{this.renderListings()}</Row>
              <LargeButton
                disabled={this.state.selectedPosition === null || metroPosition.hasPendingPositionChange}
                text="Submit Bid for Placement"
                margin="true"
                onClick={this.onConfirmation}
              />
            </Col>
          </Row>
        </Section>
        <Footer />
        {this.renderConfirmationModal()}
      </div>
    );
  }
}

const mapStateToProps = ({ subscriberBidPositions, login }) => ({
  companyId: login.companyId,
  bidPositionResults: subscriberBidPositions.results,
  bidConfirmation: subscriberBidPositions.bidConfirmation
});

export default connect(mapStateToProps, {
  clearBidConfirmation,
  fetchBidConfirmation,
  fetchBidPositions,
  submitBidConfirmation
})(Bid);
