import React from 'react';
import {
  Alert,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import { Formik } from 'formik';

const FormsOfPaymentModal = ({
  errorMessage,
  modalRef,
  modalVisible,
  onButtonSubmit,
  onFormSubmit,
  toggleModal,
  values
}) => (
  <Modal isOpen={modalVisible} toggle={toggleModal}>
    <ModalHeader
      className="d-flex align-items-center pl-5 pr-5"
      toggle={toggleModal}
    >
      Edit Company Information
    </ModalHeader>
    <ModalBody className="pl-5 pr-5">
      <Formik
        ref={modalRef}
        initialValues={{
          allowsAmex: values.allowsAmex,
          allowsDiscover: values.allowsDiscover,
          allowsMastercard: values.allowsMastercard,
          allowsVisa: values.allowsVisa,
          allowsPaypal: values.allowsPaypal,
          allowsCheck: values.allowsCheck
        }}
        onSubmit={onFormSubmit}
        render={formProps => (
          <Form onSubmit={formProps.handleSubmit}>
            {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
            <Row>
              <Checkbox
                defaultChecked={values.allowsAmex}
                label="American Express"
                name="allowsAmex"
                onChange={formProps.handleChange}
                value={values.allowsAmex}
              />
              <Checkbox
                defaultChecked={values.allowsDiscover}
                label="Discover"
                name="allowsDiscover"
                onChange={formProps.handleChange}
                value={values.allowsDiscover}
              />
              <Checkbox
                defaultChecked={values.allowsMastercard}
                label="Mastercard"
                name="allowsMastercard"
                onChange={formProps.handleChange}
                value={values.allowsMastercard}
              />
              <Checkbox
                defaultChecked={values.allowsVisa}
                label="Visa"
                name="allowsVisa"
                onChange={formProps.handleChange}
                value={values.allowsVisa}
              />
              <Checkbox
                defaultChecked={values.allowsPaypal}
                label="Paypal"
                name="allowsPaypal"
                onChange={formProps.handleChange}
                value={values.allowsPaypal}
              />
              <Checkbox
                defaultChecked={values.allowsCheck}
                label="Check"
                name="allowsCheck"
                onChange={formProps.handleChange}
                value={values.allowsCheck}
              />
            </Row>
          </Form>
        )}
      />
    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={toggleModal}>
        Cancel
      </Button>{' '}
      <Button color="secondary" className="text-white" onClick={onButtonSubmit}>
        Save
      </Button>{' '}
    </ModalFooter>
  </Modal>
);

const Checkbox = props => (
  <FormGroup check className="col-sm-3 col-6">
    <Label check>
      <Input
        type="checkbox"
        name={props.name}
        value={props.value}
        defaultChecked={props.defaultChecked}
        onChange={props.onChange}
      />{' '}
      {props.label}
    </Label>
  </FormGroup>
);

export default FormsOfPaymentModal;
