import React, { Component } from 'react';
import glamorous from 'glamorous';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Alert,
  Button,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table
} from 'reactstrap';
import FaMailReply from 'react-icons/lib/fa/mail-reply';
import FaClose from 'react-icons/lib/fa/close';
import { Formik } from 'formik';
import Yup from 'yup';
import {
  clearDeleteMessage,
  deleteMessage,
  fetchMessages,
  markMessageAsRead,
  replyToMessage
} from '../../actions/subscriber/messages';
import { Footer, Input, Section } from '../../components';
import Menu from '../../components/subscriber/Menu';
import { orange } from '../../utils/globalColors';

const StyledTable = glamorous(Table)({
  fontWeight: 400
});

const StyledFaMailReply = glamorous(FaMailReply)({
  color: orange
});

const StyledFaClose = glamorous(FaClose)({
  color: '#b4bfc9'
});

const StyledText = glamorous.p(
  {
    marginBottom: '5px'
  },
  ({ isNew, smallText }) => ({
    fontSize: smallText ? '12px' : '14px',
    fontWeight: isNew ? 'bold' : null
  })
);

const StyledSubtext = glamorous.p(
  {
    fontStyle: 'italic',
    marginBottom: '5px'
  },
  ({ isNew, smallText }) => ({
    fontSize: smallText ? '10px' : '12px',
    fontWeight: isNew ? 'bold' : null
  })
);

const StyledTD = glamorous.td({
  cursor: 'pointer'
});

const StyledModalMessageText = glamorous.p({
  marginTop: '25px',
  marginBottom: '5px'
});

const StyledModalMessageDateTime = glamorous.p({
  position: 'absolute',
  top: 10,
  right: 10,
  fontStyle: 'italic'
});

class Messages extends Component {
  state = {
    message: {},
    showDeleteModal: false,
    showReplyModal: false
  };

  componentDidMount() {
    this.props.fetchMessages();
  }

  toggleReplyModal = () => {
    this.setState(prevState => ({ showReplyModal: !prevState.showReplyModal }));
  };

  toggleDeleteModal = () => {
    this.setState(prevState => ({
      showDeleteModal: !prevState.showDeleteModal
    }));
  };

  onDeleteMessage = () => {
    const callback = () => this.toggleDeleteModal();
    this.props.deleteMessage(this.state.message, callback);
  };

  onReplyButton = () => {
    this.replyForm.submitForm();
  };

  onReplyFormSubmit = values => {
    const callback = () => this.toggleReplyModal();
    const { message } = this.state;
    this.props.replyToMessage({ message, values, callback });
  };

  onReply = message => {
    this.setState({ message, showReplyModal: true });

    if (message.isNew) {
      this.props.markMessageAsRead(message);
    }
  };

  onDelete = message => {
    this.setState({ message, showDeleteModal: true });
    this.props.clearDeleteMessage();
  };

  renderMessage = message => {
    const {
      body,
      isNew,
      messageId,
      senderEmail,
      senderName,
      subject,
      metroCity,
      metroState
    } = message;
    const onReply = () => this.onReply(message);
    const onDelete = () => this.onDelete(message);
    const dateTime = moment(message.createDate).format('MMM D, YYYY h:mm A');

    return (
      <tr key={messageId}>
        <StyledTD onClick={onReply}>
          <StyledFaMailReply size={20} />
        </StyledTD>
        <StyledTD onClick={onDelete}>
          <StyledFaClose size={20} />
        </StyledTD>
        <StyledTD onClick={onReply}>
          <StyledText isNew={isNew}>{senderName}</StyledText>{' '}
          <StyledSubtext isNew={isNew}>{senderEmail}</StyledSubtext>
        </StyledTD>
        <StyledTD onClick={onReply}>
          <StyledText isNew={isNew}>{subject}</StyledText>
        </StyledTD>
        <StyledTD onClick={onReply}>
          <StyledText smallText isNew={isNew}>
            {body}
          </StyledText>{' '}
          <StyledSubtext smallText isNew={isNew}>
            {dateTime}
          </StyledSubtext>
        </StyledTD>
        <StyledTD onClick={onReply}>
          <StyledText isNew={isNew}>
            {metroCity}, {metroState}
          </StyledText>
        </StyledTD>
      </tr>
    );
  };

  renderMessages = () => (
    <StyledTable striped>
      <thead className="text-center">
        <tr>
          <th colSpan={2} />
          <th>To</th>
          <th>Subject</th>
          <th>Message</th>
          <th>Metro</th>
        </tr>
      </thead>
      <tbody>{this.props.messages.map(this.renderMessage)}</tbody>
      <tfoot />
    </StyledTable>
  );

  renderReplyModal = () => {
    const { message, showReplyModal } = this.state;
    const { errorMessage } = this.props;
    const { body, senderEmail, senderName, subject } = message;

    const replyErrorMessage = errorMessage.length ? (
      <Alert color="danger">{errorMessage}</Alert>
    ) : null;

    const dateTime = moment(message.createDate).format('MMM D, YYYY h:mm A');

    return (
      <Modal isOpen={showReplyModal} toggle={this.toggleReplyModal}>
        <ModalHeader
          className="d-flex align-items-center pl-5 pr-5"
          toggle={this.toggleReplyModal}
        >
          Reply to Message
        </ModalHeader>
        <ModalBody className="pl-5 pr-5">
          <Alert color="primary">
            <StyledText>{senderName}</StyledText>
            <StyledSubtext>{senderEmail}</StyledSubtext>
            <StyledModalMessageText>{subject}</StyledModalMessageText>
            <StyledText>{body}</StyledText>
            <StyledModalMessageDateTime>{dateTime}</StyledModalMessageDateTime>
          </Alert>
          <Formik
            initialValues={{
              subject: '',
              body: ''
            }}
            validationSchema={validationSchema}
            ref={node => (this.replyForm = node)}
            onSubmit={this.onReplyFormSubmit}
            render={formProps => (
              <Form onSubmit={formProps.handleSubmit}>
                {replyErrorMessage}
                <Input name="subject" label="Subject" required {...formProps} />
                <Input
                  name="body"
                  label="Response"
                  required
                  rows="4"
                  type="textarea"
                  {...formProps}
                />
              </Form>
            )}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.toggleReplyModal}>
            Cancel
          </Button>{' '}
          <Button
            color="secondary"
            className="text-white"
            onClick={this.onReplyButton}
          >
            Send
          </Button>{' '}
        </ModalFooter>
      </Modal>
    );
  };

  renderDeleteModal = () => {
    const { errorMessage } = this.props;
    const deleteErrorMessage = errorMessage.length ? (
      <Alert color="danger">{errorMessage}</Alert>
    ) : null;

    return (
      <Modal
        isOpen={this.state.showDeleteModal}
        toggle={this.toggleDeleteModal}
      >
        <ModalHeader
          className="d-flex align-items-center pl-5 pr-5"
          toggle={() => {}}
        >
          Delete Message
        </ModalHeader>
        <ModalBody className="pl-5 pr-5">
          <h5>Are you sure you want to delete this message?</h5>
          <StyledText>{this.state.message.subject}</StyledText>
          {deleteErrorMessage}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.toggleDeleteModal}>
            Cancel
          </Button>{' '}
          <Button
            color="danger"
            className="text-white"
            onClick={this.onDeleteMessage}
          >
            Yes, Delete This Message
          </Button>{' '}
        </ModalFooter>
      </Modal>
    );
  };

  render() {
    return (
      <div className="pt-5">
        <Section>
          <Row>
            <Col xs="12" className="text-center">
              <h1>Messages</h1>
            </Col>
          </Row>
          <Row>
            <Menu history={this.props.history} />
            <Col xs="9">
              <Row>{this.renderMessages()}</Row>
            </Col>
          </Row>
        </Section>
        {this.renderReplyModal()}
        {this.renderDeleteModal()}
        <Footer />
      </div>
    );
  }
}

const validationSchema = Yup.object().shape({
  subject: Yup.string().required('Subject is required'),
  body: Yup.string().required('Response is required')
});

const mapStateToProps = ({ subscriberMessages }) => ({
  errorMessage: subscriberMessages.errorMessage,
  messages: subscriberMessages.messages
});

export default connect(mapStateToProps, {
  clearDeleteMessage,
  deleteMessage,
  fetchMessages,
  markMessageAsRead,
  replyToMessage
})(Messages);
