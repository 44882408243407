import React from 'react';
import glamorous from 'glamorous';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const StyledCloseDetailsButton = glamorous(Button)({
  fontWeight: 400
});

const renderAmenityInModal = amenity => {
  if (amenity.enabled) {
    return <li key={amenity.name + amenity.id}>{amenity.name}</li>;
  } else {
    return (
      <li key={amenity.name + amenity.id}>
        <strike>{amenity.name}</strike>
      </li>
    );
  }
};

const SearchAmenitiesModal = props => {
  const result = props.selectedResult;
  if (result == null) return null;

  const { company } = result;

  const busResult = props.selectedBusResult;
  if (busResult == null) return null;

  return (
    <Modal
      isOpen={props.detailAmenityModalVisible}
      toggle={props.closeAmenityDetailsModal}
      className={props.className}
      size="md"
    >
      <ModalHeader toggle={props.closeAmenityDetailsModal}>
        {company.name} - {busResult.busType}
      </ModalHeader>
      <ModalBody>
        <ul>
          {busResult.amenities.map(amenity => renderAmenityInModal(amenity))}
        </ul>
      </ModalBody>
      <ModalFooter>
        <StyledCloseDetailsButton
          color="link"
          onClick={props.closeAmenityDetailsModal}
        >
          Close
        </StyledCloseDetailsButton>
      </ModalFooter>
    </Modal>
  );
};

export default SearchAmenitiesModal;
