// @flow

import moment from 'moment';
import { reducer as formReducer } from 'redux-form';
import {
  createTransform,
  persistCombineReducers,
  persistReducer
} from 'redux-persist';

import storage from 'redux-persist/es/storage';
import homeReducer from './home';
import metadataReducer from './metadata';
import reviewReducer from './review';
import searchReducer from './search';
import confirmationReducer from './confirmation';
import subscribe from './subscribe';
import newsletterReducer from './newsletter';
import loginReducer, { INITIAL_STATE as LOGIN_INITIAL_STATE } from './login';
import signup from './signup';
import subscriberForgotReducer from './subscriber/forgot';
import subscriberBidPositions from './subscriber/bid';
import subscriberCompany from './subscriber/company';
import subscriberListing from './subscriber/listing';
import subscriberCrashListings from './subscriber/listings';
import subscriberListings from './subscriber/listings';
import subscriberPayments from './subscriber/payments';

import stripePaymentInfo from './subscriber/stripePaymentInfo';

import subscriberReports from './subscriber/reports';
import subscriberReviews from './subscriber/reviews';
import subscriberMessages from './subscriber/messages';
import userQuotes from './user/quote';
import userMessages from './user/userMessages';
import userProfile from './user/userProfile';

import init3 from './setup/init3';
import init4 from './setup/init4';
import init5 from './setup/init5';

// Remember Me
const checkRememberMe = state => {
  if (state.rememberMe) {
    if (state.rememberMeUntil) {
      if (moment(state.rememberMeUntil) <= moment()) return LOGIN_INITIAL_STATE;
    } else {
      state['rememberMeUntil'] = moment().add(2, 'weeks');
    }
  }

  return state;
};

const rememberMeTransform = createTransform(
  state => checkRememberMe(state),
  state => checkRememberMe(state),
  { whitelist: ['login'] }
);

export const persistConfig = {
  key: 'root',
  transforms: [rememberMeTransform],
  storage,
  whitelist: ['search', 'login']
};

const searchPersistConfig = {
  key: 'search',
  storage: storage,
  whitelist: [
    'departureCity',
    'departureState',
    'latitude',
    'longitude',
    'quoteRequests'
  ]
};

const rootReducer = persistCombineReducers(persistConfig, {
  confirmation: confirmationReducer,
  form: formReducer,
  home: homeReducer,
  metadata: metadataReducer,
  review: reviewReducer,
  login: loginReducer,
  newsletter: newsletterReducer,
  signup: signup,
  search: persistReducer(searchPersistConfig, searchReducer),
  subscribe: subscribe,
  subscriberCompany: subscriberCompany,
  subscriberForgot: subscriberForgotReducer,
  subscriberListing: subscriberListing,
  subscriberListings: subscriberListings,
  subscriberCrashListings: subscriberCrashListings,
  subscriberBidPositions: subscriberBidPositions,
  subscriberReviews: subscriberReviews,
  subscriberMessages: subscriberMessages,
  subscriberReports: subscriberReports,
  subscriberPayments: subscriberPayments,
  stripePaymentInfo: stripePaymentInfo,
  userQuotes: userQuotes,
  userMessages: userMessages,
  userProfile: userProfile,
  init3: init3,
  init4: init4,
  init5: init5,
});

export default rootReducer;
