import withAuthentication, { ROUTE_TYPES } from '../withAuthentication';
import withTracker from '.././withTracker';
import Payments from '../../containers/subscriber/Payments';

const subscriberPayments = {
  path: '/subscribers/payments',
  component: withTracker(withAuthentication(Payments, ROUTE_TYPES.SUBSCRIBER))
};

export default subscriberPayments;
