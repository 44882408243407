import React from "react";
import { Span } from "glamorous";

const InlineLogo = props => [
  <Span css={{ fontSize: 18 }} key="bus" className="text-primary font-weight-bold">
    Bus
  </Span>,
  <Span css={{ fontSize: 18 }} key="rates" className="text-secondary font-weight-bold">
    Rates
  </Span>
];

export default InlineLogo;
