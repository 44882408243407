import React, { Component } from 'react';
import glamorous from 'glamorous';
import { connect } from 'react-redux';
import FileDownload from 'js-file-download';
import { DateRangePicker } from 'react-dates';
import { Formik } from 'formik';
import Yup from 'yup';
import moment from 'moment';
import {
  metroBreakdownAsCsv,
  salesPushAsCsv,
  umaCompaniesAsCsv,
  metroRankingStatsAsCsv,
  registeredUsersAsCsv
} from '../../services/admin/reportsService';

import {
  Button,
  Col,
  Form,
  FormGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  Row,
} from 'reactstrap';

import {
  Footer,
  Section,
  Spinner
} from '../../components';

import Menu from '../../components/admin/Menu';

const StyledListGroupItem = glamorous(ListGroupItem)({
  paddingTop: 10
});

const StyledListGroupItemText = glamorous(ListGroupItemText)({
  marginBottom: 0
})

class Reports extends Component {
  state = { loading: false };

  renderListGroupItem = (header, text) => {
    return (
      <StyledListGroupItem>
        <ListGroupItemHeading>{header}</ListGroupItemHeading>
        <StyledListGroupItemText>
          {text}
        </StyledListGroupItemText>
      </StyledListGroupItem>
    );
  }

  exportMetroBreakdown = e => {
    this.setState({
      loading: true
    });

    metroBreakdownAsCsv().then(response => {
      FileDownload(response.data, 'metro_breakdown.csv');
      this.setState({
        loading: false
      });
    })
  }

  exportSalesPush = e => {
    this.setState({
      loading: true
    });

    salesPushAsCsv().then(response => {
      FileDownload(response.data, 'sales_push.csv');
      this.setState({
        loading: false
      });
    })
  }

  exportRegisteredUsers = e => {
    this.setState({
      loading: true
    });

    registeredUsersAsCsv().then(response => {
      FileDownload(response.data, 'registered_users.csv');
      this.setState({
        loading: false
      });
    })
  }

  exportUmaCompanies = e => {
    this.setState({
      loading: true
    });

    umaCompaniesAsCsv().then(response => {
      FileDownload(response.data, 'uma_companies.csv');
      this.setState({
        loading: false
      });
    })
  }

  exportMetroRankingStats = values => {
    this.setState({
      loading: true
    });

    var start_date_file_name = `${moment(values.startDate).format('MM-DD-YYYY')}`
    var end_date_file_name = `${moment(values.endDate).format('MM-DD-YYYY')}`

    const dates = {
      startDate: moment(values.startDate).format('YYYY-MM-DD'),
      endDate: moment(values.endDate).format('YYYY-MM-DD')
    };

    metroRankingStatsAsCsv(dates).then(response => {
      FileDownload(response.data, `metro_ranking_stats_${start_date_file_name}_${end_date_file_name}.csv`);
      this.setState({
        loading: false
      });
    })
  }

  renderSpinner = () => {
    if (!this.state.loading) return null;

    return <Spinner text="Loading" />;
  }

  renderMetroBreakdown = () => {
    return (
      <div>
        <h3>Metro Breakdown</h3>
        <p>
          This will csv export a cost breakdown of all companies metros. This report is large and could take several minutes to run so be patient after clicking the button.
        </p>
        <Button
          className="text-white mr-2"
          color="primary"
          onClick={this.exportMetroBreakdown}
          disabled={this.state.loading}
        >
          Export Metro Breakdown
        </Button>
      </div>
    );
  }

  renderSalesPush = () => {
    return (
      <div>
        <h3>Sales Push</h3>
        <p>
          This will csv export all companies in the system for a sales push. Information is Company Name, Company Contact Name, contact email, Company mailing address, Company Phone number, have a profile photo or not, and how many photos they have (including profile).
        </p>
        <Button
          className="text-white mr-2"
          color="primary"
          onClick={this.exportSalesPush}
          disabled={this.state.loading}
        >
          Export Sales Push
        </Button>
      </div>
    );
  }

  renderRegisteredUsers = () => {
    return (
      <div>
        <h3>Registered Users</h3>
        <p>
          This will csv export all registered users in the system for marketing purposes. Information is Name, Email and how many RFQ's they have submitted.
        </p>
        <Button
          className="text-white mr-2"
          color="primary"
          onClick={this.exportRegisteredUsers}
          disabled={this.state.loading}
        >
          Export Registered Users
        </Button>
      </div>
    );
  }

  renderUmaCompanies = () => {
    return (
      <div>
        <h3>UMA Companies</h3>
        <p>
          This will csv export all companies that have the UMA Member checked. Information is Company Id, Company Name, IsActive and IsApproved.
        </p>
        <Button
          className="text-white mr-2"
          color="primary"
          onClick={this.exportUmaCompanies}
          disabled={this.state.loading}
        >
          Export UMA Companies
        </Button>
      </div>
    );
  }

  renderMetroRankingStats = () => {
    return (
      <div>
        <h3>Metro Listing Position Stats</h3>
        <p>
          This will csv export stats about the metro listing positions such as RFQ's and Clicks for a date range. Information is Metro id, Metro City, Metro State, Listing Position, RFQs and Clicks.
          <br/>
          <strong><i>Note: There is a gap of Click Stats between 1/16/2018 and 4/28/2018 so that date range won't have accurate click stats.</i></strong>
        </p>
        <Formik
          ref={node => (this.metroListingPositionStatsForm = node)}
          initialValues={{
            startDate: moment().add(-1, 'weeks'),
            endDate: moment()
          }}
          validationSchema={Yup.object().shape({
            startDate: Yup.date()
              .typeError('Start Date is required')
              .required('Start Date is required'),
            endDate: Yup.date()
              .typeError('End Date is required')
              .required('End Date is required')
          })}
          onSubmit={this.exportMetroRankingStats}
          render={({ values, setValues }) => (
            <Form
              id="metro-ranking-stats-form"
              style={{ border: '1px solid #e9ecef', padding: '1em' }}
              className="mb-4"
            >
              <FormGroup className="date-range-picker">
                <DateRangePicker
                  startDate={values.startDate}
                  startDateId="reports-start-date"
                  endDate={values.endDate}
                  endDateId="reports-end-date"
                  onDatesChange={({ startDate, endDate }) =>
                    setValues({ ...values, startDate, endDate })
                  }
                  focusedInput={this.state.focusedInput}
                  onFocusChange={focusedInput => this.setState({ focusedInput })}
                  isOutsideRange={() => false}
                  minimumNights={0}
                  hideKeyboardShortcutsPanel={true}
                />
              </FormGroup>
              <Button
                className="text-white mr-2"
                color="primary"
                onClick={() => {
                  this.metroListingPositionStatsForm.submitForm();
                }}
                disabled={this.state.loading}
              >
                Export Metro Listing Position Stats
              </Button>
            </Form>
          )}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="pt-5" style={{fontWeight: '500'}}>
        <Section>
          <Row>
            <Col xs="12" className="text-center">
              <h1>Reports</h1>
            </Col>
          </Row>
          <Row>
            <Menu history={this.props.history} />
            <Col xs="12" s="12" md="9">
              {this.renderMetroBreakdown()}
              <hr/>
              {this.renderSalesPush()}
              <hr/>
              {this.renderRegisteredUsers()}
              <hr/>
              {this.renderUmaCompanies()}
              <hr/>
              {this.renderMetroRankingStats()}
              <hr/>
              {this.renderSpinner()}
            </Col>
          </Row>
        </Section>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = ( ) => ({
});

export default connect(mapStateToProps, {
})(Reports);
