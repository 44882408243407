import React, {Component} from 'react';
import glamorous from 'glamorous';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import FaCheck from 'react-icons/lib/fa/check';
import FaExclamationTriangle from 'react-icons/lib/fa/exclamation-triangle';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faTimes from '@fortawesome/fontawesome-free-solid/faTimes';

import {
    Button,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Table,
    Jumbotron,
    Alert
} from 'reactstrap';

import {Footer, Section} from '../../components';

import Menu from '../../components/subscriber/Menu';

import {
    addNewMetro,
    fetchListings,
    fetchMetrosInRadius,
    fetchNewMetros,
    removeMetro,
    updateProperty
} from '../../actions/subscriber/crash';
import {
    selfPayToAddMetroResults,
    selfPayToAddMetrosResults,
    validPaymentInfo
} from "../../services/subscriber/listingsService";

const StyledTable = glamorous(Table)({
    fontWeight: 400
});

const StyledAddNewMetroButton = glamorous(Button)({
    marginRight: 37
});

class Crash extends Component {
    state = {
        addNewListingModalVisible: false,
        typeaheadIsLoading: false,
        findMetrosButtonEnabled: false,
        payModalVisible: false,
        payAllModalVisible: false,
        payInfo: {
            companyMetroId: 0,
            companyId: 0,
            metroName: '',
            cost: ''
        },
        payAllInfo: {
            companyMetroIds: [],
            companyId: 0,
            metroNames: [],
            costTotal: '',
            proratedTotal: '',
            discountRate: '',
            proratedTotalAfterDiscount: ''
        },
        loadingCssName: "",
        canSubmit: true,
        payDisabled: false,
        listDataLoadingCssName: '',
        dataDisplay: false
    };

    componentWillMount = () => {
        // this.props.fetchListings();
        this.getFetchListings();
    };

    getFetchListings = () => {
        this.setState({listDataLoadingCssName: 'dataLoading', dataDisplay: false})
        this.props.fetchListings().then(() => {
            this.setState({listDataLoadingCssName: '', dataDisplay: true})
        })
    };

    renderApproved = () => (
        <span title="Active/Approved">
      <FaCheck color="#008000"/>
    </span>
    );

    renderPendingRemoval = () => (
        <span title="Pending Removal">
      <FontAwesomeIcon color="#dc3545" icon={faTimes}/>
    </span>
    );

    renderPendingApproval = () => (
        <span title="Pending Approval">
      <FaExclamationTriangle color="#e69a2b"/>
    </span>
    );

    renderCost = cost => {
        if (cost === 'Pending Approval') {
            return this.renderPendingApproval();
        }

        if (cost === 'Pending Removal') {
            return this.renderPendingRemoval();
        }

        return cost;
    };

    renderProrated = cost => {
        if (cost === 'Pending Approval') {
            return this.renderPendingApproval();
        }

        if (cost === 'Pending Removal') {
            return this.renderPendingRemoval();
        }

        return "$" + cost;
    };

    renderPosition = position => {
        if (position === 'Pending Approval') {
            return this.renderPendingApproval();
        }

        if (position === 'Pending Removal') {
            return this.renderPendingRemoval();
        }

        return position;
    };

    renderRemoveButton = (status, companyMetroId) => (
        <Button
            color="danger"
            block
            disabled={status === 'Pending Removal'}
            onClick={() => this.onRemoveButtonClick(companyMetroId)}
        >
            Remove
        </Button>
    );

    renderEditButton = (status, companyMetroId) => (
        <Button
            color="primary"
            block
            disabled={status !== 'Active/Approved'}
            onClick={() => this.onEditButtonClick(companyMetroId)}
        >
            Edit
        </Button>
    );

    renderPayButton = (status, companyMetroId, companyId, metroName, cost) => (
        <Button
            color="info"
            block
            disabled={!(status == 'Pending Approval')}
            hidden={!(status == 'Pending Approval')}
            onClick={() => this.onShowPayContentClick(companyMetroId, companyId, metroName, cost)}
        >
            Pay
        </Button>
    );

    renderTips = () => (
        <span
            title={'This is the prorated billing amount between ' + (this.props.today ? this.props.today : '[today\' date]') + ' and ' + (this.props.nextRecurringDate ? this.props.nextRecurringDate : '[the recurring date of the next month]') + '.'}>
            <svg t="1578970698752" className="icon" viewBox="0 0 1024 1024" version="1.1"
                 xmlns="http://www.w3.org/2000/svg"
                 p-id="1117" width="15" height="15">
            <path
                d="M509.885849 124.731816c-212.664517 0-385.02919 172.361603-385.02919 385.02612 0 212.667587 172.364673 385.02919 385.02919 385.02919s385.02612-172.361603 385.02612-385.02919C894.91197 297.092395 722.550367 124.731816 509.885849 124.731816zM565.361325 799.866267 446.992427 799.866267 446.992427 685.752276l118.367875 0L565.360302 799.866267zM674.779364 472.152457c-9.181103 11.411911-28.891004 28.634154-59.236126 51.871391-25.414833 18.983353-41.522696 35.270295-48.342008 49.067532-6.742565 13.796214-10.088776 32.832779-10.088776 57.212026L449.146487 630.303406c0-51.401694 11.592013-92.015693 34.855856-121.738645 3.292999-3.837398 24.63712-22.977317 64.188928-57.626465 22.692838-20.539802 34.078143-44.348044 34.078143-71.425749 0-21.681812-7.987928-39.729863-23.911596-54.357002-15.97688-14.576997-34.469046-21.88852-55.552224-21.88852-21.110807 0-39.240723 7.571443-54.360072 22.77061-17.298991 16.909112-28.113291 41.3385-32.391734 73.393567l-112.737644 0c0-57.419757 17.686824-103.219873 53.088102-137.555889 35.478026-34.44244 82.083483-51.611471 139.916657-51.611471 65.460898 0 116.604718 15.767102 153.37825 47.409776 36.803207 31.640628 55.137785 76.19538 55.137785 133.407406C704.836937 421.943938 694.800349 448.968431 674.779364 472.152457z"
                p-id="1118"></path>
        </svg></span>
    );

    renderListingResults = () => {
        const {listings} = this.props;

        if (!listings) return null;

        return listings.map(result => (
            <tr key={result.companyMetroId}>
                <td className="align-middle">
                    <Link
                        to={'/search/' + result.city + '/' + result.state}
                        target="_blank"
                    >
                        {result.metroName}
                    </Link>
                </td>
                <td className="align-middle">{this.renderCost(result.cost)}</td>
                <td className="align-middle">{this.renderProrated(result.payTotal)}</td>
                <td>
                    {/*{this.renderEditButton(result.status, result.companyMetroId)}*/}
                    {/*{this.renderPayButton(result.status, result.companyMetroId, result.companyId, result.metroName, result.payTotal)}*/}
                    {this.renderRemoveButton(result.status, result.companyMetroId)}
                </td>
            </tr>
        ));
    };

    renderListings = () => (
        <StyledTable striped>
            <thead className="text-center">
            <tr>
                <th>Metro</th>
                <th>Cost</th>
                <th>Prorated{this.renderTips()}</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody style={{"display": this.state.dataDisplay ? "table-row-group" : "none"}}
                   className="text-center">{this.renderListingResults()}</tbody>
            <tfoot>
            </tfoot>
        </StyledTable>
    );

    onShowPayContentClick = (companyMetroId, companyId, metroName, cost) => {
        let date = this.state.payInfo
        date.companyMetroId = companyMetroId
        date.companyId = companyId
        date.metroName = metroName
        date.cost = cost
        this.setState({
            payModalVisible: true,
            payInfo: date
        })
    };

    onShowPayAllContentClick = () => {
        const listings = this.props.listings

        const companyMetroIds = []
        let proratedTotal = 0
        let costTotal = 0
        let coastCycle = 0
        listings.forEach(listing => {
            companyMetroIds.push(listing.companyMetroId)
            if (listing.payTotal !== 'N/A') {
                proratedTotal = this.accAdd(proratedTotal, Number(listing.payTotal))
            }
            costTotal = this.accAdd(costTotal, Number(listing.costValue))
            coastCycle = listing.costCycle
        })

        let date = this.state.payAllInfo

        date.companyId = this.props.companyId
        date.companyMetroIds = companyMetroIds
        date.proratedTotal = proratedTotal
        date.costTotal = costTotal + (coastCycle ? "/" + coastCycle : "")
        date.discountRate = this.props.discountRate
        date.proratedTotalAfterDiscount = this.props.proratedTotalAfterDiscount
        this.setState({
            payAllModalVisible: true,
            payAllInfo: date
        })
    };

    closePayModel = () => {
        let date = this.state.payInfo
        date.companyMetroId = 0
        date.companyId = 0
        date.metroName = ''
        date.cost = ''
        this.setState({
            payModalVisible: false,
            payInfo: date
        })
    };

    closePayAllModel = () => {
        let date = this.state.payAllInfo
        date.companyMetroIds = []
        date.companyId = 0
        date.metroNames = []
        date.cost = ''
        this.setState({
            payAllModalVisible: false,
            payAllInfo: date
        })
    };

    onRemoveButtonClick = companyMetroId => {
        if (
            !window.confirm(
                'Please Note: After removing a metro, please be aware that the displayed pricing may require adjustment in cases where the removed metro was part of a special discount or package deal. We will email you with confirmation of your new rate within two business days. Do you want to continue and Remove the metro?'
            )
        )
            return false;

        this.setState({listDataLoadingCssName: 'dataLoading', dataDisplay: false})
        this.props.removeMetro(companyMetroId).then(() => {
            // this.props.fetchListings();
            this.getFetchListings();
        });
    };

    onEditButtonClick = companyMetroId => {
        this.props.history.push('/subscribers/listings/' + companyMetroId);
    };

    onPayClick = () => {
        if (this.state.canSubmit) {
            this.setState({
                canSubmit: false,
                loadingCssName: "buttonLoading",
                payDisabled: true
            });
            let companyMetroId = this.state.payInfo.companyMetroId
            let companyId = this.state.payInfo.companyId
            selfPayToAddMetroResults({
                company_metro_id: companyMetroId,
                company_id: companyId
            }).then(response => {
                this.setState({
                    canSubmit: true,
                    loadingCssName: "",
                    payDisabled: false
                });
                if (response.status === 200) {
                    const {success} = response.data
                    if (success) {
                        this.closePayModel()
                        this.props.history.push('/subscribers/listings/' + companyMetroId);
                    } else {
                        alert(response.data.error_message)
                    }
                } else {
                    alert('NetWork Error')
                }
            });
        }
    };

    accAdd = (arg1, arg2) => {
        var t1 = 0, t2 = 0, m;
        try {
            t1 = arg1.toString().split(".")[1].length;
        } catch (e) {
            t1 = 0;
        }
        try {
            t2 = arg2.toString().split(".")[1].length;
        } catch (e) {
            t2 = 0;
        }
        m = Math.pow(10, Math.max(t1, t2));
        return (arg1 * m + arg2 * m) / m;
    };

    onPayAllClick = () => {

        if (this.state.canSubmit) {
            this.setState({
                canSubmit: false,
                loadingCssName: "buttonLoading",
                payDisabled: true
            });
            let companyMetroId = this.state.payAllInfo.companyMetroIds
            let companyId = this.state.payAllInfo.companyId
            selfPayToAddMetrosResults({
                company_metro_ids: companyMetroId,
                company_id: companyId
            }).then(response => {
                this.setState({
                    canSubmit: true,
                    loadingCssName: "",
                    payDisabled: false
                });
                if (response.status === 200) {
                    const {success} = response.data
                    if (success) {
                        this.closePayAllModel()
                        // this.props.fetchListings();
                        this.getFetchListings();
                    } else {
                        alert(response.data.error_message)
                        if(response.data.error_message.indexOf("missing") != -1) {
                            this.props.history.push('/subscribers/payments');
                        }
                    }
                } else {
                    alert('NetWork Error')
                }
            });
        }
    };

    renderAllPayModal = () => (
        <Modal isOpen={this.state.payAllModalVisible} toggle={this.closePayAllModel}>
            <ModalHeader toggle={this.closePayAllModel} className="d-flex align-items-center pl-5 pr-5">Pay
                Content</ModalHeader>
            <ModalBody>
                <Jumbotron>
                    <p className="lead"><b>Total billing: &nbsp;</b>${this.state.payAllInfo.costTotal}</p>
                    {/*<hr className="my-2"/>*/}
                    <p><b>Prorated Billing: &nbsp;</b>${this.state.payAllInfo.proratedTotal}</p>
                    <p><b>DiscountRate: &nbsp;</b>{this.state.payAllInfo.discountRate}</p>
                    <p><b>Total: &nbsp;</b>${this.state.payAllInfo.proratedTotalAfterDiscount}</p>
                    <hr className="my-2"/>
                    <p><em>You will be charged a prorated billing amount
                        between {this.props.today ? this.props.today : '[today\' date]'} and {this.props.nextRecurringDate ? this.props.nextRecurringDate : '[the recurring date of the next month]'}.</em>
                    </p>
                </Jumbotron>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" disabled={this.state.payDisabled} className={this.state.loadingCssName}
                        onClick={this.onPayAllClick}>Pay</Button>{' '}
                <Button color="secondary" onClick={this.closePayAllModel}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );

    renderPayModal = () => (
        <Modal isOpen={this.state.payModalVisible} toggle={this.closePayModel}>
            <ModalHeader toggle={this.closePayModel} className="d-flex align-items-center pl-5 pr-5">Pay
                Content</ModalHeader>
            <ModalBody>
                <Jumbotron>
                    <p className="lead"><b>Metro:</b> {this.state.payInfo.metroName}</p>
                    <hr className="my-2"/>
                    <p><b>Total: &nbsp;</b>$ {this.state.payInfo.cost}</p>
                </Jumbotron>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" disabled={this.state.payDisabled} className={this.state.loadingCssName}
                        onClick={this.onPayClick}>Pay</Button>{' '}
                <Button color="secondary" onClick={this.closePayModel}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );

    renderPayAllButton = () => {
        const {isFreeTrial} = this.props;
        if(!isFreeTrial){
            return (
                <div style={{"display": this.props.listings && this.props.listings.length > 0 ? "block" : "none"}}>
                    <StyledAddNewMetroButton
                        className="text-white float-right"
                        onClick={this.onShowPayAllContentClick}>
                        Pay
                    </StyledAddNewMetroButton>
                </div>
            );
        }
        
    };

    renderNoDataInfo = () => (
            <div style={{"display": this.props.listings && this.props.listings.length <= 0 ? "block" : "none"}}>
                <Alert color="danger">
                    You are now showing up in hundreds of searches for group transportation each week! We find that the most successful operations are those that can communicate quickly!
                </Alert>
            </div>
        );

    render() {
        return (
            <div className="pt-5">
                <Section>
                    <Row>
                        <Col xs="12" className="text-center">
                            <h1>Invoice</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Menu history={this.props.history}/>
                        <Col xs="9" className={this.state.listDataLoadingCssName}>
                            <Row>{this.renderListings()}</Row>
                            <Row style={{"display": this.state.dataDisplay ? "block" : "none"}} className="pb-2">
                                <Col xs="12">{this.renderPayAllButton()}</Col>
                                <Col xs="12">{this.renderNoDataInfo()}</Col>
                            </Row>
                        </Col>
                    </Row>
                </Section>
                <Footer/>
                {this.renderPayModal()}
                {this.renderAllPayModal()}
            </div>
        );
    }
}

const mapStateToProps = ({subscriberCrashListings, login}) => ({
    companyId: login.companyId,
    listings: subscriberCrashListings.listings,
    locations: subscriberCrashListings.locations,
    totals: subscriberCrashListings.totals,
    metrosInRadius: subscriberCrashListings.metrosInRadius,
    newMetroSearchTerm: subscriberCrashListings.newMetroSearchTerm,
    companyApproved: subscriberCrashListings.companyApproved,
    today: subscriberCrashListings.today,
    nextRecurringDate: subscriberCrashListings.nextRecurringDate,
    discountRate: subscriberCrashListings.discountRate,
    proratedTotalAfterDiscount: subscriberCrashListings.proratedTotalAmount
});

export default connect(mapStateToProps, {
    addNewMetro,
    fetchListings,
    fetchMetrosInRadius,
    fetchNewMetros,
    removeMetro,
    updateProperty
})(Crash);
