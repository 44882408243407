import {
  buildAvailableAssociations,
  buildCompanyAssociations
} from '../reducerHelpers';

const INITIAL_STATE = {
  additionalPhotos: [],
  allAssociations: [],
  associations: [],
  dotNumber: '',
  email: '',
  responseState: '',
  successMessage: '',
  errorMessage: '',
  information: {
    city: '',
    contactEmail: '',
    contactName: '',
    contactPhone: '',
    contactPhoneExtension: '',
    description: '',
    facebookLink: '',
    hasReceptiveServices: false,
    instagramLink: '',
    isTourOperator: false,
    name: '',
    state: '',
    street: '',
    twitterLink: '',
    youtubeLink: '',
    zip: ''
  },
  paymentOptions: {
    allowsAmex: false,
    allowsCheck: false,
    allowsDiscover: false,
    allowsMastercard: false,
    allowsPaypal: false,
    allowsVisa: false
  },
  photoFilename: null,
  photoUrl: '',
  selectedAssociations: [],
  availableAssociations: [],
  associationTypes: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'FETCH_COMPANY_INFO_SUCCESS': {
      const companyInfo = action.payload;
      delete companyInfo.success;
      delete companyInfo.errorMessage;

      const associations = buildCompanyAssociations(action.payload);
      const allAssociations = buildAvailableAssociations(action.payload);

      return { ...state, ...action.payload, allAssociations, associations };
    }

    case 'UPDATE_PROFILE_MANAGEMENT_REQUEST':
      return { ...state, errorMessage: '' };

    case 'UPDATE_PROFILE_MANAGEMENT_SUCCESS':
      return {
        ...state,
        information: {
          ...state.information,
          ...action.payload,
          hasReceptiveServices: action.payload.hasReceptiveServices === 'true',
          isTourOperator: action.payload.isTourOperator === 'true'
        }
      }; // Converting "true" or "false" to boolean

    case 'UPDATE_PROFILE_MANAGEMENT_FAIL':
      return { ...state, errorMessage: action.payload };

    case 'UPDATE_COMPANY_DETAIL_REQUEST':
      return { ...state, errorMessage: '' };

    case 'UPDATE_COMPANY_SERVICES_REQUEST':
      return { ...state, errorMessage: '' };

    case 'UPDATE_COMPANY_SERVICES_SUCCESS':
      return { ...state, successMessage: 'Save Successful.', responseState: 'SUCCESS' };

    case 'UPDATE_COMPANY_SERVICES_FAIL':
      return { ...state, errorMessage: 'Network error. Please try again.', responseState: 'ERROR' };


    case 'UPDATE_COMPANY_HIGHLIGHTS_REQUEST':
      return { ...state, errorMessage: '' };

    case 'UPDATE_COMPANY_HIGHLIGHTS_SUCCESS':
      return { ...state, successMessage: 'Save Successful.', responseState: 'SUCCESS' };

    case 'UPDATE_COMPANY_HIGHLIGHTS_FAIL':
      return { ...state, errorMessage: 'Network error. Please try again.', responseState: 'ERROR' };

    case 'UPDATE_COMPANY_DETAIL_SUCCESS': {
      const associations = buildCompanyAssociations({
        associationTypes: state.associationTypes,
        availableAssociations: state.availableAssociations,
        selectedAssociations: action.payload.selectedAssociations
      });

      return {
        ...state,
        associations,
        selectedAssociations: action.payload.selectedAssociations
      };
    }

    case 'UPDATE_COMPANY_DETAIL_FAIL':
      return { ...state, errorMessage: action.payload };

    case 'ADD_COMPANY_PHOTO_REQUEST':
      return { ...state, errorMessage: action.payload };

    case 'ADD_COMPANY_PHOTO_SUCCESS':
      return { ...state, errorMessage: action.payload };

    case 'ADD_COMPANY_PHOTO_FAIL':
      return { ...state, errorMessage: action.payload };

    case 'DELETE_COMPANY_PHOTO_REQUEST':
      return { ...state, errorMessage: action.payload };

    case 'DELETE_COMPANY_PHOTO_SUCCESS':
      return { ...state, errorMessage: action.payload };

    case 'DELETE_COMPANY_PHOTO_FAIL':
      return { ...state, errorMessage: action.payload };

    case 'UPDATE_COMPANY_PROFILE_PHOTO_REQUEST':
      return { ...state, errorMessage: '' };

    case 'UPDATE_COMPANY_PROFILE_PHOTO_SUCCESS': {
      const { photoUrl, photoFilename } = action.payload;
      return { ...state, photoUrl, photoFilename };
    }

    case 'UPDATE_COMPANY_PROFILE_PHOTO_FAIL':
      return { ...state, errorMessage: action.payload };

    case 'UPDATE_FORMS_OF_PAYMENT_REQUEST':
      return { ...state, errorMessage: '' };

    case 'UPDATE_FORMS_OF_PAYMENT_SUCCESS':
      return { ...state, paymentOptions: action.payload };

    case 'UPDATE_FORMS_OF_PAYMENT_FAIL':
      return { ...state, errorMessage: action.payload };

    case 'UPDATE_PASSENGER_CARRIER_REQUEST':
      return { ...state, errorMessage: '' };

    case 'UPDATE_PASSENGER_CARRIER_SUCCESS':
      return { ...state, dotNumber: action.payload.dotNumber };

    case 'UPDATE_PASSENGER_CARRIER_FAIL':
      return { ...state, errorMessage: action.payload };

    case 'UPDATE_COMPANY_LOGIN_INFO_REQUEST':
      return { ...state, errorMessage: '' };

    case 'UPDATE_COMPANY_LOGIN_INFO_SUCCESS':
      return { ...state, email: action.payload.email };

    case 'UPDATE_COMPANY_LOGIN_INFO_FAIL':
      return { ...state, errorMessage: action.payload };

    default:
      return state;
  }
};
