import React from 'react';
import glamorous from 'glamorous';
import { FormGroup, Input as ReactstrapInput, Label } from 'reactstrap';
import InputMask from 'react-input-mask';

const StyledFormGroup = glamorous(FormGroup)({ marginBottom: '1em' });

export const Input = ({
  className,
  disabled,
  errors,
  handleChange,
  handleBlur,
  label,
  mask,
  name,
  placeholder,
  required,
  rows,
  touched,
  type,
  values
}) => {
  const labelText = required ? `* ${label}` : label;
  const maskProps = mask
    ? {
        mask,
        formatChars: {
          '9': '[0-9]',
          '*': '[0-9.]'
        },
        maskChar: ' ',
        tag: InputMask
      }
    : {};
  return (
    <StyledFormGroup className={className}>
      <Label for={name}>{labelText}</Label>

      <ReactstrapInput
        {...maskProps}
        disabled={disabled}
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        rows={rows || null}
        type={type || 'text'}
        value={values[name]}
        placeholder={placeholder || null}
        style={{'-webkit-appearance': type}}
      />

      {touched[name] &&
        errors[name] && <div className="text-danger mt-1">{errors[name]}</div>}
    </StyledFormGroup>
  );
};

export default Input;
