import API from '../baseService';

export const listingResults = companyMetroId =>
  API.get('/subscribers/listings/' + companyMetroId + '/edit');

export const listingFleet = companyMetroId =>
  API.get(`/subscribers/fleet/${companyMetroId}/listing`).then(
    response => response.data
  );

export const amenities = companyMetroId =>
  API.get(`/subscribers/fleet/${companyMetroId}/amenities`).then(
    response => response.data
  );

export const updateCompanyInformationResults = params =>
  API.post('/subscribers/listings/edit/company', { ...params });

export const updateListingContactResults = params =>
  API.post('/subscribers/listings/edit/contact', { ...params });

export const addPhoto = params =>
  API.post('/subscribers/listings/edit/addphoto', { ...params });

export const updatePhoto = params =>
  API.post('/subscribers/listings/edit/replacephoto', { ...params });

export const updateListingEmailResults = params =>
  API.post('/subscribers/listings/edit/emails', { ...params });

export const updateFleet = ({ companyMetroId, values }) =>
  API.post(`/subscribers/fleet/${companyMetroId}/${values.listingId}/update`, {
    listing_id: values.listingId,
    bus_type_id: values.busTypeId,
    quantity: values.quantity,
    seat_count: values.seatCount,
    year: values.year,
    amenities: values.amenities,
    transfer_rate: values.transferRate,
    five_hour_rate: values.fiveHourRate,
    day_rate: values.dayRate,
    mile_rate: values.mileRate
  }).then(response => response.data);

export const addFleet = ({ companyMetroId, values }) =>
  API.post(`/subscribers/fleet/${companyMetroId}/add`, {
    bus_type_id: values.busTypeId,
    quantity: values.quantity,
    seat_count: values.seatCount,
    year: values.year,
    amenities: values.amenities,
    transfer_rate: values.transferRate,
    five_hour_rate: values.fiveHourRate,
    day_rate: values.dayRate,
    mile_rate: values.mileRate
  }).then(response => response.data);

export const deleteFleet = ({ companyMetroId, listingId }) =>
  API.post(`/subscribers/fleet/${companyMetroId}/${listingId}/delete`).then(
    response => response.data
  );
