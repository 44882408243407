const INITIAL_STATE = {
  about: '',
  company: '',
  email: '',
  errorMessage: '',
  firstName: '',
  id: null,
  lastName: '',
  photoFilename: null,
  photoUrl: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'FETCH_USER_PROFILE_REQUEST':
      return { ...state };

    case 'FETCH_USER_PROFILE_SUCCESS':
      return { ...state, ...action.payload.user };

    case 'FETCH_USER_PROFILE_FAIL':
      return { ...state };

    case 'UPDATE_USER_PROFILE_REQUEST':
      return { ...state, errorMessage: '' };

    case 'UPDATE_USER_PROFILE_SUCCESS':
      return { ...state, ...action.payload };

    case 'UPDATE_USER_PROFILE_FAIL':
      return { ...state, errorMessage: action.payload };

    case 'UPDATE_USER_PROFILE_PHOTO_REQUEST':
      return { ...state, errorMessage: '' };

    case 'UPDATE_USER_PROFILE_PHOTO_SUCCESS': {
      const { photoUrl, photoFilename } = action.payload;
      return { ...state, photoUrl, photoFilename };
    }

    case 'UPDATE_USER_PROFILE_PHOTO_FAIL':
      return { ...state, errorMessage: action.payload };

    case 'UPDATE_USER_LOGIN_INFO_REQUEST':
      return { ...state, errorMessage: '' };

    case 'UPDATE_USER_LOGIN_INFO_SUCCESS':
      return state;

    case 'UPDATE_USER_LOGIN_INFO_FAIL':
      return { ...state, errorMessage: action.payload };

    default:
      return state;
  }
};
