import withAuthentication, { ROUTE_TYPES } from '../withAuthentication';
import withTracker from '../withTracker';
import Messages from '../../containers/subscriber/Messages';

const subscriberMessages = {
  path: '/subscribers/messages',
  component: withTracker(withAuthentication(Messages, ROUTE_TYPES.SUBSCRIBER))
};

export default subscriberMessages;
