import { filter, find } from 'lodash';

export const buildCompanyAssociations = ({
  associationTypes,
  availableAssociations,
  selectedAssociations
}) =>
  associationTypes.map(type => {
    const associationTypeList = selectedAssociations.reduce(
      (selectedAcc, selected) => {
        const association = find(
          availableAssociations,
          available => available.id === selected && available.typeId === type.id
        );
        if (association) selectedAcc.push(association);
        return selectedAcc;
      },
      []
    );
    return { name: type.name, list: associationTypeList };
  });

export const buildAvailableAssociations = ({
  associationTypes,
  availableAssociations
}) =>
  associationTypes.map(type => {
    const typeList = filter(
      availableAssociations,
      association => association.typeId === type.id
    );

    return { name: type.name, list: typeList };
  });
