const INITIAL_STATE = {
  errorMessage: '',
  messages: [],
  numberOfUnreadMessages: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'FETCH_MESSAGES_REQUEST':
      return { ...state };

    case 'FETCH_MESSAGES_SUCCESS': {
      const numberOfUnreadMessages = action.payload.filter(
        message => message.isNew
      ).length;

      return { ...state, messages: action.payload, numberOfUnreadMessages };
    }

    case 'FETCH_MESSAGES_FAIL':
      return { ...state };

    case 'MARK_MESSAGE_AS_READ_SUCCESS': {
      const numberOfUnreadMessages =
        state.numberOfUnreadMessages > 0 ? state.numberOfUnreadMessages - 1 : 0;
      return {
        ...state,
        messages: state.messages.map(
          message =>
            message.messageId === action.payload.messageId
              ? { ...message, isNew: false }
              : message
        ),
        numberOfUnreadMessages
      };
    }

    case 'MARK_MESSAGE_AS_READ_FAIL':
      return { ...state };

    case 'CLEAR_DELETE_MESSAGE':
    case 'DELETE_MESSAGE_REQUEST':
      return { ...state, errorMessage: '' };

    case 'DELETE_MESSAGE_SUCCESS':
      return {
        ...state,
        messages: state.messages.filter(
          message => message.messageId !== action.payload.messageId
        )
      };

    case 'DELETE_MESSAGE_FAIL':
      return { ...state, errorMessage: action.payload };

    case 'REPLY_TO_MESSAGE_REQUEST':
      return { ...state, errorMessage: '' };

    case 'REPLY_TO_MESSAGE_SUCCESS':
      return { ...state };

    case 'REPLY_TO_MESSAGE_FAIL':
      return { ...state, errorMessage: action.payload };

    case 'FETCH_SUBSCRIBER_INFORMATION_SUCCESS':
      return {
        ...state,
        numberOfUnreadMessages: action.payload.numberOfUnreadMessages
      };

    default:
      return state;
  }
};
