import React from 'react';
import uniqueId from 'lodash/uniqueId';
import glamorous from 'glamorous';
import {
  Alert,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import { RatingStars } from '../';

const StyledCloseDetailsButton = glamorous(Button)({
  fontWeight: 400
});

const renderOperatorResponse = review => {
  if (!review || !review.operatorResponse) return null;

  return (
    <div>
      <hr />
      <Alert color="primary">
        <h4 className="alert-heading">Operator Response</h4>
        <p>{review.operatorResponse}</p>
      </Alert>
    </div>
  );
};

const renderReview = ({ review, idx }) => (
  <div className="mb-3" key={uniqueId()}>
    {idx > 0 ? <hr /> : null}
    <div>
      <h5 className="text-primary mb-0">{review.name}</h5>
    </div>
    <div className="mb-2">
      {review.title}
      <RatingStars averageRating={review.rating} />
    </div>
    <div>{review.comment}</div>
    <div>Travelled on {review.travelledOn}</div>
    <div>Posted on {review.publishedOn}</div>
    {renderOperatorResponse(review)}
  </div>
);

const SearchReviewsModal = props => {
  const result = props.selectedResult;

  if (!result) return null;

  const { company, reviews } = result;

  return (
    <Modal
      isOpen={props.reviewsModalVisible}
      toggle={props.closeReviewsModal}
      className={props.className}
      size="md"
    >
      <ModalHeader toggle={props.closeReviewsModal}>
        {company.name} Reviews
      </ModalHeader>
      <ModalBody>
        {reviews.map((review, idx) => renderReview({ review, idx }))}
      </ModalBody>
      <ModalFooter>
        <StyledCloseDetailsButton
          color="link"
          onClick={props.closeReviewsModal}
        >
          Close
        </StyledCloseDetailsButton>
      </ModalFooter>
    </Modal>
  );
};

export default SearchReviewsModal;
