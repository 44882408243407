export default class HighlightsEnum {
  constructor() {
    this.list = [
      {
        name: "Free Estimates",
        type: "1",
        img: ""
      },
      {
        name: "All-inclusive pricing",
        type: "2",
        img: ""
      },
      {
        name: "Timely proposals on your upcoming trip!",
        type: "3",
        img: ""
      },
      {
        name: "Budget-friendly",
        type: "4",
        img: ""
      },
      {
        name: "Trailways-Network",
        type: "5",
        img: ""
      },
      {
        name: "LGBTQ",
        type: "6",
        img: ""
      },
      {
        name: "Rated-By-Bbb",
        type: "7",
        img: ""
      },
      {
        name: "Athletic Groups",
        type: "8",
        img: ""
      },
      {
        name: "Discounts Available",
        type: "9",
        img: ""
      },
      {
        name: "Local-Owned",
        type: "10",
        img: ""
      },
      {
        name: "Satisfaction Guaranteed",
        type: "11",
        img: ""
      },
      {
        name: "Bank-Travel",
        type: "12",
        img: ""
      },
      {
        name: "Faith-Travelers",
        type: "13",
        img: ""
      },
      {
        name: "Luxury",
        type: "14",
        img: ""
      },
      {
        name: "Family-Reunions",
        type: "15",
        img: ""
      },
      {
        name: "Political-Rally",
        type: "16",
        img: ""
      },
      {
        name: "UMA-Clean-Certified",
        type: "17",
        img: ""
      },
      {
        name: "Veteran-Owned",
        type: "18",
        img: ""
      },
      {
        name: "Weddings",
        type: "19",
        img: ""
      },
      {
        name: "Convention",
        type: "20",
        img: ""
      },
      {
        name: "Assistance-With-Destinations",
        type: "21",
        img: ""
      },
      {
        name: "Youth-Trips",
        type: "22",
        img: ""
      },
      {
        name: "On-Time Guarantee",
        type: "23",
        img: ""
      },
      {
        name: "Large-Group Friendly",
        type: "24",
        img: ""
      },
      {
        name: "Commercial Service",
        type: "25",
        img: ""
      },
      {
        name: "XYZ-Years Experience",
        type: "26",
        img: ""
      },
      {
        name: "Multiple-Vehicle Available",
        type: "27",
        img: ""
      },
      {
        name: "IMG-Network",
        type: "28",
        img: ""
      },
      {
        name: "Ada-Wheelchair",
        type: "29",
        img: ""
      },
      {
        name: "Woman-Owned",
        type: "30",
        img: ""
      },
      {
        name: "Skilled-Trained Drivers",
        type: "31",
        img: ""
      },
      {
        name: "Minority-Owned",
        type: "32",
        img: ""
      },
      {
        name: "24-7-Availability",
        type: "33",
        img: ""
      }
    ]
    
  }
  
}
