import React from 'react';
import {
  Alert,
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import { Formik } from 'formik';
import { InputCheckboxGroup } from '../../../components';

const CompanyDetailsModal = ({
  errorMessage,
  modalRef,
  modalVisible,
  onButtonSubmit,
  onFormSubmit,
  toggleModal,
  values
}) => (
  <Modal isOpen={modalVisible} toggle={toggleModal}>
    <ModalHeader
      className="d-flex align-items-center pl-5 pr-5"
      toggle={toggleModal}
    >
      Edit Company Information
    </ModalHeader>
    <ModalBody className="pl-5 pr-5">
      <Formik
        ref={modalRef}
        initialValues={{
          selectedAssociations: values.selectedAssociations
        }}
        onSubmit={onFormSubmit}
        render={formProps => (
          <Form onSubmit={formProps.handleSubmit}>
            {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
            {values.allAssociations.map(association => (
              <Row key={association.name}>
                <InputCheckboxGroup
                  className="col-md-12"
                  label={association.name}
                  name="selectedAssociations"
                  options={association.list}
                  {...formProps}
                />
              </Row>
            ))}
          </Form>
        )}
      />
    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={toggleModal}>
        Cancel
      </Button>{' '}
      <Button color="secondary" className="text-white" onClick={onButtonSubmit}>
        Save
      </Button>{' '}
    </ModalFooter>
  </Modal>
);

export default CompanyDetailsModal;
