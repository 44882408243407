import * as React from 'react';

type Props = {
  children: React.Node,
  text?: string
};

class SectionTitle extends React.Component<Props> {
  renderHeading = () => {
    const { children, text } = this.props;

    if (text) {
      return text;
    }

    return children;
  };

  render() {
    const { className, textColor } = this.props;
    return (
      <h2
        {...this.props}
        className={`text-${textColor ||
          'primary'} font-weight-bold ${className}`}
      >
        {this.renderHeading()}
      </h2>
    );
  }
}

export default SectionTitle;
