import React, { Component } from 'react';
import { connect } from 'react-redux';
import glamorous from 'glamorous';
import { Button } from 'reactstrap';
import GoogleAnalytics from 'react-ga';
import { Cart } from '../assets/img';

const Container = glamorous.div({
  bottom: 27,
  height: '56px',
  position: 'fixed',
  right: 7,
  width: '56px',
  zIndex: 1
});

const StyledButton = glamorous(Button)({
  border: '1px solid white',
  borderRadius: '100%',
  bottom: 30,
  fontSize: 18,
  height: '50px',
  position: 'fixed',
  right: 10,
  width: '50px',
  zIndex: -1
});

const Badge = glamorous.div({
  borderRadius: '50px',
  backgroundColor: '#da314b',
  float: 'right',
  paddingLeft: '6px',
  zIndex: 2
});

const BadgeNumber = glamorous.p({
  color: 'white',
  fontSize: 14,
  margin: 0,
  textAlign: 'right',
  paddingRight: 6,
  right: 6
});

const Icon = glamorous.img({
  bottom: 30,
  height: '50px',
  padding: '14px',
  position: 'fixed',
  right: 11,
  width: '50px'
});

class ShoppingCartButton extends Component {
  handleClick = numberOfQuoteRequests => {
    GoogleAnalytics.event({
      category: 'Conversions',
      action: 'Shopping Cart Clicked',
      value: numberOfQuoteRequests
    });

    this.props.history.push('/review');
  };

  render() {
    if (
      this.props.quoteRequests == null ||
      this.props.quoteRequests.length === 0
    )
      return null;

    return (
      <Container>
        <Badge>
          <BadgeNumber>{this.props.quoteRequests.length}</BadgeNumber>
        </Badge>
        <StyledButton
          id="floating-shopping-cart"
          className="text-white font-weight-bold pr-4 pl-4"
          color="primary"
          onClick={() => this.handleClick(this.props.quoteRequests.length)}
        >
          <Icon src={Cart} alt="Shopping Cart" />
        </StyledButton>
      </Container>
    );
  }
}

const mapStateToProps = ({ search }) => ({
  quoteRequests: search.quoteRequests
});

export default connect(mapStateToProps)(ShoppingCartButton);
