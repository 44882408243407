import { reviews, reviewReply } from '../../services/subscriber/reviewService';

export const fetchReviews = () => async (dispatch, getState) => {
  try {
    const { login: { companyId } } = getState();
    const response = await reviews(companyId);

    dispatch({ type: 'SUBSCRIBER_REVIEWS_SUCCESS', payload: response.reviews });
  } catch (e) {
    dispatch({ type: 'SUBSCRIBER_REVIEWS_FAIL' });
  }
};

export const sendReviewReply = ({
  callback,
  review,
  values
}) => async dispatch => {
  dispatch({ type: 'SUBSCRIBER_REPLY_REVIEW_REQUEST' });
  try {
    const response = await reviewReply(review.companyRatingId, values.response);
    if (!response.success) {
      throw response.message;
    }

    dispatch({ type: 'SUBSCRIBER_REPLY_REVIEW_SUCCESS', payload: review });
    callback();
  } catch (e) {
    const errorMessage =
      typeof e === 'string'
        ? e
        : 'There was a problem replying to this review. Please try again later.';

    dispatch({ type: 'SUBSCRIBER_REPLY_REVIEW_FAIL', payload: errorMessage });
  }
};
