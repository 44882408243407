import withAuthentication, {ROUTE_TYPES} from '../withAuthentication';
import withTracker from '.././withTracker';
import Init4 from '../../containers/subscriber/setup/init4';

const subscriberInit4 = {
    path: '/subscribers/init4',
    component: withTracker(withAuthentication(Init4, ROUTE_TYPES.SUBSCRIBER))
};

export default subscriberInit4;
