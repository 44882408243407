import React from 'react';
import { P } from 'glamorous';

import SpinnerGif from '../assets/img/spinner.gif';

const Spinner = props => (
  <div className="pt-5">
    <div className="d-flex flex-column justify-content-center align-items-center mt-5">
      <img src={SpinnerGif} alt="Loading" />
      <P css={{ marginTop: 10, fontSize: 16 }} className="text-muted">
        {props.text}
      </P>
    </div>
  </div>
);

export default Spinner;
