// @flow

import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import configureStore from './reducers/store';
import Router from './routes';

const { persistor, store } = configureStore();
export const exportedStored = store;

const App = () => (
  <PersistGate persistor={persistor}>
    <Provider store={store}>
      <Router />
    </Provider>
  </PersistGate>
);
export default App;

