const INITIAL_STATE = {
  error: '',
  joinSuccess: false,
  email: ''
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SUBSCRIBE_REQUEST':
      return { ...state, loading: true };
    case 'SUBSCRIBE_REQUEST_SUCCESS':
      return { loading: false, ...action.payload };
    case 'SUBSCRIBE_REQUEST_FAILURE':
      return { loading: false, ...action.payload };
    case "SUBSCRIBE_PROPERTY_UPDATED":
      return { ...state, [action.payload.key]: action.payload.value }
    default:
      return state;
  }
};
