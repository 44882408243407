import API from '../baseService';

export const messages = companyId =>
  API.get(`message/listing/${companyId}`).then(response => response.data);

export const readMessage = messageId =>
  API.post(`/message/${messageId}/markread`).then(response => response);

export const deleteMessage = messageId =>
  API.post(`/message/${messageId}/delete`).then(response => response);

export const messageReply = ({ body, messageId, subject }) =>
  API.post(`/message/${messageId}/reply`, { body, subject }).then(
    response => response
  );

export const sendMessage = ({
  companyMetroId,
  email,
  message,
  name,
  subject
}) =>
  API.post('/message/send', {
    from_email: email,
    from_name: name,
    message,
    company_metro_id: companyMetroId,
    subject: subject
  }).then(response => response);

export const userMessages = () =>
  API.get('/users/messages').then(response => response.data);
