import React, { Component } from 'react';
import { connect } from 'react-redux';
import glamorous from 'glamorous';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';
import {
  Reports,
} from '../../assets/img/icons/svg';

const StyledMenuItem = glamorous.li(
  {
    fontSize: 16,
    padding: 15,
    marginBottom: 10,
    '& a': {
      textDecoration: 'none'
    },
    '& img': {
      marginRight: 10
    },
    '&:hover': {
      backgroundColor: '#eee',
      '& a': {
        color: '#646464'
      }
    }
  },
  ({ active }) => ({
    backgroundColor: active ? '#eee' : 'transparent',
    '& a': {
      color: active ? '#646464' : '#333'
    }
  })
);

class Menu extends Component {
  componentDidMount() {
  }

  renderMenuItem = (linkTo, imgSrc, text, notifications) => {
    return (
      <StyledMenuItem active={window.location.pathname === linkTo}>
        <Link to={linkTo} className="d-flex align-items-middle">
          <img src={imgSrc} alt={text} />
          {text}
        </Link>
      </StyledMenuItem>
    );
  };

  render() {
    return (
      <Col md="12" sm="12" lg="3">
        <Card color="light" style={{ marginBottom: '5em' }}>
          <CardBody>
            <ul className="list-unstyled mt-4 ml-1 mr-1">
              {this.renderMenuItem('/admin/reports', Reports, 'Reports')}
            </ul>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default connect()(Menu);
