import * as React from 'react';
import glamorous from 'glamorous';

type Props = {
  children: React.Node
};

const StyledDescription = glamorous.p({
  maxWidth: 600
});

class SectionDescription extends React.Component<Props> {
  render() {
    return (
      <StyledDescription
        className={`m-auto text-center ${this.props.className}`}
      >
        {this.props.children}
      </StyledDescription>
    );
  }
}

export default SectionDescription;
