import {
  deleteMessage as deleteMessageService,
  messageReply,
  messages,
  readMessage
} from '../../services/subscriber/messagesService';

export const fetchMessages = () => async (dispatch, getState) => {
  try {
    const { login } = getState();
    const response = await messages(login.companyId);

    dispatch({ type: 'FETCH_MESSAGES_SUCCESS', payload: response.messages });
  } catch (e) {
    dispatch({ type: 'FETCH_MESSAGES_FAIL' });
  }
};

export const markMessageAsRead = message => async dispatch => {
  try {
    await readMessage(message.messageId);
    dispatch({ type: 'MARK_MESSAGE_AS_READ_SUCCESS', payload: message });
  } catch (e) {
    dispatch({ type: 'MARK_MESSAGE_AS_READ_FAIL' });
  }
};

export const deleteMessage = (message, callback) => async dispatch => {
  dispatch({ type: 'DELETE_MESSAGE_REQUEST' });
  try {
    await deleteMessageService(message.messageId);
    dispatch({ type: 'DELETE_MESSAGE_SUCCESS', payload: message });
    callback();
  } catch (e) {
    const errorMessage =
      'There was a problem deleting this message. Please try again later.';
    dispatch({ type: 'DELETE_MESSAGE_FAIL', payload: errorMessage });
  }
};

export const clearDeleteMessage = () => ({ type: 'CLEAR_DELETE_MESSAGE' });

export const replyToMessage = ({
  callback,
  message,
  values
}) => async dispatch => {
  dispatch({ type: 'REPLY_TO_MESSAGE_REQUEST' });
  try {
    await messageReply({
      messageId: message.messageId,
      ...values
    });

    dispatch({ type: 'REPLY_TO_MESSAGE_SUCCESS', payload: message });
    callback();
  } catch (e) {
    const errorMessage =
      'There was a problem replying to this message. Please try again later.';
    dispatch({ type: 'REPLY_TO_MESSAGE_FAIL', payload: errorMessage });
  }
};
