import React, { Component } from 'react';
import glamorous from 'glamorous';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';

import { openSubscriberLoginModal } from '../../actions/subscriber/login';

import { Footer, Section } from '../../components';

const StyledText = glamorous.div({
  fontWeight: 400
});

class Unauthenticated extends Component {
  componentWillMount = () => {};

  render() {
    return (
      <StyledText>
        <Section className="pb-5 pt-5 mt-5 pb-2" style={{ minHeight: '30vh' }}>
          <Row>
            <Col xs="12" className="text-center">
              <h1 className="pt-2 mt-4 mb-4">Oops!</h1>
            </Col>
          </Row>
          <Row>
            <Col xs="12" className="text-center">
              <h4>
                You are not currently logged in. <br />Please{' '}
                <a
                  style={{ color: '#E99B3C', cursor: 'pointer' }}
                  onClick={() => this.props.openSubscriberLoginModal()}
                >
                  login
                </a>{' '}
                to access the Subscriber functionality.
              </h4>
            </Col>
          </Row>
        </Section>
        <Footer />
      </StyledText>
    );
  }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
  openSubscriberLoginModal
})(Unauthenticated);
