import GoogleAnalytics from 'react-ga';
import { fetchSearchResults } from '../services/searchService';
import { sendMessage as sendMessageService } from '../services/subscriber/messagesService';

export const fetchResults = ({
  tripType,
  city,
  state,
  freeFormLocation,
  history
}) => async (dispatch, getState) => {
  dispatch({
    type: 'SEARCH_REQUEST'
  });

  const numberOfPeople = getState().search.numberOfPeople;
  const filterZip = getState().search.filterZip;
  let filterZipWithin = getState().search.filterZipWithin;
  let filterAmenities = getState().search.filterAmenities;
  let filterNumberOfPeople = getState().search.filterNumberOfPeople;

  if (filterNumberOfPeople == null) {
    filterNumberOfPeople = getState().search.numberOfPeople;
  }

  if (filterAmenities == null) {
    filterAmenities = [];
  }

  if (filterZipWithin == null) {
    filterZipWithin = 50;
  }

  let filterCities = [];
  let metros = getState().metadata.metros;

  if (state && metros) {
    metros.forEach(metro => {
      if (metro.state === state) {
        filterCities.push(metro.city);
      }
    });
  }

  try {
    const resultsResponse = await fetchSearchResults({
      number_of_people: filterNumberOfPeople,
      trip_type: tripType,
      freeform_location: freeFormLocation,
      city,
      state,
      amenities: filterAmenities,
      zip: filterZip,
      zip_range: filterZipWithin
    });

    if (resultsResponse && resultsResponse.data) {
      dispatch({
        type: 'SEARCH_REQUEST_SUCCESS',
        payload: {
          results: resultsResponse.data.results,
          unfilteredResults: resultsResponse.data.results,
          busTypes: resultsResponse.data.busTypes,
          availableAmenities: resultsResponse.data.availableAmenities,
          longitude: resultsResponse.data.longitude,
          latitude: resultsResponse.data.latitude,
          numberOfPeople,
          tripType,
          departureCity: resultsResponse.data.city,
          departureState: resultsResponse.data.state,
          busTypeSelected: 'all',
          filterNumberOfPeople,
          filterAmenities,
          filterZip,
          filterZipWithin,
          filterCities,
          quoteRequests: getState().search.quoteRequests
        }
      });

      history.push(`/search/${resultsResponse.data.urlFriendlyCity}/${resultsResponse.data.urlFriendlyState}`);
    }
  } catch (error) {
    dispatch({
      type: 'SEARCH_REQUEST_FAILURE',
      payload: error
    });
  }
};

export const filterByBusType = busType => (dispatch, getState) => {
  let filteredResults = getState().search.unfilteredResults;

  filteredResults = getState().search.unfilteredResults.filter(
    result =>
      result.busResults.filter(busResult => busResult.busType === busType)
        .length > 0
  );

  dispatch({
    type: 'SEARCH_RESULTS_FILTERED',
    payload: filteredResults
  });
};

export const refineSearch = () => (dispatch, getState) => {
  let filteredResults = getState().search.unfilteredResults;

  dispatch({
    type: 'SEARCH_RESULTS_FILTERED',
    payload: filteredResults
  });
};

export const changeState = newState => (dispatch, getState) => {
  let filteredCities = [];

  getState().metadata.metros.forEach(metro => {
    if (metro.state === newState) {
      filteredCities.push(metro.city);
    }
  });

  let newCity = filteredCities[0];

  dispatch({
    type: 'STATE_CHANGED',
    payload: {
      filteredCities,
      newState,
      newCity
    }
  });
};

export const updateProperty = (key, value) => dispatch => {
  dispatch({
    type: 'FILTER_PROPERTY_UPDATED',
    payload: { key, value }
  });
};

export const openMoreDetailsModal = result => dispatch => {
  dispatch({
    type: 'MORE_DETAILS_MODAL_OPENED',
    payload: { result }
  });
};

export const closeMoreDetailsModal = () => dispatch => {
  dispatch({
    type: 'MORE_DETAILS_MODAL_CLOSED'
  });
};

export const openReviewsModal = result => dispatch => {
  dispatch({
    type: 'REVIEWS_MODAL_OPENED',
    payload: { result }
  });
};

export const closeReviewsModal = () => dispatch => {
  dispatch({
    type: 'REVIEWS_MODAL_CLOSED'
  });
};

export const openAmenityDetailsModal = busResult => dispatch => {
  dispatch({
    type: 'AMENITY_DETAILS_MODAL_OPENED',
    payload: { busResult }
  });
};

export const closeAmenityDetailsModal = () => dispatch => {
  dispatch({
    type: 'AMENITY_DETAILS_MODAL_CLOSED',
    payload: {}
  });
};

export const clearQuoteRequests = () => dispatch => {
  dispatch({
    type: 'CLEAR_QUOTE_REQUESTS',
    payload: {
      quoteRequests: []
    }
  });
};

export const onAddToQuoteRequest = (id, companyName) => (
  dispatch,
  getState
) => {
  const quoteRequests = getState().search.quoteRequests;
  const intId = parseInt(id, 10);

  GoogleAnalytics.event({
    category: 'Conversions',
    action: 'Add To Quote Request Clicked',
    label: companyName
  });

  if (quoteRequests.filter(r => r.id === intId).length > 0) {
    return;
  }

  dispatch({
    type: 'ADD_TO_QUOTE_REQUEST',
    payload: {
      quoteRequest: { id: intId, companyName }
    }
  });
};

export const onRemoveFromQuoteRequest = (id, companyName) => (
  dispatch,
  getState
) => {
  const quoteRequests = getState().search.quoteRequests;
  const intId = parseInt(id, 10);

  if (quoteRequests.filter(r => r.id === intId).length > 0) {
    GoogleAnalytics.event({
      category: 'Conversions',
      action: 'Remove Quote Request Clicked',
      label: companyName
    });

    dispatch({
      type: 'REMOVE_FROM_QUOTE_REQUEST',
      payload: {
        quoteRequest: { id: intId }
      }
    });
  }
};

export const sendMessage = ({
  values,
  callback,
  companyMetroId,
  errorCallback
}) => async () => {
  try {
    const requestObject = {
      message: values.additionalInfo,
      companyMetroId,
      email: values.email,
      name: values.name,
      subject: values.subject
    };
    await sendMessageService(requestObject);
    callback();
  } catch (e) {
    errorCallback();
  }
};
