const INITIAL_STATE = {
  errorMessage: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'TOGGLE_SIGNUP_MODAL':
    case 'LOGIN_MODAL_CLOSED_CREATE_ACCOUNT_OPEN':
      return { ...state };

    case 'USER_SIGNUP_REQUEST':
      return { ...state, errorMessage: '' };

    case 'USER_SIGNUP_SUCCESS':
      return { ...state };

    case 'USER_SIGNUP_FAIL':
      return { ...state, errorMessage: action.payload };

    default:
      return state;
  }
};
