import withAuthentication, {ROUTE_TYPES} from '../withAuthentication';
import withTracker from '.././withTracker';
import Init1 from '../../containers/subscriber/setup/init';

const subscriberInit1 = {
    path: '/subscribers/init',
    component: withTracker(withAuthentication(Init1, ROUTE_TYPES.SUBSCRIBER))
};

export default subscriberInit1;
