import React, { Component } from 'react';
import { connect } from 'react-redux';
import glamorous from 'glamorous';
import { Alert, Button, Form }  from 'reactstrap';
import { Formik } from 'formik';
import Yup from 'yup';
import { Input } from '../../components';

import { signup } from '../../actions/user/userSignup';

const StyledButtonContainer = glamorous.div({
  margin: '10px 0',
  textAlign: 'center'
});

const StyledButton = glamorous(Button)({
  fontSize: 20
});

class Join extends Component {
  onFormSubmit = values => {
    this.props.signup({ values, history: this.props.history });
  };

  render() {
    const { errorMessage } = this.props;
    return (
      <div className="pt-5 mt-5 pl-3 pr-3 lg-container">
        <div className="lg-container pb-3 pl-3 pr-3">
          <div style={{ backgroundColor: '#e9ecef', padding: '2em' }}>
            <h3
              className="font-weight-bolder text-primary mb-0"
              style={{ padding: '1em 0em' }}
            >
              User Registration
            </h3>
            <Formik
              validationSchema={validationSchema}
              onSubmit={this.onFormSubmit}
              render={formProps => (
                <Form onSubmit={formProps.handleSubmit}>
                  {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                  <Input
                    name="firstName"
                    label="First Name"
                    required
                    {...formProps}
                  />
                  <Input
                    name="lastName"
                    label="Last Name"
                    required
                    {...formProps}
                  />
                  <Input
                    name="company"
                    label="Company / Group / Organization"
                    {...formProps}
                  />
                  <Input
                    name="about"
                    label="Tell Us About Yourself"
                    type="textarea"
                    row="3"
                    {...formProps}
                  />
                  <Input name="email" label="Email" required {...formProps} />
                  <Input
                    name="password"
                    label="Password (8 character minimum)"
                    required
                    type="password"
                    {...formProps}
                  />
                  <Input
                    name="confirmPassword"
                    label="Password Confirmation"
                    required
                    type="password"
                    {...formProps}
                  />
                  <StyledButtonContainer>
                    <StyledButton
                      className="text-white"
                      onClick={formProps.submitForm}
                    >
                      Create My Account
                    </StyledButton>
                  </StyledButtonContainer>
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}

Yup.addMethod(Yup.mixed, 'sameAs', function(ref, message) {
  return this.test('sameAs', message, function(value) {
    const other = this.resolve(ref);
    return !other || !value || value === other;
  });
});

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string()
    .email('Must use valid email')
    .required('Email is required'),
  password: Yup.string().min(8, 'Password must be at least 8 characters long'),
  confirmPassword: Yup.string()
    .sameAs(Yup.ref('password'), 'Passwords must match')
    .required('Confirmationpassword is required')
});

const mapStateToProps = ({ signup: { errorMessage } }) => ({
  errorMessage,
});

export default connect(mapStateToProps, {
  signup,
})(Join);
