import API from './baseService';

export const TRACKING_TYPES = {
  WEBSITE: '1',
  YOUTUBE: '3',
  FACEBOOK: '4',
  TWITTER: '5',
  MORE_DETAILS: '6',
  PHONE: '9',
  INSTAGRAM: '13',
  CONTACT_US: '16'
};

export const trackAnalytics = ({ companyMetroId, trackingType }) =>
  API.post('/tracking/', {
    company_metro_id: companyMetroId,
    tracking_type: trackingType,
    count: 1
  });
