import withAuthentication, { ROUTE_TYPES } from '../withAuthentication';
import withTracker from '.././withTracker';
import Reports from '../../containers/admin/Reports';

const adminReports = {
  path: '/admin/reports',
  component: withTracker(withAuthentication(Reports, ROUTE_TYPES.ADMIN))
};

export default adminReports;
