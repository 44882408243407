import React from 'react';
import {
  Alert,
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import { Formik } from 'formik';
import Yup from 'yup';
import { Input } from '../../../components';

const EditUserProfileModal = ({
  errorMessage,
  modalRef,
  modalVisible,
  onButtonSubmit,
  onFormSubmit,
  toggleModal,
  values
}) => (
  <Modal isOpen={modalVisible} toggle={toggleModal}>
    <ModalHeader
      className="d-flex align-items-center pl-5 pr-5"
      toggle={toggleModal}
    >
      Edit Listing Information
    </ModalHeader>
    <ModalBody className="pl-5 pr-5">
      <Formik
        ref={modalRef}
        initialValues={{
          about: values.about,
          firstName: values.firstName,
          lastName: values.lastName,
          company: values.company
        }}
        validationSchema={validationSchema}
        onSubmit={onFormSubmit}
        render={formProps => (
          <Form onSubmit={formProps.handleSubmit}>
            {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
            <Input
              name="firstName"
              label="First Name"
              required
              {...formProps}
            />
            <Input name="lastName" label="Last Name" required {...formProps} />
            <Input
              name="company"
              label="Company / Group / Organization"
              required
              {...formProps}
            />
            <Input
              name="about"
              label="About Me"
              type="textarea"
              rows="3"
              {...formProps}
            />
          </Form>
        )}
      />
    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={toggleModal}>
        Cancel
      </Button>{' '}
      <Button color="secondary" className="text-white" onClick={onButtonSubmit}>
        Save
      </Button>{' '}
    </ModalFooter>
  </Modal>
);

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required')
});

export default EditUserProfileModal;
