export const INITIAL_STATE = {
  bidConfirmation: {
    proratedTotal: '',
    currentPosition: '',
    currentPrice: '',
    errorMessage: '',
    guid: '',
    metroName: '',
    newPosition: '',
    newPrice: '',
    proratedAmount: '',
    renewalDate: ''
  },
  results: []
};

export const BID_TYPES = {
  SUBSCRIBER_LISTINGS_FAILURE: 'SUBSCRIBER_LISTINGS_FAILURE',
  SUBSCRIBER_LISTINGS_REQUEST: 'SUBSCRIBER_LISTINGS_REQUEST',
  SUBSCRIBER_LISTINGS_SUCCESS: 'SUBSCRIBER_LISTINGS_SUCCESS',
  SUBSCRIBER_BIDDING_POSITIONS_FAILURE: 'SUBSCRIBER_BIDDING_POSITIONS_FAILURE',
  SUBSCRIBER_BIDDING_POSITIONS_REQUEST: 'SUBSCRIBER_BIDDING_POSITIONS_REQUEST',
  SUBSCRIBER_BIDDING_POSITIONS_SUCCESS: 'SUBSCRIBER_BIDDING_POSITIONS_SUCCESS',
  BID_CONFIRMATION_REQUEST: 'BID_CONFIRMATION_REQUEST',
  BID_CONFIRMATION_SUCCESS: 'BID_CONFIRMATION_SUCCESS',
  BID_CONFIRMATION_FAILURE: 'BID_CONFIRMATION_FAILURE',
  SUBMIT_BID_CONFIRMATION_REQUEST: 'SUBMIT_BID_CONFIRMATION_REQUEST',
  SUBMIT_BID_CONFIRMATION_SUCCESS: 'SUBMIT_BID_CONFIRMATION_SUCCESS',
  SUBMIT_BID_CONFIRMATION_FAILURE: 'SUBMIT_BID_CONFIRMATION_FAILURE',
  CLEAR_BID_CONFIRMATION: 'CLEAR_BID_CONFIRMATION'
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BID_TYPES.SUBSCRIBER_LISTINGS_REQUEST:
      return { ...state, metrosInRadius: null };
    case BID_TYPES.SUBSCRIBER_LISTINGS_SUCCESS:
      return { ...state, ...action.payload, metrosInRadius: null };
    case BID_TYPES.SUBSCRIBER_LISTINGS_FAILURE:
      return { ...state, ...action.payload };
    case BID_TYPES.SUBSCRIBER_BIDDING_POSITIONS_REQUEST:
      return { ...state };
    case BID_TYPES.SUBSCRIBER_BIDDING_POSITIONS_SUCCESS:
      return { ...state, ...action.payload };
    case BID_TYPES.SUBSCRIBER_BIDDING_POSITIONS_FAILURE:
      return { ...state, ...action.payload };
    case BID_TYPES.BID_CONFIRMATION_REQUEST:
      return { ...state };
    case BID_TYPES.BID_CONFIRMATION_SUCCESS: {
      const confirmationMetro = action.payload.newMetros[0];
      return {
        ...state,
        bidConfirmation: {
          ...state.bidConfirmation,
          proratedTotal: action.payload.proratedTotal,
          guid: action.payload.guid,
          ...confirmationMetro
        }
      };
    }
    case BID_TYPES.BID_CONFIRMATION_FAILURE:
      return { ...state };
    case BID_TYPES.SUBMIT_BID_CONFIRMATION_REQUEST:
      return {
        ...state,
        bidConfirmation: {
          ...state.bidConfirmation,
          errorMessage: ''
        }
      };
    case BID_TYPES.SUBMIT_BID_CONFIRMATION_FAILURE:
      return {
        ...state,
        bidConfirmation: {
          ...state.bidConfirmation,
          errorMessage: action.payload
        }
      };
    case BID_TYPES.CLEAR_BID_CONFIRMATION:
      return {
        ...state,
        bidConfirmation: {
          ...INITIAL_STATE.bidConfirmation
        }
      };
    default:
      return state;
  }
};
