import React from 'react';
import {
    Alert,
    Button,
    Form,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from 'reactstrap';
import {Formik} from 'formik';
import Yup from 'yup';
import {Input, InputRadioGroup} from '../../../components';

const ProfileManagementModal = ({
                                    errorMessage,
                                    modalRef,
                                    modalVisible,
                                    onButtonSubmit,
                                    onFormSubmit,
                                    toggleModal,
                                    values
                                }) => (
    <Modal isOpen={modalVisible} toggle={toggleModal}>
        <ModalHeader
            className="d-flex align-items-center pl-5 pr-5"
            toggle={toggleModal}
        >
            Edit Company Information
        </ModalHeader>
        <ModalBody className="pl-5 pr-5">
            <Formik
                ref={modalRef}
                initialValues={{
                    city: values.information.city || '',
                    contactEmail: values.information.contactEmail || '',
                    contactName: values.information.contactName || '',
                    contactPhone: values.information.contactPhone || '',
                    description: values.information.description || '',
                    hoursOfOperationMon: values.information.hoursOfOperationMon || '',
                    hoursOfOperationSat: values.information.hoursOfOperationSat || '',
                    hoursOfOperationSum: values.information.hoursOfOperationSum || '',
                    facebookLink: values.information.facebookLink || '',
                    hasReceptiveServices:
                        values.information.hasReceptiveServices || false,
                    instagramLink: values.information.instagramLink || '',
                    isTourOperator: values.information.isTourOperator || false,
                    name: values.information.name || '',
                    state: values.information.state || '',
                    street: values.information.street || '',
                    twitterLink: values.information.twitterLink || '',
                    youtubeLink: values.information.youtubeLink || '',
                    website: values.information.website || '',
                    zip: values.information.zip || '',
                    saveToAllListings: false
                }}
                validationSchema={validationSchema}
                onSubmit={onFormSubmit}
                render={formProps => (
                    <Form onSubmit={formProps.handleSubmit}>
                        {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                        <Row>
                            <Input
                                className="col-md-6"
                                label="Name"
                                name="name"
                                disabled
                                {...formProps}
                            />
                            <Input
                                className="col-md-6"
                                label="Address"
                                name="street"
                                {...formProps}
                            />
                        </Row>
                        <Row>
                            <Input
                                className="col-md-6"
                                label="City"
                                name="city"
                                required
                                {...formProps}
                            />
                            <Input
                                className="col-md-6"
                                label="State or Province"
                                name="state"
                                required
                                {...formProps}
                            />
                        </Row>
                        <Row>
                            <Input
                                className="col-md-6"
                                label="Contact Name"
                                name="contactName"
                                required
                                {...formProps}
                            />
                            <Input
                                className="col-md-6"
                                label="Zip"
                                name="zip"
                                required
                                {...formProps}
                            />
                        </Row>
                        <Row>
                            <Input
                                className="col-md-6"
                                label="Email"
                                name="contactEmail"
                                required
                                {...formProps}
                            />
                            <Input
                                className="col-md-6"
                                label="Website"
                                name="website"
                                {...formProps}
                            />
                        </Row>
                        <Row>
                            <Input
                                className="col-md-6"
                                label="Phone"
                                name="contactPhone"
                                required
                                {...formProps}
                            />

                        </Row>
                        <Row>
                            <Input
                                className="col-md-6"
                                label="HoursOfOperation(MON-FRI)"
                                name="hoursOfOperationMon"
                                type="text"
                                placeholder="MON-FRI：9:00 AM - 6:00PM"
                                required
                                {...formProps}
                            />
                            <Input
                                className="col-md-6"
                                label="HoursOfOperation(SAT)"
                                name="hoursOfOperationSat"
                                required
                                placeholder="SAT：9:00 AM - 12:00 AM"
                                type="text"
                                {...formProps}
                            />
                        </Row>
                        <Row>
                            <Input
                                className="col-md-6"
                                label="HoursOfOperation(SUN)"
                                name="hoursOfOperationSum"
                                type="text"
                                placeholder="SUN：CLOSE"
                                required
                                {...formProps}
                            />
                        </Row>
                        <Row>
                            <InputRadioGroup
                                className="col-md-6"
                                label="Are you a Tour Operator?"
                                name="isTourOperator"
                                options={[
                                    {name: 'Yes', key: true},
                                    {name: 'No', key: false}
                                ]}
                                required
                                {...formProps}
                            />
                            <InputRadioGroup
                                className="col-md-6"
                                label="Do you offer Receptive Services?"
                                name="hasReceptiveServices"
                                options={[
                                    {name: 'Yes', key: true},
                                    {name: 'No', key: false}
                                ]}
                                required
                                {...formProps}
                            />
                        </Row>
                        <Row>
                            <Input
                                className="col-md-12"
                                label="Description"
                                name="description"
                                required
                                type="textarea"
                                rows={5}
                                {...formProps}
                            />
                        </Row>
                        <Row>
                            <Input
                                className="col-md-6"
                                label="Facebook Link"
                                name="facebookLink"
                                {...formProps}
                            />
                            <Input
                                className="col-md-6"
                                label="Twitter Link"
                                name="twitterLink"
                                {...formProps}
                            />
                        </Row>
                        <Row>
                            <Input
                                className="col-md-6"
                                label="Instagram Link"
                                name="instagramLink"
                                {...formProps}
                            />
                            <Input
                                className="col-md-6"
                                label="YouTube Link"
                                name="youtubeLink"
                                {...formProps}
                            />
                        </Row>
                    </Form>
                )}
            />
        </ModalBody>
        <ModalFooter>
            <Button color="primary" onClick={toggleModal}>
                Cancel
            </Button>{' '}
            <Button color="secondary" className="text-white" onClick={() => onButtonSubmit(false)}>
                Save
            </Button>{' '}
            <Button color="danger" className="text-white" onClick={() => {
                if (window.confirm('This will update all listings with this information, are you sure you want to do this?')) {
                    onButtonSubmit(true);
                }
            }}>
                Save to All Listings
            </Button>{' '}
        </ModalFooter>
    </Modal>
);

const validationSchema = Yup.object().shape({
    city: Yup.string().required('Company city is required'),
    contactEmail: Yup.string().email('Email is not valid').required('Contact email is required'),
    contactName: Yup.string().required('Contact name is required'),
    contactPhone: Yup.string().required('Contact phone is required'),
    website: Yup.string().trim().matches(/[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/, 'Website must be a valid url'),
    description: Yup.string().required('Description is required'),
    hoursOfOperationMon: Yup.string().trim().required('HoursOfOperation(MON-FRI) is required'),
    hoursOfOperationSat: Yup.string().trim().required('HoursOfOperation(SAT) is required'),
    hoursOfOperationSum: Yup.string().trim().required('HoursOfOperation(SUN) is required'),
    hasReceptiveServices: Yup.string().required(
        'Receptive Services answer is required'
    ),
    isTourOperator: Yup.string().required('Tour operator answer is required'),
    state: Yup.string().required('Company state is required'),
    street: Yup.string().required('Company address is required'),
    zip: Yup.string().required('Company zip is required')
});

export default ProfileManagementModal;
