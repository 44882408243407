import React, { Component } from 'react';
import {
  Alert,
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import glamorous from 'glamorous';
import ReactCrop, { makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { InputFileUpload } from '../../../components';
import { cropImage } from '../../../utils/imageUtils';
import loadImage from 'blueimp-load-image/js';

const StyledImage = glamorous.img({
  width: '100%',
  height: 'auto'
});

class CompanyAdditionalPhotoModal extends Component {
  state = {
    crop: {},
    file: undefined,
    croppedImage: undefined
  };

  onSubmit = () => {
    if (this.state.croppedImage) {
      this.props.onButtonSubmit(this.state.croppedImage, this.onClose);
    }
  };

  onClose = () => {
    this.props.toggleModal();
    this.setState({ file: undefined, croppedImage: undefined });
  };

    dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
  }

  onFileChange = file => {

    let f = this.dataURLtoFile(file.base64,"test.jpeg")

    let self = this;
    loadImage( f, (img) => {
      var base64data = img.toDataURL('image/jpeg');
      self.setState({ file: base64data });
      },
        { orientation: true, }
        );
    // this.setState({ file: file.base64 });
  };

  onCrop = crop => {
    this.setState({ crop });
  };

  onComplete = crop => {
    const croppedImage = cropImage({ crop, file: this.state.file });
    this.setState({ croppedImage });
  };

  onImageLoaded = image => {
    const crop = {
      aspect: 11 / 7,
      x: 5,
      y: 5,
      width: 90,
      height: 95
    };

    this.setState({
      crop: makeAspectCrop(crop, image.width / image.height)
    });
    this.onComplete(crop);
  };

  render() {
    const { modalVisible, toggleModal } = this.props;
    return (
      <Modal isOpen={modalVisible} toggle={toggleModal}>
        <ModalHeader
          className="d-flex align-items-center pl-5 pr-5"
          toggle={toggleModal}
        >
          Add An Additional Photo
        </ModalHeader>
        <ModalBody className="pl-5 pr-5">
          <Row>
            <InputFileUpload
              className="col-md-12"
              label="Upload and crop an image"
              name="profileImage"
              onChange={this.onFileChange}
            />
          </Row>
          <Row>
            <Col xs="6">
              {this.state.file && (
                <ReactCrop
                  src={this.state.file}
                  onChange={this.onCrop}
                  onComplete={this.onComplete}
                  onImageLoaded={this.onImageLoaded}
                  crop={this.state.crop}
                />
              )}
            </Col>
            {this.state.croppedImage && (
              <Col xs="6">
                <StyledImage src={this.state.croppedImage} />
                <h5 className="text-center">Cropped Image</h5>
              </Col>
            )}
          </Row>
          <Row style={{"margin-left":" 15px","margin-top":" 15px"}}>
            <Alert color="danger">
              All photos should be formatted as jpeg and should be no larger than 10mb。All videos should be links to YouTube only. All photos and videos must adhere to the standards of our terms and conditions.
            </Alert>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.onClose}>
            Cancel
          </Button>{' '}
          <Button
            color="secondary"
            className="text-white"
            onClick={this.onSubmit}
          >
            Save
          </Button>{' '}
        </ModalFooter>
      </Modal>
    );
  }
}

export default CompanyAdditionalPhotoModal;
