import React, { Component } from 'react';

import { StarEnabled, StarDisabled } from '../assets/img';

class RatingStars extends Component {
  render() {
    const { averageRating } = this.props;

    if (!averageRating) return null;

    var stars = [];

    for (var i = 1; i <= 5; i++) {
      if (averageRating >= i) {
        stars.push(
          <img key={i} className="ml-1" src={StarEnabled} alt="star" />
        );
      } else {
        stars.push(
          <img key={i} className="ml-1" src={StarDisabled} alt="star" />
        );
      }
    }

    return <div style={{ marginLeft: -4 }}>{stars}</div>;
  }
}

export default RatingStars;
