import API from '../baseService';
import axios from 'axios';

export const listingsRecommendResults = companyId =>
    API.get('/subscribers/listings/recommend/metros', { companyId: companyId });

export const listingsResults = companyId =>
  API.get('/subscribers/listings', { company_id: companyId });

export const listingsPendingResults = companyId =>
  API.get('/subscribers/listings_pending', { company_id: companyId });

export const searchForNewMetros = params =>
  API.post('/subscribers/listings/search', { ...params });

export const findMetrosInRadius = params =>
  API.post('/subscribers/listings/findmetros', { ...params });

export const addNewMetroResults = params =>
  API.post('/subscribers/listings/addmetro', { ...params });

export const removeMetroResults = params =>
  API.post('/subscribers/listings/removemetro', { ...params });

export const selfPayToAddMetroResults = params =>
     axios.post(`${ process.env.REACT_APP_API_URL}/subscribers/self_pay`,params)

export const selfPayToAddMetrosResults = params =>
     axios.post(`${ process.env.REACT_APP_API_URL}/subscribers/self_pay_multiple`,params)

export const validPaymentInfo = companyId =>
    API.get('/subscribers/valid_pay', { company_id: companyId });


