import React from "react";
import glamorous from "glamorous";
import { Input } from "reactstrap";

const StyledInput = glamorous(Input)({
  backgroundClip: "inherit",
  border: "1px solid #fff",
  borderLeft: "1px solid #eee",
  borderRadius: 0,
  fontSize: 18,
  fontWeight: 300,
  height: 58,
  outline: "none !important"
});

const LargeInput = props => {
  // TODO: Handle Addons
  return (
    <StyledInput
      autoComplete="false"
      className="w-100"
      type={props.type}
      name={props.name}
      placeholder={props.placeholder}
      onChange={props.onChange}
      value={props.value}
    />
  );
};

export default LargeInput;
