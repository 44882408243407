import React from 'react';
import { Button } from 'reactstrap';

const QuoteRequestButton = ({
  id,
  companyName,
  onAddToQuoteRequest,
  onRemoveFromQuoteRequest,
  quoteRequests
}) => {
  if (!quoteRequests) return null;

  const intId = parseInt(id, 10);

  if (quoteRequests.filter(r => r.id === intId).length > 0) {
    return (
      <Button
        color="danger"
        style={{ margin: '.75em' }}
        className="text-white align-self-end"
        onClick={() => onRemoveFromQuoteRequest(id, companyName)}
      >
        Remove Quote Request
      </Button>
    );
  }

  return (
    <Button
      style={{ margin: '.75em' }}
      className="text-white align-self-end"
      onClick={() => onAddToQuoteRequest(id, companyName)}
    >
      Add To Quote Request
    </Button>
  );
};

export default QuoteRequestButton;
