const INITIAL_STATE = {
  departureDate: null,
  departureTime: null,
  destinationCity: '',
  destinationState: '',
  basicNameOfGroup: '',
  basicFirstName: '',
  basicLastName: '',
  basicPhone: '',
  basicEmail: '',
  notes: '',
  originLatitude: '',
  originLongitude: '',
  returnDate: null,
  returnTime: null,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "REVIEW_REQUEST":
      return { loading: true, ...state };
    case "REVIEW_REQUEST_SUCCESS":
      return { loading: false, ...action.payload };
    case "REVIEW_PROPERTY_UPDATED": {
      return { ...state, [action.payload.key]: action.payload.value }
    }
    case 'REVIEW_FETCH_DESTINATION_REQUEST':
      return { ...state };
    case 'REVIEW_FETCH_DESTINATION_REQUEST_SUCCESS':
      return { ...state, ...action.payload };
    case 'REVIEW_FETCH_DESTINATION_REQUEST_FAILURE':
      return { ...state, ...action.payload };
    case "SUBMIT_REQUEST_SUCCESS":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};